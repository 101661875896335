import { Field, useFormikContext } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import './_option.scss';
import { ICreateSurvey } from '../../CreateSurveyHelper';
import { useUserSurveySubQuestionMutation } from '../../../../../../api/services/surveys/surveyEndpoints';
import Question from '../Question/Question';
import SubQuestion from '../Question/SubQuestion';
import { okayMessage } from '../../../_global/components/Modal/ConfirmMessage';
import { KTCard } from '../../../../../../_metronic/helpers';

type Props = {
  questionType: number;
  option: any;
  questionIndex: number;
  optionIndex: number;
  onCompleteOption?: () => void;
  isActive?: boolean;
  selectedCountData?: number;
  questionid: number;
  mainQuestionSelectcoun?:number
};

const SubOption: FC<Props> = ({
  questionIndex,
  questionType,
  option,
  onCompleteOption,
  isActive,
  selectedCountData,
  questionid,
  mainQuestionSelectcoun
}) => {
  const { values, setFieldValue } = useFormikContext<ICreateSurvey>();
  const question = values.questions[questionIndex];
  const [getSubQuestion, { data: subQuestionData }] = useUserSurveySubQuestionMutation();
  const selectedCount = values.questions[questionIndex]?.question_checkbox_ids_sub?.length || 0;
  const [stringValue, setStringValue] = useState<any>('');

  const isChecked = () => {
    if (values.questions) {
      if (questionType === 2) {
        return values?.questions[questionIndex]?.question_radio_id_sub === option.choiceid.toString() ? true : false;
      } else if (questionType === 3) {
        return (
          values?.questions[questionIndex]?.question_checkbox_ids_sub?.find((i) => i === option.choiceid.toString()) ===
          option.choiceid.toString()
        );
      }
    }
  };

  const handleClick = async () => {
    if (onCompleteOption) {
      onCompleteOption();
    }
    if (isChecked() && questionType !== 3) {
      return;
    }
    setFieldValue(`questions[${questionIndex}].passablesub`, 1);
    if (questionType === 2) {
      setFieldValue(`questions[${questionIndex}].question_radio_id_sub`, option.choiceid.toString());
      await getSubQuestion({ choiceId: option.choiceid, surveyId: values.questions[questionIndex].surveyid });
      if (onCompleteOption) {
        onCompleteOption();
      }
    } else if (questionType === 3) {
      const isRemove = question.question_checkbox_ids_sub?.some((i) => i === option.choiceid.toString());
      if (isRemove) {
        const newOptions = question.question_checkbox_ids_sub?.filter((i) => i !== option.choiceid.toString());
        setFieldValue(`questions[${questionIndex}].question_checkbox_ids_sub`, newOptions);
        if (onCompleteOption) {
          onCompleteOption();
        }
      } else {
        if (
          questionType === 3 &&
          questionid === values.questions[questionIndex]?.subquestionid &&
          selectedCountData !== undefined &&
          selectedCountData > 1 &&
          selectedCount >= selectedCountData
        ) {
          if (onCompleteOption) {
            onCompleteOption();
          }
          okayMessage('Hata', `En fazla ${selectedCountData} seçenek seçebilirsiniz.`);
          return;
        }
        const newOptions = question.question_checkbox_ids_sub || [];
        newOptions?.push(option.choiceid.toString());
        setFieldValue(`questions[${questionIndex}].question_checkbox_ids_sub`, newOptions);
        if (onCompleteOption) {
          onCompleteOption();
        }
        await getSubQuestion({ choiceId: option.choiceid, surveyId: values.questions[questionIndex].surveyid });
      }
    }
  };

  const handleChange = (e: any, optionIndex: number, type: number) => {
    const newOptions = values.questions[questionIndex].question_option_val || [];
    newOptions[optionIndex] = e.target.value;
    setFieldValue(`questions[${questionIndex}].question_option_val`, newOptions);
    setStringValue(e.target.value);
    setFieldValue(`questions[${questionIndex}].passablesub`, 1);
  };
  function getQuestionOptionValueLength(values: any, questionIndex: number): string {
    return `${stringValue.length}/500 karakter`;
  }

  return (
    <div
      className={`border border-dashed mb-4 rounded-1 px-5 py-4 d-flex flex-column align-items-start cursor-pointer ${
        isChecked() ? 'border-info bg-light-info' : 'border-gray-300 bg-white'
      }`}
    >
      <div className="d-flex align-items-center" onClick={handleClick}>
        {option?.multimediapath !== undefined && option?.multimediapath != null && (
          <KTCard className="event-card image-card-style">
            <img
              width={60}
              height={60}
              alt="market_image"
              src={option?.multimediapath ?? ''}
              style={{ objectFit: 'contain', padding: '10px' }}
              className="rounded-3 image-card-style"
            />
          </KTCard>
        )}
        <span className="form-check form-check-custom form-check-solid me-4">
          <Field
            className="form-check-input cursor-pointer"
            type={questionType === 2 ? 'radio' : 'checkbox'}
            value={values?.questions![questionIndex]?.choicelist?.find((i: any) => i === option.choiceid)}
            checked={isChecked()}
          />
        </span>
        <div className="fs-5 fw-bold">{option.choicetext}</div>
      </div>
      {/* Eğer isother === 1 ise bir textarea göster */}
      {option.isother === 1 && isChecked() && (
        <div style={{ width: '42vw', display: 'block' }}>
          <Field
            as="textarea"
            className="form-control form-control-lg resize-none"
            onChange={(e: any) => handleChange(e, question.questionid, 1)}
            rows={3}
            placeholder="Metin giriniz."
            value={stringValue}
            maxLength={500}
          />
          <div>{getQuestionOptionValueLength(values, questionIndex)}</div>
        </div>
      )}
      {subQuestionData !== undefined &&
        subQuestionData != null &&
        subQuestionData.questions !== undefined &&
        subQuestionData.questions != null && (
          <div className="sub-questions mt-3">
            {subQuestionData?.questions.map(
              (subQuestion: { questionid: React.Key | null | undefined }, index: number) => (
                <div key={subQuestion.questionid} className="w-100">
                  <SubQuestion
                    question={subQuestion}
                    questionIndex={index}
                    id={values.questions[questionIndex].surveyid}
                    onNext={() => {}}
                    onComplete={() => {}}
                    isLastQuestion={false}
                    isSubQuestion={true}
                  />
                </div>
              ),
            )}
          </div>
        )}
    </div>
  );
};

export default SubOption;
