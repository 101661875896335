import React, { FC, Fragment } from 'react';
import { useGetGameDetailQuery } from '../../../../../../api/services/games/gamesEndpoints';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { useAppDispatch, useAppSelector } from '../../../../../../api/store/hooks';
import { gamesSelector, setAnswersModal } from '../../../../../../api/features/games/gamesSlice';
import Answers from './components/Answers/Answers';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../../../components/Loading';
import { FormattedMessage } from 'react-intl';

type Props = {
  id: number;
};

const ResultDetail: FC<Props> = ({ id }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isOpenAnswersTab = useAppSelector((state) => gamesSelector(state.games).isOpenAnswersTab);
  const { data, isLoading: fetch, isFetching: refetch } = useGetGameDetailQuery(id);
  const isLoading = fetch || refetch;

  const { game_result, user_result, main_result } = data || {};
  const { congratulation_image } = main_result || {};
  const { added_user_id, name, game_name, game_answered_time, game_answered_correct, rank, branch, avatar } =
    user_result || {};

  return (
    <Fragment>
      {isLoading ? (
        <Loading />
      ) : !isOpenAnswersTab ? (
        <div>
          {congratulation_image && (
            <div className="border mb-8 rounded-3 h-255px">
              <img
                src={congratulation_image}
                alt="tebrikler gorseli"
                width={860}
                height={255}
                className="rounded-3 w-100"
                onError={(e: any) => {
                  e.target.onerror = null;
                  e.target.src = toAbsoluteUrl('/media/svg/KutlamaError.svg');
                }}
                style={{ objectFit: 'cover' }}
              />
            </div>
          )}
          <h1>
            <FormattedMessage id="GAMES.CONGRATS" values={{ name: name }} />
          </h1>
          <div className="text-gray-500 mb-8">
            <FormattedMessage
              id="GAMES.RESULT_FINISH_GAME_MESSAGE"
              defaultMessage={`${game_name} oyununu ${game_answered_time} sürede ${game_answered_correct} doğru yaparak ${rank}.sırada
            tamamladın.`}
              values={{ game_name, game_answered_time, game_answered_correct, rank }}
            />
          </div>
          <button onClick={() => dispatch(setAnswersModal(true))} className="btn btn-info px-8 py-4 mb-8">
            <FormattedMessage id="GAMES.VIEW_ANSWERS" defaultMessage="Cevapları Gör" />
          </button>
          <div className="border-gray-300 border-bottom-dashed border-bottom-1 mb-8" />
          <div className="border p-8 rounded-3 mb-8">
            <div className="fw-bold fs-4 mb-4">
              <FormattedMessage id="GAMES.MY_RANK" defaultMessage="Derecem" />
            </div>
            <div className="d-flex  align-items-center justify-content-between mb-8">
              <div
                className="d-flex align-items-center gap-3 cursor-pointer"
                onClick={() => navigate(`/profile/${added_user_id}`)}
              >
                <div className="text-gray-500 fw-bold">{rank}.</div>
                <img
                  src={avatar || toAbsoluteUrl('/media/avatars/Default Avatar.svg')}
                  alt="user"
                  width={50}
                  height={50}
                  className="rounded-3"
                />
                <div>
                  <div className="fw-bold text-dark">{name}</div>
                  <span className="text-gray-500 fw-bold">{branch}</span>
                </div>
              </div>
              <div>
                <div className="fw-bold">
                  <FormattedMessage
                    id="GAMES.COUNT_OF_CORRET"
                    defaultMessage={`${game_answered_correct} Doğru`}
                    values={{ game_answered_correct }}
                  />
                </div>
                <div>{game_answered_time}</div>
              </div>
            </div>
            <div className="border-gray-300 border-bottom-dashed border-bottom-1 mb-8" />
            <div className="fw-bold fs-4 mb-1">
              <FormattedMessage id="GAMES.OVERALL_RANKING" defaultMessage="Genel Sıralama" />
            </div>
            <span className="text-gray-500 fw-bold">
              <FormattedMessage
                id="GAMES.TOTAL_PARTICIPANTS"
                defaultMessage={`Toplam ${game_result?.length} katılımcı`}
                values={{
                  totalParticipants: game_result?.length,
                }}
              />
            </span>
            <div className="row">
              <div className="card-body py-3">
                {/* begin::Table container */}
                <div className="table-responsive d-block px-2 overflow-auto" style={{ maxHeight: 400 }}>
                  {/* begin::Table */}
                  <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    {/* begin::Table head */}
                    <thead className="sticky-top bg-white z-4">
                      <tr className="fw-bold text-muted">
                        <th className="w-25px">
                          <FormattedMessage id="GAMES.RANK" defaultMessage="Sıra" />
                        </th>
                        <th className="min-w-410px">
                          <FormattedMessage id="GAMES.NAME_AND_COMPANY" defaultMessage="İsim & İşletme" />
                        </th>
                        <th className="min-w-100px text-end">
                          <FormattedMessage id="GAMES.CORRECT_ANSWERS_AND_TIME" defaultMessage="Doğru Sayısı ve Süre" />
                        </th>
                      </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                      {game_result?.map((i: any, index: number) => (
                        <tr key={i.id}>
                          <td>{index + 1}</td>
                          <td>
                            <div
                              className="d-flex align-items-center cursor-pointer"
                              onClick={() => navigate(`/profile/${i.user_id}`)}
                            >
                              <div className="symbol symbol-45px me-5">
                                <img
                                  src={i.avatar || toAbsoluteUrl('/media/avatars/Default Avatar.svg')}
                                  alt="avatar"
                                />
                              </div>
                              <div className="d-flex justify-content-start flex-column">
                                <span className="text-dark fw-bold text-hover-info fs-6">{i.user_name}</span>
                                <span className="text-muted fw-semibold text-muted d-block fs-7">{i.branch}</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex justify-content-end flex-shrink-0">
                              <div>
                                <div className="fw-bold">
                                  <FormattedMessage
                                    id="GAMES.COUNT_OF_CORRET"
                                    defaultMessage={`${i.correct_answer_count} Doğru`}
                                    values={{ game_answered_correct: i.correct_answer_count }}
                                  />
                                </div>
                                <div>{i.game_answered_time}</div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    {/* end::Table body */}
                  </table>
                  {/* end::Table */}
                </div>
                {/* end::Table container */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Answers id={id} />
      )}
    </Fragment>
  );
};

export default ResultDetail;
