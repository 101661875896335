import * as React from 'react';
const SvgSurvey = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} fill="none" {...props}>
    <rect width={50} height={50} fill="#7239EA" rx={6} />
    <path
      fill="#F8F5FF"
      d="M32.778 11.216H17.195a6.39 6.39 0 0 0-6.361 6.389v9.662a6.39 6.39 0 0 0 6.39 6.389.694.694 0 0 1 .693.694v3.074a1.416 1.416 0 0 0 2.168 1.105l6.332-4.618c.237-.166.519-.255.808-.255h5.51a6.388 6.388 0 0 0 6.432-6.39v-9.661a6.39 6.39 0 0 0-6.389-6.39Z"
      opacity={0.3}
    />
    <path
      fill="#F8F5FF"
      d="M25 25.41a1.062 1.062 0 0 1-1.062-1.062v-.396a3.528 3.528 0 0 1 1.63-3.06 1.105 1.105 0 0 0 .566-.964 1.133 1.133 0 0 0-2.267 0 1.063 1.063 0 0 1-2.125 0 3.258 3.258 0 1 1 4.902 2.834 1.416 1.416 0 0 0-.581 1.218v.397A1.063 1.063 0 0 1 25 25.41ZM25.001 28.527a1.063 1.063 0 1 0 0-2.125 1.063 1.063 0 0 0 0 2.125Z"
    />
  </svg>
);
export default SvgSurvey;
