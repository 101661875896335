import * as React from 'react';

const SvgFourthIcon = (props: any) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8" cy="8" r="7" fill="#C4C4C4"/>
<circle cx="8" cy="8" r="7" fill="url(#paint0_linear_5379_32967)"/>
<circle cx="8" cy="8" r="7" fill="url(#paint1_linear_5379_32967)"/>
<circle cx="8" cy="8" r="7" fill="url(#paint2_linear_5379_32967)"/>
<circle cx="8" cy="8" r="7" fill="url(#paint3_linear_5379_32967)"/>
<circle cx="8" cy="8" r="7" fill="url(#paint4_linear_5379_32967)"/>
<circle cx="5" cy="5" r="5" transform="matrix(1 0 0 -1 3 13)" fill="url(#paint5_linear_5379_32967)"/>
<path d="M7.55176 11V10.1694L8.23291 10.0332V9.45752H5.69727L5.61816 8.78516L8.22852 4.60156H9.63477V8.55225H10.3071V9.45752H9.63477V10.0332L10.3027 10.1694V11H7.55176ZM6.89697 8.55225H8.23291V6.45166L8.20654 6.44287L8.09229 6.60986L6.89697 8.55225Z" fill="#DCB8FF"/>
<defs>
<linearGradient id="paint0_linear_5379_32967" x1="8" y1="1" x2="8" y2="15" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFDD86"/>
<stop offset="1" stop-color="#FFC32A"/>
</linearGradient>
<linearGradient id="paint1_linear_5379_32967" x1="8" y1="1" x2="8" y2="15" gradientUnits="userSpaceOnUse">
<stop stop-color="#E7E7E7"/>
<stop offset="1" stop-color="#B5B5B5"/>
</linearGradient>
<linearGradient id="paint2_linear_5379_32967" x1="8" y1="1" x2="8" y2="15" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFA37B"/>
<stop offset="1" stop-color="#CF5D2D"/>
</linearGradient>
<linearGradient id="paint3_linear_5379_32967" x1="8" y1="1" x2="8" y2="15" gradientUnits="userSpaceOnUse">
<stop stop-color="#5D308C"/>
<stop offset="1" stop-color="#301948"/>
</linearGradient>
<linearGradient id="paint4_linear_5379_32967" x1="8" y1="1" x2="8" y2="15" gradientUnits="userSpaceOnUse">
<stop stop-color="#793FB5"/>
<stop offset="1" stop-color="#5D308C"/>
</linearGradient>
<linearGradient id="paint5_linear_5379_32967" x1="5" y1="0" x2="5" y2="10" gradientUnits="userSpaceOnUse">
<stop stop-color="#793FB5"/>
<stop offset="1" stop-color="#5D308C"/>
</linearGradient>
</defs>
</svg>

);
export default SvgFourthIcon;



