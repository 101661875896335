import { configureStore } from '@reduxjs/toolkit';
import { api } from '../api';
import authReducer from '../features/auth/authSlice';
import moduleReducer from '../features/module/moduleSlice';
import gamesReducer from '../features/games/gamesSlice';
import birthdaysReducer from '../features/birthdays/birthdaysSlice';
import feedReducer from '../../app/modules/apps/feed_module/features/feedSlice';
import inviteFriendsReducer from '../features/invite_friends/inviteFriendsSlice';
import easyLinksReducer from '../features/easy_links/easyLinksSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    module: moduleReducer,
    games: gamesReducer,
    feed: feedReducer,
    birthdays: birthdaysReducer,
    easyLinks: easyLinksReducer,
    inviteFriends: inviteFriendsReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware),
});

export { store };
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
