import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';

import Select from 'react-dropdown-select';
import { KTIcon } from '../../../../../_metronic/helpers';
import { DatePicker } from 'antd';
import { useIntl } from 'react-intl';
import { ImageUpload } from '../../_global/components/Input/ImageUpload';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';

import tr from 'date-fns/locale/tr';
import {
  useCreateNearMissMutation,
  useGetbranchesListMutation,
  useGetincidenttypesListMutation,
  useGetriskseveritiesListMutation,
  useGetrisktypesListMutation,
} from '../../../../../api/services/near-miss/nearMissEndpoints';
import { okayMessage } from '../../_global/components/Modal/ConfirmMessage';
import { useNavigate } from 'react-router-dom';
import { FormDatePicker } from '../../_global/components/Input/FormDatePicker';
import { FormDateRangePicker } from '../../_global/components/Input/FormDateRangePicker';
import { useUploadServiceMutation } from '../../feedback_module/core/_requests';
registerLocale('tr', tr);

const MemoizedImageUpload = React.memo(ImageUpload);

export default function CreateNearMissModal(props: any) {
  const [branchList, setBranchList] = useState<any>([]);
  const [riskSeveritiesList, setRiskSeveritiesList] = useState<any>([]);
  const [riskTypesList, setRiskTypeList] = useState<any>([]);
  const [incidentTypesList, setIncidentTypesList] = useState<any>([]);
  const [imageValues, setImageValues] = useState<any>(null);

  const [selectedIncidentType, setSelectedIncidentType] = useState('');
  const [selectedRiskType, setSelectedRiskType] = useState('');
  const [selectedRiskSituationType, setSelectedRiskSituationType] = useState('');
  const [selectedBranch, setSelectedBranch] = useState({});
  const [description, setDescription] = useState('');
  const [takePrevent, setTakePrevent] = useState('');
  const [incidentDate, setIncidentDate] = useState<Date | string>(new Date());
  const [selectedDateIso, setSelectedDateIso] = useState<string>('');
  const [uploadImage] = useUploadServiceMutation();
  const fileRef = useRef(null);

  const intl = useIntl();

  const [createNearMiss, { isLoading }] = useCreateNearMissMutation();

  const [incidenttypes] = useGetincidenttypesListMutation();
  const [risktypes] = useGetrisktypesListMutation();
  const [riskseverities] = useGetriskseveritiesListMutation();
  const [getBranches] = useGetbranchesListMutation();

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await getBranches({}).unwrap();
        if (response && response.nodelist) {
          const formattedBranches = response.nodelist.map((branch: any) => ({
            value: branch.nodeid,
            label: branch.nodename, 
          }));
          setBranchList(formattedBranches); 
        }
      } catch (error) {
        console.error('Error fetching branches:', error);
      }
    }
  
    const fetchRiskSeverities = async () => {
      try {
        const response = await riskseverities({}).unwrap();
        if (response && response.riskseverities) {
          const formattedRiskSeverities = response.riskseverities.map((riskSeverity: any) => ({
            value: riskSeverity.riskseverityid, 
            label: riskSeverity.riskseveritytitle,
            description: riskSeverity.riskseveritydesc, 
            icon: (
              <img
                src={riskSeverity.mediauri} 
                alt={riskSeverity.riskseveritytitle}
                style={{ width: 24, height: 24 }} 
              />
            ), 
          }));
          
          setRiskSeveritiesList(formattedRiskSeverities);
        }
      } catch (error) {
        console.error('Error fetching risk severities:', error);
      }
    };
  
    const fetchRiskType = async () => {
      try {
        const response = await risktypes({}).unwrap();
        if (response && response.risktypes) {
          const formattedRiskTypes = response.risktypes.map((riskType: any) => ({
            value: riskType.risktypeid, 
            label: riskType.risktypedesc, 
          }));
          
          setRiskTypeList(formattedRiskTypes);
        }
      } catch (error) {
        console.error('Error fetching risk types:', error);
      }
    };
  
    const fetchIncidentType = async () => {
      try {
        const response = await incidenttypes({}).unwrap();
        if (response && response.incedenttypes) {
          const formattedIncidentTypes = response.incedenttypes.map((incidentType: any) => ({
            value: incidentType.incidenttypeid, 
            label: incidentType.incidenttypetitle,
            description: incidentType.incidenttypedesc, 
            icon: (
              <img
                src={incidentType.mediauri} 
                alt={incidentType.incidenttypetitle}
                style={{ width: 24, height: 24 }} 
              />
            ),
          }));
          
          setIncidentTypesList(formattedIncidentTypes);
        }
      } catch (error) {
        console.error('Error fetching incident types:', error);
      }
    };
  
    fetchBranches();
    fetchRiskSeverities();
    fetchRiskType();
    fetchIncidentType();
  }, [getBranches, incidenttypes, riskseverities, risktypes]);

  const currentDate = new Date();
  const minTime = new Date(currentDate);
  minTime.setHours(0, 0, 0, 0); // Set to 00:00:00

  const submitData = async () => {
    const data = {
      incident_date: dayjs(selectedDateIso).format('DD/MM/YYYY HH:mm:ss'),
      incident_type: selectedIncidentType,
      severity: selectedRiskType,
      branch_id: selectedBranch,
      risk_status: selectedRiskSituationType,
      description: description,
      precaution: takePrevent,
      images: imageValues,
    };
    const response = await createNearMiss(data).unwrap();
    if (response != null) {
      props.setIsOpen && props.setIsOpen(false);
      props.onSuccess && props.onSuccess();
      okayMessage(
        'Teşekkürler',
        'Ramak Kala Bildirimin şirket yetkililerine iletilecek. İş güvenliği ve deneyimini geliştirmeye yönelik bildirimin için teşekkürler!',
        () => {
          props.toggle && props.toggle();
        },
      );
    }
  };

  const customContentRenderer = ({ props, state }: any) => {
    return (
      <>
        {state.values.length === 0 ? (
          <div className="fw-semibold text-gray-500 ms-4">Seçim Yapınız</div>
        ) : (
          <div className="p-4" style={{ display: 'flex', alignItems: 'center' }}>
            {state.values[0]?.icon && state.values[0]?.icon}
            {state.values[0]?.label && <div className="ms-2 fw-semibold text-gray-800">{state.values[0].label}</div>}
            {state.values[0]?.description && (
              <div className="ms-2 fw-semibold text-gray-500">{state.values[0].description}</div>
            )}
          </div>
        )}
      </>
    );
  };

  const handleImageUpload = useCallback(
    async (images: any) => {
      const uploadedImagePaths: string[] = [];
      for (const image of images) {
        try {
          const result = await uploadImage(image).unwrap();
          if (result.filepath) {
            uploadedImagePaths.push(result.filepath);
          }
        } catch (error) {
          console.error('Resim yükleme hatası:', error);
        }
      }
      setImageValues(uploadedImagePaths);
    },
    [uploadImage],
  );
  
  const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref) => (
    <div onClick={onClick} className="border-gray-300 rounded border p-3" style={{ width: '45.5vw', borderColor: '#' }}>
      <div className={`ms-2 fw-semibold ${selectedDateIso === '' ? 'text-gray-600' : ''}`}>
        {selectedDateIso === '' ? 'gg/aa/yyyy - ss:dd' : dayjs(selectedDateIso).format('DD/MM/YYYY HH:mm')}
      </div>
    </div>
  ));

  return (
    <div>
      <div>
        <div className="fw-bold fs-5">Risk Bilgileri</div>
        <div className="mt-6">
          <div className="form-label fs-6">
            Olay Türü <span className="text-danger">*</span>
          </div>
          <Select
            placeholder="Seçim Yapınız"
            className="mt-2 rounded border-gray-300"
            options={incidentTypesList || []} // Eğer incidentTypesList boşsa boş bir dizi kullan
            values={[]}
            contentRenderer={customContentRenderer}
            itemRenderer={({ item, methods }: { item: any; methods: any }) => (
              <div
                onClick={() => methods.addItem(item)}
                className="p-4"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {item.icon}
                <div className="ms-2 fw-semibold text-gray-800">{item.label}</div>
                <div className="ms-2 fw-semibold text-gray-500">{item.description}</div>
              </div>
            )}
            onChange={(value: any) => {
              setSelectedIncidentType(value[0].value);
            }}
          />
        </div>
        <div className="mt-3">
          <div className="form-label fs-6">
            Risk Durumu <span className="text-danger">*</span>{' '}
          </div>
          <Select
            placeholder="Seçim Yapınız"
            className="mt-2 rounded border-gray-300"
            options={riskTypesList || []}
            values={[]}
            contentRenderer={customContentRenderer}
            itemRenderer={({ item, methods }: { item: any; methods: any }) => (
              <div
                onClick={() => methods.addItem(item)}
                className="p-4"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <div className="ms-2 fw-semibold text-gray-800">{item.label}</div>
              </div>
            )}
            onChange={(value: any) => {
              setSelectedRiskSituationType(value[0].value);
            }}
          />
        </div>
        <div className="mt-3">
          <div className="form-label fs-6">
            Risk Tipi <span className="text-danger">*</span>{' '}
          </div>
          <Select
            placeholder="Seçim Yapınız"
            className="mt-2 rounded border-gray-300"
            options={riskSeveritiesList || []}
            values={[]}
            contentRenderer={customContentRenderer}
            itemRenderer={({ item, methods }: { item: any; methods: any }) => (
              <div
                onClick={() => methods.addItem(item)}
                className="p-4"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                {item.icon}
                <div className="ms-2 fw-semibold text-gray-800">{item.label}</div>
                <div className="ms-2 fw-semibold text-gray-500">{item.description}</div>
              </div>
            )}
            onChange={(value: any) => {
              setSelectedRiskType(value[0].value);
            }}
          />
        </div>
      </div>
      <div className="mt-5">
        <div className="fw-bold fs-5">Olay Zamanı ve Yeri</div>
        <div className="mt-6">
          <div style={{ display: 'flex', zIndex: 1 }}>
            <FormDatePicker
              title="Olay Tarihi"
              name="datepicker"
              required
              changeEvent={(date: any) => {
                setIncidentDate(dayjs(date).toISOString());
                setSelectedDateIso(dayjs(date).toISOString());
              }}
            />
          </div>
        </div>
        <div className="mt-3">
          <div className="form-label fs-6">
            Olay Lokasyonu <span className="text-danger">*</span>{' '}
          </div>
          <Select
            placeholder="Seçim Yapınız"
            className="mt-2 rounded border-gray-300"
            options={branchList || []}
            values={[]}
            contentRenderer={customContentRenderer}
            itemRenderer={({ item, methods }: { item: any; methods: any }) => (
              <div
                onClick={() => methods.addItem(item)}
                className="p-4"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <div className="ms-2 fw-semibold text-gray-800">{item?.label}</div>
              </div>
            )}
            onChange={(value: any) => {
              setSelectedBranch(value[0].value);
            }}
          />
        </div>
      </div>
      <div className="mt-5">
        <div className="fw-bold fs-5">Açıklama ve Belge</div>
        <div className="mt-6">
          <div className="form-label fs-6">
            Açıklama <span className="text-danger">*</span>
          </div>
          <div>
            <textarea
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              rows={3}
              className="form-control"
              name={'feedBackDescription'}
              placeholder={intl.formatMessage({ id: 'TEXT_AREA_PLACEHOLDER' })}
            />
          </div>
        </div>
        <div className="mt-3">
          <div className="form-label fs-6">Alınacak Önlem</div>
          <textarea
            onChange={(e) => {
              setTakePrevent(e.target.value);
            }}
            value={takePrevent}
            rows={3}
            className="form-control"
            name={'feedBackDescription'}
            placeholder={intl.formatMessage({ id: 'TEXT_AREA_PLACEHOLDER' })}
          />
        </div>
        <div className="mt-3">
          <div className="form-label fs-6">İlgili Görsel</div>
          <MemoizedImageUpload
            ref={fileRef}
            width="200px"
            isMulti={true}
            maxImageCount={5}
            onImageUpload={handleImageUpload}
          />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button
          disabled={
            !selectedBranch ||
            !selectedIncidentType ||
            !selectedRiskSituationType ||
            !selectedRiskType ||
            !description ||
            !selectedDateIso
          }
          onClick={submitData}
          className="btn btn-info mt-5 "
          style={{ minWidth: 150 }}
        >
          {isLoading ? <span className="spinner-border spinner-border-sm align-middle"></span> : 'Gönder'}
        </button>
      </div>
    </div>
  );
}
