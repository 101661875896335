import api from '../../api';

interface ICompleteSurvey {
  success: boolean;
}

export const surveyApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSurveyQuestions: builder.query<any, number>({
      query: (id) => {
        return {
          url: `/surveys/${id}/questions/user`,
        };
      },
    }),
    answerQuestion: builder.mutation<any, any>({
      query: (question) => ({
        url: `/questionanswer`,
        method: 'POST',
        body: question,
      }),
    }),
    userSurveySubQuestion: builder.mutation<any, any>({
      query: ({choiceId,surveyId} ) => ({
        url: `/surveys/${surveyId}/questions/subquestion/${choiceId}`,
        method: 'GET',
      }),
    }),
    deleteAnswerQuestion: builder.mutation<any, any>({
      query: (question) => ({
        url: `/survey/uncheck?survey_id=${question.survey_id}&question_id=${question.question_id}&question_option_id=${question.question_option_id}&question_type=${question.question_type}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetSurveyQuestionsQuery,
  useAnswerQuestionMutation,
  useDeleteAnswerQuestionMutation,
  useUserSurveySubQuestionMutation
} = surveyApi;

export default surveyApi;
