import { FC } from 'react';
import { useLang } from './Metronici18n';
import { IntlProvider } from 'react-intl';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/de';
import '@formatjs/intl-relativetimeformat/locale-data/es';
import '@formatjs/intl-relativetimeformat/locale-data/fr';
import '@formatjs/intl-relativetimeformat/locale-data/ja';
import '@formatjs/intl-relativetimeformat/locale-data/zh';

import deMessages from './messages/de.json';
import enMessages from './messages/en.json';
import esMessages from './messages/es.json';
import frMessages from './messages/fr.json';
import jaMessages from './messages/ja.json';
import zhMessages from './messages/zh.json';
import trMessages from './messages/tr.json';
import trUser from './messages/tr/user.json';
import trHome from './messages/tr/home.json';
import enHome from './messages/en/home.json';
import enUser from './messages/en/user.json';
import trAnnouncement from './messages/tr/announcement.json';
import enAnnouncement from './messages/en/announcement.json';
import trSurveys from './messages/tr/surveys.json';
import enSurveys from './messages/en/surveys.json';
import trGames from './messages/tr/games.json';
import enGames from './messages/en/games.json';
import trPillInformation from './messages/tr/pill_information.json';
import enPillInformation from './messages/en/pill_information.json';
import trUsers from './messages/tr/users.json';
import enUsers from './messages/en/users.json';
import trFoodMenu from './messages/tr/food_menu.json';
import enFoodMenu from './messages/en/food_menu.json';
import trMarket from './messages/tr/market.json';
import enMarket from './messages/en/market.json';
import trGeneral from './messages/tr/general.json';
import enGeneral from './messages/en/general.json';
import trLeadership from './messages/tr/leadership.json';
import enLeadership from './messages/en/leadership.json';
import enSecondHands from './messages/en/secondhands.json';
import trSecondHands from './messages/tr/secondhands.json';
import enBirthdays from './messages/en/birthdays.json';
import trBirthdays from './messages/tr/birthdays.json';
import enDeparmentProfile from './messages/en/department_profile.json';
import trDepartmentProfile from './messages/tr/department_profile.json';
import trEvents from './messages/tr/events.json';
import enEvents from './messages/en/events.json';
import trFeedback from './messages/tr/feedback.json';
import enFeedback from './messages/en/feedback.json';
import trInviteFriends from './messages/tr/invite_friends.json';
import enInviteFriends from './messages/en/invite_friends.json';
import trInAppSearch from './messages/tr/in_app_search.json';
import enInAppSearch from './messages/en/in_app_search.json';
import trPayroll from './messages/tr/payrolls.json';
import enPayroll from './messages/en/payrolls.json';
import trSettings from './messages/tr/settings.json';
import enSettings from './messages/en/settings.json';
import trUserFeelings from './messages/tr/user_feelings.json';
import enUserFeelings from './messages/en/user_feelings.json';
import trFeeds from './messages/tr/feed.json';
import enFeeds from './messages/en/feed.json';
import trUserProfile from './messages/tr/user_profile.json';
import enUserProfile from './messages/en/user_profile.json';
import enCheers from './messages/en/cheers.json';
import trCheers from './messages/tr/cheers.json';

import { WithChildren } from '../helpers';

let trJson = {
  ...trMessages,
  ...trUser,
  ...trHome,
  ...trAnnouncement,
  ...trPillInformation,
  ...trSurveys,
  ...trUsers,
  ...trFoodMenu,
  ...trGames,
  ...trMarket,
  ...trGeneral,
  ...trLeadership,
  ...trSecondHands,
  ...trBirthdays,
  ...trDepartmentProfile,
  ...trEvents,
  ...trFeedback,
  ...trInviteFriends,
  ...trInAppSearch,
  ...trPayroll,
  ...trSettings,
  ...trUserFeelings,
  ...trFeeds,
  ...trUserProfile,
  ...trCheers
};
let enJson = {
  ...enMessages,
  ...enUser,
  ...enHome,
  ...enAnnouncement,
  ...enPillInformation,
  ...enSurveys,
  ...enUsers,
  ...enFoodMenu,
  ...enGames,
  ...enMarket,
  ...enGeneral,
  ...enLeadership,
  ...enSecondHands,
  ...enBirthdays,
  ...enDeparmentProfile,
  ...enEvents,
  ...enFeedback,
  ...enInviteFriends,
  ...enInAppSearch,
  ...enPayroll,
  ...enSettings,
  ...enUserFeelings,
  ...enFeeds,
  ...enUserProfile,
  ...enCheers
};

const allMessages = {
  de: deMessages,
  en: enJson,
  es: esMessages,
  fr: frMessages,
  ja: jaMessages,
  zh: zhMessages,
  tr: trJson,
};
const I18nProvider: FC<WithChildren> = ({ children }) => {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export { I18nProvider };
