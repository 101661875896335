import React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material';
import { KTIcon } from '../../../../../../_metronic/helpers';

export function SuccessSuggestionStepper() {
  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 8,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
      zIndex: 1,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#A1A5B7',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#784af4',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 6,
      borderRadius: 10,
      zIndex:-1
    },
  }));

  const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#F6C000',
    }),

    '& .QontoStepIcon-completedIcon': {
      color: '#eaeaf0',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 38,
      height: 38,
      backgroundColor: 'currentColor',
      borderRadius: 24,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      zIndex: 23,
    },
  }));

  function QontoStepIcon(props: StepIconProps, index: any) {
    const { active, completed, className } = props;
    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              borderRadius: 78,
              width: '38px',
              height: '38px',
              zIndex: 10,
              backgroundColor: '#14A44D',
            }}
          >
            <KTIcon iconName="check-circle" className="fs-2x text-white " iconOverride="text-white" />
          </div>
        ) : active ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{
              borderRadius: 78,
              width: '38px',
              height: '38px',
              zIndex: 10,
              backgroundColor: '#14A44D',
            }}
          >
             <KTIcon iconName="check-circle" className="fs-2x text-white " iconOverride="text-white" />
          </div>
        ) : (
          <div
            className="bg-gray-500 d-flex justify-content-center align-items-center text-white fs-1 fw-bold"
            style={{
              borderRadius: 78,
              width: '38px',
              height: '38px',
              zIndex: 1,
              background: '#eaeaf0',
            }}
          >
            <span style={{ fontSize: 18 }}> {index + 1}</span>
          </div>
        )}
      </QontoStepIconRoot>
    );
  }

  const steps = ['Öneri Kabul Edildi', 'Çözüm Sağlandı', 'Kaizen Durumu'];

  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper
        alternativeLabel
        connector={<QontoConnector />}
        activeStep={1}
        sx={{
          '& .MuiStepConnector-root.Mui-active .MuiStepConnector-line': {
            borderColor: 'green',
          },
          '& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line': {
            borderColor: 'green',
          },
          '& .MuiStepConnector-root.Mui-completed QontoStepIcon-circle': {
            backgroundColor: 'green',
          },
        }}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel
              StepIconComponent={(props) => {
                return QontoStepIcon(props, index);
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
