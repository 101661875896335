// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-card {
  padding: 30px;
  width: "100%";
  height: 305px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-fullname {
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.18px;
  margin-bottom: 10px;
}

.user-position {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
}

.button-md {
  font-size: 13px;
  font-weight: 600;
  line-height: 14px;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/home/components/UserCard/_userCard.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AACA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;AAEF;;AACA;EACE,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAEF;;AACA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,WAAA;AAEF","sourcesContent":[".user-card {\n  padding: 30px;\n  width: '100%';\n  height: 305px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.user-fullname {\n  font-size: 18px;\n  font-weight: 600;\n  line-height: 18px;\n  letter-spacing: -0.18px;\n  margin-bottom: 10px;\n}\n\n.user-position {\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 14px;\n}\n\n.button-md {\n  font-size: 13px;\n  font-weight: 600;\n  line-height: 14px;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
