// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.quicklinks-card {
  width: "100%";
  background-color: #fff;
  border-radius: 12px;
  padding: 30px;
}

.subTitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
}

.quicklinks-card-header {
  padding-bottom: 30px;
}

.title {
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.18px;
}

.see-more {
  padding: 12px 9px;
}

.quicklinks-image {
  width: 20px;
  height: 20px;
}

.quicklinks-body {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/home/components/QuickLinks/_quickLinks.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,oBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,YAAA;EACA,8BAAA;AACF","sourcesContent":[".quicklinks-card {\n  width: '100%';\n  background-color: #fff;\n  border-radius: 12px;\n  padding: 30px;\n}\n\n.subTitle {\n  font-size: 14px;\n  font-weight: 600;\n  line-height: 14px;\n}\n\n.quicklinks-card-header {\n  padding-bottom: 30px;\n}\n\n.title {\n  font-size: 18px;\n  font-weight: 600;\n  line-height: 18px;\n  letter-spacing: -0.18px;\n}\n\n.see-more {\n  padding: 12px 9px;\n}\n\n.quicklinks-image {\n  width: 20px;\n  height: 20px;\n}\n\n.quicklinks-body {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
