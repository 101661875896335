import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InviterUser {
    selected_user_id: string;
}

const initialState: InviterUser = {
    selected_user_id: '',
};

const inviteFriendsSlice = createSlice({
    name: 'inviteFriends',
    initialState,
    reducers: {
        setSelected: (state: InviterUser, action: PayloadAction<any>) => {
            if (action.payload != null) {
                state.selected_user_id = action.payload.selected_user_id;
            }
        },
        resetSelected: () => initialState,
    },
});

export const { setSelected, resetSelected } = inviteFriendsSlice.actions;

export const inviteFriendsSelector = (state: InviterUser) => state;

export default inviteFriendsSlice.reducer;