import { useNavigate } from 'react-router-dom';
import Loading from '../../../../../components/Loading';
import { FC, Fragment, memo, useRef, useState } from 'react';
import { KTIcon } from '../../../../../../_metronic/helpers';
import UserCommentBadge from '../../../_global/components/UserCommentBadge/UserCommentBadge';
import { ElementAnimateUtil } from '../../../../../../_metronic/assets/ts/_utils';
import { useIntl } from 'react-intl';

type Props = {
  data: any;
  refetch: any;
  postId: number;
  totalCount: number;
  isPostOwner: boolean;
  setVisibleComments: any;
  setCommentCount: Function;
  isLoadingComments: boolean;
  isVisibleComments: boolean;
  handleScrollBackToPost: Function;
  setVisibleCommentsReply?:any;
  isVisibleCommentsReply?:boolean;
  setCommentParentId?:any
};

const Comments: FC<Props> = ({
  data,
  refetch,
  totalCount,
  isPostOwner,
  setCommentCount,
  isLoadingComments,
  isVisibleComments,
  setVisibleComments,
  handleScrollBackToPost,
  postId,
  setVisibleCommentsReply,
  isVisibleCommentsReply,
  setCommentParentId
}) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const endOfCommentsRef = useRef<any>(null);
  const [limit, setLimit] = useState(5);
  const [visibleReplies, setVisibleReplies] = useState<{ [key: string]: boolean }>({});
  const groupComments = (comments: any[]) => {
    const grouped = comments?.reduce((acc: any, comment: any) => {
      if (comment?.commentid === comment?.parentid) {
        
        acc.push({ ...comment, replies: [] });
      } else {
        const parent = acc?.find((parentComment: any) => parentComment?.commentid === comment?.parentid);
        if (parent) {
          parent?.replies?.push(comment);
        }
      }
      return acc;
    }, []);
    return grouped;
  };

  const groupedComments = groupComments(data);
  const toggleRepliesVisibility = (commentId: string) => {
    setVisibleReplies((prevState) => ({
      ...prevState,
      [commentId]: !prevState[commentId],
    }));
  };
  const handleVisible = () => {
    if (isVisibleComments) {
      setVisibleComments(false);
      setCommentParentId(0)
      setVisibleCommentsReply(false)
      handleScrollBackToPost();
    }
    if (totalCount <= 5) {
      setVisibleComments(false);
      setVisibleCommentsReply(false)
      setCommentParentId(0)
      handleScrollBackToPost();
    } else {
      if (limit < totalCount) {
        setLimit(limit + 5);
        setVisibleComments(true);
        ElementAnimateUtil.scrollTo(endOfCommentsRef.current, 100);
      } else {
        setLimit(5);
        setVisibleComments(false);
        setVisibleCommentsReply(false)
      setCommentParentId(0)

      }
    }
  };
  return (
    <Fragment>
      {isLoadingComments ? (
        <Loading height={100} />
      ) : (
        <div>
          {groupedComments &&
            groupedComments
              ?.slice(0, limit)
              ?.map((comment: any) => (
                <Fragment key={comment.commentid}>
                  <UserCommentBadge
                    className="mb-5"
                    comment={comment}
                    refetch={refetch}
                    isPostOwner={isPostOwner}
                    setCommentCount={setCommentCount}
                    navigate={() => navigate(`/profile/${comment?.userid}`)}
                    feedid={postId}
                    mainComment={true}
                    setVisibleCommentsReply={setVisibleCommentsReply}
                    isVisibleCommentsReply={isVisibleCommentsReply}
                    setCommentParentId={setCommentParentId}
                  />
                  {comment?.replies?.map((reply: any) => (
                    <div key={reply.commentid} className="ml-15" style={{marginLeft:70}}>
                    {visibleReplies[comment.commentid] &&
                      <>
                        <UserCommentBadge
                          className="mb-5"
                          comment={reply}
                          refetch={refetch}
                          isPostOwner={isPostOwner}
                          setCommentCount={setCommentCount}
                          navigate={() => navigate(`/profile/${reply?.userid}`)}
                          feedid={postId}
                          />
                      
                        
                      </>
                      
                    }
                    </div>
                  ))}
                      <>
                      {!visibleReplies[comment.commentid] ?
                       (comment?.replies?.length>0&&
                        <div onClick={() => toggleRepliesVisibility(comment.commentid)} style={{display:'flex',alignItems:'center',marginBottom:5,marginTop:-10,cursor:'pointer'}}>
                          <hr style={{border:" 1px solid black;",width:'5%',marginRight:20,}} /> {comment?.replies?.length} yorumu gör
                        </div>)
                        :
                        <div onClick={() => toggleRepliesVisibility(comment.commentid)}  style={{display:'flex',alignItems:'center',marginBottom:5,marginTop:-10,cursor:'pointer'}}>
                      <hr style={{border:" 1px solid black;",width:'5%',marginRight:20,}} /> {comment?.replies?.length} yorumu gizle</div>
                        }
                      </>

                </Fragment>
              ))}
          {totalCount > 0 && (
            <div
              onClick={handleVisible}
              className="d-flex justify-content-center align-items-center gap-2 cursor-pointer"
            >
              <KTIcon
                iconName={`${limit >= totalCount || totalCount <= 5 ? 'double-up' : 'double-down'}`}
                className="fs-3"
              />
              <div ref={endOfCommentsRef} className="justify-content-center text-center fw-bold text-gray-600 fs-5">
                {intl.formatMessage({
                  id: limit >= totalCount || totalCount <= 5 ? 'FEED_HIDE_COMMENTS' : 'FEED_SHOW_MORE_COMMENTS',
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
};

export default memo(Comments);