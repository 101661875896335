import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../../../api/store/hooks';
import { KTCard, toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { authSelector } from '../../../../../../api/features/auth/authSlice';
import GenericEmptyContent from '../../../_global/components/EmptyContent/GenericEmptyContent';

type Props = {
  dataa: any;
  filterComponent?: any;
  className?: string;
  setPage?:any;
  page?:number
};

export default function GamesResultCard({ dataa, setPage,page}: Props) {
  const intl = useIntl();
  const navigate = useNavigate();
  const { currentUser } = useAppSelector((state) => authSelector(state.auth));
  const containerRef = useRef<HTMLTableSectionElement>(null);
  const [data,setData]=useState<any[]>([])
  const handleScroll = async () => {
    const container = containerRef.current;
    if (container) {
      // Scroll sona geldi mi kontrolü
      if (container.scrollTop + container.clientHeight >= container.scrollHeight - 10) {
        // Yeni veri yükle
        setPage((prevPage: any) => prevPage + 1);

      }
    }
  };
  useEffect(() => {
    if (dataa&&page===1) {
      setData((prevData: any) => dataa);
    }
    if(dataa&&(page&&page<1)){
      setData((prevData: any) => [...prevData, ...dataa]);

    }
  }, [page,dataa]);
  const findMyRanking = useCallback(() => {
    const rankingInfo = data?.find((item: any) => item?.userid === currentUser?.usersecret?.userid);
    if (rankingInfo === undefined) {
      return { place: undefined, user_total_point: undefined };
    }
    return { ...rankingInfo, place: data?.indexOf(rankingInfo) + 1 };
  }, [currentUser?.usersecret?.userid, data]);

  return (
    <KTCard className={`event-card w-400`}>
      <div className={`mx-8 mt-8`}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="col-md-6">
            
            <div className="title fw-bolder">{intl.formatMessage({ id: 'DEPARTMENT_LEADERSHIP_TITLE' })}</div>
            
          </div>
          {/* {filterComponent && <div className="col-md-4">{filterComponent}</div>} */}
        </div>
      </div>

      {data?.length === 0 ? (
        <GenericEmptyContent
          imgPath="/media/svg/empty/games/GamesResultEmpty.svg"
          title="LEADERSHIP_PAGE_EMPTY_TITLE"
          directSubContent="LEADERSHIP_EMPTY_TEXT_CONTENT"
          className="m-10"
        />
      ) : (
        <>
          {findMyRanking().place !== undefined ? (
            <KTCard className="event-card my-8 mx-8">
              <div className="mx-5 my-4">
                <div className="fw-bold fs-4 mb-4">{intl.formatMessage({ id: 'LEADERSHIP_MY_RANKING_TEXT' })}</div>
                <div className="d-flex align-items-stretch gap-5 mb-6 me-5">
                  <div
                    className="col-md-6 border border-gray-300 border-dashed rounded-1 px-4 py-3 d-flex align-items-center cursor-pointer bg-white"
                    onClick={() => navigate(`/profile/${currentUser?.usersecret?.userid}`)}
                  >
                    <div className="d-flex align-items-center gap-3">
                      <img
                        src={currentUser?.pictureurl || toAbsoluteUrl('/media/avatars/Default Avatar.svg')}
                        alt="user"
                        width={50}
                        height={50}
                        className="rounded-3 "
                      />
                      <div>
                        <div className="fw-bold text-dark">{currentUser?.userdesc}</div>
                        <span className="text-gray-500 fw-bold">{currentUser?.nodename}</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 border border-gray-300 border-dashed rounded-1 px-4 d-flex align-items-center bg-white">
                    <div>
                      <div className="fw-bold">{`${findMyRanking().place}. ${intl.formatMessage({
                        id: 'LEADERSHIP_RANK_TEXT',
                      })}`}</div>
                      {/* <div>
                        {findMyRanking().score
                          ? `${findMyRanking().score} ${intl.formatMessage({
                              id: 'LEADERSHIP_POINT_TEXT',
                            })}`
                          : intl.formatMessage({ id: 'FOOD_MENU_EMPTY_KCAL' })}
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </KTCard>
          ) : (
            <div className="my-4"></div>
          )}

          <KTCard className="event-card mx-8 mb-8">
            <div className="my-4">
              <div className="row">
                <div className="p-10 py-3">
                  {/* begin::Table container */}
                  <div className="table-responsive d-block overflow-auto" style={{ maxHeight: 400 }} onScroll={handleScroll} ref={containerRef}>
                    <div className="fw-bold fs-4 mb-1">{intl.formatMessage({ id: 'LEADERSHIP_RANKING_TABLE' })}</div>
                    {/* begin::Table */}
                   
                    <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                      {/* begin::Table head */}
                      <thead className="sticky-top bg-white" style={{ zIndex: 1 }}>
                        <tr className="fw-bold text-muted">
                          <th className="w-25px">{intl.formatMessage({ id: 'LEADERSHIP_RANK_TEXT' })}</th>
                          <th className="min-w-410px">{intl.formatMessage({ id: 'LEADERSHIP_NAME_POSITION_TEXT' })}</th>
                          <th className="min-w-100px text-end">
                            {intl.formatMessage({ id: 'LEADERSHIP_POINT_TEXT' })}
                          </th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody ref={containerRef} onScroll={handleScroll} style={{ maxHeight: '400px', overflowY: 'auto' }}>
                        {data?.map((i: any, index: number) => (
                          <>
                          <tr key={index} >
                            
                            <td>{index + 1}.</td>
                            <td>
                              <div
                                className="d-flex align-items-center cursor-pointer"
                                onClick={() => navigate(`/profile/${i?.userid}`)}
                              >
                                <div className="symbol symbol-45px me-5">
                                  <img
                                    src={i?.pictureurl || toAbsoluteUrl('/media/avatars/Default Avatar.svg')}
                                    alt="avatar"
                                  />
                                </div>
                                <div className="d-flex justify-content-start flex-column">
                                  <span className="text-dark fw-bold text-hover-info fs-6">{i?.fullname}</span>
                                  <span className="text-muted fw-semibold text-muted d-block fs-7">{i?.nodename}</span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex justify-content-end flex-shrink-0">
                                <div>
                                  <div className="fw-bold">{i?.contestpoint} {intl.formatMessage({ id: 'LEADERSHIP_TRUE' })}</div>
                                  <div className="fw-text-muted fw-semibold text-muted d-block fs-7">{i?.durationsecondsdisplay}</div> {/*durationseconds */}
                                </div>
                              </div>
                            </td>
                          </tr>
                         
                         </>
                        ))}
                      </tbody>
                      {/* end::Table body */}
                    </table>
                  </div>
                    {/* end::Table */}
                
                  {/* end::Table container */}
                </div>
              </div>
            </div>
          </KTCard>
        </>
      )}
    </KTCard>
  );
}
