import React, { createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { WithChildren } from '../../helpers';

const MetronicSplashScreenContext = createContext<Dispatch<SetStateAction<number>> | undefined>(undefined);

const MetronicSplashScreenProvider: FC<WithChildren> = ({ children }) => {
  const [count, setCount] = useState(0);
  let visible = count > 0;

  useEffect(() => {
    // Show SplashScreen
    if (visible) {
      document.body.classList.remove('page-loading');

      return () => {
        document.body.classList.add('page-loading');
      };
    }

    // Hide SplashScreen
    let timeout: number;
    if (!visible) {
      timeout = window.setTimeout(() => {
        document.body.classList.add('page-loading');
      }, 3000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [visible]);

  return <MetronicSplashScreenContext.Provider value={setCount}>{children}</MetronicSplashScreenContext.Provider>;
};

const LayoutSplashScreen: FC<{ visible?: boolean }> = ({ visible = true }) => {
  // Everything are ready - remove splashscreen
  const setCount = useContext(MetronicSplashScreenContext);

  useEffect(() => {
    if (!visible) {
      return;
    }

    //   const loadingEl = document.createElement('div');
    //   document.body.prepend(loadingEl);
    //   loadingEl.classList.add('page-loader');
    //   loadingEl.classList.add('flex-column');
    //   loadingEl.classList.add('justify-content-center');
    //   loadingEl.classList.add('align-items-center');
    //   loadingEl.innerHTML = `
    //   <img
    //   src="/media/logos/logo_renkli.png"
    //   width="200"
    //   class="dark-logo"
    //   alt="Metronic dark logo"
    // />

    // <span>Loading ...</span>
    //   `;

    if (setCount) {
      setCount((prev) => {
        return prev + 1;
      });
    }

    return () => {
      if (setCount) {
        setCount((prev) => {
          return prev - 1;
        });
      }
    };
  }, [setCount, visible]);

  return null;
};

export { MetronicSplashScreenProvider, LayoutSplashScreen };
