import * as React from 'react';

const SvgFirstIcon = (props: any) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_5379_32901)">
    <rect x="1.5" width="13" height="10" rx="4" fill="#E1422D"/>
    <rect x="4.62109" width="7.28" height="10" fill="#EA9241"/>
    </g>
    <circle cx="8" cy="9" r="7" fill="#C4C4C4"/>
    <circle cx="8" cy="9" r="7" fill="url(#paint0_linear_5379_32901)"/>
    <circle cx="5" cy="5" r="5" transform="matrix(1 0 0 -1 3 14)" fill="url(#paint1_linear_5379_32901)"/>
    <path d="M6.18066 12V11.1694L7.28809 11.0332V6.83643L6.17188 6.83203V6.04102L8.69434 5.60156V11.0332L9.80176 11.1694V12H6.18066Z" fill="#937017"/>
    <defs>
    <linearGradient id="paint0_linear_5379_32901" x1="8" y1="2" x2="8" y2="16" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FFDD86"/>
    <stop offset="1" stop-color="#FFC32A"/>
    </linearGradient>
    <linearGradient id="paint1_linear_5379_32901" x1="5" y1="0" x2="5" y2="10" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FFDD86"/>
    <stop offset="1" stop-color="#FFC32A"/>
    </linearGradient>
    <clipPath id="clip0_5379_32901">
    <rect x="1.5" width="13" height="10" rx="4" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    
);
export default SvgFirstIcon;



