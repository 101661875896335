import { useIntl } from 'react-intl';
import { KTIcon } from '../../../../../../_metronic/helpers';
import { setAnswersModal } from '../../../../../../api/features/games/gamesSlice';
import { useAppDispatch } from '../../../../../../api/store/hooks';

const BackResult = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  return (
    <button
      onClick={() => dispatch(setAnswersModal(false))}
      className="btn btn-light-secondary text-dark d-flex justify-content-center"
    >
      <KTIcon iconName="arrow-left" className="fs-1" iconOverride="text-dark" />
      <span>{intl.formatMessage({ id: 'GAMES.BACK_TO_RESULTS' })}</span>
    </button>
  );
};

export default BackResult;
