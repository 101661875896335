import { rtkTags } from './constants';
import { RootState } from './store/store';
import { logout } from './features/auth/authSlice';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    const headerCode = (getState() as RootState).auth.headerCode;
    headers.set('Accept', `application/json`);
    headers.set('Cache-Control', 'no-cache');
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    if (headerCode) {
      headers.set('Authorization', `Bearer ${headerCode}`);
    }
    return headers;
  },
});

const baseQueryWithInterceptors: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  let result = await baseQuery(args, api, extraOptions);
  //? If the result is an error and the status is 401, we'll redirect to the login page
  if (result.error && result.error.status === 401) {
    api.dispatch(logout());
  }
  return result;
};

export const api = createApi({
  reducerPath: 'api',
  tagTypes: rtkTags,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  baseQuery: baseQueryWithInterceptors,
  endpoints: (_) => ({}),
});

export default api;
