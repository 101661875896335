import React, { FC, useState } from 'react';
import Question from '../Question/Question';
import Expandable from '../../../_global/components/Expandable/Expandable';
import { useGetSurveyQuestionsQuery } from '../../../../../../api/services/surveys/surveyEndpoints';
import { useIntl } from 'react-intl';
import { Form, Formik, useFormikContext } from 'formik';
import { ICreateSurvey, createSurveySchemas, inits } from '../../CreateSurveyHelper';
import { okayMessage } from '../../../_global/components/Modal/ConfirmMessage';
import Loading from '../../../../../components/Loading';
import { formattedRemainingTime } from '../../../../_helpers/Helpers';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import UserBadge from '../../../_global/components/UserBadge/UserBadge';
import SecurityLockIcon from '../SurveyCard/security_lock.svg';

type Props = {
  expectedduration?: number;
  title: string;
  remaining_time: string;
  description: string;
  id: number;
  toggle?: Function;
  refetch: Function;
  isMustSurvey?: boolean;
  closeModal?: Function;
  department_name?: string;
  created_date_formatted?: string;
  anonymous?: number;
};

const SurveyModal: FC<Props> = ({
  expectedduration,
  title,
  remaining_time,
  description,
  id,
  toggle,
  refetch,
  closeModal,
  isMustSurvey,
  department_name,
  created_date_formatted,
  anonymous
}) => {
  const [initValues] = useState<ICreateSurvey>(inits);
  const { data, isLoading } = useGetSurveyQuestionsQuery(id);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); 

  const handleNextQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };

  const handleCompleteSurvey = () => {
    if (toggle) toggle(false);
    closeModal && closeModal();
    okayMessage('Tebrikler', 'Anketi başarıyla tamamladınız, katılımınız için teşekkür ederiz.');
    refetch();
  };

  return (
    <Formik
      isInitialValid={false}
      initialValues={initValues}
      validationSchema={createSurveySchemas}
      onSubmit={() => { }}
      validateOnMount
    >
      {({ isValid, errors }) => {
        return !isLoading ? (
          <div className="mx-auto mw-1000px w-100 pt-1 pb-10">
            {anonymous === 1 ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#E6F4EA', // Arka plan rengi
                  border: '1px solid #22A657', // Yeşil kenarlık
                  borderRadius: '8px', // Köşeleri yuvarlama
                  padding: '12px 16px', // İç boşluklar
                  color: '#22A657',
                  marginBottom: '20px',
                  paddingBottom: '10px',
                }}
              >
                <img
                  src={SecurityLockIcon}
                  alt="security lock"
                  style={{ width: '24px', height: '24px', marginRight: '8px' }}
                />
                <div style={{ fontWeight: '600', fontSize: '14px' }}>
                  Bu anket anonimdir. Verdiğiniz cevaplar tamamen gizli tutulacaktır.
                </div>
              </div>
            ) : (
              <div></div>
            )}
            <div className="mb-4">
              <div className="survey-subtitle text-gray-500 mb-3">Son Katılım tarihi {remaining_time}</div>
              <div className="survey-title mb-3">{title}</div>
              <div className="survey-subtitle text-gray-500">Tahmini Süre: {expectedduration} dakika</div>
            </div>
            {isMustSurvey && department_name && created_date_formatted && (
              <UserBadge
                image={toAbsoluteUrl('/media/svg/corporate-icon.svg')}
                title={department_name}
                subtitle={created_date_formatted}
              />
            )}
            <div className="border-gray-300 border-bottom-dashed border-bottom-1 my-8" />

            {data?.questions?.length > 0 ? (
              <Question
                key={data.questions[currentQuestionIndex].questionid}
                question={data.questions[currentQuestionIndex]}
                questionIndex={currentQuestionIndex}
                id={id}
                onNext={handleNextQuestion}
                onComplete={handleCompleteSurvey}
                isLastQuestion={currentQuestionIndex === data.questions.length - 1}
                isSubQuestion={false}
                passable={data.questions[currentQuestionIndex].passable}
              />
            ) : (
              <div className="text-danger">Herhangi bir soru bulunamadı.</div>
            )}
          </div>
        ) : (
          <Loading />
        );
      }}
    </Formik>
  );
};

export default SurveyModal;
