import { FC, useEffect, useMemo, useState } from 'react';
import { KTCard, toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { GET_BADGES_URL, useGetBadgesQuery, useGetCheersBadgesQuery } from '../services/cheersEndpoints';
import { Badge } from '../core/_modals';
import { useAppSelector } from '../../../../../api/store/hooks';
import { authSelector } from '../../../../../api/features/auth/authSlice';
import './BadgeSelection.scss';
import MingMonyIcon from '../../../../../_metronic/assets/icontsx/mingMony';

type Props = {
  badgeClicked: Function;
  cheersbadgeidSelected: number;
  cheerstypeid: number;
  isThereAnyBadge: Function;
  score: number;
};

const BadgeSelection: FC<Props> = ({ badgeClicked, cheersbadgeidSelected, cheerstypeid, isThereAnyBadge, score }) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [badges, setBadges] = useState<Badge[]>([]);
  const { data: badgesList, refetch} = useGetCheersBadgesQuery({ cheersTypeId: cheerstypeid });

  const user = useAppSelector((state) => authSelector(state.auth));

  useEffect(() => {
    refetch();
    setBadges(badgesList?.badges ?? []);
  }, [badgesList, cheerstypeid, refetch]);

  useEffect(() => {
    isThereAnyBadge(badges.length > 0);
  }, [badges]);

  useEffect(() => {
    if (badges.length > 0) {
      setSelectedIndex(0);
      badgeClicked(badges[0].cheersbadgeid);
    }
  }, [badges]);

  const rows = [];
  for (let i = 0; i < badges.length; i += 6) {
    rows.push(badges.slice(i, i + 6));
  }

  const makeBorder = (index: number) => {
    setSelectedIndex(index);
  };
  console.log(rows);
  return (
    <KTCard>
      <div>
        <div className="badges">
          {rows.map((row, rowIndex) => (
            <>
              {row.map((col: Badge, index: number) => (
                <div
                  onClick={() => {
                    if (col.score < score) {
                      makeBorder(index + rowIndex * 12);
                      badgeClicked(col.cheersbadgeid);
                    }
                  }}
                  key={index}
                  className={`badge-item ${selectedIndex === index + rowIndex * 12 && 'badge-item-active'} ${
                    col.score > score && 'badge-item-disabled'
                  }`}
                >
                  <div className={`badge-image ${selectedIndex === index + rowIndex * 12 && 'badge-image-active'}`}>
                    <img
                      className={`rounded-2"  ${col.score > score && 'badge-image-disabled'}`}
                      alt="badge"
                      src={col.cheersbadgeiconurl}
                    ></img>
                  </div>
                  <div className="badge-bottom">
                    <div className="badge-title">{col.title}</div>
                    <div className="badge-title">
                      {col.score}{' '}
                      {/* <img
                        alt="coin"
                        width={16}
                        height={16}
                        className={`rounded-2`}
                        src={toAbsoluteUrl('/media/svg/coin/coin.svg')}
                      /> */}
                            <MingMonyIcon />
                    </div>
                  </div>
                </div>
              ))}
            </>
          ))}
        </div>
      </div>
    </KTCard>
  );
};

export { BadgeSelection };
