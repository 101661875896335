import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface Feed {
  likedUsers: any;
  location: number;
  isOpenPostEdit: boolean;
  isOpenLikeModal: boolean;
  isOpenPostCreate: boolean;
  editPostId: number | undefined;
  selectedPostId: number | undefined;
}

const initialState: Feed = {
  location: 0,
  likedUsers: [],
  editPostId: undefined,
  isOpenPostEdit: false,
  isOpenLikeModal: false,
  isOpenPostCreate: false,
  selectedPostId: undefined,
};

const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    setSelectedPostId: (state: any, action: PayloadAction<any>) => {
      if (action.payload !== null) {
        state.selectedPostId = action.payload;
      }
    },
    setLikeModal: (state: any) => {
      state.isOpenLikeModal = !state.isOpenLikeModal;
    },
    setOpenPostCreate: (state: any) => {
      state.isOpenPostCreate = !state.isOpenPostCreate;
    },
    setOpenPostEdit: (state: any) => {
      state.isOpenPostEdit = !state.isOpenPostEdit;
    },
    setEditPostId: (state: any, action: PayloadAction<any>) => {
      state.editPostId = action.payload;
    },
    setLocation: (state: any, action: PayloadAction<any>) => {
      if (action.payload !== null) {
        state.location = action.payload;
      }
    },
    setLikedUsers: (state: any, action: PayloadAction<any>) => {
      if (action.payload != null) {
        state.likedUsers = action.payload;
      }
    },
  },
});

export const { setLikeModal, setSelectedPostId, setLikedUsers, setOpenPostEdit, setOpenPostCreate, setEditPostId, setLocation } = feedSlice.actions;

export const feedSelector = (state: Feed) => state;

export default feedSlice.reducer;
