import { FC } from 'react';
import { useListView } from '../../providers/ListViewProvider';
import { KTIcon } from '../../../../../../_metronic/helpers';
type Props = {
  title: string;
  toggle?: Function;
  subTitle?: any;
  leftComponent?: any;
};
const ModalHeader: FC<Props> = (props) => {
  const { setItemIdForUpdate, setitemImagePathFormPreview } = useListView();
  return (
    <div className="modal-header">
      <div className="d-flex align-items-center gap-4">
        <div>{props.leftComponent}</div>
        <div>
          <div className="fw-bolder fs-1">{props.title}</div>
          {props.subTitle && <span className="fw-bold text-gray-500">{props.subTitle}</span>}
        </div>
      </div>
      {props.toggle && (
        <div
          className="btn btn-icon btn btn-active-light-info"
          data-kt-users-modal-action="close"
          onClick={() => {
            setItemIdForUpdate(undefined);
            setitemImagePathFormPreview(undefined);
            if (props.toggle) props.toggle!(false);
          }}
          style={{ cursor: 'pointer' }}
        >
          <KTIcon iconName="cross" className="fs-2qx" iconOverride="text-hover-info text-gray-600" />
          {/* <i className="bi bi-x-lg fs-2 text-black"></i> */}
        </div>
      )}
    </div>
  );
};
export { ModalHeader };
