import { useIntl } from 'react-intl';
import { KTIcon } from '../../../../helpers';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './_animatedSearchBar.scss';

export default function AnimatedSearchbar() {
  const intl = useIntl();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleSearchbar = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  const handleCloseButtonClick = useCallback(() => {
    setIsExpanded(false);
    setSearchTerm('');
  }, []);

  const handleSearch = useCallback(() => {
    if (searchTerm.length < 3) {
      return;
    }
    setIsExpanded(false);
    navigate(`/search?searchTerm=${searchTerm}`);
    setSearchTerm('');
  }, [navigate, searchTerm]);

  return (
    <div className={`search-bar me-5 ${isExpanded && 'expanded'}`}>
      <div className={`search-input-container ${isExpanded && 'expanded'}`}>
        {!isExpanded && (
          <div className={`btn btn-icon btn-active-light-info`} onClick={toggleSearchbar}>
            <KTIcon iconName="magnifier" iconOverride="text-gray-600" className={`fs-2x`} />
          </div>
        )}

        {isExpanded && (
          <div className="d-flex align-items-center position-relative w-100">
            <div className="position-absolute ms-4 mt-0">
              <div className={`btn m-0 p-0`} onClick={handleSearch}>
                <KTIcon iconName="magnifier" iconOverride="text-gray-600" className="fs-1" />
              </div>
            </div>

            <input
              autoFocus
              type="text"
              value={searchTerm}
              className="form-control  ps-14"
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
              placeholder={intl.formatMessage({ id: 'SEARCH_PLACEHOLDER_TEXT' })}
            />

            <div className="position-absolute top-50 end-0 translate-middle-y">
              <button type="button" className="btn btn-icon btn-active" onClick={handleCloseButtonClick}>
                <KTIcon iconName="cross" iconOverride="text-gray-800" className="fs-1" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
