import { lazy, FC, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import HomePage from '../pages/home/Home';
import { MenuTestPage } from '../pages/MenuTestPage';
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils';
import { WithChildren } from '../../_metronic/helpers';
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper';
import { useAppSelector } from '../../api/store/hooks';
import { moduleSelector } from '../../api/features/module/moduleSlice';
import SuggestionPage from '../modules/apps/suggestion_module/SuggestionsPage';
import CheersLeadershipPage from '../modules/apps/cheers/CheersLeadershipPage';
import CheersPage from '../modules/apps/cheers/CheersPage';
import { authSelector } from '../../api/features/auth/authSlice';

const PrivateRoutes = () => {
  const modules = useAppSelector((state) => moduleSelector(state));
  const { currentUser } = useAppSelector((state) => authSelector(state.auth));
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));
  const DepartmentProfilePage = lazy(() => import('../pages/department_profile/DepartmentProfilePage'));
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'));
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'));
  const AnnouncementsPage = lazy(() => import('../modules/apps/announcement_module/AnnouncementsPage'));
  const FeedbackPage = lazy(() => import('../modules/apps/feedback_module/FeedbackPage'));
  const PillInformationsPage = lazy(() => import('../modules/apps/hap_bilgi_module/Pill-InformationsPage'));
  const UsersPage = lazy(() => import('../modules/apps/users_module/UsersPage'));
  const FoodMenuPage = lazy(() => import('../modules/apps/food_menu_module/FoodMenuPage'));
  const EventsPage = lazy(() => import('../modules/apps/events/EventsPage'));
  const JobsPage = lazy(() => import('../modules/apps/jobs/JobPage'));
  const SurveysPage = lazy(() => import('../modules/apps/surveys_module/SurveysPage'));
  // const FeedPage = lazy(() => import('../modules/apps/feed_module/FeedPage'));
  const GamesPage = lazy(() => import('../modules/apps/games_module/GamesPage'));
  const MarketPage = lazy(() => import('../modules/apps/market_module/MarketPage'));
  const LeadershipPage = lazy(() => import('../modules/apps/leadership_module/LeadershipPage'));
  const InviteFriendsPage = lazy(() => import('../modules/apps/invite_friends_module/InviteFriendsPage'));
  const SecondHandsPage = lazy(() => import('../modules/apps/secondhands_module/secondHandsPage'));
  const BirthdaysPage = lazy(() => import('../modules/apps/birthdays_module/BirthdaysPage'));
  const InAppSearchPage = lazy(() => import('../modules/apps/inapp_search_module/InAppSearchPage'));
  const PayrollPage = lazy(() => import('../modules/apps/payroll_module/PayrollModulePage'));
  const SettingsPage = lazy(() => import('../modules/settings/SettingsPage'));
  const NearMissPage = lazy(() => import('../modules/apps/near_miss_module/NearMissPage'));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/home" />} />
        {/* Pages */}
        <Route path="home" element={<HomePage />} />
        <Route path="builder" element={<BuilderPageWrapper />} />
        <Route path="menu-test" element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path="apps/surveys/*"
          element={
            <SuspensedView>
              <SurveysPage />
            </SuspensedView>
          }
        />

        <Route
          path="apps/games/*"
          element={
            <SuspensedView>
              <GamesPage />
            </SuspensedView>
          }
        />
        {/* <Route
          path="feed/*"
          element={
            <SuspensedView>
              <FeedPage />
            </SuspensedView>
          }
        /> */}
        <Route
          path="/profile/:id"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path="/department/:id"
          element={
            <SuspensedView>
              <DepartmentProfilePage />
            </SuspensedView>
          }
        />
        {/* {event && ( */}
        <Route
          path="apps/events/*"
          element={
            <SuspensedView>
              <EventsPage />
            </SuspensedView>
          }
        />
        {/* )} */}
        {/* {jobs && ( */}
        <Route
          path="apps/jobs/*"
          element={
            <SuspensedView>
              <JobsPage />
            </SuspensedView>
          }
        />
        {/* )} */}

        <Route
          path="crafted/pages/wizards/*"
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/account/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/announcements/*"
          element={
            <SuspensedView>
              <AnnouncementsPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/feedback/*"
          element={
            <SuspensedView>
              <FeedbackPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/informations/*"
          element={
            <SuspensedView>
              <PillInformationsPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/users/*"
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/food-menu/*"
          element={
            <SuspensedView>
              <FoodMenuPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/market/*"
          element={
            <SuspensedView>
              <MarketPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/leadership/*"
          element={
            <SuspensedView>
              <LeadershipPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/secondhands/*"
          element={
            <SuspensedView>
              <SecondHandsPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/suggestions/*"
          element={
            <SuspensedView>
              <SuggestionPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/payroll/*"
          element={
            <SuspensedView>
              <PayrollPage />
            </SuspensedView>
          }
        />
        <Route
          path="settings/*"
          element={
            <SuspensedView>
              <SettingsPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/birthdays/*"
          element={
            <SuspensedView>
              <BirthdaysPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/near-miss/*"
          element={
            <SuspensedView>
              <NearMissPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/invite-friends/*"
          element={
            <SuspensedView>
              <InviteFriendsPage />
            </SuspensedView>
          }
        />

        {currentUser?.companyid=== 10 || process.env.REACT_APP_CHEERS_OPEN_FOR_TEST == 'true' ? (
          <>
            <Route
              path="apps/cheers/leadership/*"
              element={
                <SuspensedView>
                  <CheersLeadershipPage />
                </SuspensedView>
              }
            />
            <Route
              path="apps/cheers/cheers/*"
              element={
                <SuspensedView>
                  <CheersPage />
                </SuspensedView>
              }
            />
          </>
        ) : (
          ''
        )}

        <Route
          path="/search/*"
          element={
            <SuspensedView>
              <InAppSearchPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-info');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1.5,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
