import { FC, useEffect, useState } from 'react';
import { useListView } from '../../providers/ListViewProvider';
import './_modal.scss';

type Props = {
  headerComponent?: any;
  formComponent: any;
  footerComponent?: any;
  isCenterFooter?: boolean;
  id: string;
  toggle?: Function;
  className?: string;
  isDisposableOnOutsideClick?: boolean;
  isScrollable?: boolean;
  bodyClassName?: string;
  bg?:boolean;
};
const Modal: FC<Props> = ({
  headerComponent,
  formComponent,
  footerComponent,
  isCenterFooter,
  id,
  toggle,
  className,
  isDisposableOnOutsideClick = true,
  isScrollable = true,
  bodyClassName,
  bg=true
}) => {
  const { setItemIdForUpdate, setitemImagePathFormPreview } = useListView();
  const [animationState, setAnimationState] = useState({
    init: true,
    animateForward: false,
    animateBackward: false,
  });
  useEffect(() => {
    if (document.body.getElementsByClassName(id).length > 0)
      document.body.getElementsByClassName(id)[0].classList.add('modal-open');
    return () => {
      if (document.body.getElementsByClassName(id).length > 0)
        document.body.getElementsByClassName(id)[0].classList.remove('modal-open');
    };
  }, []);

  const init = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    document.body.style.overflow = 'hidden';
    if (isMobile) {
      document.body.style.position = 'fixed';
    }
  };

  useEffect(() => {
    if (!isScrollable) {
      init();
    } else {
      document.body.style.overflow = 'auto';
      document.body.style.position = '';
    }
    return () => {
      document.body.style.overflow = 'auto';
      document.body.style.position = '';
    };
  }, [isScrollable]);

  return (
    <>
      <div
        className={`modal fade show d-block ${
          !animationState.init
            ? animationState.animateForward
              ? 'scale-up-entered'
              : animationState.animateBackward
              ? 'scale-down-entered'
              : ''
            : ''
        } ${id}`}
        id={id}
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
        onClick={() => {
          if (isDisposableOnOutsideClick) {
            setItemIdForUpdate(undefined);
            setitemImagePathFormPreview(undefined);
            if (toggle) toggle!(false);
          } else {
            setAnimationState({
              init: false,
              animateForward: true,
              animateBackward: false,
            }); //** */ Trigger scale-up-down animation
            setTimeout(() => {
              setAnimationState({
                init: false,
                animateForward: false,
                animateBackward: true,
              });
            }, 300);
          }
        }}
      >
        {/* begin::Modal dialog */}
        <div
          onClick={(e) => e.stopPropagation()}
          className={`modal-dialog modal-dialog-centered mw-1000px ${className}`}
        >
          {/* begin::Modal content */}
          <div className="modal-content">
            {headerComponent ?? undefined}
            {/* begin::Modal body */}
            <div
              className={`modal-body scroll-y ${bodyClassName ? `${bodyClassName}` : 'mx-5 mx-xl-15 '}`}
              style={{ maxHeight: footerComponent ? 'calc(100vh - 240px)' : 'calc(100vh - 200px)' }}
            >
              {formComponent}
            </div>

            {footerComponent && (
              <div className={`modal-footer col-md-12 w-100  justify-content-${isCenterFooter ? 'center' : 'end'}`}>
                {footerComponent}
              </div>
            )}
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      {bg&&
      <div className="modal-backdrop fade show"></div> 
      }
      {/* end::Modal Backdrop */}
    </>
  );
};

export { Modal };
