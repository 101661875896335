import React, { FC, useEffect, useState } from 'react';
import { KTIcon, toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { useLikeCommentMutation } from '../../../feed_module/services/feedEndpoints';
import CommentDotsDropdown from '../Dropdown/CommentDotsDropDown';
import { useIntl } from 'react-intl';
import { format, parse } from 'date-fns';
import { authSelector } from '../../../../../../api/features/auth/authSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../api/store/hooks';
function mingFlowDurationRounded(stringDateTime: string | null): string {
  if (!stringDateTime) {
    return '';
  }

  let duration: Date;
  if (stringDateTime.length === 16) {
    duration = parse(stringDateTime, 'dd/MM/yyyy HH:mm', new Date());
  } else if (stringDateTime.length === 19) {
    duration = parse(stringDateTime, 'dd/MM/yyyy HH:mm:ss', new Date());
  } else {
    return '';
  }

  const now = new Date();
  const difference = now.getTime() - duration.getTime(); // Get difference in milliseconds

  const differenceInDays = Math.floor(difference / (1000 * 60 * 60 * 24));
  const differenceInHours = Math.floor((difference / (1000 * 60 * 60)) % 24);
  const differenceInMinutes = Math.floor((difference / (1000 * 60)) % 60);

  if (differenceInDays > 0) {
    return `${differenceInDays}g`;
  } else if (differenceInHours > 0) {
    return `${differenceInHours}s`;
  } else if (differenceInMinutes > 0) {
    return `${differenceInMinutes}d`;
  } else {
    return '1d';
  }
}
type Props = {
  refetch: any;
  comment: any;
  navigate?: any;
  rounded?: string;
  className?: string;
  isPostOwner: boolean;
  setCommentCount: Function;
  feedid:number;
  mainComment?:boolean;
  setVisibleCommentsReply?:any;
  isVisibleCommentsReply?:boolean;
  setCommentParentId?:any
};

const UserCommentBadge: FC<Props> = ({
  comment,
  className,
  rounded,
  navigate,
  refetch,
  isPostOwner,
  setCommentCount,
  feedid,
  mainComment=false,
  setVisibleCommentsReply,
  isVisibleCommentsReply,
  setCommentParentId
}) => {
  const intl = useIntl();
  const { commenttext, pictureurl, created, like_count, userid, did_i_like, owner,fullname,commentid} = comment || {};
  const [likeCount, setLikeCount] = useState(0);
  const [isLiked, setLiked] = useState(false);
  const [likeComment] = useLikeCommentMutation();
  const { currentUser } = useAppSelector((state) => authSelector(state.auth));

  useEffect(() => {
    setLiked(did_i_like);
    setLikeCount(like_count);
  }, [did_i_like, like_count]);

  const handleLike = () => {
    setLiked(!isLiked);
    setLikeCount((prev: number) => (isLiked ? prev - 1 : prev + 1));
    likeComment({
      comment_id: commentid,
    });
  };
  return (
    <div className={`d-flex gap-5 ${className}`}>
      <img
        onClick={navigate}
        src={pictureurl ?? toAbsoluteUrl(`/media/avatars/Default Avatar.svg`)}
        alt="user"
        width={50}
        height={50}
        className={`${rounded ?? 'rounded-2'} cursor-pointer`}
      />
      <div className="d-flex flex-column justify-content-between w-100">
        <div className="d-flex gap-4 align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-2">
            <div onClick={navigate} className="fw-bold fs-4 cursor-pointer">
              {fullname}
            </div>
            <div className="fw-medium fs-6 text-gray-500">{mingFlowDurationRounded(created)}</div>
          </div>
         {currentUser?.usersecret.userid === userid &&
          <CommentDotsDropdown
            commentId={commentid}
            isOwner={currentUser?.usersecret.userid === userid ? true : false}
            refetch={refetch}
            isPostOwner={isPostOwner}
            setCommentCount={setCommentCount}
            feedid={feedid}
          />}
        </div>
        <div className="fw-medium fs-6 text-gray-800 mb-3">{commenttext}</div>
        <div>
          {mainComment &&
          <div
          onClick={()=>{
            setVisibleCommentsReply(!isVisibleCommentsReply);
            setCommentParentId(comment.parentid)}}
          style={{fontWeight:'bold',cursor:'pointer'}}>
            Yanıtla
          </div>}
         { /* 
         <button
            type="button"
            onClick={handleLike}
            className={`d-flex align-items-center gap-2 border-0 rounded-1 px-2 ${
              isLiked ? 'bg-light-primary text-primary' : 'bg-gray-300'
            }`}
          >
            <KTIcon
              iconName="heart"
              iconOverride={isLiked ? '#3E97FF' : '#7E8299'}
              className={`fs-3 ${isLiked ? 'text-primary' : ''}`}
            />
            {likeCount > 0 && (
              <div className={`d-flex align-items-center gap-2 fs-6 ${isLiked ? 'text-primary' : 'text-gray-600'}`}>
                <div className={`fs-6 fw-bold ${isLiked ? 'text-primary' : 'text-gray-600'}`}> {likeCount}</div>
                <div className="fs-8">&#x2022;</div>
              </div>
            )}
            <div className={`fs-6 fw-bold ${isLiked ? 'text-primary' : 'text-gray-600'}`}>
              {intl.formatMessage({ id: isLiked ? 'FEED_LIKED' : 'FEED_LIKE' })}
            </div>
          </button>
         */}
          
        </div>
      </div>
    </div>
  );
};

export default UserCommentBadge;
