import SimpleCardSkeleton from './SimpleCardSkeleton';
import { Grid, Skeleton, Stack } from '@mui/material';

export default function TimeLineSkeleton() {
  return (
    <Grid className="mt-1" container>
      <Grid item xs={4} lg={3}>
        <Stack direction="column" gap={2}>
          <SimpleCardSkeleton />
          <SimpleCardSkeleton />
        </Stack>
      </Grid>
      <Grid item xs={8} lg={6}>
        <Stack direction="column" gap={5}>
          <Stack marginX={2} gap={0}>
            <Stack direction="column">
              <Skeleton variant="text" width="30%" height={40} className="" />
              <Skeleton variant="text" width="40%" height={30} className="mb-4" />
            </Stack>

            <Stack marginLeft={1} marginBottom={3} direction="column">
              <Stack marginTop={1} direction="row" justifyContent="start" alignItems="center" gap={2}>
                <Skeleton variant="circular" width={60} height={55} animation="wave" />
                <Stack direction="column" width={'100%'}>
                  <Skeleton variant="text" width="30%" height={30} className="mb-2" />
                  <Skeleton variant="text" width="20%" height={30} className="mb-2" />
                </Stack>
              </Stack>
              <Stack direction="column" marginX={2} marginTop={2}>
                <Skeleton sx={{ borderRadius: '8px' }} variant="rounded" width="100%" height={200} animation="wave" />
                <Skeleton variant="text" width="100%" height={90} className="my-2" />

                <Skeleton variant="text" width="100%" height={3} />
                <Stack direction="row" gap={2} marginY={1}>
                  <Skeleton variant="rounded" width="17%" height={45} animation="wave" />
                  <Skeleton variant="rounded" width="17%" height={45} animation="wave" />
                </Stack>
                <Skeleton variant="text" width="100%" height={3} />
              </Stack>
            </Stack>

            <Stack marginLeft={1} direction="column">
              <Stack marginTop={1} direction="row" justifyContent="start" alignItems="center" gap={2}>
                <Skeleton variant="circular" width={60} height={55} animation="wave" />
                <Stack direction="column" width={'100%'}>
                  <Skeleton variant="text" width="30%" height={30} className="mb-2" />
                  <Skeleton variant="text" width="20%" height={30} className="mb-2" />
                </Stack>
              </Stack>

              <Stack direction="column" marginX={2} marginTop={2}>
                <Skeleton sx={{ borderRadius: '8px' }} variant="rounded" width="100%" height={100} animation="wave" />
                <Stack direction="row" marginTop={2} gap={2}>
                  <Skeleton variant="rounded" width="100%" height={35} animation="wave" />
                </Stack>
              </Stack>
            </Stack>

            {/*  */}
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={6} lg={3}>
        <Stack direction="column" gap={2}>
          <SimpleCardSkeleton />
          <SimpleCardSkeleton />
          <SimpleCardSkeleton />
        </Stack>
      </Grid>
    </Grid>
  );
}
