import api from '../../../../../api/api';
import { IBirthdayListRequest, ICelebrateBirthdayRequest } from './_modals';

export const birthdaysApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBirthdayList: builder.query<any, IBirthdayListRequest>({
      query: ({ search_text, branch_id, branch, department }) => {
        const addedParams: any = {};
        if (search_text) {
          addedParams['search_text'] = search_text;
        }
        if (branch_id) {
          addedParams['branch_id'] = branch_id;
        }
        if (branch) {
          addedParams['branch'] = branch;
        }
        if (department) {
          addedParams['department'] = department;
        }
        const params = new URLSearchParams(addedParams);
        return `birthdays?${params.toString()}`;
      },
    }),
    getBirthdayCelebrationTemplates: builder.query<any, any>({
      query: ({userid}) => {
        return `/birthdays/default`;
      },
    }),
    celebrateBirthday: builder.mutation<any, any>({
      query: ({ birthday_user_id, template_id, default_message, gift_score }) => {
        var body = {
          celebrationmessageid: template_id,
          celebrationmessagedesc: default_message,
          gift: gift_score,
        };
        return {
          url: `birthdays/${birthday_user_id}`,
          method: 'POST',
          body: body,
        };
      },
    }),
    getBirthdayPoint: builder.query<any, any>({
      query: ({id}) => {
        return `/birthdays/points`;
      },
    }),
    getBirthdayProfile: builder.query<any, { receiveruserid?: number; page: number; pagesize: number }>({
      query: ({ receiveruserid, page, pagesize }) => {
          return `/birthdays/celebrations/${receiveruserid}`;
      },
      serializeQueryArgs: ({ endpointName }) => endpointName,
      // merge: (currentCache, newItems, { arg }) => {
      //   if (arg.receiveruserid) {
      //     currentCache.congratulations = newItems.congratulations;
      //   } else {
      //     currentCache.congratulations.push(...newItems.congratulations);
      //   }
      // },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page;
      },
  }),
  }),
});

export const { useGetBirthdayListQuery, useGetBirthdayCelebrationTemplatesQuery, useCelebrateBirthdayMutation,useGetBirthdayPointQuery,useGetBirthdayProfileQuery } =
  birthdaysApi;
