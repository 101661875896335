import * as Yup from 'yup';
import React, { Fragment, useCallback, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { Stack } from '@mui/material';
import Loading from '../../../../components/Loading';
import { useCreateFeedbackMutation, useGetFeedbackCategoriesQuery, useUploadServiceMutation } from '../core/_requests';
import GenericSelect from '../../_global/components/Select/GenericSelect';
import InputWrapper from '../../_global/components/Input/InputWrapper';
import { useIntl } from 'react-intl';
import { ImageUpload } from '../../_global/components/Input/ImageUpload';
import { okayMessage } from '../../_global/components/Modal/ConfirmMessage';

type Props = {
  setIsOpen?: any;
  toggle?: Function;
  refetchFeedbacks?: any;
  setReflesh?:any
};

const MemoizedImageUpload = React.memo(ImageUpload);

export default function CreateFeedbackForm({ setIsOpen, toggle, refetchFeedbacks,setReflesh }: Props) {
  const intl = useIntl();
  const fileRef = useRef(null);
  const [imageValues, setImageValues] = useState<any>(null); // Image list
  const { data: feedBackCategories, isLoading: fetch, isFetching: refetch } = useGetFeedbackCategoriesQuery();
  const [createFeedback, { isLoading: createFeedbackFetch }] = useCreateFeedbackMutation();
  const [uploadImage] = useUploadServiceMutation();
  const isLoading = fetch || refetch;

  const validationSchema = Yup.object().shape({
    feedBackCategory: Yup.string().required(intl.formatMessage({ id: 'FEEDBACK_CATEGORY_EMPTY_ERROR' })),
    feedBackDescription: Yup.string().required(intl.formatMessage({ id: 'FEEDBACK_DESCRIPTION_EMPTY_ERROR' })),
  });

  const handleImageUpload = useCallback(
    async (images: any) => {
      const uploadedImagePaths: string[] = [];
      for (const image of images) {
        try {
          const result = await uploadImage(image).unwrap();
          if (result.filepath) {
            uploadedImagePaths.push(result.filepath);
          }
        } catch (error) {
          console.error('Resim yükleme hatası:', error);
        }
      }
      setImageValues(uploadedImagePaths);
    },
    [uploadImage],
  );

  return (
    <Fragment>
      {isLoading ? (
        <Loading />
      ) : (
        <Formik
          initialValues={{
            feedBackCategory: '',
            feedBackDescription: '',
          }}
          onReset={() => {
            setImageValues(null);
          }}
          validationSchema={validationSchema}
          onSubmit={async (values: any) => {
            const requestBody = {
              feedback_type_id: values.feedBackCategory,
              title: '', // You can add a title field if necessary
              description: values.feedBackDescription,
              images: imageValues, // Pass images here
            };
            await createFeedback(requestBody)
              .unwrap()
              .then(async(_: any) => {
                okayMessage(
                  intl.formatMessage({ id: 'SEND_FEEDBACK_SUCCESS_TITLE_TEXT' }),
                  intl.formatMessage({ id: 'SEND_FEEDBACK_SUCCESS_SUBTITLE_TEXT' }),
                );
              await  setIsOpen && setIsOpen(false);
              toggle && toggle();
              await  setImageValues(null);
                setReflesh &&setReflesh(true)

              })
              .catch((_: any) => {
                okayMessage(
                  intl.formatMessage({ id: 'SEND_FEEDBACK_ERROR_TITLE_TEXT' }),
                  intl.formatMessage({ id: 'SEND_FEEDBACK_ERROR_SUBTITLE_TEXT' }),
                );
              });
            refetchFeedbacks && (await refetchFeedbacks());
          }}
        >
          {(props) => {
            return (
              <Form>
                <Stack direction="column" justifyContent="center" alignItems="center" gap={1}>
                  <div className="row col-md-12">
                    <GenericSelect
                      required
                      closeMenuOnSelect
                      isClearable={false}
                      name="feedBackCategory"
                      value={props?.values?.feedBackCategory}
                      title={intl.formatMessage({ id: 'FEEDBACK_PAGE_CATEGORY_SELECT_TITLE' })}
                      options={feedBackCategories?.data?.map((item: any) => ({
                        value: item?.categoryid,
                        label: item?.categorydesc,
                      }))}
                    />
                  </div>
                  <div className="row col-md-12">
                    <InputWrapper
                      required={true}
                      name={'feedBackDescription'}
                      title={intl.formatMessage({ id: 'FEEDBACK_PAGE_CATEGORY_TEXT_AREA_TITLE' })}
                    >
                      <textarea
                        rows={3}
                        className="form-control"
                        name={'feedBackDescription'}
                        value={props?.values?.feedBackDescription}
                        onBlur={props.handleBlur}
                        onChange={props.handleChange}
                        placeholder={intl.formatMessage({ id: 'TEXT_AREA_PLACEHOLDER' })}
                      />
                    </InputWrapper>
                  </div>
                  <div className="row col-md-12 align-self-start ms-1">
                    <InputWrapper title={intl.formatMessage({ id: 'FEEDBACK_PAGE_CATEGORY_BELONGING_IMAGE' })}>
                      <MemoizedImageUpload
                        width="200px"
                        ref={fileRef}
                        isMulti={true}
                        maxImageCount={5}
                        onImageUpload={handleImageUpload}
                      />
                    </InputWrapper>
                  </div>
                  <button
                    type="submit"
                    disabled={props.errors ? false : true}
                    className={`btn ${
                      props.values.feedBackCategory &&
                      props.values.feedBackDescription &&
                      Object.keys(props.errors).length === 0
                        ? 'btn-info'
                        : 'btn-secondary'
                    }`}
                  >
                    {createFeedbackFetch ? (
                      <span className="indicator-progress" style={{ display: 'block' }}>
                        {intl.formatMessage({ id: 'PLEASE.WAITING' })}
                        <span className="spinner-border spinner-border-sm align-middle ms-3"></span>
                      </span>
                    ) : (
                      intl.formatMessage({ id: 'FEEDBACK_PAGE_SEND_FEEDBACK' })
                    )}
                  </button>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      )}
    </Fragment>
  );
}
