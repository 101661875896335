import {Outlet, Route, Routes} from "react-router-dom";
import React from "react";
import CheersList from "./CheersList";

const CheersPage = () => {
    return (
        <Routes>
            <Route element={<Outlet />} />
            <Route index element={<CheersList/>} />
        </Routes>
    );
};

export default CheersPage;