import {
  AuthModel,
  CheckUserResponse,
  OwnedDataModel,
  SmsVerificationModel,
  StartModel,
  UserInformation,
  UserModel,
} from "../../../app/modules/auth";
import api from "../../api";

//? We can move this type definitions to "_models" file
interface IAuthResponse {
  userId: number;
  id: number;
  title: string;
  completed: boolean;
}

//? We can move this type definitions to "_requests" file
export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<CheckUserResponse, any>({
      query: (credentials) => ({
        url: `checkuserkey`,
        method: "POST",
        body: credentials,
      }),
    }),
    loginPassword: builder.mutation<UserInformation, any>({
      query: (credentials) => ({
        url: `newlogin`,
        method: "POST",
        body: credentials,
      }),
    }),
    forgotPassword: builder.mutation<any, any>({
      query: (credentials) => ({
        url: `forgotpassword`,
        method: "POST",
        body: credentials,
      }),
    }),
    smsVerification: builder.mutation<any, any>({
      query: (credentials) => ({
        url: `/requestpasswordreset`,
        method: "POST",
        body: credentials,
      }),
    }),
    start: builder.mutation<StartModel, any>({
      query: (credentials) => ({
        url: `/start`,
        method: "GET",
      }),
    }),
    getUserByToken: builder.mutation<UserModel, string | void>({
      query: (apiToken) => ({
        url: `/verify_token`,
        method: "POST",
        body: { api_token: apiToken },
      }),
    }),
    formDataExample: builder.mutation({
      query: (newPost) => ({
        url: "form-data-example",
        method: "POST",
        body: newPost,
        formData: true,
      }),
    }),
    smsForgotPassword: builder.mutation<any, any>({
      query: (credentials) => ({
        url: `/forgotpassword`,
        method: "POST",
        body: credentials,
      }),
    }),
    postSetPassword: builder.mutation<any, any>({
      query: (credentials) => ({
        url: `/newsetpassword`,
        method: "POST",
        body: credentials,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useFormDataExampleMutation,
  useLoginPasswordMutation,
  useGetUserByTokenMutation,
  useSmsVerificationMutation,
  useStartMutation,
  useSmsForgotPasswordMutation,
  usePostSetPasswordMutation,
  useForgotPasswordMutation
} = authApi;

export default authApi;
