import { useState } from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import LoginInput from './LoginInput';
import SmsVerificationInput from './SmsVerificationInput';
import PasswordInput from './loginPassword';
import { useForgotPasswordMutation } from '../../../../api/services/auth/authEndpoints';

export function Login() {
  const [isSmsVerification, setIsSmsVerification] = useState(false);
  const [username, setUsername] = useState('');
  const [userdata, setUserData] = useState<UserData | null>(null);

  const onsubmitLogin = async (contact: any, username: string) => {
    setUsername(username);
    setUserData(contact);
    setIsSmsVerification(true);
  };
  const [forgotPassword, { isLoading: loadinforgot }] = useForgotPasswordMutation();

  interface RequiredDoc {
    consentdocdesc: string;
    consentdocid: number;
    fileuri: string;
    isrequired: number;
    version: string;
  }

  interface UserData {
    otpid: number;
    otpseconds: number;
    requireconsent: boolean;
    requireddocs: RequiredDoc[];
    showotp: boolean;
  }

  const handleForgotPassword = async (username: string) => {
    try {
      const response = await forgotPassword({
        username: username,
      }).unwrap();
      setUserData(response);
    } catch (error) {}
  };

  return (
    <div className="container-fluid">
      <div className="row">
        {!isSmsVerification ? (
          <LoginInput onSubmitLogin={onsubmitLogin} />
        ) : userdata?.showotp ? (
          <SmsVerificationInput userData={userdata} username={username} />
        ) : (
          <PasswordInput
            forgotPasswordFunc={handleForgotPassword}
            username={username}
            consentsList={userdata !== null ? userdata.requireddocs : []}
          />
        )}

        <div
          className="col-md-6 d-flex flex-column justify-content-center align-items-center"
          style={{
            backgroundImage: `url(${toAbsoluteUrl('/media/auth/bg4.png')})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            height: '100vh',
          }}
        >
          <img
            alt="Logo"
            src={toAbsoluteUrl('/media/logos/ming_amblem.svg')}
            style={{
              width: '255px',
              height: '255px',
              justifyContent: 'center',
              alignSelf: 'center',
            }}
          />
          <div className="text-white fs-3x fw-bolder mt-5">Tüm şirket</div>
          <div className="fs-3x fw-bolder" style={{ color: '#FF7B57' }}>
            Tek Platform.
          </div>
        </div>
      </div>
    </div>
  );
}
