import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthModel, UserInfo, UserInformation } from "../../../app/modules/auth";

// Helper function to store auth token in local storage
const storeAuthToken = (token: string) => {
  localStorage.setItem("authToken", token);
};

const storeAuthHeaderCode = (token: string) => {
  localStorage.setItem("headerCode", token);
};

// Helper function to remove auth token from local storage
const removeAuthToken = () => {
  localStorage.removeItem("authToken");
};
const removeHeaderCode = () => {
  localStorage.removeItem("headerCode");
};

// Helper function to get auth token from local storage
const getAuthToken = () => {
  return localStorage.getItem("authToken");
};

const getHeaderCode = () => {
  return localStorage.getItem("headerCode");
};

interface AuthState {
  auth: UserInformation | undefined;
  currentUser: UserInformation | undefined;
  list: any;
  token: string | null;
  headerCode: string | null;
}


const initialState: AuthState = {
  auth: undefined,
  currentUser: undefined,
  list: undefined,
  headerCode: getHeaderCode(),
  token: getAuthToken(),
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<UserInformation | undefined>) => {
      state.auth = action.payload;

      // Tüm kullanıcı bilgilerini kaydediyoruz
      if (action.payload) {
        // Tokeni saklama işlemi
        const sessionKey = action.payload.usersecret.token;
        if (sessionKey) {
          storeAuthToken(sessionKey); // Tokeni localStorage'a yaz
          storeAuthHeaderCode(sessionKey); // headerCode'u da yaz
          state.token = sessionKey;
          state.headerCode = sessionKey;
        }

        // İsteğe bağlı olarak diğer bilgileri de localStorage'a yazabilirsiniz
        localStorage.setItem('userInfo', JSON.stringify(action.payload)); // Tüm kullanıcı bilgilerini saklayın
        state.currentUser = action.payload; // Redux state'e tüm kullanıcı bilgilerini kaydediyoruz
      } else {
        state.token = null;
        state.headerCode = null;
        state.currentUser = undefined;
        removeAuthToken(); // Tokeni temizliyoruz
        removeHeaderCode(); // Header kodunu temizliyoruz
        localStorage.removeItem('userInfo'); // Kullanıcı bilgilerini localStorage'dan temizliyoruz
      }
    },
    setHeaderCode: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload) {
        state.headerCode = action.payload;
        storeAuthHeaderCode(action.payload); // headerCode'u localStorage'a yazıyoruz
      }
    },
    setCurrentUser: (state, action: PayloadAction<UserInformation | undefined>) => {
      state.currentUser = action.payload;
    },
    setList: (state, action: PayloadAction<any>) => {
      state.list = action.payload;
    },
    logout: (state) => {
      state.auth = undefined;
      state.currentUser = undefined;
      state.token = null;
      state.headerCode = null;
      removeAuthToken();
      removeHeaderCode();
      localStorage.removeItem('userInfo'); // localStorage'dan kullanıcı bilgilerini temizliyoruz
    },
  },
});

export const { setAuth, setCurrentUser, setList, logout, setHeaderCode } =
  authSlice.actions;

export const authSelector = (state: AuthState) => state;

export default authSlice.reducer;