import React, { FC } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 0px 50px 0px rgba(82, 63, 105, 0.15)",
    color: "#6d6d80",
    fontSize: 12,
    fontWeight: 500,
    padding: 10,
  },
}));

type Props = {
  title: string;
};

const TooltipInfo: FC<Props> = ({ title }) => {
  return (
    <div>
      <LightTooltip title={title} placement="right-start">
        <i className="fas fa-exclamation-circle cursor-pointer"></i>
      </LightTooltip>
    </div>
  );
};

export default TooltipInfo;
