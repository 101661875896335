import React, { useCallback, useEffect, useRef, useState } from 'react';
import InputWrapper from '../_global/components/Input/InputWrapper';
import { ImageUpload } from '../_global/components/Input/ImageUpload';
import { Stack } from 'react-bootstrap';

import AsyncSelect from 'react-select/async';
import { useGetUsersQuery } from '../users_module/core/_requests';
import { KTIcon, toAbsoluteUrl, useDebounce } from '../../../../_metronic/helpers';
import { authSelector } from '../../../../api/features/auth/authSlice';
import { useAppSelector } from '../../../../api/store/hooks';
import { Divider } from 'antd';
import axios from 'axios';
import ImageSlider from '../_global/components/Swiper/ImageSlider/ImageSlider';

export default function CreateKaizenForm({ onSuccess, selectedSuggestion, isReview, isList = false }: any) {
  const fileRef = useRef(null);
  const fileAfterRef = useRef(null);

  const [imageValues, setImageValues] = useState<any>(null);
  const [imageAfterValues, setImageAfterValues] = useState<any>(null);

  const [imageKazienAfter, setImageKaizenAfter] = useState<any>(null);

  const [beforeKaizenText, setBeforeKaizenText] = useState('');
  const [afterKazienText, setAfterKaizenText] = useState('');

  const user = useAppSelector((state) => authSelector(state.auth));

  const [selectedEmployees, setSelectedEmployees] = useState<any>([
    { label: 'Seçiniz', value: null, image: null, position: null },
  ]);

  useEffect(() => {
    if (isReview) {
      fetchKaizen(selectedSuggestion?.kaizenid);
    }
  }, []);

  const fetchKaizen = async (id: any) => {
    const res = await fetch(`https://suggestion.mingapp.co/kaizen/detail/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const result = await res.json();
    const parseImage = JSON.parse(result?.data?.before_media);
    const beforeImage = JSON.parse(result?.data?.kaizen_media);

    setBeforeKaizenText(result?.data?.loss_before_kaizen);
    setAfterKaizenText(result?.data?.gain_after_kaizen);
    setImageKaizenAfter(parseImage);
    setImageAfterValues(beforeImage?.map((e: any) => ({ horizontal_image: e })));
    setSelectedEmployees(
      result?.data?.solver_users?.map((e: any, index: number) => ({
        label: `${e?.name} ${e?.lastName}`,
        value: index,
        image: e?.image,
        position: e?.position,
      })),
    );
  };

  const loadOptions = (inputValue: any) => {
    return fetch(`https://mobile.mingapp.co/api/v1/search/addressbook?search=${inputValue}&limit=20&page=1`, {
      headers: {
        Authorization: `Bearer ${user.token}`,
        HeaderCode: `${user.headerCode}`,
      },
    })
      .then((response) => response.json())
      .then((data) =>
        data.data.map((user: any) => ({
          label: user.name,
          value: user.id,
          image: user.avatar,
          position: user.position,
        })),
      );
  };

  const createSuggestion = async () => {
    const id: any = user.currentUser?.usersecret?.userid;
    var bodyFormData = new FormData();
    var asdsad = isList ? JSON.parse(selectedSuggestion?.media) : selectedSuggestion?.media ?? [];
    console.warn(asdsad);
    const persons = selectedEmployees.map((e: any) => e.value);
    console.log(imageAfterValues);
    bodyFormData.append('user_id', id);
    bodyFormData.append('suggestion_id', selectedSuggestion?.id);
    bodyFormData.append('loss_before_kaizen', beforeKaizenText);
    bodyFormData.append('gain_after_kaizen', afterKazienText);
    bodyFormData.append('title', 'sasasdasdsadsadsa');
    bodyFormData.append('description', 'kaizenAfterText');
    imageAfterValues.forEach((image: any, index: number) => {
      bodyFormData.append(`kaizen_media`, image);
    });

    if (selectedSuggestion?.media === null) {
      imageValues.forEach((image: any, index: number) => {
        bodyFormData.append(`before_media`, image);
      });
    }

    bodyFormData.append('solver_ids', persons);

    await axios({
      method: 'POST',
      url: 'https://suggestion.mingapp.co/kaizen',
      data: bodyFormData,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((response: any) => {
        console.warn(response);
        onSuccess();
      })
      .catch((error) => {});
  };

  const handleImageUpload = useCallback((images: any) => {
    setImageValues(images ?? null);
  }, []);
  const handleAfterImage = useCallback((images: any) => {
    setImageAfterValues(images ?? null);
  }, []);

  const formatOptionLabel = ({ value, label, position, image }: any) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={image ?? toAbsoluteUrl('/media/avatars/Default Avatar.svg')}
        alt={value?.name}
        height={25}
        width={25}
        className="mr-4"
        style={{ marginRight: 10, borderRadius: 360 }}
      />
      <div>{label}</div>
      <div style={{ marginLeft: '10px', color: '#ccc' }}>{position}</div>
    </div>
  );
  console.log(imageValues);

  return (
    <div>
      <div className="px-4 form-label fw-bold fs-6">{selectedSuggestion?.title}</div>
      <div className="px-4 mt-1 fs-6 text-gray-500">{selectedSuggestion?.description}</div>
      <div className="row col-md-12 align-self-start ms-1 mt-5">
        <InputWrapper
          title={
            selectedSuggestion?.media !== null ? 'Görsel' : imageKazienAfter?.length > 0 ? 'Görsel' : 'Görsel Ekle'
          }
          required={selectedSuggestion?.media === null ? (imageKazienAfter?.length > 0 ? false : true) : false}
        >
          {selectedSuggestion?.media !== null ? (
            <ImageSlider
              images={isList ? selectedSuggestion?.media : selectedSuggestion?.media ?? []}
              isPreviewEnabled={true}
            />
          ) : (
            <>
              {imageKazienAfter?.length > 0 ? (
                <ImageSlider images={imageKazienAfter ?? []} isPreviewEnabled={true} />
              ) : (
                <ImageUpload
                  isPreview={isReview}
                  width="200px"
                  ref={fileRef}
                  isMulti={true}
                  maxImageCount={10}
                  onImageUpload={handleImageUpload}
                />
              )}
            </>
          )}
        </InputWrapper>
      </div>
      <InputWrapper title={'Kaizen Öncesi Kayıp Açıklaması (Opsiyonel)'} containerClassName="px-4 mt-5">
        <textarea
          rows={3}
          disabled={isReview}
          className="form-control"
          placeholder={'Metin Giriniz'}
          value={beforeKaizenText}
          onChange={(e) => setBeforeKaizenText(e.target.value)}
        />
      </InputWrapper>
      <Divider />
      <InputWrapper required={true} title={'Kaizen Sonrası Kazanım Açıklaması'} containerClassName="px-4 mt-5">
        <textarea
          rows={3}
          disabled={isReview}
          className="form-control"
          placeholder={'Metin Giriniz'}
          value={afterKazienText}
          onChange={(e) => setAfterKaizenText(e.target.value)}
        />
      </InputWrapper>
      <div className="row col-md-12 align-self-start ms-1 mt-5">
        {isReview ? (
          <InputWrapper title={'Görsel'}>
            <ImageSlider images={imageAfterValues} isPreviewEnabled={true} />
          </InputWrapper>
        ) : (
          <InputWrapper title={'Görsel Ekle'} required>
            <ImageUpload
              width="200px"
              ref={fileAfterRef}
              isMulti={true}
              maxImageCount={10}
              onImageUpload={handleAfterImage}
            />
          </InputWrapper>
        )}
      </div>
      <InputWrapper required={true} title={'Kaizen Çalışmasında Yer Alan Çalışanlar'} containerClassName="px-4 mt-5">
        {selectedEmployees?.map((e: any, index: any) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className="mb-5" style={{ flex: 1 }}>
                <span className={`${index === 0 && 'required'}`}>{index + 1}.Çalışan</span>
                <AsyncSelect
                  isDisabled={isReview}
                  isClearable={index === 0 ? false : true}
                  formatOptionLabel={formatOptionLabel}
                  cacheOptions
                  placeholder="Seçiniz"
                  loadOptions={loadOptions}
                  defaultOptions
                  value={e}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      height: 48,
                    }),
                  }}
                  classNames={{
                    control: (state) => 'mt-2',
                  }}
                  onChange={(value: any) => {
                    const updatedEmployees = [...selectedEmployees];
                    updatedEmployees[index] = value;
                    setSelectedEmployees(updatedEmployees);
                  }}
                />
              </div>
            </div>
          );
        })}
        {!isReview && (
          <button
            type="submit"
            disabled={false}
            className={`btn ${'btn-info'} `}
            style={{ alignSelf: 'center' }}
            onClick={() => {
              setSelectedEmployees([...selectedEmployees, { label: null, value: null, image: null, position: null }]);
            }}
          >
            Çalışan Ekle
          </button>
        )}
      </InputWrapper>

      {!isReview && (
        <Stack>
          <button
            type="submit"
            disabled={
              imageAfterValues?.length === 0 ||
              imageAfterValues === null ||
              afterKazienText === '' ||
              selectedEmployees[0].value === null ||
              (imageValues === null ? false : imageValues.length === 0 ? true : false)
            }
            className={`btn ${'btn-info'}`}
            style={{ alignSelf: 'center' }}
            onClick={createSuggestion}
          >
            Kaizeni Onaya Gönder
          </button>
        </Stack>
      )}
    </div>
  );
}
