import React, { FC, useEffect, useRef, useState } from 'react';
import { KTCard, toAbsoluteUrl } from '../../../../../_metronic/helpers';
import './_basicFeedCard.scss';
import Expandable from '../../../../modules/apps/_global/components/Expandable/Expandable';
import { dateFormatter } from '../../../../modules/_helpers/Helpers';
import ImageSlider from '../../../../modules/apps/_global/components/Swiper/ImageSlider/ImageSlider';
import { format, parse } from 'date-fns';
import LikeAndComment from '../../../../modules/apps/feed_module/components/LikeAndComment/LikeAndComment';
import CommentInput from '../../../../modules/apps/feed_module/components/CommentInput/CommentInput';
import Comments from '../../../../modules/apps/feed_module/components/Comments/Comments';
import LikeAndCommentCount from '../../../../modules/apps/feed_module/components/LikeAndCommentCount/LikeAndCommentCount';
import { useAppDispatch, useAppSelector } from '../../../../../api/store/hooks';
import { authSelector } from '../../../../../api/features/auth/authSlice';
import { useGetPostCommentsMutation } from '../../../../modules/apps/feed_module/services/feedEndpoints';
import { ElementAnimateUtil } from '../../../../../_metronic/assets/ts/_utils';
import { setLikeModal, setSelectedPostId } from '../../../../modules/apps/feed_module/features/feedSlice';
import { useNavigate } from 'react-router-dom';


type Props = {
  data: any;
  icon?: any;
  headerTitle: any;
};

function mingFlowDurationRounded(stringDateTime: string | null): string {
  if (!stringDateTime) {
    return '';
  }

  let duration: Date;
  if (stringDateTime.length === 16) {
    duration = parse(stringDateTime, 'dd/MM/yyyy HH:mm', new Date());
  } else if (stringDateTime.length === 19) {
    duration = parse(stringDateTime, 'dd/MM/yyyy HH:mm:ss', new Date());
  } else {
    return '';
  }

  const now = new Date();
  const difference = now.getTime() - duration.getTime(); // Get difference in milliseconds

  const differenceInDays = Math.floor(difference / (1000 * 60 * 60 * 24));
  const differenceInHours = Math.floor((difference / (1000 * 60 * 60)) % 24);
  const differenceInMinutes = Math.floor((difference / (1000 * 60)) % 60);

  if (differenceInDays > 0) {
    return `${differenceInDays}g`;
  } else if (differenceInHours > 0) {
    return `${differenceInHours}s`;
  } else if (differenceInMinutes > 0) {
    return `${differenceInMinutes}d`;
  } else {
    return '1d';
  }
}

const BasicFeedCard: FC<Props> = ({ data, icon, headerTitle }) => {
  const { startdate,title } = data || {};
  const dispatch = useAppDispatch();
  const postRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const commentsRef = useRef<HTMLInputElement>(null);


  const {
    promoted,
    createuser,
    mediauri,
    liked,
    created,
    total_comment_count,
    feedid,
    allowcomment,
    allowinteraction
  } = data || {};
  const { count, current_user_status } = liked || {};
  const { currentUser } = useAppSelector((state) => authSelector(state.auth));
  let isOwner;
  if(createuser === undefined ){
    isOwner = true;
  }else{
    isOwner = Number(createuser) === Number(currentUser?.usersecret.userid);
  }
 
  const [getPostComments, { data: comments, isLoading: isPostCommentsLoading }] = useGetPostCommentsMutation();

  const [likeCount, setCount] = useState(0);
  const [commentCount, setCommentCount] = useState(0);
  const [isLiked, setLiked] = useState(false);
  const [isVisibleComments, setVisibleComments] = useState(false);
  const [isVisibleCommentsReply, setVisibleCommentsReply] = useState(false);
  const [isCommentAvaliable, setIsCommentAvaliable] = useState(true);
  const [commentParentId,setCommentParentId]=useState<number>(0)

  useEffect(() => {
    setCount(count);
    setLiked(current_user_status);
    setCommentCount(total_comment_count);
    if (allowcomment === 0) {
      setIsCommentAvaliable(false);
    } else {
      setIsCommentAvaliable(true);
    }
  }, [current_user_status, count, total_comment_count, allowcomment]);

  const handleScrollToFirstComment = () => {
    if (commentsRef.current) {
      ElementAnimateUtil.scrollTo(commentsRef.current, 100);
    }
  };

  const handleScrollBackToPost = () => {
    if (postRef.current) {
      ElementAnimateUtil.scrollTo(postRef.current, 100);
    }
  };

  const commentButtonClick = async (isFocused?: boolean, scrollToFirstComment?: boolean) => {
    if (!isVisibleComments) {
      setVisibleComments(true);
      await getPostComments({ postId: feedid }).then((res: any) => {
        if (scrollToFirstComment && res?.data?.data?.length > 0) {
          handleScrollToFirstComment();
        }
        if (isFocused) {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }
      });
    } else {
      setVisibleComments(false);
      setCommentParentId(0)
      setVisibleCommentsReply(false)
    }
  };
  const likeButtonClick = async () => {
    dispatch(setLikeModal());
    dispatch(setSelectedPostId(feedid));
  };
  return (
    <KTCard className="p-8 event-card mb-8">
      <div className="d-flex gap-4 align-items-center mb-8">
        {icon}
        <div>
          <div className="fw-bold fs-3">{headerTitle}</div>
          <span className="fw-bold text-gray-500">{mingFlowDurationRounded(data.created)}</span>
        </div>
      </div>
      <div className="fw-bold text-gray-800 fs-4">{title}</div>
      <div className="post-description text-gray-600 my-3">
      {data?.description}
      </div>
      <div className="d-flex justify-content-center">
        <ImageSlider images={data?.mediauri} isPreviewEnabled={true} />
      </div>
      
      <div className="d-flex justify-content-between align-items-center">
        <LikeAndCommentCount
          likeCount={likeCount}
          commentCount={commentCount}
          likeButtonClick={likeButtonClick}
          isCommentAvaliable={isCommentAvaliable}
          commentButtonClick={() => commentButtonClick(false, true)}
        />
        {created && <div className={`${mediauri.length > 0 && 'mt-6'} mb-6`}>{mingFlowDurationRounded(created)}</div>}
      </div>

      
        <div className={`border-top border-bottom border-top-2 border-bottom-2 py-2 ${isVisibleComments && 'mb-6'}`}>
          <LikeAndComment
            allowinteraction={allowinteraction}
            allowcomment={allowcomment}
            postId={feedid}
            innerRef={commentsRef}
            isLiked={isLiked}
            setCount={setCount}
            setLiked={setLiked}
            isPromoted={promoted}
            isCommentAvaliable={isCommentAvaliable}
            commentButtonClick={() => commentButtonClick(true, true)}
          />
        </div>
      

      {isVisibleComments && (
        <Comments
          postId={feedid}
          data={comments?.comments}
          isPostOwner={isOwner}
          totalCount={comments?.comments.length}
          setCommentCount={setCommentCount}
          isVisibleComments={isVisibleComments}
          setVisibleComments={setVisibleComments}
          isLoadingComments={isPostCommentsLoading}
          handleScrollBackToPost={handleScrollBackToPost}
          refetch={() => getPostComments({ postId: feedid })}
          setVisibleCommentsReply={setVisibleCommentsReply}
          isVisibleCommentsReply={isVisibleCommentsReply}
          setCommentParentId={setCommentParentId}
        />
      )}
      {(isVisibleComments&& isVisibleCommentsReply===false) && (
        <CommentInput
          data={comments?.comments}
          id={feedid}
          ref={inputRef}
          image={currentUser?.pictureurl === '' || currentUser?.pictureurl == null ? toAbsoluteUrl('/media/avatars/blank.png') : currentUser?.pictureurl }
          setCommentCount={setCommentCount}
          getPostComments={getPostComments}
          handleScrollToFirstComment={handleScrollToFirstComment}
          setVisibleCommentsReply={setVisibleCommentsReply}

        />
      )}
{isVisibleCommentsReply && (
        <CommentInput
          data={comments?.comments}
          id={feedid}
          ref={inputRef}
          image={
            currentUser?.pictureurl === '' || currentUser?.pictureurl == null
              ? toAbsoluteUrl('/media/avatars/blank.png')
              : currentUser?.pictureurl
          }
          setCommentCount={setCommentCount}
          getPostComments={getPostComments}
          handleScrollToFirstComment={handleScrollToFirstComment}
          commentReplyPlaceholder='Yanıtla'
          commentParentId={commentParentId}
          setVisibleCommentsReply={setVisibleCommentsReply}
        />
      )}
    </KTCard>
  );
};

export default BasicFeedCard;
