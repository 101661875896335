import React, { FC, useEffect, useState } from 'react';
import './_question.scss';

import { useIntl } from 'react-intl';
import Option from '../../../Option/Option';
import Timer from '../Timer/Timer';
import {
  IQuestion,
  useAnswerGameMutation,
  useGetGamesQuery,
} from '../../../../../../../../api/services/games/gamesEndpoints';
import { okayMessage } from '../../../../../_global/components/Modal/ConfirmMessage';
import { useAppDispatch } from '../../../../../../../../api/store/hooks';
import { setGamesModal } from '../../../../../../../../api/features/games/gamesSlice';
import { KTCard } from '../../../../../../../../_metronic/helpers';

type Props = {
  questions: any;
  gameId: number;
  questionsLength: number;
};

const Question: FC<Props> = ({ questions, gameId, questionsLength }) => {
  const [answerGame, { isLoading }] = useAnswerGameMutation();
  const { refetch } = useGetGamesQuery({ page: 1 }); // bu yontem degisecek
  const [optionId, setOptionId] = useState('');

  const dispatch = useAppDispatch();
  const maxTime = questionsLength * 1000000000;
  const [counter, setCounter] = useState(1000000000);
  const [totalTime, setTotalTime] = useState(0);
  const [nextCounter, setNextCounter] = useState(5);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const question = questions?.questions[currentQuestion];
  const isLastQuestion = questions?.questions.length - 1 === currentQuestion;

  const intl = useIntl();

  const nextQuestion = async () => {
    setCounter(1000000000);
    setNextCounter(5);
    setOptionId('');
    await answerGame({
      game_id: gameId,
      game_question_id: question.questionid.toString(),
      game_question_option_id: optionId,
      answered_time: String(totalTime),
    });
    setCurrentQuestion(currentQuestion + 1);
  };

  const completeGame = async () => {
    await answerGame({
      game_id: gameId,
      game_question_id: question.questionid.toString(),
      game_question_option_id: optionId,
      answered_time: String(totalTime),
    });
    refetch();
    await dispatch(setGamesModal());
    okayMessage(
      'Tebrikler',
      'Oyunu tamamladınız. Sonuçlara kalan süreyi Sonuçlar menüsü üzerinden takip edebilirsiniz.',
    );
  };
  useEffect(() => {
    if (counter === 0) {
      const timer = setTimeout(() => {
        if (nextCounter !== 0) {
          setNextCounter(nextCounter - 1);
        } else {
          if (!isLastQuestion) {
            setCounter(1000000000);
            setNextCounter(5);
            setCurrentQuestion(currentQuestion + 1);
          } else {
            refetch();
            dispatch(setGamesModal());
            okayMessage(
              'Tebrikler',
              'Oyunu tamamladınız. Sonuçlara kalan süreyi Sonuçlar menüsü üzerinden takip edebilirsiniz.',
            );
          }
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [counter, nextCounter, currentQuestion, isLastQuestion, dispatch, refetch]);

  useEffect(() => {
    if (totalTime <= maxTime) {
      const timer = setTimeout(() => {
        setTotalTime(totalTime + 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [maxTime, totalTime]);

  return (
    <div className=" d-flex flex-column justify-content-between">
      <div>
        <div className="d-flex align-items-center mb-8 gap-6">
          <Timer counter={counter} setCounter={setCounter} />
          <div>
            <div className="question-info text-gray-500 fw-bold ">
              {currentQuestion + 1}. Soru -{' '}
              {intl.formatMessage({
                id: 'SURVEYS.QUESTION_OPTION_RADIO_MESSAGE',
              })}
            </div>
            {/* <KTCard className="event-card image-card-style"> */}
         {question?.mediauri&& <img
            width={130}
            height={130}
            alt=" "
            src={question?.mediauri ?? ''}
            style={{ objectFit: 'contain' ,padding: '10px'}}
            className="rounded-3 image-card-style"
          />}
        {/* </KTCard> */}
            <div className="text-gray-800 fw-bold fs-4">{question.questiontext}</div>
          </div>
        </div>
        <div className="mb-4">
          {questions?.choices?.map((option: any, index: number) => {
            if (question?.questionid === option?.questionid) {
              return (
                <Option
                  key={option.id}
                  option={option}
                  questionIndex={1}
                  optionIndex={index}
                  setOptionId={setOptionId}
                  optionId={optionId}
                  disabled={counter === 0}
                />
              );
            }
            return null;
          })}
        </div>
        {counter === 0 && (
          <div className="text-center">
            <span className="text-danger">
              Verilen süre içerisinde soruyu cevaplamadınız. Diğer soruya geçiş yapılacak.
            </span>
          </div>
        )}
      </div>

      <div className="justify-content-center d-flex mt-4">
        <button
          type="button"
          disabled={optionId === '' || counter === 0}
          onClick={isLastQuestion ? completeGame : nextQuestion}
          className={`btn ${
            optionId === '' && counter !== 0 ? 'btn-secondary' : 'btn-info'
          } px-16 py-3 d-flex align-items-center gap-3`}
        >
          <span>
            {isLastQuestion
              ? `Oyunu Tamamla`
              : `Diğer Soruya Geç`}
          </span>
          {isLoading && isLastQuestion ? <div className="spinner-border spinner-border-md text-white" /> : null}
        </button>
      </div>
    </div>
  );
};

export default Question;
