import React, { FC, useEffect, useState } from 'react';
import { KTCard, toAbsoluteUrl } from '../../../../../_metronic/helpers';
import './_gameCard.scss';
import SvgGame from '../../../../modules/apps/_global/svg/Game';
import { dateFormatter } from '../../../../modules/_helpers/Helpers';
import { useAppDispatch } from '../../../../../api/store/hooks';
import { setGame, setGamesModal } from '../../../../../api/features/games/gamesSlice';
import { FormattedMessage, useIntl } from 'react-intl';
import { parse, differenceInSeconds } from 'date-fns';
import gameCardBackground from '../../../../../app/modules/apps/games_module/components/GamesCard/game_card_background.svg';
import lock from '../../../../../app/modules/apps/games_module/components/GamesCard/lock.png';
import timeOut from '../../../../../app/modules/apps/games_module/components/GamesCard/time_out.png';
import moment from 'moment';
import { useGetUserResultQuery } from '../../../../../api/services/games/gamesEndpoints';
import GamesResultCard from '../../../../modules/apps/games_module/components/BackResult/gameresult';
import { Modal } from '@mui/material';

type Props = {
  data: any;
  refetch: Function;
};

const GameCard: FC<Props> = ({ data, refetch }) => {
  const intl = useIntl();
  const {
    contestcategorypictureurl,
    contestcategoryname,
    contestname,
    score,
    time_remaining,
    completed,
    contentid,
    contentenddate,
    description,
    startdate,
    title,
    created,
    currentuserinteractions,
  } = data || {};

  const dispatch = useAppDispatch();
  const [timeLeft, setTimeLeft] = useState<string | null>(null);
  const [timeStart, setTimeStart] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [isVisible,setVisible] = useState<boolean>(false)

  const {
    data: userGames,
    isLoading: resultIsLoading,
    isFetching: resultIsFetching,
    refetch: userGamesRefetch,
  } = useGetUserResultQuery({ contestId: contentid, query: `?page=${page}` },{ skip: !isVisible });

  const handleClick = () => {
    userGamesRefetch();
  };


  const beforeNow = (date: string): boolean => {
    let isBefore = false;
    if (date?.length > 0) {
      if (date != null && date.includes('/')) {
        date = date.replace(/\//g, '-');
      }
      const parsedDate = moment(date, 'DD-MM-YYYY hh:mm');
      if (parsedDate.isBefore(moment())) {
        isBefore = true;
      }
    }
    return isBefore;
  };

  const afterNow = (date: string): boolean => {
    let isAfter = false;
    if (date !== undefined) {
      if (date != null && date.includes('/')) {
        date = date.replace(/\//g, '-');
      }
      const parsedDate = moment(date, 'DD-MM-YYYY hh:mm');
      if (parsedDate.isAfter(moment())) {
        isAfter = true;
      }
    }
    return isAfter;
  };

  const startTimer = (date: string): string => {
    if (!date) return '';

    let formattedDate = date.includes('/') ? date.replace(/\//g, '-') : date;
    let parsedDate = parse(formattedDate, 'dd-MM-yyyy HH:mm:ss', new Date());

    let second = differenceInSeconds(parsedDate, new Date());

    if (second <= 0) return '0';

    const duration = {
      days: Math.floor(second / (60 * 60 * 24)),
      hours: Math.floor((second % (60 * 60 * 24)) / (60 * 60)),
      minutes: Math.floor((second % (60 * 60)) / 60),
      seconds: second % 60,
    };

    return (
      `${duration.days > 0 ? `${duration.days} Gün ` : ''}` +
      `${duration.hours > 0 ? `${duration.hours} Saat ` : ''}` +
      `${duration.minutes > 0 ? `${duration.minutes} Dakika ` : ''}` +
      `${duration.seconds > 0 ? `${duration.seconds} Saniye ` : ''}`
    );
  };

  function mingFlowDurationRounded(stringDateTime: string | null): string {
    if (!stringDateTime) {
      return '';
    }

    let duration: Date;
    if (stringDateTime.length === 16) {
      duration = parse(stringDateTime, 'dd/MM/yyyy HH:mm', new Date());
    } else if (stringDateTime.length === 19) {
      duration = parse(stringDateTime, 'dd/MM/yyyy HH:mm:ss', new Date());
    } else {
      return '';
    }

    const now = new Date();
    const difference = now.getTime() - duration.getTime(); // Get difference in milliseconds

    const differenceInDays = Math.floor(difference / (1000 * 60 * 60 * 24));
    const differenceInHours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const differenceInMinutes = Math.floor((difference / (1000 * 60)) % 60);

    if (differenceInDays > 0) {
      return `${differenceInDays}g`;
    } else if (differenceInHours > 0) {
      return `${differenceInHours}s`;
    } else if (differenceInMinutes > 0) {
      return `${differenceInMinutes}d`;
    } else {
      return '1d';
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (contentenddate) {
        const countdownText = startTimer(contentenddate);
        setTimeLeft(countdownText);
      }
    }, 1000);

    // Clean up interval on unmount
    return () => clearInterval(interval);
  }, [contentenddate]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (startdate) {
        const countdownText = startTimer(startdate);
        setTimeStart(countdownText);
      }
    }, 1000);

    // Clean up interval on unmount
    return () => clearInterval(interval);
  }, [startdate]);
  const getButtonStatus = () => {
    if (currentuserinteractions == null) {
      if (beforeNow(startdate) === true && afterNow(contentenddate) === true) {
        return <FormattedMessage id="GAMES.PLAY_NOW" defaultMessage="Oyun oyna" />;
      }
      if (afterNow(startdate) === true) {
        return (
          <FormattedMessage
            id="GAMES.START_COUNTDOWN"
            defaultMessage={`Başlamasına ${timeStart} kaldı.`}
            values={{ time: timeStart }}
          />
        );
      } else {
        return <FormattedMessage id="GAMES.Katılım Süresi Geçti" defaultMessage="Katılım Süresi geçti" />;
      }
    } else if (currentuserinteractions === 2) {
      if (afterNow(contentenddate) === true) {
        return (
          <FormattedMessage
            id="GAMES.TIME_REMAINING_RESULT"
            defaultMessage={`Sonuçlara ${timeLeft} kaldı.`}
            values={{ time: timeLeft }}
          />
        );
      } else {
        return <FormattedMessage id="GAMES.VIEW_RESULTS" defaultMessage={`Sonuçları gör.`} />;
      }
    } else {
      return <FormattedMessage id="GAMES.NOT_COMPLETED" defaultMessage="Tamamlanmadı" />;
    }
  };

  const isDisabled = () => {
    if (currentuserinteractions === 2) {
      if (afterNow(contentenddate) === true) {
        return true;
      } else {
        return false;
      }
    } else if (currentuserinteractions == null) {
      if (beforeNow(startdate) === true && afterNow(contentenddate) === true) {
        return false;
      }
      if (afterNow(startdate) === true) {
        return true;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const isDisabledOpacity = () => {
    if (currentuserinteractions === 2) {
      if (afterNow(contentenddate) === true) {
        return false;
      } else {
        return false;
      }
    } else if (currentuserinteractions == null) {
      if (beforeNow(startdate) === true && afterNow(contentenddate) === true) {
        return false;
      }
      if (afterNow(startdate) === true) {
        return true;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  return (
    <KTCard className="p-8 event-card mb-8">
      <div className="d-flex gap-4 align-items-center mb-8">
        <SvgGame />
        <div>
          <div className="fw-bold fs-3">
            <FormattedMessage id="GAMES.GAME_SHARED" defaultMessage="Bir oyun paylaşıldı." />
          </div>
          <span className="fw-bold text-gray-500">{mingFlowDurationRounded(created)}</span>
        </div>
      </div>
      <KTCard className="p-8 border mb-8">
        <div className="mb-8">
          <div className="text-gray-500 fw-bold">{data.title}</div>
          <div className="fw-bold fs-4 text-gray-800">{data.description}</div>
        </div>
        <div className="d-flex justify-content-center">
          <div className="img-card">
            <img
              src={contestcategorypictureurl ?? toAbsoluteUrl('/media/profile/header-photo.png')}
              alt="dummy"
              width={420}
              height={180}
              className="mb-8 rounded-2 w-100 h-100"
              style={{
                objectFit: 'cover',
                backgroundImage: `url(${gameCardBackground})`,
                opacity:
                  isDisabledOpacity() || (currentuserinteractions === 2 && afterNow(contentenddate) === true)
                    ? 0.25
                    : 1,
              }}
            />
            {isDisabledOpacity() && (
              <>
                <img
                  src={lock}
                  alt="lock"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 3,
                    pointerEvents: 'none',
                  }}
                />
              </>
            )}
            {currentuserinteractions === 2 && afterNow(contentenddate) === true && (
              <>
                <img
                  src={timeOut}
                  alt="timer"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 3,
                    pointerEvents: 'none',
                  }}
                />
              </>
            )}
          </div>
        </div>
      </KTCard>
      <button
        disabled={isDisabled()}
        onClick={async () => {
          if (currentuserinteractions === 2) {
            if (afterNow(enddate) === true) {
            } else {
              return (
                await setVisible(true)),
                await handleClick()
            }
          }


          var enddate = contentenddate;
          await dispatch(
            setGame({
              contentid,
              title,
              description,
              category: { title: contestcategoryname },
              enddate,
            }),
          );
          await dispatch(setGamesModal());
        }}
        className={`btn ${
          (currentuserinteractions == null && beforeNow(startdate) && afterNow(contentenddate)) ||
          (currentuserinteractions === 2 && !afterNow(contentenddate))
            ? 'btn-info' // Eğer currentuserinteractions == null ve başlangıç tarihlerine göre sınırdaysa
            : 'btn-info'
        } ${
          (currentuserinteractions == null && afterNow(startdate)) ||
          (currentuserinteractions === 2 && afterNow(contentenddate)) ||
          currentuserinteractions === 1
            ? 'btn-secondary' // Eğer currentuserinteractions == null ve etkinlik zaman aralığındaysa
            : 'btn-secondary'
        } py-3 px-4`}
      >
        {getButtonStatus()}
      </button>
      <Modal open={isVisible} onClose={()=>setVisible(false)}>
        <div
        style={{
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%', 
    height: 'auto', 
    zIndex: 1300, 
        }}
        >
          <GamesResultCard dataa={userGames?.rankings} setPage={setPage}  page={page} />

        </div>
      </Modal>
    </KTCard>
    
  );
};

export default GameCard;
