// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.collapse-container {
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  height: 0;
}

.collapse-open {
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/apps/_global/components/Collapse/_collapse.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,mCAAA;EACA,SAAA;AACF;;AAEA;EACE,YAAA;AACF","sourcesContent":[".collapse-container {\n  overflow: hidden;\n  transition: height 0.3s ease-in-out;\n  height: 0;\n}\n\n.collapse-open {\n  height: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
