import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../../api/store/hooks';
import { KTCard, toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { authSelector } from '../../../../../api/features/auth/authSlice';
import GenericEmptyContent from '../../_global/components/EmptyContent/GenericEmptyContent';
import { daysUntilNextMonth } from '../utils/utils';
import SvgFirstIcon from './SvgRankingFirst';
import SvgSecondIcon from './SvgRankingSecond';
import SvgThirdIcon from './SvgRankingThird';
import SvgFourthIcon from './SvgRankingFourth';
import SvgFifthIcon from './SvgRankingFifth';
import InfiniteScroll from 'react-infinite-scroll-component';
import DefaultLoadingSpinner from '../../_global/components/Loading/DefaultLoadingSpinner';
import Loading from '../../../../components/Loading';

type Props = {
  data: any;
  userRank: any;
  filterComponent?: any;
  className?: string;
  month: any;
  setPage: (page: number) => void;
  page: number;
  isLoading: boolean;
};

export default function RankingCard({
  data,
  userRank,
  filterComponent,
  className,
  month,
  setPage,
  page,
  isLoading,
}: Props) {
  const intl = useIntl();
  const navigate = useNavigate();
  const { currentUser } = useAppSelector((state) => authSelector(state.auth));
  const icons = [<SvgFirstIcon />, <SvgSecondIcon />, <SvgThirdIcon />, <SvgFourthIcon />, <SvgFifthIcon />];

  return (
    <KTCard className={`event-card ${className ? className : 'mt-10'}`}>
      <div className={`mx-8 mt-8`}>
        <div className="d-flex justify-content-between align-items-center">
          {
            <div className="col-md-6">
              <div className="title fw-bolder">{`${intl.formatMessage({
                id: `MONTH_${month.value}`,
              })} ${intl.formatMessage({ id: 'LEADERSHIP_MING_GENERAL_RANKING' })}`}</div>
              <div className="text-muted fw-bold mt-1">
                {month?.label === new Date().toLocaleString('tr-TR', { month: 'long' }) &&
                  `${intl.formatMessage({ id: 'LEADERSHIP_REMAINING_DAY_TEXT' }, { day: daysUntilNextMonth() })} `}
              </div>
            </div>
          }
          {filterComponent && <div className="col-md-4">{filterComponent}</div>}
        </div>
      </div>

      {data.leaderboard?.length === 0 ? (
        <GenericEmptyContent
          imgPath="/media/svg/empty/games/GamesResultEmpty.svg"
          title="LEADERSHIP_PAGE_EMPTY_TITLE"
          directSubContent="LEADERSHIP_EMPTY_TEXT_CONTENT"
          className="m-10"
        />
      ) : (
        <>
          <KTCard className="event-card my-8 mx-8">
            {userRank?.totalscore !== 0 && userRank?.totalscore && (
              <div className="mx-5 my-4">
                <div className="fw-bold fs-4 mb-4">{intl.formatMessage({ id: 'LEADERSHIP_MY_RANKING_TEXT' })}</div>

                <div className="d-flex align-items-stretch gap-5 mb-6 me-5">
                  <div
                    className="col-md-6 border border-gray-300 border-dashed rounded-1 px-4 py-3 d-flex align-items-center cursor-pointer bg-white"
                    onClick={() => navigate(`/profile/${currentUser?.usersecret?.userid}`)}
                  >
                    <div className="d-flex align-items-center gap-3">
                      <img
                        src={userRank?.pictureurl || toAbsoluteUrl('/media/avatars/Default Avatar.svg')}
                        alt="user"
                        width={50}
                        height={50}
                        className="rounded-3 "
                      />
                      <div>
                        <div className="fw-bold text-dark">{userRank?.fullname}</div>
                        <span className="text-gray-500 fw-bold">{userRank?.positiondesc}</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 border border-gray-300 border-dashed rounded-1 px-4 d-flex align-items-center bg-white">
                    <div>
                      <div className="fw-bold">{` ${userRank.cheerscount}.${intl.formatMessage({
                        id: 'LEADERSHIP_RANK_TEXT',
                      })}`}</div>
                      <div>
                        {userRank
                          ? `${userRank?.totalscore} ${intl.formatMessage({
                              id: 'LEADERSHIP_POINT_TEXT',
                            })}`
                          : intl.formatMessage({ id: 'FOOD_MENU_EMPTY_KCAL' })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </KTCard>

          <KTCard className="event-card mx-8 mb-8">
            <div className="my-4">
              <div className="row">
                <div className="card-body py-3">
                  {/* begin::Table container */}
                  <div className="table-responsive d-block overflow-auto" style={{ maxHeight: 400 }}>
                    <div className="fw-bold fs-4 mb-1">{intl.formatMessage({ id: 'LEADERSHIP_RANKING_TABLE' })}</div>
                    {/* begin::Table */}
                    <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                      {/* begin::Table head */}
                      <thead className="sticky-top bg-white" style={{ zIndex: 1 }}>
                        <tr className="fw-bold text-muted">
                          <th className="w-25px">{intl.formatMessage({ id: 'LEADERSHIP_RANK_TEXT' })}</th>
                          <th className="min-w-410px">{intl.formatMessage({ id: 'LEADERSHIP_NAME_TEXT' })}</th>
                          <th className="min-w-100px text-end">
                            {intl.formatMessage({ id: 'LEADERSHIP_POINT_TEXT' })}
                          </th>
                        </tr>
                      </thead>
                      {/* end::Table head */}
                      {/* begin::Table body */}
                      <tbody>
                        {data?.leaderboard?.map((i: any, index: number) => (
                          <tr key={index}>
                            <td>
                              {index < 5 ? icons[index] : <span className="text-gray-500">{`${index + 1}.`}</span>}
                            </td>
                            <td>
                              <div
                                className="d-flex align-items-center cursor-pointer"
                                onClick={() => navigate(`/profile/${i?.userid}`)}
                              >
                                <div className="symbol symbol-45px me-5">
                                  <img
                                    src={i.pictureurl || toAbsoluteUrl('/media/avatars/Default Avatar.svg')}
                                    alt="avatar"
                                  />
                                </div>
                                <div className="d-flex justify-content-start flex-column">
                                  <span className="text-dark fw-bold text-hover-info fs-6">{i?.fullname}</span>
                                  <span className="text-muted fw-semibold text-muted d-block fs-7">{i?.branch}</span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex justify-content-end flex-shrink-0">
                                <div>
                                  <div className="fw-bold">{i?.totalscore}</div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      {/* end::Table body */}
                    </table>
                    {/* end::Table */}
                  </div>
                  {/* end::Table container */}
                </div>
              </div>
            </div>
          </KTCard>
        </>
      )}
    </KTCard>
  );
}
