export const constants = {
  logout: '/logout',
  announcements: '/announcements',
  announcement: '/announcement',
  announcementPublish: '/announcement/publish',
  announcementUnpublish: '/announcement/unpublish',
  login: '/login',
  branchs: '/branchs',
  cms: '/cms',
  careerListings: '/careerlistings',
  careerListing: '/careerlisting',
  survey: '/survey',
  surveyPublish: '/survey/publish',
  surveyUnpublish: '/survey/unpublish',
  citys: '/citys',
  departments: '/departments',
  events: '/events',
  eventsUnpublish: '/events/unpublish',
  feedbacks: '/feedbacks',
  feedback: '/feedback',
  feedbackTypes: '/feedback/types',
  listing: '/listing',
  listingMessages: '/listing/messages',
  listingCategories: '/listing/categories',
  positions: '/positions',
  audienceCount: '/audience/count',
  audienceList: '/audience/list',
  surveyCompletionList: '/survey/completion_list',
  surveyReport: '/survey/report',
  userFeels: '/userfeels',
  users: '/user',
  birthdays: '/birthdays',
};

type Tag =
  | 'Announcements'
  | 'Post'
  | 'CurrentUser'
  | 'OrganizationChart'
  | 'ProfilePosts'
  | 'OtherTagName'
  | 'Survey'
  | 'CombinedList';
type Tags = Tag[];

export const rtkTags: Tags = [
  'Announcements',
  'Post',
  'CurrentUser',
  'OrganizationChart',
  'ProfilePosts',
  'OtherTagName',
  'Survey',
  'CombinedList',
];
