import React, { FC, memo, useEffect, useState } from 'react';

type Props = {
  counter: number;
  setCounter: Function;
};

const Timer: FC<Props> = ({ counter, setCounter }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (counter !== 0) {
        setCounter(counter - 1);
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [counter]);

  return (
    <div
      // className="border border-gray-300 border-dashed d-flex align-items-center justify-content-center gap-1 rounded"
      // style={{
      //   width: 76,
      //   height: 38,
      // }}
    >
      {/* <span className="fw-bold fs-2">{counter}</span>
      <span className="text-gray-500 fs-4 fw-bold">sn</span> */}
    </div>
  );
};

export default memo(Timer);
