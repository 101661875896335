import { FC } from 'react';
import { Grid } from '@mui/material';
import QuickLinks from '../components/QuickLinks/QuickLinks';
import { SimpleCard } from '../components/SimpleCard/SimpleCard';
import { ID, toAbsoluteUrl } from '../../../../_metronic/helpers';
import StickyBox from 'react-sticky-box';
import { useIntl } from 'react-intl';
import moment from 'moment';

type Props = {
  surveys: any;
  games: any;
  links: any;
  birthdays: any;
  food: any;
  selectedDate: any;
};

const RightSideBar: FC<Props> = ({ games, surveys, links, birthdays, food, selectedDate }) => {
  const intl = useIntl();


  const beforeNow = (date: string): boolean => {
    let isBefore = false;
    if (date?.length > 0) {
      if (date != null && date.includes('/')) {
        date = date.replace(/\//g, '-');
      }
      const parsedDate = moment(date, 'DD-MM-YYYY hh:mm');
      if (parsedDate.isBefore(moment())) {
        isBefore = true;
      }
    }
    return isBefore;
  };

  const afterNow = (date: string): boolean => {
    let isAfter = false;
    if (date !== undefined) {
      if (date != null && date.includes('/')) {
        date = date.replace(/\//g, '-');
      }
      const parsedDate = moment(date, 'DD-MM-YYYY hh:mm');
      if (parsedDate.isAfter(moment())) {
        isAfter = true;
      }
    }
    return isAfter;
  };
  const isDisabled = (currentuserinteractions: number | null,enddate: any,startdate: any) => {
    if (currentuserinteractions === 2) {
      if (afterNow(enddate) === true) {
        return true;
      } else {
        return false;
      }
    } else if (currentuserinteractions == null) {
      if (beforeNow(startdate) === true && afterNow(enddate) === true) {
        return false;
      }
      if (afterNow(startdate) === true) {
        return true;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };


  // Linkler için kontrol, eğer veri yoksa boş array döneriz
  const linksData = links?.list?.length
    ? links?.list.map((i: any) => ({
        subTitle: i.quickaccessname,
        link: i.quickaccesslink,
        image: i?.mediauri,
      }))
    : [];


    const getButtonStatusToColor = (survey:any) => {
      const now = new Date();
      const [datePart, timePart] = survey.enddate != null ? survey.enddate.split(' ') : "";
      const [day, month, year] = survey.enddate != null ? datePart.split('/') : "";
  
      const formattedEndDate = new Date(`${month}/${day}/${year} ${timePart}`);
      if (survey.iscompleted === 1) {
        return false;
      }
      else if (survey.enddate != null && formattedEndDate < now) {
        return false;
      }else {
        return true;
      }
    };
  // Anketler için kontrol, filtreleme ve veri mapleme işlemleri
  const surveysData: Array<{
    id: ID;
    subTitle: string;
    description: string;
  }> = surveys?.surveylist?.length
    ? surveys.surveylist
        ?.filter((survey: any) => {
          if (getButtonStatusToColor(survey)) {
            return survey;
          }
          return null;
        })
        ?.slice(0, 2)
        .map((i: any) => ({
          id: i.surveyid,
          subTitle: i.surveyname,
          description: i.surveydesc,
        }))
    : [];

  // Oyunlar için kontrol
  const gamesData: Array<{
    id: ID;
    subTitle: string;
    description: string;
    image: string;
  }> = games?.contests?.length
    ? games.contests
        ?.filter((game: any) => {
          if (isDisabled(game.currentuserinteractions,game.enddate,game.startdate) === true) {
            return game;
          }
          return null;
        })
        ?.slice(0, 2)
        .map((i: any) => ({
          id: i.contestid,
          subTitle: i.contestname,
          description: i.contestdesc,
          image: i.contestcategorypictureurl,
        }))
    : [];

  // Doğum günleri için kontrol
  const birthdaysData: Array<{
    id: ID;
    subTitle: string;
    description: string;
    image: string;
  }> = birthdays?.today.length
    ? birthdays.today.slice(0, 2).map((i: any) => ({
        id: i.userid,
        subTitle: i.fullname,
        description: i.positiondesc,
        image: i.pictureurl ?? toAbsoluteUrl(`/media/avatars/Default Avatar.svg`),
      }))
    : [];

  // Yemek menüsü için kontrol
  const foodData: Array<{
    id: ID;
    subTitle: string;
    description: string;
  }> = food?.dailymenus?.length
    ? food?.dailymenus[0]?.meals?.map((i: any) => ({
        id: i.dailymenuid,
        subTitle: i.menuitemname,
        description: i.menuitemcalories ? i.menuitemcalories + intl.formatMessage({ id: 'HOME.FOOD_MENU_CALORIE' }) : '',
      }))
    : [];

  return (
    <Grid item sx={{ display: { xs: 'none', lg: 'block' } }} lg={3}>
      <StickyBox offsetTop={20} offsetBottom={20}>
        <div className="d-flex flex-column gap-6">
          {links?.list?.length > 0 && <QuickLinks title="HOME.FAST_ACCESS" data={links?.list} />}
          {surveysData.length > 0 && <SimpleCard title="HOME.SURVEYS" rows={surveysData} seeAllRoute="/apps/surveys" />}
          {gamesData?.length > 0 && <SimpleCard title="HOME.GAMES" rows={gamesData} seeAllRoute="/apps/games" />}
          {foodData?.length > 0 && (
            <SimpleCard title="HOME.FOOD_MENU" rows={foodData} seeAllRoute="/apps/food-menu" />
          )}
          {birthdaysData?.length > 0 && (
            <SimpleCard title="HOME.BIRTHDAY" rows={birthdaysData} type="avatar" seeAllRoute="/apps/birthdays" />
          )}
        </div>
      </StickyBox>
    </Grid>
  );
};

export default RightSideBar;
