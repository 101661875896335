import React, { FC, useEffect } from 'react';
import PreApproval from './components/PreApproval/PreApproval';
import Question from './components/Question/Question';
import { useJoinGameMutation } from '../../../../../../api/services/games/gamesEndpoints';
import { useAppDispatch } from '../../../../../../api/store/hooks';
import { setJoinGameResponse } from '../../../../../../api/features/games/gamesSlice';

type Props = {
  id: number;
  title: string;
  description: string;
  category: string;
  resultDate: string;
};

const GamesModal: FC<Props> = ({ id, title, description, category, resultDate }) => {
  const dispatch = useAppDispatch();
  const [joinGame, { data, isLoading }] = useJoinGameMutation();

  useEffect(() => {
    dispatch(setJoinGameResponse(data));
  }, [data, dispatch]);

  return (
    <div className="mx-auto mw-1000px w-100 pt-1">
      {data?.questions === undefined && (
        <PreApproval
          title={title}
          description={description}
          category={category}
          resultDate={resultDate}
          joinGame={async () => await joinGame(id)}
          isLoading={isLoading}
        />
      )}
      {data && data?.questions?.length > 0 && (
        <Question questions={data} gameId={id} questionsLength={data.questions?.length} />
      )}
    </div>
  );
};

export default GamesModal;
