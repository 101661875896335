import confirmMessage from '../Modal/ConfirmMessage';
import BulkDotsSquare from '../../svg/BulkDotsSquare';
import { KTIcon } from '../../../../../../_metronic/helpers';
import { FC, Fragment, useEffect, useRef, useState } from 'react';
import { useAppDispatch } from '../../../../../../api/store/hooks';
import { useDeletePostMutation } from '../../../feed_module/services/feedEndpoints';
import { setEditPostId, setOpenPostCreate } from '../../../feed_module/features/feedSlice';
import { useIntl } from 'react-intl';

type Props = {
  postId: number;
  isOwner: boolean;
  refetch?: Function;
  approval_status?: string;
};

const BulkDotsDropdown: FC<Props> = ({ isOwner, postId, refetch, approval_status }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const [isOpen, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [deletePost] = useDeletePostMutation();

  const handleOutsideClick = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', handleOutsideClick);
    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleDelete = () => {
    confirmMessage(
      intl.formatMessage({ id: 'FEED_DELETE_POST' }),
      intl.formatMessage({ id: 'FEED_DELETE_POST_DESCRIPTION' }),
      async () => {
        await deletePost(postId)
          .then(() => {
            if (refetch) refetch();
          });
        setOpen(false);
      },
      undefined,
      intl.formatMessage({ id: 'APPROVE_TEXT' }),
      intl.formatMessage({ id: 'CANCEL_TEXT' }),
      undefined,
      '#F1416C',
    );
  };

  return (
    <Fragment>
      {isOwner && (
        <div className="position-relative">
          <BulkDotsSquare
            className="cursor-pointer align-items-center "
            onClick={(e: any) => {
              setOpen(!isOpen);
            }}
          />
          <div
            ref={dropdownRef}
            className={`menu menu-sub menu-sub-dropdown w-200px w-md-250px m-2 px-4 py-2 ${
              isOpen ? 'show' : ''
            } position-absolute`}
            style={{
              right: 0,
            }}
          >
            {
              <div>
                {/* {approval_status === 'LIVE' && (
                  <button
                    onClick={() => {
                      dispatch(setEditPostId(postId));
                      dispatch(setOpenPostCreate());
                    }}
                    type="button"
                    className="border-0 bg-white rounded-1 p-2 d-flex aling-items-center gap-4 w-100"
                  >
                    <KTIcon iconName="pencil" className="fs-2 text-gray-600" />
                    <span className="fw-bold text-gray-600">{intl.formatMessage({ id: 'FEED_EDIT_TEXT' })}</span>
                  </button>
                )} */}

                <button
                  onClick={handleDelete}
                  type="button"
                  className="border-0 bg-white rounded-1 p-2 d-flex aling-items-center gap-4 mt-1 w-100"
                >
                  <KTIcon iconName="trash" className="fs-2" iconOverride="text-danger" />
                  <span className="fw-bold text-danger">{intl.formatMessage({ id: 'FEED_DELETE_TEXT' })}</span>
                </button>
              </div>
            }
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default BulkDotsDropdown;
