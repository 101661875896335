import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../../../components/Loading';
import { feedSelector, setLikeModal } from '../../features/feedSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../api/store/hooks';
import { useGetPostLikedListQuery } from '../../services/feedEndpoints';
import UserBadge from '../../../_global/components/UserBadge/UserBadge';
import DashedLine from '../../../_global/components/DashedLine/DashedLine';
import { useIntl } from 'react-intl';

const LikedModal = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const postId = useAppSelector((state) => feedSelector(state.feed).selectedPostId);
  const {
    data: likedUsers,
    isLoading: isPostLikedFetch,
    isFetching: isPostLikedRefetch,
  } = useGetPostLikedListQuery(postId);
  const isLoading = isPostLikedFetch || isPostLikedRefetch;
  const { interactions } = likedUsers || {};
  const listLength = interactions?.length;

  return (
    <Fragment>
      {isLoading ? (
        <Loading height={300} />
      ) : (
        <div>
          {interactions?.map((user: any, index: number) => (
            <div className="mb-4" key={user.userid}>
              <UserBadge
                subtitle={user.nodename}
                title={user.fullname}
                image={user.pictureurl}
                navigate={() => {
                  dispatch(setLikeModal());
                  navigate(`/profile/${user?.userid}`);
                }}
              />
              {listLength - 1 !== index && <DashedLine />}
            </div>
          ))}
          <div className="text-center text-gray-500 fs-5 fw-medium">
            {listLength} {intl.formatMessage({ id: 'APP_SEARCH_ALL_RESULTS_SHOWN' })}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default LikedModal;
