/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, Fragment } from 'react';
import './_simpleCard.scss';
import { useIntl } from 'react-intl';
import { KTCard } from '../../../../../_metronic/helpers';
import { useNavigate } from 'react-router-dom';
type Props = {
  className?: string;
  title?: string;
  rows: Array<{ description: string }>;
  type?: string;
  route?: string;
  seeAllRoute?: string;
};

const SimpleCard: FC<Props> = ({ className, title, rows, type, seeAllRoute }) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const currentDay = new Date().getDay();
  const currentMonth = new Date().getMonth();
  const currentMonthDay = new Date().getDate();
  const days = ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'];
  const months = [
    'Ocak',
    'Şubat',
    'Mart',
    'Nisan',
    'Mayıs',
    'Haziran',
    'Temmuz',
    'Ağustos',
    'Eylül',
    'Ekim',
    'Kasım',
    'Aralık',
  ];

  return (
    <KTCard className={`simple-card ${className}`}>
      <div className="pb-8">
        {
          title !== 'HOME.FOOD_MENU' ? (
            <h3 className="title">{intl.formatMessage({ id: title })}</h3>
          ) : (
            <>
              <h3 onClick={() => navigate(seeAllRoute ?? '#')} className="title cursor-pointer">{intl.formatMessage({ id: title })}</h3>
              <span className='text-gray-500 fw-semibold' style={{ fontSize: 14 }}>{currentMonthDay} {months[currentMonth]} - {days[currentDay]}</span>
            </>
          )
        }
      </div>
      <div>
        {rows?.map((row: any, index) => (
          <Fragment key={`lw26-rows-${index}`}>
            <div onClick={() => navigate(seeAllRoute ?? '#')}
              className={title !== 'HOME.FOOD_MENU' ? "d-flex align-items-center mb-5 cursor-pointer" : 'd-flex align-items-center h-40px cursor-pointer'}>
              {row.image && (
                <img
                  src={row.image}
                  className={`me-4 ${type === 'avatar' ? 'rounded-circle' : 'rounded-3'}`}
                  alt="row-img"
                  width={type === 'avatar' ? 40 : 80}
                  height={type === 'avatar' ? 40 : 60}
                />
              )}
              <div className="d-flex flex-column gap-1">
                <span className="me-2 fw-bold fs-5 line-clamp-1">{row.subTitle}</span>
                {
                  title === 'HOME.FOOD_MENU' && row.description === '' ? (
                    <></>
                  ) : (
                    <span className="me-2 text-gray-500 fw-bold fs-6 line-clamp-1">{row.description}</span>
                  )
                }
              </div>
            </div>
            {rows.length - 1 > index && <div className="separator separator-dashed my-3" />}
          </Fragment>
        ))}
        {
          title !== 'HOME.FOOD_MENU' ? (
            <div onClick={() => navigate(seeAllRoute ?? '#')} className="text-center cursor-pointer see-more-simple">
              <span className="fw-bolder text-info">{intl.formatMessage({ id: 'HOME.SEE_ALL' })}</span>
            </div>
          ) : (<></>)
        }

      </div>
    </KTCard>
  );
};
export { SimpleCard };
