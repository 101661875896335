import * as Yup from 'yup';

export interface ICreateSurvey {
  questions: [
    {
      surveyid: number;
      questionid: number;
      subquestionid: number;
      question_type: string;
      required_question: string;
      choicelist?: any;
      response?: string;
      selectcount:number;
      question_radio_id?: string;
      question_radio_id_sub?:string;
      question_checkbox_ids?: string[];
      question_checkbox_ids_sub?: string[];
      question_option_val?: string[];
      questiontypeid?:number;
      selectoption?:number;
      passablesub?:number;
    },
  ];
}

export const createSurveySchemas = Yup.object().shape({
  questions: Yup.array().of(
    Yup.object().shape({
      question_type: Yup.string(),
      required_question: Yup.string(),
      question_radio_id: Yup.string().when(['question_type', 'required_question'], (data: any) => {
        const question_type = data[0];
        const required_question = data[1];
        if (
          (question_type === 'radio' && required_question === 'true') ||
          question_type === undefined ||
          required_question === undefined
        ) {
          return Yup.string().required('Bu alan zorunludur.');
        }
        return Yup.string().notRequired();
      }),
      question_checkbox_ids: Yup.array().when(['question_type', 'required_question'], (data: any) => {
        const question_type = data[0];
        const required_question = data[1];
        if (
          (question_type === 'checkbox' && required_question === 'true') ||
          question_type === 'undefined' ||
          required_question === 'undefined'
        ) {
          return Yup.array().min(1, 'En az 1 seçenek seçilmelidir.').required();
        }

        return Yup.array().notRequired();
      }),
      question_option_val: Yup.string().when(['question_type', 'required_question'], (data: any) => {
        const question_type = data[0];
        const required_question = data[1];
        if (
          (question_type === 'freetext' && required_question === 'true') ||
          question_type === undefined ||
          required_question === undefined
        ) {
          return Yup.string().required('Bu alan zorunludur.');
        }
        return Yup.string().notRequired();
      }),
    }),
  ),
});

export const inits: ICreateSurvey = {
  questions: [
    {
      surveyid: 0,
      questionid: 0,
      subquestionid:0,
      question_type: '',
      required_question: 'false',
      question_radio_id: '',
      choicelist: [],
      response: '',
      selectcount:0,
      question_checkbox_ids: [],
      question_checkbox_ids_sub:[],
      question_option_val:[],
      question_radio_id_sub:'',
      questiontypeid:-1,
      selectoption:0,
      passablesub:-1,
    },
  ],
};
