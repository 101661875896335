import { Link } from 'react-router-dom';
import { KTCard, toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { useIntl } from 'react-intl';
import { useMediaQuery, useTheme } from '@mui/material';

type Props = {
  title: string;
  imgPath: string;
  className?: string;
  navigationPath?: string;
  navigationTitle?: string;
  directSubContent?: string; //! It ignores the title ,navigation link and text's. Only shows 'directSubContent' below the title
  bottomButtonComponent?: React.ReactNode;
  textBeforeNavigationLink?: string;
  textAfterNavigationLink?: string;
};

function GenericEmptyContent({
  title,
  imgPath,
  className,
  navigationPath,
  navigationTitle,
  directSubContent,
  bottomButtonComponent,
  textBeforeNavigationLink,
  textAfterNavigationLink,
}: Props) {
  const intl = useIntl();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <KTCard className={`p-8 event-card mb-8 ${className}`}>
      <div className="row d-flex justify-content-center align-items-center ">
        <img
          src={toAbsoluteUrl(imgPath)}
          alt="Empty Content"
          width={'w-100'}
          height={isSmallScreen ? '250px' : '450px'}
          className="rounded-2 col-12"
          style={{
            objectFit: 'contain',
          }}
        />
        <div className="col-12 mt-15 mx-15 text-center">
          <div className="text-gray-700 fs-4 fw-bold">{title && intl.formatMessage({ id: title })}</div>
          {directSubContent ? (
            <div className="text-gray-500 fs-6 fw-medium  mt-3">{intl.formatMessage({ id: directSubContent })}</div>
          ) : (
            <>
              {title !== 'FOOD_MENU_PAGE_NO_DINING_HALL' ? (
                <div className="text-gray-500 fs-6 fw-medium  mt-3">
                  {`${textBeforeNavigationLink && intl.formatMessage({ id: textBeforeNavigationLink })} `}
                  {textAfterNavigationLink && (
                    <Link to={navigationPath ?? '/apps/feedback'} className="text-primary">
                      {navigationTitle ?? intl.formatMessage({ id: 'ANNOUNCEMENTS_LINK_TEXT' })}
                    </Link>
                  )}

                  {textAfterNavigationLink &&
                    intl.formatMessage({ id: textAfterNavigationLink ?? 'ANNOUNCEMENTS_TEXT_AFTER_NAVIGATION_LINK' })}
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
        {bottomButtonComponent && <div className="col-12 mx-15 text-center">{bottomButtonComponent}</div>}
      </div>
    </KTCard>
  );
}

export default GenericEmptyContent;
