import { Logout } from '../Logout';
import { FC, useState, useEffect } from 'react';
import { useAppDispatch } from '../../../../api/store/hooks';
import { WithChildren, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { LayoutSplashScreen } from '../../../../_metronic/layout/core';
import { setAuth, setCurrentUser } from '../../../../api/features/auth/authSlice';

const AuthInit: FC<WithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  useEffect(() => {
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      dispatch(setAuth(JSON.parse(userInfo)));
    }
  }, [dispatch]);

  useEffect(() => {
    setShowSplashScreen(false);
  }, [dispatch]);

  // return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
  return showSplashScreen ? (
    <div className="d-flex flex-column justify-content-center align-items-center vh-100">
      <img
        src={toAbsoluteUrl('/media/logos/MingNewLogo.png')}
        className="dark-logo"
        alt="Ming Logo"
        width={125}
        height='auto'
        style={{objectFit:'contain'}}
      />
    </div>
  ) : (
    <>{children}</>
  );
};

//! Can be no need in future but for now it still present
function setupAxios(axios: any) {
  axios.defaults.headers.Accept = '*';
  axios.defaults.headers.withCredentials = false;

  axios.interceptors.response.use(
    (response: any) => {
      return response;
    },
    async function (error: any) {
      if (error.response.status === 401) {
        Logout();
      }
      return Promise.reject(error);
    },
  );
  axios.interceptors.request.use(
    (config: { headers: any }) => {
      const token = localStorage.getItem('authToken');
      const headerCode = localStorage.getItem('headerCode');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      if (headerCode) {
        config.headers['headerCode'] = headerCode;
      }
      return config;
    },
    (err: any) => Promise.reject(err),
  );
}
export { AuthInit, setupAxios };
