import { Fragment } from 'react';
import EasyLinkItem from './EasyLinkItem';
import { useAppDispatch, useAppSelector } from '../../../../../api/store/hooks';
import {
  toggleAddFeedbackModal,
  toggleAddPostModal,
  toggleAddSecondhandModal,
  toggleAddNearMissModal,
} from '../../../../../api/features/easy_links/easyLinksSlice';
import { moduleSelector } from '../../../../../api/features/module/moduleSlice';
import { useGetModulesQuery } from '../../../../../api/services/home/homeEndpoints';

export function MenuInner() {
  const dispatch = useAppDispatch();
  const { modules } = useAppSelector((state) => moduleSelector(state));
  const { data: modulesList = [], isLoading: modulesLoading } = useGetModulesQuery();

  return (
    <Fragment>
      <EasyLinkItem
        iconName="plus-square"
        title="FEED_CREATE_FEED_TEXT"
        onClick={() => {
          dispatch(toggleAddPostModal());
        }}
      />
      {modulesList?.modules?.find((module: any) => module.moduledesc === 'İlanlar') && (
        <EasyLinkItem
          iconName="shop"
          title="CREATE_SECONDHAND_SECONDHAND"
          onClick={() => {
            dispatch(toggleAddSecondhandModal());
          }}
        />
      )}
      {modulesList?.modules?.find((module: any) => module.moduledesc === 'Geri Bildirim') && (
        <EasyLinkItem
          iconName="sms"
          title="FEEDBACK_PAGE_CREATE_FEEDBACK"
          onClick={() => {
            dispatch(toggleAddFeedbackModal());
          }}
        />
      )}
      {modulesList?.modules?.find((module: any) => module.moduledesc === 'Ramak Kala') && (
        <EasyLinkItem
          iconName="information-3"
          title="Ramak Kala Bildirimi Oluştur"
          onClick={() => {
            dispatch(toggleAddNearMissModal());
          }}
        />
      )}

      {/* <MenuItem icon="plus-square" title={intl.formatMessage({ id: 'FEED_CREATE_FEED_TEXT' })} to="/home" />
      <MenuItem
        icon="add-item"
        title={intl.formatMessage({ id: 'FEEDBACK_PAGE_CREATE_FEEDBACK' })}
        to="/apps/feedback"
      />
      <MenuItem
        icon="add-item"
        onClick={() => {}}
        title={intl.formatMessage({ id: 'CREATE_SECONDHAND_SECONDHAND' })}
        to="/crafted/quizs/create"
      /> */}
      {/*<MenuItem icon="add-item" title={"Quiz Oluştur"} to='/crafted/quizs/create' />
      <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />
      <MenuItem title='Layout Builder' to='/builder' />
      <MenuInnerWithSub
        title='Crafted'
        to='/crafted'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
       
        <MenuInnerWithSub
          title='Pages'
          to='/crafted/pages'
          fontIcon='bi-archive'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuInnerWithSub
            title='Profile'
            to='/crafted/pages/profile'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
            <MenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
            <MenuItem
              to='/crafted/pages/profile/connections'
              title='Connections'
              hasBullet={true}
            />
          </MenuInnerWithSub>
          <MenuInnerWithSub
            title='Wizards'
            to='/crafted/pages/wizards'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/crafted/pages/wizards/horizontal' title='Horizontal' hasBullet={true} />
            <MenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
          </MenuInnerWithSub>
        </MenuInnerWithSub>
 
        <MenuInnerWithSub
          title='Accounts'
          to='/crafted/accounts'
          fontIcon='bi-person'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
          <MenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
        </MenuInnerWithSub>
 
        <MenuInnerWithSub
          title='Errors'
          to='/error'
          fontIcon='bi-sticky'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/error/404' title='Error 404' hasBullet={true} />
          <MenuItem to='/error/500' title='Error 500' hasBullet={true} />
        </MenuInnerWithSub>
 
        <MenuInnerWithSub
          title='Widgets'
          to='/crafted/widgets'
          fontIcon='bi-layers'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
          <MenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
          <MenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
          <MenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
          <MenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
          <MenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
        </MenuInnerWithSub>
      </MenuInnerWithSub>

      <MenuInnerWithSub title='Apps' to='/apps' menuPlacement='bottom-start' menuTrigger='click'>
     
        <MenuInnerWithSub
          title='Chat'
          to='/apps/chat'
          icon='message-text-2'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
          <MenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
          <MenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
        </MenuInnerWithSub>
        <MenuItem icon='abstract-28' to='/apps/user-management/users' title='User management' />
      </MenuInnerWithSub>

      <MenuInnerWithSub
        isMega={true}
        title='Mega menu'
        to='/mega-menu'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MegaMenu />
      </MenuInnerWithSub>
  */}
    </Fragment>
  );
}
