import * as React from 'react';

const SvgSecondIcon = (props: any) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_5379_32923)">
<rect x="1.5" width="13" height="10" rx="4" fill="#804CB6"/>
<rect x="4.62109" width="7.28" height="10" fill="#B46BFF"/>
</g>
<circle cx="8" cy="9" r="7" fill="#C4C4C4"/>
<circle cx="8" cy="9" r="7" fill="url(#paint0_linear_5379_32923)"/>
<circle cx="8" cy="9" r="7" fill="url(#paint1_linear_5379_32923)"/>
<circle cx="5" cy="5" r="5" transform="matrix(1 0 0 -1 3 14)" fill="url(#paint2_linear_5379_32923)"/>
<path d="M5.81152 12V11.0903L7.88135 8.88867C8.07764 8.66309 8.23291 8.4668 8.34717 8.2998C8.46436 8.12988 8.54785 7.97607 8.59766 7.83838C8.64746 7.69775 8.67236 7.55859 8.67236 7.4209C8.67236 7.19238 8.60791 6.99902 8.479 6.84082C8.35303 6.67969 8.16406 6.59912 7.91211 6.59912C7.64258 6.59912 7.43896 6.68994 7.30127 6.87158C7.1665 7.05322 7.09912 7.30225 7.09912 7.61865H5.72803L5.71924 7.59229C5.71045 7.19971 5.79395 6.84668 5.96973 6.5332C6.14844 6.21973 6.40479 5.9707 6.73877 5.78613C7.07275 5.60156 7.4668 5.50928 7.9209 5.50928C8.37207 5.50928 8.75732 5.58838 9.07666 5.74658C9.39893 5.90479 9.64648 6.12598 9.81934 6.41016C9.99512 6.69434 10.083 7.02539 10.083 7.40332C10.083 7.66113 10.0376 7.90283 9.94678 8.12842C9.85596 8.35107 9.71094 8.58838 9.51172 8.84033C9.31543 9.08936 9.05615 9.38379 8.73389 9.72363L7.62207 10.9102L7.63086 10.9321H9.10303L9.16895 10.3564H10.2412V12H5.81152Z" fill="#545050"/>
<defs>
<linearGradient id="paint0_linear_5379_32923" x1="8" y1="2" x2="8" y2="16" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFDD86"/>
<stop offset="1" stop-color="#FFC32A"/>
</linearGradient>
<linearGradient id="paint1_linear_5379_32923" x1="8" y1="2" x2="8" y2="16" gradientUnits="userSpaceOnUse">
<stop stop-color="#E7E7E7"/>
<stop offset="1" stop-color="#B5B5B5"/>
</linearGradient>
<linearGradient id="paint2_linear_5379_32923" x1="5" y1="0" x2="5" y2="10" gradientUnits="userSpaceOnUse">
<stop stop-color="#E7E7E7"/>
<stop offset="1" stop-color="#B5B5B5"/>
</linearGradient>
<clipPath id="clip0_5379_32923">
<rect x="1.5" width="13" height="10" rx="4" fill="white"/>
</clipPath>
</defs>
</svg>

    
);
export default SvgSecondIcon;



