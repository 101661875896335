import React, { FC, useEffect, useState } from 'react';
import './_question.scss';
import Option from '../Option/Option';
import { Field, useFormikContext } from 'formik';
import { ICreateSurvey } from '../../CreateSurveyHelper';
import { useAnswerQuestionMutation } from '../../../../../../api/services/surveys/surveyEndpoints';
import { useIntl } from 'react-intl';
import SubOption from '../Option/subOption';
import { useAppDispatch, useAppSelector } from '../../../../../../api/store/hooks';
import { setIsButtonDisabledSlice } from '../../../../../../api/features/games/gamesSlice';

type Props = {
  question: any;
  questionIndex: number;
  id?: number;
  onNext: () => void; // Bir sonraki soruya geçiş için fonksiyon
  onComplete: () => void; // Anketi tamamlama fonksiyonu
  isLastQuestion: boolean; // Son soru olup olmadığını kontrol etmek için
  isSubQuestion?: boolean;
  passable?: number;
  mainQuestionSelectcoun?:number
};

const SubQuestion: FC<Props> = ({
  question,
  questionIndex,
  id,
  onNext,
  onComplete,
  isLastQuestion,
  isSubQuestion,
  passable,
  mainQuestionSelectcoun
}) => {
  const { questiontext, description, questiontypeid, choicelist, required_question } = question || {};
  const { setFieldValue, values } = useFormikContext<ICreateSurvey>();
  const [answerQuestion] = useAnswerQuestionMutation();
  const [answerList, setAnswerList] = useState<Array<any>>([]);
  const [stringValue, setStringValue] = useState<any>('');
  const [stringValue2, setStringValue2] = useState<any>('');

  const { isButtonDisabled } = useAppSelector((state) => state.games);

  const dispatch = useAppDispatch();

  const setIsButtonDisabled = (isDisabled: boolean) => {
    dispatch(setIsButtonDisabledSlice(isDisabled));
  };
  useEffect(() => {
    if (question) {
      setFieldValue(`questions[${questionIndex}].surveyid`, id);
      setFieldValue(`questions[${questionIndex}].subquestionid`, question.questionid);
      setFieldValue(`questions[${questionIndex}].questiontypeid`, question.questiontypeid);
      setFieldValue(`questions[${questionIndex}].required_question`, question.required_question);
      setFieldValue(`questions[${questionIndex}].choicelist`, question.choicelist);
      setFieldValue(`questions[${questionIndex}].passablesub`, question.passable);
    }
  }, [question, setFieldValue, questionIndex,id]);

  useEffect(() => {
    checkbutton();
  }, [values.questions, questionIndex, questiontypeid]);

  const checkbutton = () => {
    if (passable === 1) {
      setIsButtonDisabled(false);
    } else {
      if (questiontypeid === 1 || questiontypeid === 2) {
        // Serbest metin
        setIsButtonDisabled(!values.questions[questionIndex]?.question_option_val);
      } else if (questiontypeid === 3 && question.selectcount === 1) {
        // Tek seçim (radio)
        setIsButtonDisabled(!values.questions[questionIndex]?.question_radio_id);
      } else if (questiontypeid === 3 && question.selectcount > 1) {
        // Çoklu seçim (checkbox)
        const selectedCount = values.questions[questionIndex]?.question_checkbox_ids_sub?.length || 0;
        setIsButtonDisabled(selectedCount === question.selectcount?false: true);
      }
    }
  };
  const selectedCount = values.questions[questionIndex]?.question_checkbox_ids_sub?.length || 0;
  const handleAnswerSubmit = async () => {
    const newAnswers: Array<any> = [];
    try {
      if (question.questiontypeid === 1 || question.questiontypeid === 2) {
        newAnswers.push({
          surveyid: id,
          questionid: question.questionid,
          response: values.questions[questionIndex].question_option_val,
          choiceid: null,
        });
      } else if (question.questiontypeid === 3) {
        const selectedChoices = [
          ...(values.questions[questionIndex]?.question_checkbox_ids_sub || []),
          ...(values.questions[questionIndex]?.question_radio_id
            ? [values.questions[questionIndex]?.question_radio_id]
            : []),
        ];
        const isOtherOptionSelected = values.questions[questionIndex]?.choicelist.find(
          (choice: any) => selectedChoices.includes(choice.choiceid.toString()) && choice.isother === 1,
        );

        const validChoices = selectedChoices.filter((choice): choice is string => typeof choice === 'string');
        validChoices.forEach((choiceId: string) => {
          newAnswers.push({
            surveyid: id,
            questionid: question.questionid,
            response:
              isOtherOptionSelected !== undefined && isOtherOptionSelected.isother === 1
                ? values.questions[questionIndex].question_option_val
                : null,
            choiceid: choiceId,
          });
        });
        if (
          values.questions[questionIndex].subquestionid !== undefined &&
          values.questions[questionIndex].subquestionid !== 0
        ) {
          const selectedChoices = [
            ...(values.questions[questionIndex]?.question_checkbox_ids_sub || []),
            ...(values.questions[questionIndex]?.question_radio_id_sub
              ? [values.questions[questionIndex]?.question_radio_id_sub]
              : []),
          ];
          const isOtherOptionSelected = values.questions[questionIndex]?.choicelist.find(
            (choice: any) => selectedChoices.includes(choice.choiceid.toString()) && choice.isother === 1,
          );
          const validChoices = selectedChoices.filter((choice): choice is string => typeof choice === 'string');
          validChoices.forEach((choiceId: string) => {
            newAnswers.push({
              surveyid: id,
              questionid: question.subquestionid,
              response:
                isOtherOptionSelected !== undefined && isOtherOptionSelected.isother === 1
                  ? values.questions[questionIndex].question_option_val
                  : null,
              choiceid: choiceId,
            });
          });
        }
      }

      // Yeni cevapları answerList'e ekleyin
      setAnswerList((prevAnswers) => [...prevAnswers, ...newAnswers]);

      // Son soruda gönder
      if (isLastQuestion) {
        const requestBody = {
          answerlist: answerList.concat(newAnswers),
          //  surveycompleted: 1,
          surveyid: id,
        };

        await answerQuestion(requestBody);
        onComplete();
      } else {
        onNext();
      }
    } catch (error) {
      console.error('Cevap gönderilirken bir hata oluştu:', error);
      alert('Cevap gönderilirken bir hata oluştu.');
    }
  };

  useEffect(() => {
    if (isSubQuestion) {
      handleAnswerSubmit();
    }
  }, [values.questions[questionIndex]?.choicelist]);

  const handleChange = (e: any, optionIndex: number, type: number) => {
    const newOptions = values.questions[questionIndex].question_option_val || [];
    if (type === 1) {
      setStringValue(e.target.value);
    } else {
      setStringValue2(e.target.value);
    }
    newOptions[optionIndex] = e.target.value;
    setFieldValue(`questions[${questionIndex}].question_option_val`, newOptions);
    setFieldValue(`questions[${questionIndex}].passablesub`, 1);
  };

  function getQuestionOptionValueLength(values: any, questionIndex: number): string {
    return `${stringValue.length}/500 karakter`;
  }

  function getQuestionOptionValueLength2(values: any, questionIndex: number): string {
    return `${stringValue2.length}/500 karakter`;
  }

  return (
    <div className="mb-12">
      {question.multimediapath && (
        <img
          src={question.multimediapath}
          style={{
            width: '150px',
            height: '150px',
            objectFit: 'contain',
          }}
          alt="question"
          className="mb-8  "
        />
      )}
      <div className={`question-info text-gray-500 fw-bold mb-3 ${required_question === 'true' ? 'required' : ''}`}>
        {questiontext} -{' '}
        {questiontypeid === 1
          ? 'Serbest Metin'
          : questiontypeid === 2
          ? 'Sayısal Değer'
          : questiontypeid === 3 && question.selectcount === 1
          ? 'Tek seçim'
          : question.selectcount > 1
          ? 'Çoklu seçim'
          : ''}
      </div>
      <div className="text-gray-800 mb-8 fw-bold fs-4">{description}</div>
      {questiontypeid !== 1 && questiontypeid !== 2 ? (
        choicelist?.map((option: any, index: number) => (
          <SubOption
            key={option.subquestionid}
            questionType={
              questiontypeid === 3 && question.selectcount === 1
                ? 2
                : questiontypeid === 3 && question.selectcount > 1
                ? 3
                : 1
            }
            option={option}
            questionIndex={questionIndex}
            optionIndex={index}
            onCompleteOption={checkbutton}
            selectedCountData={question.selectcount}
            questionid={question.questionid}
            mainQuestionSelectcoun={mainQuestionSelectcoun}
          />
        ))
      ) : (
        <div>
          {question.questiontypeid === 2 ? (
            // Number input if questiontypeid is 2
            <div style={{ width: '42vw', display: 'block' }}>
              <Field
                type="number"
                className="form-control form-control-lg resize-none"
                onChange={(e: any) => handleChange(e, question.questionid, 1)}
                style={{ width: '100%', display: 'block' }}
                placeholder="Sayı giriniz."
                value={stringValue}
                maxLength={500}
              />
              <div>{getQuestionOptionValueLength(values, questionIndex)}</div>
            </div>
          ) : (
            // Textarea if questiontypeid is not 2 (e.g., questiontypeid === 1)
            <div style={{ width: '42vw', display: 'block' }}>
              <Field
                as="textarea"
                className="form-control form-control-lg resize-none"
                onChange={(e: any) => handleChange(e, question.questionid, 2)}
                rows={3}
                style={{ width: '100%', display: 'block' }}
                placeholder="Metin giriniz."
                value={stringValue2}
                maxLength={500}
              />
              <div>{getQuestionOptionValueLength2(values, questionIndex)}</div>
            </div>
          )}
        </div>
      )}
      {questiontypeid === 3 && question.selectcount > 1 && (
        <div className="text-muted mt-2">
          {selectedCount < question.selectcount
            ? `Maksimum ${question.selectcount} seçenek işaretleyebilirsiniz. Kalan: ${
                question.selectcount - selectedCount
              }`
            : `En fazla ${question.selectcount} seçenek işaretleyebilirsiniz.`}
        </div>
      )}
      {passable === 1 ? <div style={{ fontWeight: '600', fontSize: '14px' }}>Opsiyonel</div> : <div></div>}
    </div>
  );
};

export default SubQuestion;
