import api from '../../../../../api/api';

//? We can move this type definitions to "_requests" file

export type IResponse = {
    success: boolean;
};

const API_URL = process.env.REACT_APP_CHEERS_API_URL;

export const LEADERBOARD_URL = `${API_URL}/leaderboard`;
export const USERRANK_URL = `${API_URL}/userrank`;

export const ledaershipEndpoints = api.injectEndpoints({
    endpoints: (builder) => ({
        getLeaderboard: builder.query<any, any>({
            query: ({page, pagesize, startdate, enddate}: any) => {
                return `cheers/leaderboard?page=${page}&pagesize=${pagesize}&startdate=${startdate}&enddate=${enddate}`;
            },
            serializeQueryArgs: ({ endpointName, queryArgs }) => endpointName,
            merge: (currentCache, newItems, otherArgs) => {
                if (otherArgs.arg?.page === 1) {
                    currentCache.leaderboard = newItems.leaderboard;
                } else {
                    currentCache.leaderboard.push(...newItems.leaderboard);
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;
            },
        }),
        getUserRank: builder.query<any, any>({
            query: ({userid,startdate,enddate}: any) => {
                const addedParams: any = {};
                if (startdate) {
                    addedParams['startdate'] = startdate;
                }
                if (enddate) {
                    addedParams['enddate'] = enddate;
                }
                const params = new URLSearchParams(addedParams);

                return `${USERRANK_URL}/${userid}?${params.toString()}`;
            },
        }),
    }),
});

export const {
    useGetUserRankQuery,
    useGetLeaderboardQuery
} = ledaershipEndpoints;

export default ledaershipEndpoints;
