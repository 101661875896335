import * as React from 'react';
const SvgBook = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} fill="none" {...props}>
    <rect width={50} height={50} fill="#3E97FF" rx={6} />
    <path
      fill="#EEF6FF"
      d="M23.938 14.42a13.751 13.751 0 0 0-7.438-2.172c-1.613 0-3.163.277-4.604.786-.425.15-.709.552-.709 1.002v20.188a1.063 1.063 0 0 0 1.417 1.001 11.667 11.667 0 0 1 3.896-.664c2.826 0 5.416 1.002 7.438 2.671V14.42ZM26.063 37.232a11.635 11.635 0 0 1 7.437-2.671c1.368 0 2.679.234 3.896.664a1.063 1.063 0 0 0 1.416-1.001V14.036c0-.45-.283-.852-.708-1.002a13.792 13.792 0 0 0-4.604-.786c-2.738 0-5.29.797-7.438 2.172v22.812Z"
    />
  </svg>
);
export default SvgBook;
