import Grid from '@mui/material/Grid';
import LeftSideBar from './layout/LeftSideBar';
import { FC, Fragment, useEffect, useState } from 'react';
import RightSideBar from './layout/RightSideBar';
import HomeTimeline from './layout/HomeTimeline';
import { useGetGamesQuery } from '../../../api/services/games/gamesEndpoints';
import {
  useGetAnnouncementsQuery,
  useGetApproveRejectListQuery,
  useGetCombinedListQuery,
  useGetEventsQuery,
  useGetLinksQuery,
  useGetSurveysQuery,
} from '../../../api/services/home/homeEndpoints';
import { useGetBirthdayListQuery } from '../../modules/apps/birthdays_module/core/_requests';
import TimeLineSkeleton from '../../modules/apps/_global/components/Skeleton/TimeLineSkeleton';
import { useGetFoodMenuQuery } from '../../modules/apps/food_menu_module/core/_requests';
import { useAppDispatch, useAppSelector } from '../../../api/store/hooks';
import { authSelector, setAuth } from '../../../api/features/auth/authSlice';
import { setActiveModules } from '../../../api/features/module/moduleSlice';

const HomePage: FC = () => {
  const [page, setPage] = useState(1);

  const { data: surveysResponse, isLoading: surveysLoading, refetch: surveysRefetch } = useGetSurveysQuery({ query: "page=1&pagesize=100" });
  const { data: games, isLoading: gamesLoading, refetch: gamesRefetch } = useGetGamesQuery({ page: 1 });
  const {
    data: feeds = [],
    isLoading: feedLoading,
    isFetching: feedRefetch,
    refetch: postRefetch,
  } = useGetCombinedListQuery({ page });
  const currentDate = new Date();
  const { currentUser } = useAppSelector((state) => authSelector(state.auth));

  const {
    data: approveRejectList,
    isLoading: approveRejectListLoading,
    refetch: approveRejectListRefetch,
  } = useGetApproveRejectListQuery();


  function formatDate(locale: string | undefined = 'tr', date: Date): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const { data: announcements, isLoading: announcementsLoading } = useGetAnnouncementsQuery();
  const { data: events, isLoading: eventsLoading } = useGetEventsQuery(formatDate(undefined, new Date()).toString());

  const { data: links, isLoading: linksLoading } = useGetLinksQuery();
  const { data: birthdays, isLoading: birthdaysLoading } = useGetBirthdayListQuery({});
  const { data: food, isLoading: foodLoading } = useGetFoodMenuQuery({
    year: currentDate.getFullYear().toString(),
    month: (currentDate.getMonth() + 1).toString(),
    branchId: currentUser?.companyid?.toString() ?? '',
    shift: 'day',
    day: currentDate.getDate().toString(),
  });

  const auth = useAppSelector((state) => authSelector(state.auth));

  const isLoading =
    surveysLoading ||
    gamesLoading ||
    feedLoading ||
    announcementsLoading ||
    eventsLoading ||
    linksLoading ||
    birthdaysLoading ||
    approveRejectListLoading ||
    foodLoading;

  const isFeedLoading = feedLoading || feedRefetch;

  const dispatch = useAppDispatch();

  useEffect(() => {
   
  }, [auth.currentUser]);

  if (isLoading) {
    return <TimeLineSkeleton />;
  }

  return (
    <Fragment>
      <Grid className="mb-6" container spacing={2}>
        <LeftSideBar eventResponse={events} announcements={announcements} />
        <HomeTimeline
          page={page}
          setPage={setPage}
          refetch={async () => {
            postRefetch();
            approveRejectListRefetch();
          }}
          isLoading={isFeedLoading}
          timeLineData={feeds} 
          approveRejectList={approveRejectList?.feeds || []}
          gamesRefetch={gamesRefetch}
          surveysRefetch={surveysRefetch}
        />  
        <RightSideBar
          surveys={surveysResponse} 
          games={games}
          birthdays={birthdays}
          links={links}
          food={food}
          selectedDate={currentDate}
        />
      </Grid>
    </Fragment>
  );
};

export default HomePage;
