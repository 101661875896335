import React, { useMemo, useEffect, useState, useRef } from 'react';
import { ImageFileAndPreviewInput } from '../../_global/components/Input/ImageFileAndPreviewInput';
import { Stack } from '@mui/material';
import InputWrapper from '../../_global/components/Input/InputWrapper';
import { ISelectStringType } from '../../_global/components/Select/ISelectType';
import Select from 'react-select';
import { useAppSelector } from '../../../../../api/store/hooks';
import { authSelector } from '../../../../../api/features/auth/authSlice';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  useCreateSecondHandMutation,
  useUpdateSecondHandMutation,
  useUpdateStatusSecondHandMutation,
} from '../../../../../api/services/secondhands/secondHandsEndpoints';
import { useIntl } from 'react-intl';
import { KTIcon } from '../../../../../_metronic/helpers';
import confirmMessage, { okayMessage } from '../../_global/components/Modal/ConfirmMessage';
import {
  useGetListCitiesQuery,
  useGetListiningCategoriesQuery,
} from '../../../../../api/services/secondhands/secondHandsEndpoints';

type TypeSecondHandDetail = {
  title: string;
  description: string;
  price?: number;
  city_id?: number;
  category_id?: number;
  is_free?: boolean;
  images?: [any];
  currency_id?: number;
  phone?: string;
};

const Schema = Yup.object<TypeSecondHandDetail>().shape({
  title: Yup.string().required('Girilmesi zorunludur.'),
  description: Yup.string().required('Girilmesi zorunludur.'),
  price: Yup.string().required('Girilmesi zorunludur.'),
  category_id: Yup.number().required('Girilmesi zorunludur.'),
  city_id: Yup.number().required('Girilmesi zorunludur.'),
  images: Yup.array().min(1).required('Girilmesi zorunludur.'),
});

export default function AddSecondHand({ updateItem, successUpdateItem, successCreateItem, itemid }: any) {
  const intl = useIntl();

  const { currentUser } = useAppSelector((state) => authSelector(state.auth));
  const [categoryList, setCategoryList] = useState<[]>([]);
  const [cityList, setCityList] = useState<any | undefined>();
  const [selectedCategory, setSelectedCategory] = useState<ISelectStringType>({
    label: intl.formatMessage({ id: 'CREATE_SECONDHAND_CHOOSE' }),
    value: '0',
  });
  const [selectedCity, setSelectedCity] = useState<ISelectStringType>({
    label: intl.formatMessage({ id: 'CREATE_SECONDHAND_CHOOSE' }),
    value: '0',
  });

  const [removeImageIds, setRemoveImageIds] = useState<any>([]);

  const isUpdate = updateItem !== undefined && Object.keys(updateItem).length !== 0;

  const [createSecondHand, { isLoading: createLoading }] = useCreateSecondHandMutation();
  const [updateStatusSecondHand] = useUpdateStatusSecondHandMutation();
  const [updateSecondHand, { isLoading: updateLoading }] = useUpdateSecondHandMutation();

  const [initialValues, setInitialValues] = useState<TypeSecondHandDetail>({
    title: '',
    description: '',
    is_free: false,
    currency_id: 1,
  });

  const { data: getCategoriesList } = useGetListiningCategoriesQuery();
  const { data: getCityList } = useGetListCitiesQuery();

  useEffect(() => {
    if (getCategoriesList) {
      const temp = getCategoriesList.categories.map((cat: any) => ({
        value: cat.listingcategoryid,
        label: cat.categoryname,
      }));
      setCategoryList(temp);
    }
    if (getCityList) {
      const temp = getCityList.cities.map((city: any) => ({
        value: city.cityid,
        label: city.cityname,
      }));
      setCityList(temp);
    }

    if (updateItem !== undefined && Object.keys(updateItem).length !== 0) {
      formik.setFieldValue('images', updateItem.medias);
      formik.setFieldValue('title', updateItem.title);
      formik.setFieldValue('description', updateItem.description);
      formik.setFieldValue('price', updateItem.price);
      formik.setFieldValue('is_free', updateItem.price !== 0 ? false : true);
      formik.setFieldValue('category_id', updateItem.listingcategoryid);
      setSelectedCategory({ value: updateItem.listingcategoryid, label: updateItem?.categoryname });
      setSelectedCity({ value: updateItem.cityid, label: updateItem?.cityname });
      formik.setFieldValue('city_id', updateItem.cityid);
      formik.setFieldValue('itemid', itemid);
    }
  }, [getCategoriesList, getCityList]);

  const handleKeyDown = (e: any) => {
    // Allow numeric keys, backspace, delete, and arrow keys
    if (
      !/^\d$/.test(e.key) &&
      e.key !== 'Backspace' &&
      e.key !== 'Delete' &&
      e.key !== 'ArrowLeft' &&
      e.key !== 'ArrowRight' &&
      e.key !== 'ArrowUp' &&
      e.key !== 'ArrowDown'
    ) {
      e.preventDefault();
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values: TypeSecondHandDetail) => {
      console.log('form value', values);
      if (!isUpdate) {
        const data = await createSecondHand({ ...values, phone: currentUser?.phone, currency_id: 1 }).unwrap();
        if (data != null) {
          okayMessage(
            intl.formatMessage({ id: 'CREATE_SECONDHAND_CONFIRM_HEADER' }),
            intl.formatMessage({ id: 'CREATE_SECONDHAND_CONFIRM_DESC' }),
            successCreateItem,
          );
        }
      } else {
        const data = await updateSecondHand({
          ...values,
          item_id: itemid,
          phone: currentUser?.phone,
          deleted_image_ids: removeImageIds,
        }).unwrap();
        if (data != null) {
          okayMessage(
            intl.formatMessage({ id: 'CREATE_SECONDHAND_CONFIRM_HEADER' }),
            intl.formatMessage({ id: 'CREATE_SECONDHAND_CONFIRM_DESC' }),
            successCreateItem,
          );
        }
      }
    },
  });

  return (
    <Stack direction="column" marginX={2}>
      <div className="scrollable-container">
        <form className="d-flex flex-column" onSubmit={formik.handleSubmit}>
          {updateItem !== undefined && updateItem?.statusid === 'true' && (
            <div className="alert bg-light-success border border-success border-1 d-flex flex-column flex-sm-row p-5 mb-10 align-items-center">
              <KTIcon iconName="check-circle" iconOverride="fs-2x text-success" />
              <h5 className="ms-4 mt-3">{intl.formatMessage({ id: 'SECOND_HAND_ACTIVE_WITH_HEADER' })}</h5>
              <div className="ms-auto d-flex">
                <a
                  href="#"
                  className="btn btn-success"
                  onClick={async () => {
                    confirmMessage(
                      intl.formatMessage({ id: 'CREATE_SECONDHAND_SELL_CONFIRM_HEADER' }),
                      intl.formatMessage({ id: 'CREATE_SECONDHAND_SELL_CONFIRM_DESC' }),
                      async () => {
                        const res = await updateStatusSecondHand({ list_id: updateItem?.id, sold_status: true });
                        successUpdateItem(updateItem?.id);
                      },
                    );
                  }}
                >
                  {intl.formatMessage({ id: 'SECOND_HAND_SELL_CHECK' })}
                </a>
              </div>
            </div>
          )}
          <ImageFileAndPreviewInput
            width={200}
            required
            imagesLength={10}
            currentImages={formik.getFieldProps('images')}
            changeEvent={(events: any, removeImageIds: any) => {
              console.log('events', events);
              setRemoveImageIds(removeImageIds);
              let newEvents = extractMediaUris(events);
              console.log('newEvents', newEvents);
              formik.setFieldValue('images', [...newEvents]);
            }}
          />

          <InputWrapper
            title={intl.formatMessage({ id: 'CREATE_SECONDHAND_TITLE' })}
            containerClassName="mt-7 d-flex"
            required={true}
          >
            <input
              {...formik.getFieldProps('title')}
              name="title"
              placeholder={intl.formatMessage({ id: 'CREATE_SECONDHAND_TEXT_PLACEHOLDER' })}
              className="form-control border rounded  mt-2"
              style={{ flex: 1, width: '100%', height: 48, paddingLeft: 15, border: 0 }}
            />
          </InputWrapper>
          <InputWrapper
            title={intl.formatMessage({ id: 'CREATE_SECONDHAND_DESCRIPTION' })}
            containerClassName="mt-7 d-flex"
            required={true}
          >
            <textarea
              {...formik.getFieldProps('description')}
              name="description"
              placeholder={intl.formatMessage({ id: 'CREATE_SECONDHAND_TEXT_PLACEHOLDER' })}
              className="form-control border rounded  mt-2"
              style={{ flex: 1, width: '100%', paddingLeft: 15, border: 0, height: 80, paddingTop: 10 }}
            />
          </InputWrapper>
          <div className="row">
            <div className="col">
              <InputWrapper
                title={intl.formatMessage({ id: 'CREATE_SECONDHAND_PRICE' })}
                containerClassName="mt-7"
                required={true}
              >
                <input
                  disabled={formik.getFieldProps('is_free').value}
                  {...formik.getFieldProps('price')}
                  placeholder={intl.formatMessage({ id: 'CREATE_SECONDHAND_PRICE_PLACEHOLDER' })}
                  className="form-control border rounded  mt-2"
                  style={{ height: 48, paddingLeft: 15, border: 0 }}
                  onKeyDown={handleKeyDown}
                />
              </InputWrapper>
            </div>
            <div className="col">
              <InputWrapper
                title={intl.formatMessage({ id: 'CREATE_SECONDHAND_CATEGORY' })}
                containerClassName="mt-7"
                required={true}
              >
                <Select
                  options={categoryList}
                  placeholder={intl.formatMessage({ id: 'CREATE_SECONDHAND_CHOOSE' })}
                  value={selectedCategory}
                  onChange={(data: any) => {
                    setSelectedCategory(data);
                    formik.setFieldValue('category_id', data?.value);
                  }}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      height: 48,
                    }),
                  }}
                  classNames={{
                    control: (state) => 'mt-2',
                  }}
                />
              </InputWrapper>
            </div>
          </div>
          <div className="mt-7">
            <div className="form-check form-switch form-check-custom form-check-solid">
              <label className="form-check-label">{intl.formatMessage({ id: 'CREATE_SECONDHAND_FREE' })}</label>
              <input
                className="form-check-input ms-4"
                type="checkbox"
                id="flexSwitchDefault"
                onChange={(event) => {
                  if (event?.target?.checked) {
                    formik.setFieldValue('price', 0);
                  }
                  formik.setFieldValue('is_free', event?.target?.checked);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <InputWrapper
                title={intl.formatMessage({ id: 'CREATE_SECONDHAND_CITY' })}
                containerClassName="mt-7"
                required={true}
              >
                <Select
                  placeholder={intl.formatMessage({ id: 'CREATE_SECONDHAND_CHOOSE' })}
                  options={cityList}
                  value={selectedCity}
                  onChange={(data: any) => {
                    setSelectedCity(data);
                    formik.setFieldValue('city_id', data?.value);
                  }}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      height: 48,
                    }),
                  }}
                  classNames={{
                    control: (state) => 'mt-2  ',
                  }}
                />
              </InputWrapper>
            </div>
            <div className="col">
              <InputWrapper
                title={intl.formatMessage({ id: 'CREATE_SECONDHAND_CONTACT_NUMBER' })}
                containerClassName="mt-7"
                required={true}
              >
                <input
                  disabled={true}
                  placeholder="Metin giriniz"
                  className="form-control border rounded border-gray-300 mt-2"
                  style={{ height: 48, paddingLeft: 15, border: 0 }}
                  value={currentUser?.phone}
                />
              </InputWrapper>
            </div>
          </div>
          <div className=" d-flex align-items-center justify-content-center p-5 mt-4 text-center">
            <button
              type="submit"
              className="btn btn-info mt-5"
              style={{ width: '30rem', alignSelf: 'center' }}
              disabled={formik.isSubmitting || !(formik.isValid && formik.dirty)}
            >
              {createLoading || updateLoading ? (
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              ) : (
                <>
                  {!isUpdate
                    ? intl.formatMessage({ id: 'CREATE_SECONDHAND_PUBLISH' })
                    : intl.formatMessage({ id: 'CREATE_SECONDHAND_PUBLISH_UPDATE' })}
                </>
              )}
            </button>
          </div>
        </form>
      </div>
    </Stack>
  );
}

function extractMediaUris(data: any[]): string[] {
  return data
    .filter((item) => typeof item === 'object' && item.mediauri) // mediauri içeren nesneleri filtrele
    .map((item) => item.mediauri) // mediauri değerlerini al
    .concat(
      // doğrudan string olan elemanları da ekle
      data.filter((item) => typeof item === 'string'),
    );
}
