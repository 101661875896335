import api from '../../../../../api/api';
import { ICreateFeedback } from './_modals';

export const feedbackApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFeedbackCategories: builder.query<any, void>({
      query: () => {
        return `feedbackcategories`;
      },
    }),
    getFeedbacks: builder.query<any, string | number | void>({
      query: (query:string) => {
        return `feedbacks${query}`;
      },
    }),
    getFeedbackDetail: builder.query<any, string | void>({
      query: (feedback_id: string) => {
        return `settings/feedback/detail/${feedback_id}`;
      },
    }),
    createFeedback: builder.mutation<any, ICreateFeedback>({
      query: ({ feedback_type_id, title, description, images }: ICreateFeedback) => {
        const imageArray: any[] = [];
        if (images && images.length > 0) {
          images.forEach((image: any) => {
            imageArray.push(image);
          });
        }
        const requestBody = {
          categoryid: feedback_type_id,
          feedbacktext: description,
          images: imageArray.length > 0 ? imageArray : [],
          
        };
        return {
          url: `feedbacks`,
          method: 'POST',
          body: requestBody,
          formData: true,
        };
      },
    }),
    uploadService: builder.mutation<any, File>({
      query: (file: File) => {
        const formData = new FormData();
        formData.append('file', file);
        return {
          url: `/upload`,
          method: 'POST',
          body: formData,
          formData: true, 
        };
      },
    }),
    getFeedbacksComment: builder.mutation<any, { requestBody: any, feedbackid: number }>({
      query: ({ requestBody, feedbackid }) => ({
        url: `feedbacks/${feedbackid}`,
        method: 'POST',
        body: requestBody,
        formData: true,
      }),
    }),
  }),
});

export const {
  useGetFeedbackCategoriesQuery,
  useGetFeedbacksQuery,
  useGetFeedbackDetailQuery,
  useGetFeedbacksCommentMutation,
  useCreateFeedbackMutation,
  useUploadServiceMutation,
} = feedbackApi;
