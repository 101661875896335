import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { KTCard } from '../../../../_metronic/helpers';
import { Divider } from '@mui/material';
import dayjs from 'dayjs';
import ImageSlider from '../_global/components/Swiper/ImageSlider/ImageSlider';
import Loading from '../../../components/Loading';

import { WaitSuggestionStepper } from './components/Steppers/SuggestionStepper';
import { RedSuggestionStepper } from './components/Steppers/RedSuggestionStepper';
import { WaitingSuggestionStepper } from './components/Steppers/WaitSuggestionStepper';
import { SendKaizenStepper } from './components/Steppers/SendKazizenStepper';
import { SuccessKaizenStepper } from './components/Steppers/SuccessKazizenStepper';
import { RedKaizenStepper } from './components/Steppers/RedKazizenStepper';
import { RedPurposeStepper } from './components/Steppers/RedPurposeStepper';
import { SuccessSuggestionStepper } from './components/Steppers/SuccessSuggestion';
import confirmMessage from '../_global/components/Modal/ConfirmMessage';
import { ModalHeader } from '../_global/components/Modal/ModalHeader';
import CreateKaizenForm from './CreateKaizenModal';
import { Modal } from '../_global/components/Modal/Modal';
import { useSuggestionDetailQuery } from '../../../../api/services/home/homeEndpoints';

export default function SuggestionDetail() {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({});
  const [lastAction, setLastAction] = useState<any>({});
  const [solutionData, setSolutionData] = useState<any>({});
  const [lastActionList, setLastActionList] = useState<any>([]);

  const [isCreateKaizenFormOpen, setIsCreateKaizenFormOpen] = useState({ show: false, isPreview: false });
  const [suggestionId, setSuggestionId] = useState<any | null>(null); 
  const [selectedSuggestion, setSelectedSuggestion] = useState<any>({});
  const { data: suggestions, isLoading, refetch } = useSuggestionDetailQuery(suggestionId ?? undefined, {
    skip: suggestionId === null,
  });

  useEffect(() => {
    if (params.id) {
      setSuggestionId(Number(params.id));
    }
  }, [params.id]);

  useEffect(() => {
    if (suggestionId) {
      refetch();
    }
  }, [suggestionId, refetch]);

  useEffect(() => {
    if (suggestions) {
      fetchSuggestion();
    }
  }, [suggestions]);

  const fetchSuggestion = async () => {
    setLoading(true);
    const result = suggestions;
    setData(result?.suggestion);
    setLastAction(result?.last_actions?.[0]);
    if (
      result?.suggestion?.statusid === 6 ||
      result?.suggestion?.statusid === 8 ||
      result?.suggestion?.statusid === 5||result?.suggestion?.statusid === 9||
      result?.suggestion?.statusid === 10
    ) {
      await fetchSolution(result.data.solution_id);
    }
    setLastActionList(result?.suggestionactions);
    setLoading(false);
  };

  const fetchSolution = async (id: any) => {
    const res = await fetch(`https://suggestion.mingapp.co/solution/detail/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const result = await res.json();
    setSolutionData(result?.data);
  };

  const handleCloseModalWarning = useCallback(() => {
    setIsCreateKaizenFormOpen({ show: false, isPreview: false });
  }, []);

  const returnStepper = (string: number) => {
    switch (string) {
      case 1:
        return <WaitSuggestionStepper />;
      case 3:
        return <RedSuggestionStepper />;
      case 4:
        return <WaitingSuggestionStepper />;
      case 6:
        return <SuccessSuggestionStepper />;
      case 7:
        return <RedPurposeStepper />;
      case 1 || 2 || 4 || 6 :
        return <SendKaizenStepper />;
      case 10:
        return <RedKaizenStepper />;
      case 5 || 9:
        return <SuccessKaizenStepper />;
      default:
        return <WaitSuggestionStepper />;
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <KTCard className="p-8">
        <div className="fw-bold fs-4">{data?.title}</div>
        <Divider className="my-5" />
        {<div className="mt-5">{returnStepper(data?.statusid)}</div>}
        {data?.statusid === 3 && (
          <div>
            <div className="mt-5" style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
              <div>
                <div className="fs-7 text-gray-500 ">Ret Gerekçesi</div>
                <div className="text-gray-800">
                  <div className="text-gray-800">{lastAction?.rejected_reason?.text}</div>
                </div>
              </div>
            </div>
            <div className="mt-5" style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
              <div>
                <div className="fs-7 text-gray-500 ">Ret Açıklaması</div>
                <div className="text-gray-800">{lastAction?.note}</div>
              </div>
            </div>
          </div>
        )}
        {data?.statusid === 10 && (
          <div>
            <div className="mt-5" style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
              <div>
                <div className="fs-7 text-gray-500 ">Kaizen Ret Açıklaması</div>
                {lastActionList?.filter((action: any) => action?.action_type === 'kaizen_rejected')?.[0]?.note}
              </div>
            </div>
          </div>
        )}
      </KTCard>
      {(data?.statusid === 10 ||
        data?.statusid === 6||
        data?.statusid === 5 ||   data?.statusid === 9 ) && (
        <KTCard className="mt-5 p-8">
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div className="fw-bold fs-4">Önce-Sonra Kaizen Formu</div>
            {data?.statusid === 10 && (
              <div style={{ justifySelf: 'end' }}>
                <button
                  onClick={() => {
                    setSelectedSuggestion(data);
                    setIsCreateKaizenFormOpen({ show: true, isPreview: false });
                  }}
                  type="submit"
                  disabled={false}
                  className={`btn btn-sm ${'btn-info'}`}
                  style={{ alignSelf: 'flex-end', justifySelf: 'flex-end' }}
                >
                  Kaizen Formunu Yeniden Gönder
                </button>
              </div>
            )}
            {data?.statusid === 6 && (
              <div style={{ justifySelf: 'end' }}>
                <button
                  onClick={() => {
                    setSelectedSuggestion(data);
                    setIsCreateKaizenFormOpen({ show: true, isPreview: false });
                  }}
                  type="submit"
                  disabled={false}
                  className={`btn btn-sm ${'btn-info'}`}
                  style={{ alignSelf: 'flex-end', justifySelf: 'flex-end' }}
                >
                  Kaizen Formu Oluştur
                </button>
              </div>
            )}
            { (data?.statusid === 5 ||   data?.statusid === 9)  && (
              <div style={{ justifySelf: 'end' }}>
                <button
                  onClick={() => {
                    setSelectedSuggestion(data);
                    setIsCreateKaizenFormOpen({ show: true, isPreview: true });
                  }}
                  type="submit"
                  disabled={false}
                  className={`btn btn-sm ${'btn-info'}`}
                  style={{ alignSelf: 'flex-end', justifySelf: 'flex-end' }}
                >
                  Kaizen Formunu Görüntüle
                </button>
              </div>
            )}
          </div>
        </KTCard>
      )}

      {(data?.statusid === 6 ||
        data?.statusid === 8 ||
        data?.statusid === 5 ||   data?.statusid === 9 ||
        data?.statusid === 10) && (
        <KTCard className="mt-4 p-8">
          <div className="fw-bold fs-4">Çözüm Detayları</div>
          <div className="mt-5">
            <div className="fs-7 text-gray-500 ">Çözüm Açıklaması</div>
            <div className="text-gray-800">{solutionData?.solve_details}</div>
          </div>
          {solutionData?.media_display !== 0  && (
            <div className="mt-5" style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
              <div>
                <div className="fs-7 text-gray-500 mb-2">Medyalar</div>
                <ImageSlider images={solutionData?.media} isPreviewEnabled={true} />
              </div>
            </div>
          )}
        </KTCard>
      )}

      <KTCard className="p-8 mt-5">
        <div className="fw-bold fs-4">Öneri Bilgileri</div>
        <div className="mt-5" style={{ display: 'grid', gridTemplateColumns: '0.5fr 0.5fr' }}>
          <div>
            <div className="fs-7 text-gray-500">Bildirim Tarihi</div>
            <div className="text-gray-800">{data?.created}</div>
          </div>
          <div>
            <div className="fs-7 text-gray-500 ">Öneri Türü</div>
            <div className="text-gray-800">{data?.suggestiontypedesc}</div>
          </div>
        </div>
        <div className="mt-5" style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
          <div>
            <div className="fs-7 text-gray-500 ">Lokasyon</div>
            <div className="text-gray-800">{data?.branchdesc}</div>
          </div>
        </div>
        <div className="mt-5" style={{ display: 'grid', gridTemplateColumns: '0.5fr 0.5fr' }}>
          <div>
            <div className="fs-7 text-gray-500 ">Öneri Açıklaması</div>
            <div className="text-gray-800">{data?.description}</div>
          </div>
          <div>
            <div className="fs-7 text-gray-500 ">Çözüm Önerisi</div>
            <div className="text-gray-800">{data?.solutionproposal}</div>
          </div>
        </div>
        {data?.images?.length > 0 && (
          <div className="mt-5" style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
            <div>
              <div className="fs-7 text-gray-500 mb-2">Medyalar</div>
              <ImageSlider images={data?.images} isPreviewEnabled={true} />
            </div>
          </div>
        )}
        <div className="mt-5" style={{ display: 'grid', gridTemplateColumns: '1fr' }}>
          <div>
            <div className="fs-7 text-gray-500 ">Öneri ID</div>
            <div className="text-gray-800">{params?.id}</div>
          </div>
        </div>
      </KTCard>
      {isCreateKaizenFormOpen.show && (
        <Modal
          id="feedback-modal"
          isScrollable={false}
          bodyClassName="mx-xl-0"
          toggle={handleCloseModalWarning}
          isDisposableOnOutsideClick={false}
          headerComponent={<ModalHeader title={'Kaizen Formu'} toggle={handleCloseModalWarning} />}
          formComponent={
            <CreateKaizenForm
              onSuccess={() => {
                setIsCreateKaizenFormOpen({ show: false, isPreview: false });
                fetchSuggestion();
              }}
              isReview={isCreateKaizenFormOpen.isPreview}
              selectedSuggestion={selectedSuggestion}
            />
          }
        />
      )}
    </div>
  );
}
