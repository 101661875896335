/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useAppDispatch } from '../../../../api/store/hooks';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { setAuth, setHeaderCode } from '../../../../api/features/auth/authSlice';
import { useLoginPasswordMutation } from '../../../../api/services/auth/authEndpoints';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import { useState } from 'react';
import axios from 'axios';
import ContractModal from './ContractModal';
import { okayMessage } from '../../apps/_global/components/Modal/ConfirmMessage';

type Consent = {
  consentdocdesc: string;
  consentdocid: number;
  fileuri: string;
  isrequired: number;
  version: string;
};

interface LoginCreatePasswordProps {
  consentsList: Consent[];
  username: string;
  forgotPasswordFunc: (username: string) => Promise<void>;
}

const initialValues = {
  password: '',
};

const Schema = Yup.object().shape({
  password: Yup.string().required('Şifre girilmesi zorunludur.'),
});

export default function PasswordInput({
  username,
  consentsList,
  forgotPasswordFunc,
}: {
  username: string;
  consentsList: Consent[];
  forgotPasswordFunc: (username: string) => Promise<void>;
}) {
  const [loginPassword, { isLoading: loading }] = useLoginPasswordMutation();
  const dispatch = useAppDispatch();
  const analytics = getAnalytics();
  const [checkedConsentsList, setCheckedConsentsList] = useState<boolean[]>([]);
  const [modalContent, setModalContent] = useState('');
  const [activeConsentTitle, setActiveConsentTitle] = useState<string | null>(null);
  const [activeConsentIndex, setActiveConsentIndex] = useState<number | null>(null);
  const [consentedDocIds, setConsentedDocIds] = useState<number[]>([]);
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values) => {
      try {
        const response = await loginPassword({
          username: username,
          usersecret: values.password, // Kullanıcı tarafından girilen şifre
          clienttypeid: 4,
          appversion: '',
          ipv4: '',
          devicekey: '',
          devicedesc: '',
          devicemodel: '',
          version: '',
          macaddress: '',
          consenteddocids: consentedDocIds ?? [],
        }).unwrap();
        logEvent(analytics, 'loginWeb', {
          content_type: 'loginUserWeb',
          content_id: 'loginWeb',
        });
        setUserProperties(analytics, { client: 'web' }, { global: true });
        dispatch(setAuth(response));
        dispatch(setHeaderCode(response.usersecret.token));
      } catch (error: any) {
        okayMessage('Hata', `${error.data.exMsg}`);
        formik.setFieldError('password', error.data.exMsg);
      }
    },
  });

  const handleShowDocument = async (url: string, consentTitle: string, index: number) => {
    setActiveConsentTitle(consentTitle);
    setActiveConsentIndex(index);
    setModalContent(url);
  };

  const acceptConsent = () => {
    if (activeConsentIndex !== null) {
      const updatedCheckedConsentsList = [...checkedConsentsList];
      updatedCheckedConsentsList[activeConsentIndex] = true;
      setCheckedConsentsList(updatedCheckedConsentsList);

      const consentId = consentsList[activeConsentIndex].consentdocid;
      setConsentedDocIds((prev) => [...prev, consentId]);

      // Close modal
      setModalContent('');
      setActiveConsentTitle(null);
      setActiveConsentIndex(null);
    }
  };

  return (
    <div className="col-md-6 d-flex flex-column justify-content-center align-items-center py-10">
      <form className="d-flex flex-column" onSubmit={formik.handleSubmit}>
        <div className="fs-3x fw-bolder mt-10" style={{ color: '#5E13D9' }}>
          Girişini
        </div>
        <div className="fs-3x fw-bolder mb-10">Doğrula</div>
        <div className="mb-3 mt-10" style={{ width: '30rem' }}>
          <label htmlFor="password" className="form-label">
            Lütfen şifrenizi giriniz
          </label>
          <input
            placeholder="Şifre"
            {...formik.getFieldProps('password')}
            className={clsx('form-control bg-transparent mt-5')}
            type="password"
            name="password"
            autoComplete="off"
          />
          {formik.touched.password && formik.errors.password && (
            <div className="fv-plugins-message-container text-danger">
              <span role="alert" className="--bs-danger-text-emphasis">
                {formik.errors.password}
              </span>
            </div>
          )}
          <div
            className="d-flex justify-content-end mt-2"
            style={{ cursor: 'pointer' }}
            onClick={async () => {
              await forgotPasswordFunc(username);
            }}
          >
            Şifrenizi mi unuttunuz?
          </div>
        </div>
        {consentsList?.map((consent, index) => (
          <div key={index}>
            <label>
              <input
                type="checkbox"
                checked={checkedConsentsList[index]}
                id="isPlatformRulesCheck"
                className="form-check-input"
                {...formik.getFieldProps('isPlatformRulesCheck')}
                onChange={() => handleShowDocument(consent.fileuri, consent.consentdocdesc, index)}
                {...(checkedConsentsList[index]
                  ? {}
                  : {
                      'data-bs-toggle': 'modal',
                      'data-bs-target': `#contract_modal1${consent.consentdocid}`,
                    })}
              />
              <button
                type="button"
                onClick={() => handleShowDocument(consent.fileuri, consent.consentdocdesc, index)}
                style={{
                  background: 'none',
                  border: 'none',
                  color: 'blue',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  padding: '0',
                }}
              >
                {consent.consentdocdesc}
              </button>
            </label>
          </div>
        ))}
        <button
          type="submit"
          className="btn btn-info mt-5"
          style={{ width: '30rem' }}
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {loading ? <span className="spinner-border spinner-border-sm align-middle" /> : 'Devam Et'}
        </button>
        <img
          className="mt-17"
          alt="Logo"
          src={toAbsoluteUrl('/media/logos/logo_renkli.png')}
          style={{
            width: '140px',
            height: '75px',
            justifyContent: 'center',
            alignSelf: 'center',
            objectFit: 'contain',
          }}
        />
      </form>

      {consentsList?.map((consent, index) => (
        <ContractModal
          text={modalContent}
          id={`contract_modal1${consent.consentdocid}`}
          loading={false}
          contractTitle={activeConsentTitle || 'Document'}
          acceptClick={acceptConsent}
        />
      ))}
    </div>
  );
}
