import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Birthday {
    birthday_user_id: string;
    template_id: any;
    default_message: string;
    gift_score: {
        id: string;
        title: string;
        value: string;
    };
}

const initialState: Birthday = {
    birthday_user_id: '',
    template_id: null,
    default_message: '',
    gift_score: {
        id: '',
        title: '',
        value: '',
    },
};

const birthdaysSlice = createSlice({
    name: 'birthdays',
    initialState,
    reducers: {
        setFilter: (state: Birthday, action: PayloadAction<any>) => {
            if (action.payload != null) {
                state.birthday_user_id = action.payload.birthday_user_id;
                state.template_id = action.payload.template_id;
                state.default_message = action.payload.default_message;
                state.gift_score = action.payload.gift_score;
            }
        },
        resetFilter: () => initialState,
    },
});

export const { setFilter, resetFilter } = birthdaysSlice.actions;

export const birthdaysSelector = (state: Birthday) => state;

export default birthdaysSlice.reducer;