import * as React from 'react';
const SvgMovie = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} fill="none" {...props}>
    <rect width={50} height={50} fill="#3E97FF" rx={6} />
    <path
      fill="#EEF6FF"
      fillRule="evenodd"
      d="M10.125 15.969a2.656 2.656 0 0 1 2.656-2.656H37.22a2.656 2.656 0 0 1 2.656 2.656V34.03a2.656 2.656 0 0 1-2.656 2.657H12.78a2.656 2.656 0 0 1-2.656-2.657V15.97Zm2.125 0v2.125c0 .293.238.531.531.531h2.125a.531.531 0 0 0 .531-.531v-2.125a.531.531 0 0 0-.53-.531H12.78a.531.531 0 0 0-.531.53Zm22.844-.531a.531.531 0 0 0-.532.53v2.126c0 .293.238.531.532.531h2.125a.531.531 0 0 0 .531-.531v-2.125a.531.531 0 0 0-.531-.531h-2.125Zm2.656 5.843a.531.531 0 0 0-.531-.531h-2.125a.531.531 0 0 0-.532.531v2.125c0 .294.238.532.532.532h2.125a.531.531 0 0 0 .531-.532v-2.125Zm0 5.313a.531.531 0 0 0-.531-.532h-2.125a.531.531 0 0 0-.532.532v2.125c0 .293.238.531.532.531h2.125a.531.531 0 0 0 .531-.531v-2.125Zm0 5.312a.531.531 0 0 0-.531-.531h-2.125a.531.531 0 0 0-.532.531v2.125c0 .294.238.532.532.532h2.125a.531.531 0 0 0 .531-.532v-2.125Zm-22.844 2.657a.531.531 0 0 0 .531-.532v-2.125a.531.531 0 0 0-.53-.531H12.78a.531.531 0 0 0-.531.531v2.125c0 .294.238.532.531.532h2.125Zm-2.125-5.313h2.125a.531.531 0 0 0 .531-.531v-2.125a.531.531 0 0 0-.53-.532H12.78a.531.531 0 0 0-.531.532v2.125c0 .293.238.531.531.531Zm0-5.313h2.125a.531.531 0 0 0 .531-.53V21.28a.531.531 0 0 0-.53-.531H12.78a.531.531 0 0 0-.531.531v2.125c0 .294.238.532.531.532Zm5.844 0a1.063 1.063 0 0 0 0 2.125h12.75a1.063 1.063 0 0 0 0-2.125h-12.75Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgMovie;
