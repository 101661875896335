import api from '../../../../../api/api';
import { IFoodMenu, IGetFoodMenuRequest } from './_modals';

export const foodMenuApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFoodMenu: builder.query<IFoodMenu, IGetFoodMenuRequest | void>({
      query: ({ month,day }: IGetFoodMenuRequest) => {
        return `dailymenus/current?day=${day}&nodeid=`;
      },
    }),
    getMenuTitle:builder.query<any,any>({
      query:({nodeid}:any)=>{
        return `/dailymenus?nodeid=${nodeid}`;
      },
    }),
  }),
});

export const { useGetFoodMenuQuery,useGetMenuTitleQuery } = foodMenuApi;
