import { Field, useFormikContext } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import './_option.scss';
import { ICreateSurvey } from '../../CreateSurveyHelper';
import { useUserSurveySubQuestionMutation } from '../../../../../../api/services/surveys/surveyEndpoints';
import Question from '../Question/Question';
import SubQuestion from '../Question/SubQuestion';
import { okayMessage } from '../../../_global/components/Modal/ConfirmMessage';
import { KTCard } from '../../../../../../_metronic/helpers';

type Props = {
  questionType: number;
  option: any;
  questionIndex: number;
  optionIndex: number;
  onCompleteOption?: () => void;
  isActive?: boolean;
  mainQuestionSelectcoun?:number
  setIsSubQuestionSelectCount?:any
};

const Option: FC<Props> = ({ questionIndex, questionType, option, onCompleteOption, isActive ,mainQuestionSelectcoun,setIsSubQuestionSelectCount}) => {
  const { values, setFieldValue } = useFormikContext<ICreateSurvey>();
  const question = values.questions[questionIndex];
  const [getSubQuestion, { data: subQuestionData }] = useUserSurveySubQuestionMutation();
  const selectedCount = values.questions[questionIndex]?.question_checkbox_ids?.length || 0;
  const [stringValue, setStringValue] = useState<any>('');

  const isChecked = () => {
    if (values.questions) {
      if (questionType === 2) {
        return values?.questions[questionIndex]?.question_radio_id === option.choiceid.toString() ? true : false;
      } else if (questionType === 3) {
        return (
          values?.questions[questionIndex]?.question_checkbox_ids?.find((i) => i === option.choiceid.toString()) ===
          option.choiceid.toString()
        );
      }
    }
  };
  useEffect(() => {
    if (subQuestionData?.questions?.length > 0) {
      const selectcount = subQuestionData.questions.map((item: any) => item.questionid);
      setIsSubQuestionSelectCount(selectcount[0]);
    } else {
      setIsSubQuestionSelectCount(0); // Eğer data yoksa 0 gönder
    }
  }, [subQuestionData]);
  const handleClick = async () => {
    setFieldValue(`questions[${questionIndex}].passablesub`, 1);
    if (onCompleteOption) {
      onCompleteOption();
    }
    setFieldValue(`questions[${questionIndex}].selectoption`, option.choiceid.toString());
    if (questionType === 2) {
      setFieldValue(`questions[${questionIndex}].question_radio_id`, option.choiceid.toString());
      await getSubQuestion({ choiceId: option.choiceid, surveyId: values.questions[questionIndex].surveyid });
    } else if (questionType === 3) {
      const isRemove = question.question_checkbox_ids?.some((i) => i === option.choiceid.toString());
      if (isRemove) {
        const newOptions = question.question_checkbox_ids?.filter((i) => i !== option.choiceid.toString());
        setFieldValue(`questions[${questionIndex}].question_checkbox_ids`, newOptions);
      } else {
        if (questionType === 3 && question.selectcount > 1 && selectedCount >= question.selectcount) {
          okayMessage('Hata', `En fazla ${question.selectcount} seçenek seçebilirsiniz.`);
          return;
        }
        const newOptions = question.question_checkbox_ids || [];
        newOptions?.push(option.choiceid.toString());
        setFieldValue(`questions[${questionIndex}].question_checkbox_ids`, newOptions);
        await getSubQuestion({ choiceId: option.choiceid, surveyId: values.questions[questionIndex].surveyid });
      }
    }
  };
  const handleChange = (e: any, optionIndex: number, type: number) => {
    const newOptions = values.questions[questionIndex].question_option_val || [];
    newOptions[100] = e.target.value;
    setFieldValue(`questions[${questionIndex}].question_option_val`, newOptions);
    setStringValue(e.target.value);
  };

  function getQuestionOptionValueLength(values: any, questionIndex: number): string {
    return `${stringValue.length}/500 karakter`;
  }

  return (
    <div
      className={`border border-dashed mb-4 rounded-1 px-5 py-4 d-flex flex-column align-items-start cursor-pointer ${
        isChecked() ? 'border-info bg-light-info' : 'border-gray-300 bg-white'
      }`}
    >
      <div className="d-flex align-items-center" onClick={handleClick}>
        {option?.multimediapath !== undefined && option?.multimediapath != null && (
          <KTCard className="event-card image-card-style">
            <img
              width={60}
              height={60}
              alt="market_image"
              src={option?.multimediapath ?? ''}
              style={{ objectFit: 'contain', padding: '10px' }}
              className="rounded-3 image-card-style"
            />
          </KTCard>
        )}
        <span className="form-check form-check-custom form-check-solid me-4 ps-1">
          <Field
            className="form-check-input cursor-pointer"
            type={questionType === 2 ? 'radio' : 'checkbox'}
            value={
              questionType === 2
                ? values?.questions![questionIndex]?.question_radio_id
                : values?.questions![questionIndex]?.question_checkbox_ids?.find(
                    (i) => i === option.choiceid.toString(),
                  )
            }
            checked={isChecked()}
          />
        </span>
        <div className="fs-5 fw-bold">{option.choicetext}</div>
      </div>
      {/* Eğer isother === 1 ise bir textarea göster */}
      {option.isother === 1 && isChecked() && (
        <div style={{ width: '42vw', display: 'block' }}>
          <Field
            as="textarea"
            className="form-control form-control-lg resize-none"
            onChange={(e: any) => handleChange(e, question.questionid, 1)}
            rows={3}
            placeholder="Metin giriniz."
            value={stringValue}
            maxLength={500}
          />
          <div>{getQuestionOptionValueLength(values, questionIndex)}</div>
        </div>
      )}
      {isChecked() &&
        subQuestionData !== undefined &&
        subQuestionData != null &&
        subQuestionData.questions !== undefined &&
        subQuestionData.questions != null && (
          <div className="sub-questions mt-3">
            {subQuestionData?.questions.map(
              (subQuestion: { questionid: React.Key | null | undefined }, index: number) => (
                <div key={subQuestion.questionid} className="w-100">
                  <SubQuestion
                    question={subQuestion}
                    questionIndex={questionIndex}
                    id={values.questions[questionIndex].surveyid}
                    onNext={() => {}}
                    onComplete={() => {}}
                    isLastQuestion={false}
                    isSubQuestion={true}
                    mainQuestionSelectcoun={mainQuestionSelectcoun}
                  />
                </div>
              ),
            )}
          </div>
        )}
    </div>
  );
};

export default Option;
