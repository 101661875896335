/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Languages } from './Languages';
import { Link } from 'react-router-dom';
import { KTIcon, toAbsoluteUrl } from '../../../helpers';
import { useAppDispatch, useAppSelector } from '../../../../api/store/hooks';
import { authSelector, logout } from '../../../../api/features/auth/authSlice';
import { clearActiveModules } from '../../../../api/features/module/moduleSlice';
import confirmMessage from '../../../../app/modules/apps/_global/components/Modal/ConfirmMessage';
import { Menu } from '@mui/material';
import MingMonyIcon from '../../../assets/icontsx/mingMony';
import axios from 'axios';
import { useGetMoneyQuery } from '../../../../api/services/home/homeEndpoints';

type Props = {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: any;
};

const HeaderUserMenu: FC<Props> = ({ anchorEl, open, handleClose }) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { currentUser } = useAppSelector((state) => authSelector(state.auth));
  const {data:money,isLoading} =useGetMoneyQuery()

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 0px 50px rgba(82,63,105,0.15))',
          borderRadius: 1.5,
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <div className="menu-item">
        <Link to={`/profile/${currentUser?.usersecret.userid}`} className="text-dark menu-link">
          <div className="menu-content d-flex align-items-center px-3">
            <div className="symbol symbol-50px me-5">
              <img alt="Logo" src={currentUser?.pictureurl || toAbsoluteUrl(`/media/avatars/blank.png`)} />
            </div>
            <div className="d-flex flex-column">
              <div className="fw-bold text-gray-900 d-flex align-items-center fs-5">
                {currentUser?.userdesc}
              </div>
              <div className="fw-bold text-muted fs-7">{currentUser?.phone}</div>
            </div>
          </div>
        </Link>
      </div>

      <div className="separator my-2"></div>
      {/*
      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>My Projects</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
        </a>
      </div>

      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          My Statements
        </a>
      </div>
  */}
      {/* <div className="separator my-2"></div> */}

      <div className="menu-item px-5">
        <Link to="/apps/market" className="d-flex menu-link text-hover-info p-2 fw-bold fs-5">
          {/* <img
            alt="coin"
            width={23}
            height={23}
            className="rounded-2 me-4"
            src={toAbsoluteUrl('/media/svg/coin/coin.svg')}
          /> */}
          <MingMonyIcon />
          {intl.formatMessage({ id: 'MENU.MY.POINTS' })}
          <div className="btn btn-outline btn-outline-dashed btn-outline-secondary btn-active-light-info p-1 m-auto me-0">
            <span className="text-gray-700 fw-bold fs-5">{money?.mingmoney||0}</span>
          </div>
        </Link>
      </div>

      {/* <Languages /> */}

      <div className="menu-item px-5 d-flex align-items-center fs-5">
        <Link to="/settings" className="menu-link text-hover-info p-2">
          <KTIcon className="fs-1 me-4" iconName="setting-2" iconOverride="text-gray-700" />
          {intl.formatMessage({ id: 'MENU.SETTINGS' })}
        </Link>
      </div>

      <div className="menu-item px-5">
        <a
          className="menu-link text-hover-info fs-5 p-2"
          onClick={() => {
            confirmMessage(
              intl.formatMessage({ id: 'AUTH.LOGOUT.POPUP.TITLE' }),
              intl.formatMessage({ id: 'AUTH.LOGOUT.POPUP.DESCRIPTION' }),
              () => {
                dispatch(clearActiveModules());
                dispatch(logout());
              },
              undefined,
              intl.formatMessage({ id: 'APPROVE_TEXT' }),
              intl.formatMessage({ id: 'CANCEL_TEXT' }),
            );
          }}
        >
          <KTIcon className="fs-1 me-4" iconName="exit-right" iconOverride="text-gray-700" />
          {intl.formatMessage({ id: 'AUTH.LOGOUT.BUTTON' })}
        </a>
      </div>
    </Menu>
  );
};

export { HeaderUserMenu };
