import React, { useCallback, useEffect, useRef, useState } from 'react';
import GenericSelect from '../_global/components/Select/GenericSelect';
import InputWrapper from '../_global/components/Input/InputWrapper';
import { ImageUpload } from '../_global/components/Input/ImageUpload';
import { Stack } from 'react-bootstrap';
import { getBranches } from '../_global/queries/requests';
import SuggestionList from './SuggestionList';
import axios from 'axios';
import { useAppSelector } from '../../../../api/store/hooks';
import { authSelector } from '../../../../api/features/auth/authSlice';
import { useCreateSuggestionMutation, useGetSuggestionTypeQuery } from '../../../../api/services/home/homeEndpoints';
import { useGetbranchesListMutation } from '../../../../api/services/near-miss/nearMissEndpoints';
import { useUploadServiceMutation } from '../feedback_module/core/_requests';

export default function CreateSuggestionModal({ onSuccess }: any) {
  const fileRef = useRef(null);

  const [imageValues, setImageValues] = useState<any[]>([]);

  const [suggestionType, setSuggestionType] = useState<any>(null);
  const [suggestionLocation, setSuggestionLocation] = useState<any>(null);
  const [suggestionName, setSuggestionName] = useState('');
  const [suggestionDescription, setSuggestionDescription] = useState('');
  const [suggestionSolution, setSuggestionSolution] = useState('');
  const [branchList, setBranchList] = useState<any>([]);
  const [uploadImage] = useUploadServiceMutation();
  const [isLoading, setIsLoading] = useState(false);

  const user = useAppSelector((state) => authSelector(state.auth));
  const { data: typeList, isLoading: SuggestionLoading, refetch: suggestionefetch } = useGetSuggestionTypeQuery();
  const [getBranches] = useGetbranchesListMutation();
  const [create] = useCreateSuggestionMutation();

  const createSuggestion = async () => {
    setIsLoading(true);
    const imageArray: any[] = [];
    if (imageValues && imageValues.length > 0) {
      imageValues.forEach((image: any) => {
        imageArray.push(image);
      });
    }
    await create({
      title: suggestionName,
      description: suggestionDescription,
      solutionproposal: suggestionSolution,
      suggestiontypeid: suggestionType,
      nodeid: suggestionLocation,
      images: imageArray,
    })
      .unwrap()
      .then((_: any) => {
        onSuccess();
      })
      .catch((_: any) => {
        setIsLoading(false);
      });
    setIsLoading(false);
  };
  const handleImageUpload = useCallback(
    async (images: File[]) => {
      if (images.length > 0) {
        const updatedImages = Array.isArray(imageValues) ? [...imageValues] : [];
        for (const image of images) {
          const alreadyExists = updatedImages.some(
            (existingImage) => existingImage.size === image.size && existingImage.name === image.name,
          );

          if (!alreadyExists) {
            try {
              const result = await uploadImage(image).unwrap();
              if (result.filepath) {
                // const imageObject = {
                //   cover: 0,
                //   mediauri: result.filepath,
                //   multimediaid: 0,
                //   size: image.size,
                //   name: image.name,
                // };
                const imageObject =  result.filepath

                updatedImages.push(imageObject);
              }
            } catch (error) {
              console.error('Error uploading image:', error);
            }
          }
        }

        setImageValues(updatedImages);
      } else {
        setImageValues([]);
      }
    },
    [imageValues, uploadImage],
  );

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await getBranches({}).unwrap();
        if (response && response.nodelist) {
          const formattedBranches = response.nodelist.map((branch: any) => ({
            value: branch.nodeid,
            label: branch.nodename,
          }));
          setBranchList(formattedBranches);
        }
      } catch (error) {
        console.error('Error fetching branches:', error);
      }
    };

    fetchBranches();
    suggestionefetch();
  }, [getBranches, suggestionefetch, typeList]);
  const suggestionTypeListMap = typeList?.suggestiontypes?.map((item: any) => ({
    label: item.suggestiontypedesc,
    value: item.suggestiontypeid,
  }));

  return (
    <div>
      <div className="" style={{ display: 'grid', gridTemplateColumns: '0.5fr 0.5fr' }}>
        <div className="px-4">
          <GenericSelect
            value={suggestionType}
            title={'Öneri Türü'}
            closeMenuOnSelect
            required
            options={suggestionTypeListMap}
            changeEvent={(data: any) => {
              setSuggestionType(data.value);
            }}
          />
        </div>
        <div className="px-4">
          <GenericSelect
            closeMenuOnSelect
            value={suggestionLocation}
            title={'Öneri Lokasyonu'}
            options={branchList}
            changeEvent={(data: any) => {
              setSuggestionLocation(data.value);
            }}
            required
          />
        </div>
      </div>
      <div className="fw-bold fs-1 p-4 mt-2">Açıklama ve Görsel</div>
      <InputWrapper required={true} title={'Öneri Başlığı'} containerClassName="px-4">
        <input
          className="form-control"
          placeholder={'Metin Giriniz'}
          onChange={(e) => setSuggestionName(e.target.value)}
        />
      </InputWrapper>
      <InputWrapper required={true} title={'Açıklama'} containerClassName="px-4 mt-5">
        <textarea
          rows={3}
          className="form-control"
          placeholder={'Metin Giriniz'}
          onChange={(e) => setSuggestionDescription(e.target.value)}
        />
      </InputWrapper>
      <InputWrapper required={true} title={'Çözüm Önerisi'} containerClassName="px-4 mt-5">
        <textarea
          rows={3}
          className="form-control"
          placeholder={'Metin Giriniz'}
          onChange={(e) => setSuggestionSolution(e.target.value)}
        />
      </InputWrapper>
      <div className="row col-md-12 align-self-start ms-1 mt-5">
        <InputWrapper title={'Görsel Ekle'}>
          <ImageUpload
            width="200px"
            ref={fileRef}
            isMulti={true}
            maxImageCount={10}
            onImageUpload={handleImageUpload}
          />
        </InputWrapper>
      </div>
      <Stack>
        <button
          type="submit"
          disabled={
            suggestionDescription === '' ||
            suggestionName === '' ||
            suggestionSolution === '' ||
            suggestionLocation === null ||
            suggestionType === null
          }
          className={`btn ${'btn-info'}`}
          style={{ alignSelf: 'center' }}
          onClick={createSuggestion}
        >
          {isLoading ? <span className="spinner-border spinner-border-sm align-middle"></span> : 'Gönder'}
        </button>
      </Stack>
    </div>
  );
}
