// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simple-card {
  width: "100%";
  padding: 30px;
  padding-bottom: 20px;
  background-color: #fff;
  border-radius: 12px;
}

.subtitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 7px;
}

.description {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
}

.title {
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.18px;
  color: #3f4254;
}

.see-more-simple {
  padding: 12px 16x;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/home/components/SimpleCard/_simpleCard.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,oBAAA;EACA,sBAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EACA,cAAA;AACF;;AAEA;EACE,iBAAA;AACF","sourcesContent":[".simple-card {\n  width: '100%';\n  padding: 30px;\n  padding-bottom: 20px;\n  background-color: #fff;\n  border-radius: 12px;\n}\n\n.subtitle {\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 16px;\n  margin-bottom: 7px;\n}\n\n.description {\n  font-size: 14px;\n  font-weight: 600;\n  line-height: 14px;\n}\n\n.title {\n  font-size: 18px;\n  font-weight: 600;\n  line-height: 18px;\n  letter-spacing: -0.18px;\n  color: #3f4254;\n}\n\n.see-more-simple {\n  padding: 12px 16x;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
