import React from 'react';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import SvgThanksLine from './SvgThanksLine';
import SvgAppreciationLine from './SvgAppreciationLine';
import SvgBadgeIcon from './SvgBadgeIcon';
import '../style/CheersCard.scss';
import { timeAgo } from '../utils/utils';
import { useIntl } from 'react-intl';
import SvgAppreciationIcon from './SvgAppreciationIcon';
import SvgThanksIcon from './SvgThanksIcon';
import { format, parse } from 'date-fns';

type Props = {
  senderFirstName: string;
  receiverFirstName: string;
  senderPhotoUrl: string;
  receiverPhotoUrl: string;
  senderFullName: string;
  receiverFullName: string;
  cheersType: number;
  badgeTitle: string;
  description: string;
  created: string;
  cheersBadgeIconUrl: string;
};

function mingFlowDurationRounded(stringDateTime: string | null): string {
  if (!stringDateTime) {
    return '';
  }

  let duration: Date;
  if (stringDateTime.length === 16) {
    duration = parse(stringDateTime, 'dd/MM/yyyy HH:mm', new Date());
  } else if (stringDateTime.length === 19) {
    duration = parse(stringDateTime, 'dd/MM/yyyy HH:mm:ss', new Date());
  } else {
    return '';
  }

  const now = new Date();
  const difference = now.getTime() - duration.getTime(); // Get difference in milliseconds

  const differenceInDays = Math.floor(difference / (1000 * 60 * 60 * 24));
  const differenceInHours = Math.floor((difference / (1000 * 60 * 60)) % 24);
  const differenceInMinutes = Math.floor((difference / (1000 * 60)) % 60);

  if (differenceInDays > 0) {
    return `${differenceInDays}g`;
  } else if (differenceInHours > 0) {
    return `${differenceInHours}s`;
  } else if (differenceInMinutes > 0) {
    return `${differenceInMinutes}d`;
  } else {
    return '1d';
  }
}

export const CheersCard: React.FC<Props> = ({
  senderFirstName,
  receiverFirstName,
  senderPhotoUrl,
  receiverPhotoUrl,
  senderFullName,
  receiverFullName,
  cheersType,
  badgeTitle,
  description,
  created,
  cheersBadgeIconUrl,
}) => {
  const intl = useIntl();

  return (
    <div className="cheers">
      <div className="cheers-content">
        <div className="title">
          <p>
            {cheersType === 1 ? <SvgAppreciationIcon /> : <SvgThanksIcon />}
            &nbsp; {senderFirstName}, {receiverFirstName}
            {cheersType === 1
              ? intl.formatMessage({ id: 'CHEERS_APPRECIATE' })
              : intl.formatMessage({ id: 'CHEERS_THANKS' })}
            {intl.formatMessage({ id: 'CHEERS_EXCELLENT_WORK' })} {receiverFirstName}!
          </p>
        </div>
        <div className="badge">
          <div className="person">
            <img
              src={senderPhotoUrl || toAbsoluteUrl('/media/avatars/Default Avatar.svg')}
              alt="useravatar"
              className="avatar"
            />
            <div className="person-title">{senderFullName}</div>
          </div>
          <div className="bar">
            {cheersType === 1 ? (
              <SvgAppreciationLine width={'100%'} className="bar" />
            ) : cheersType === 2 ? (
              <SvgThanksLine width={'100%'} className="bar" />
            ) : null}
          </div>
          <div className="badge-icon">
            <img src={cheersBadgeIconUrl} alt="Badge Icon" className="badge-icon-img" />
            <div>{badgeTitle}</div>
          </div>
          <div className="person2">
            <img
              src={receiverPhotoUrl || toAbsoluteUrl('/media/avatars/Default Avatar.svg')}
              alt="useravatar"
              className="avatar"
            />
            <div className="person-title">{receiverFullName}</div>
          </div>
        </div>
        <div className="description">
          <p>{description}</p>
        </div>
        <div className="time-ago">
          <p>{mingFlowDurationRounded(created)}</p>
        </div>
      </div>
    </div>
  );
};
