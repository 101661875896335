import { Grid } from '@mui/material';
import { ID } from '../../../../_metronic/helpers';
import UserCard from '../components/UserCard/UserCard';
import { useAppSelector } from '../../../../api/store/hooks';
import { SimpleCard } from '../components/SimpleCard/SimpleCard';
import MobileAppCard from '../components/MobileAppCard/MobileAppCard';
import { authSelector, setAuth } from '../../../../api/features/auth/authSlice';
import SimpleCardSkeleton from '../../../modules/apps/_global/components/Skeleton/SimpleCardSkeleton';
import StickyBox from 'react-sticky-box';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const LeftSideBar = ({ eventResponse, announcements }: any) => {
  const { currentUser } = useAppSelector((state) => authSelector(state.auth));
 // BURAYA BAK const { userid } = currentUser || {};
  
  const eventsData: Array<{
    id: ID;
    subTitle: string;
    description: string;
  }> = eventResponse.events?.slice(0, 2).map((i: any) => ({
    id: i.eventid,
    subTitle: i.title,
    description: i.fullname,
  }));

  const announcementsData: Array<{
    id: ID;
    subTitle: string;
    description: string;
  }> = announcements?.feeds?.slice(0, 2).map((i: any) => ({
    id: i.feedid,
    subTitle: i.title,
    description: i.feedownerdesc,
  }));

  return (
    <Grid item xs={4} lg={3}>
      <StickyBox offsetTop={20} offsetBottom={20}>
        <div className="d-flex flex-column gap-6">
          {currentUser ? <UserCard /> : <SimpleCardSkeleton />}
          {announcementsData?.length > 0 && (
            <SimpleCard title="HOME.ANNOUNCEMENTS" rows={announcementsData} seeAllRoute="/apps/announcements" />
          )}

          {eventsData?.length > 0 && <SimpleCard title="HOME.EVENTS" rows={eventsData} seeAllRoute="/apps/events" />}
          {/* BURAYA BAK {user_mobile_downloaded === 0 && <MobileAppCard />} */}
        </div>
      </StickyBox>
    </Grid>
  );
};

export default LeftSideBar;
