import React, { FC, useState } from 'react';
import { KTCard } from '../../../../../_metronic/helpers';
import { dateFormatter } from '../../../../modules/_helpers/Helpers';
import ExpandableHtml from '../../../../modules/apps/_global/components/ExpandableHtml/ExpandableHtml';
import { useIntl } from 'react-intl';
import { Modal } from '../../../../modules/apps/_global/components/Modal/Modal';
import { ModalHeader } from '../../../../modules/apps/_global/components/Modal/ModalHeader';
import SurveyModal from '../../../../modules/apps/surveys_module/components/SurveyModal/SurveyModal';
import confirmMessage from '../../../../modules/apps/_global/components/Modal/ConfirmMessage';
import { parse, differenceInSeconds } from 'date-fns';
import SecurityLockIcon from './security_lock.svg';

type Props = {
  data: any;
  icon?: any;
  header: any;
  refetch: any;
};

function mingFlowDurationRounded(stringDateTime: string | null): string {
  if (!stringDateTime) {
    return '';
  }

  let duration: Date;
  if (stringDateTime.length === 16) {
    duration = parse(stringDateTime, 'dd/MM/yyyy HH:mm', new Date());
  } else if (stringDateTime.length === 19) {
    duration = parse(stringDateTime, 'dd/MM/yyyy HH:mm:ss', new Date());
  } else {
    return '';
  }

  const now = new Date();
  const difference = now.getTime() - duration.getTime(); // Get difference in milliseconds

  const differenceInDays = Math.floor(difference / (1000 * 60 * 60 * 24));
  const differenceInHours = Math.floor((difference / (1000 * 60 * 60)) % 24);
  const differenceInMinutes = Math.floor((difference / (1000 * 60)) % 60);

  if (differenceInDays > 0) {
    return `${differenceInDays}g`;
  } else if (differenceInHours > 0) {
    return `${differenceInHours}s`;
  } else if (differenceInMinutes > 0) {
    return `${differenceInMinutes}d`;
  } else {
    return '1d';
  }
}

const SurveyCard: FC<Props> = ({ data, icon, header, refetch }) => {
  const intl = useIntl();
  const {
    description,
    startdate,
    title,
    participation,
    contentid,
    time_remaining,
    currentuserinteractions,
    contentenddate,
    expectedduration,
    nodename,
    created,
    anonymous,
  } = data || {};
  const [isOpen, setOpen] = useState(false);

  const showSurveyWarning = (open: boolean) => {
    confirmMessage(
      intl.formatMessage({ id: 'Anketten Ayrıl' }),
      intl.formatMessage({
        id: 'Anketten ayrılırsanız girdiğiniz cevaplar kaybolacaktır. Ayrılmak istediğinize emin misiniz?',
      }),
      () => {
        return setOpen(open);
      },
      undefined,
      'Onayla',
      'Vazgeç',
    );
  };

  const getButtonStatus = () => {
    const now = new Date();
    const [datePart, timePart] = contentenddate != null ? contentenddate.split(' ') : "";
    const [day, month, year] = contentenddate != null ? datePart.split('/') : "";

    const formattedEndDate = new Date(`${month}/${day}/${year} ${timePart}`);
    if (currentuserinteractions === 1) {
      return intl.formatMessage({
        id: 'SURVEYS.TOOK_THE_SURVEY',
      });
    } else if (contentenddate != null && formattedEndDate < now) {
      return intl.formatMessage({
        id: 'SURVEYS.PASS_SURVEY_DATA',
      });
    } else {
      return intl.formatMessage({
        id: 'SURVEYS.TAKE_THE_SURVEY',
      });
    }
  };

  const getButtonStatusToColor = () => {
    const now = new Date();
    const [datePart, timePart] = contentenddate != null ? contentenddate.split(' ') : "";
    const [day, month, year] = contentenddate != null ? datePart.split('/') : "";

    const formattedEndDate = new Date(`${month}/${day}/${year} ${timePart}`);
    if (currentuserinteractions === 1) {
      return false;
    }
    else if (contentenddate != null && formattedEndDate < now) {
      return false;
    }else {
      return true;
    }
  };

  return (
    <KTCard className="p-8 event-card mb-8">
      <div className="d-flex gap-4 align-items-center mb-8">
        {icon}
        <div>
          <div className="fw-bold fs-3">{header}</div>
          <span className="fw-bold text-gray-500">{mingFlowDurationRounded(created)}</span>
        </div>
      </div>
      <div className="border rounded-3 p-8 mb-8">
        <h3>{title}</h3>
        <ExpandableHtml className="text-gray-500 fw-bold fs-6" description={description} />
      </div>
      {anonymous === 1 ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={SecurityLockIcon} alt="timer" />
          <div style={{ marginLeft: '8px', marginTop:'4px' }} className="text-gray-600 mb-2">
            Anonim anket
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <button
        disabled={!getButtonStatusToColor()}
        onClick={() => {
          setOpen(true);
        }}
        className={`btn  ${!getButtonStatusToColor() ? 'btn-secondary' : 'btn-info'} py-3 px-4`}
      >
        {getButtonStatus()}
      </button>

      {isOpen === true && (
        <Modal
          isScrollable={false}
          headerComponent={<ModalHeader title={title} toggle={showSurveyWarning} />}
          formComponent={
            <SurveyModal
              title={title}
              remaining_time={contentenddate}
              description={description}
              id={contentid}
              toggle={setOpen}
              refetch={() => refetch()}
              expectedduration={expectedduration}
              department_name={nodename}
              anonymous={anonymous}
            />
          }
          isDisposableOnOutsideClick={false}
          id="survey"
          toggle={() => setOpen(!isOpen)}
        />
      )}
    </KTCard>
  );
};

export default SurveyCard;
