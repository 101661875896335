import api from '../../api';

//? We can move this type definitions to "_requests" file

export interface IParticipant {
  userid: number;
  fullname: string;
  pictureurl: string;
}

export interface Idata {
  event: IEventDetail;
  participants: IParticipant[]; // participants bir dizi olacak şekilde güncellendi
}

export interface IEventDetail {
  address: string;
  cityid: number;
  cityname: string;
  created?: string;
  description: string;
  enddate: string;
  eventid: number;
  eventtypedesc: string;
  eventtypeid: number;
  feedid: number;
  feedstatusid: number;
  fullname: string;
  images: IImage[];  // images boş bir dizi olabilir
  lastsubscriptiondate: string;
  mediauri: string | null;
  online: number;
  publishdepartmentid: number;
  publishdepartmentname: string;
  remainingquota: number;
  startdate: string;
  surveyid: number | null;
  targetdesc: string;
  targetgroupid: number;
  title: string;
  userid: number;
  userlimit: number;
  lcvcount:number
}

interface IImage {
  optimized_image: string;
}

interface IJoinorCancel {
  success: boolean;
}


export const eventApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getEventDetail: builder.mutation<Idata, any>({
      query: ({ event_id }) => ({
        url: `/events/${event_id}`,
        method: 'GET',
      }),
    }),
    joinOrCancelEvent: builder.mutation<IJoinorCancel, any>({
      query: (credentials) => ({
        url: `/event/participant`,
        method: 'POST',
        body: credentials,
      }),
    }),
    joinEvent: builder.mutation<IJoinorCancel, { eventId: string; credentials: any }>({
      query: ({ eventId, credentials }) => ({
        url: `/events/${eventId}/participants`, 
        method: 'POST',
        body: credentials, 
      }),
    }),
    cancelEvent: builder.mutation<IJoinorCancel, { eventId: string; credentials: any }>({
      query: ({ eventId, credentials }) => ({
        url: `/events/${eventId}/participants`, 
        method: 'DELETE',
        body: credentials, 
      }),
    }),
    getEventList: builder.query<any, string>({
      query: (queryParams) => ({
        url: `/events?${queryParams}&sortby=startdate&sortorder=ASC`,
      }),
    }),
    getDepartmentEventList: builder.query<any, any>({
      query: ({ department_id, order_by }) => ({
        url: `/event/search?time_filter=all&owner_id=${department_id}&order_by=${order_by}`,
      }),
    }),
  }),
});

export const {
  useGetDepartmentEventListQuery,
  useGetEventListQuery,
  useGetEventDetailMutation,
  useJoinOrCancelEventMutation,
  useJoinEventMutation,
  useCancelEventMutation
} = eventApi;
