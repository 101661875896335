import api from '../../api';

type IResponse = {
  data: any;
  success: boolean;
};

export const nearMissApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getNearMissList: builder.mutation<IResponse, any>({
      query: (credentials) => ({
        url: `/nearmiss?${credentials}`,
        method: 'GET',
      }),
    }),
    getNearMissListDetail: builder.mutation<any, any>({
      query: (nearMissId) => ({
        url: `/nearmiss/${nearMissId}`,
        method: 'GET',
      }),
    }),
    createNearMiss: builder.mutation<IResponse, any>({
      query: ({ incident_date, incident_type, severity, branch_id, risk_status, description, precaution, images }) => {
        const imageArray: any[] = [];
        if (images && images.length > 0) {
          images.forEach((image: any) => {
            imageArray.push(image);
          });
        }
        const requestBody = {
          incidentdate: incident_date,
          incidenttypeid: incident_type,
          riskseverityid: severity,
          branchid: branch_id,
          risktypeid: risk_status,
          description: description,
          precaution: precaution,
          images: imageArray.length > 0 ? imageArray : [],
          
        };
        return {
          url: `/nearmiss`,
          method: 'POST',
          body: requestBody, 
        };
      },
    }),
    getincidenttypesList: builder.mutation<any, any>({
      query: () => ({
        url: `/nearmiss/incidenttypes`,
        method: 'GET',
      }),
    }),
    getrisktypesList: builder.mutation<any, any>({
      query: () => ({
        url: `/nearmiss/risktypes`,
        method: 'GET',
      }),
    }),
    getriskseveritiesList: builder.mutation<any, any>({
      query: () => ({
        url: `/nearmiss/riskseverities`,
        method: 'GET',
      }),
    }),
    getbranchesList: builder.mutation<any, any>({
      query: () => ({
        url: `/branches`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetNearMissListMutation,useGetNearMissListDetailMutation, useCreateNearMissMutation, useGetincidenttypesListMutation, useGetrisktypesListMutation, useGetriskseveritiesListMutation, useGetbranchesListMutation } = nearMissApi;

export default nearMissApi;
