import { Fragment } from 'react';
import { useIntl } from 'react-intl';
import homeApi from '../../../api/services/home/homeEndpoints';
import { useAppDispatch, useAppSelector } from '../../../api/store/hooks';
import { Modal } from '../../modules/apps/_global/components/Modal/Modal';
import { ModalHeader } from '../../modules/apps/_global/components/Modal/ModalHeader';
import AddSecondHand from '../../modules/apps/secondhands_module/components/AddSecondHand';
import CreateFeedbackForm from '../../modules/apps/feedback_module/components/CreateFeedbackForm';
import CreatePostModal from '../../modules/apps/feed_module/components/CreatePostModal/CreatePostModal';
import {
  easyLinkSelector,
  toggleAddFeedbackModal,
  toggleAddPostModal,
  toggleAddSecondhandModal,
  toggleAddNearMissModal,
} from '../../../api/features/easy_links/easyLinksSlice';
import { authSelector } from '../../../api/features/auth/authSlice';
import CreateNearMissModal from '../../modules/apps/near_miss_module/components/CreateNearMissModal';

export default function EasyLinkModals() {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const easyLinks = useAppSelector((state) => easyLinkSelector(state.easyLinks));
  const auth = useAppSelector((state) => authSelector(state.auth));

  return (
    <Fragment>
      {easyLinks.isAddPostModalOpen && (
        <Modal
          className="w-75"
          isScrollable={false}
          id="feed_create_post"
          isDisposableOnOutsideClick={false}
          toggle={() => dispatch(toggleAddPostModal())}
          headerComponent={
            <ModalHeader
              toggle={() => dispatch(toggleAddPostModal())}
              title={intl.formatMessage({ id: 'FEED_CREATE_FEED_TEXT' })}
            />
          }
          formComponent={
            <CreatePostModal
              refetch={async () => {
                //BURAYA BAK
                await dispatch(homeApi.endpoints.getApproveRejectList.initiate()).then((res) => {});
                await dispatch(homeApi.endpoints.getCombinedList.initiate({ page: 1 })).then((res) => {});
              }}
              toggle={() => dispatch(toggleAddPostModal())}
            />
          }
        />
      )}
      {easyLinks.isAddSecondhandModalOpen && (
        <Modal
          className="z-n1"
          isDisposableOnOutsideClick={false}
          id="secondhand"
          isScrollable={false}
          bodyClassName="mx-xl-0"
          toggle={() => dispatch(toggleAddSecondhandModal())}
          headerComponent={
            <ModalHeader
              title={intl.formatMessage({ id: 'CREATE_SECONDHAND_SECONDHAND' })}
              toggle={() => dispatch(toggleAddSecondhandModal())}
            />
          }
          formComponent={
            <AddSecondHand
              successCreateItem={() => {
                dispatch(toggleAddSecondhandModal());
              }}
            />
          }
        />
      )}

      {easyLinks.isAddFeedbackModalOpen && (
        <Modal
          id="feedback-modal"
          isScrollable={false}
          bodyClassName="mx-xl-0"
          toggle={() => dispatch(toggleAddFeedbackModal())}
          isDisposableOnOutsideClick={false}
          headerComponent={
            <ModalHeader
              title={intl.formatMessage({ id: 'FEEDBACK_PAGE_CREATE_FEEDBACK' })}
              toggle={() => dispatch(toggleAddFeedbackModal())}
            />
          }
          formComponent={<CreateFeedbackForm toggle={() => dispatch(toggleAddFeedbackModal())} />}
        />
      )}
      {easyLinks.isAddNearMissModalOpen && (
        <Modal
          id="nearmiss-modal"
          isScrollable={false}
          bodyClassName="mx-xl-0"
          toggle={() => dispatch(toggleAddNearMissModal())}
          isDisposableOnOutsideClick={false}
          headerComponent={
            <ModalHeader
              title={"Ramak Kala Bildirimi Oluştur"}
              toggle={() => dispatch(toggleAddNearMissModal())}
            />
          }
          formComponent={<CreateNearMissModal toggle={() => dispatch(toggleAddNearMissModal())} />}
        />
      )}
    </Fragment>
  );
}
