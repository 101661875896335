import { FC, Fragment, useRef, useState } from 'react';
import { Autoplay, Keyboard, Navigation, Pagination, SwiperOptions } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperButtonPrev from './SwiperButtonPrev';
import SwiperButtonNext from './SwiperButtonNext';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import './_imageSlider.scss';
import ImagesSliderModal from '../ImageSliderModal/ImageSliderModal';
import { KTCard } from '../../../../../../../_metronic/helpers';

type Props = {
  images: any;
  isPreviewEnabled?: boolean;
  height?:string
};

const ImageSlider: FC<Props> = ({height, images, isPreviewEnabled }) => {
  const swiperRef = useRef<any>();
  const [imageIndex, setImageIndex] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const [arrowVisibility, setArrowVisibility] = useState({ left: false, right: true });

  const sliderSettings: SwiperOptions = {
    modules: [Navigation, Autoplay, Keyboard, Pagination],
    spaceBetween: 10,
    slidesPerView: 'auto',
    keyboard: {
      onlyInViewport: true,
      enabled: true,
    },
    pagination: {
      clickable: true,
    },
  };

  return (
    <Fragment>
      <KTCard>
        {images?.length > 1 && <SwiperButtonPrev arrowVisibility={arrowVisibility.left} swiperRef={swiperRef} />}
        <Swiper
          {...sliderSettings}
          style={{ width: 'fit-content' }}
          className=" position-relative mb-2 w-100"
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
          onPaginationUpdate={(swiper) => {
            if (swiper.isBeginning) {
              setArrowVisibility({ left: false, right: true });
            } else if (swiper.isEnd) {
              setArrowVisibility({ left: true, right: false });
            } else {
              setArrowVisibility({ left: true, right: true });
            }
          }}
        >
          {images?.map((i: any, index: number) => (
            <SwiperSlide
              key={i.multimediaid ?? index}
              className="rounded-4 cursor-pointer"
              style={{ maxHeight:height?? '450px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              onClick={() => {
                setImageIndex(index);
                setOpen(true);
              }}
            >
              <img
                className="rounded-4"
                style={{
                  maxHeight: '450px',
                  maxWidth: '100%',
                  objectFit: 'contain',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                src={i?.mediauri ?? i}
                alt={i?.mediauri?.split('/')?.pop() ?? i?.split('/')?.pop()}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        {images?.length > 1 && <SwiperButtonNext arrowVisibility={arrowVisibility.right} swiperRef={swiperRef} />}
      </KTCard>
      {isOpen && isPreviewEnabled && (
        <ImagesSliderModal id="hab_info_preview" images={images} initialSlide={imageIndex} setIsOpen={setOpen} />
      )}
    </Fragment>
  );
};

export default ImageSlider;
