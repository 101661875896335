// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-check-correct:checked {
  background-color: #47be7d;
}

.form-check-wrong:checked {
  background-color: #d9214e;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/apps/games_module/components/ResultDetail/components/ResultOption/_option.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;;AAEA;EACE,yBAAA;AACF","sourcesContent":[".form-check-correct:checked {\n  background-color: #47be7d;\n}\n\n.form-check-wrong:checked {\n  background-color: #d9214e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
