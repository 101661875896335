import { useIntl } from 'react-intl';
import { KTIcon } from '../../../../helpers';

type Props = {
  title: string;
  iconName: string;
  onClick: () => void;
};
export default function EasyLinkItem({ iconName, title, onClick }: Props) {
  const intl = useIntl();
  return (
    <div className="menu-item me-lg-1" onClick={onClick}>
      <div className="btn btn-active-light-info d-flex justify-content-center align-items-center ">
        <KTIcon iconName={iconName} className="fs-2" iconOverride="text-gray-600" />
        <span className="fw-medium text-gray-600 text-hover-info">{intl.formatMessage({ id: title })}</span>
      </div>
    </div>
  );
}
