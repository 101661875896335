/* eslint-disable array-callback-return */
import api from '../../../../../api/api';
import homeApi from '../../../../../api/services/home/homeEndpoints';

interface IAddComment {
  post_id: number;
  comment: string;
  comment_id?: string;
  parent_id:number;
  post_image:string;
}

export interface ILikePost {
  content_id: number;
  type: string;
  promoted_status?: string;
}

export interface ILikeComment {
  comment_id: number;
}

export interface IAddPost {
  images: [];
  description:string
  comment: number;
}

export const feedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFeedPosts: builder.query<any, any>({
      query: ({ branch_id, page }) => {
        return {
          url: `/feed/list?type=post&branch_id=${branch_id}&page=${page}`,
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => endpointName,

      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg?.page === 1) {
          currentCache.items = newItems?.items;
        } else {
          currentCache?.items?.push(...newItems?.items);
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: (result, error, arg) =>
        result ? [...result?.items?.map(({ item }: any) => ({ type: 'Post', id: item?.id })), 'Post'] : ['Post'],
    }),
    addComment: builder.mutation<any, IAddComment>({
      query: (body) => {
        var requestBody = {
          "parentid": body.parent_id,
          "commenttext": body.comment,
          "mediauri": body.post_image
        };
        return {
          url: `/feed/${body.post_id}/comments`,
          method: 'POST',
          body:requestBody,
        };
      },
    }),
    likePost: builder.mutation<any, ILikePost>({
      query: (body) => {
        var requestBody = {
          "binaryvalue": 1,
        };
        return {
          url: `/feed/${body.content_id}/interactions`,
          method: 'POST',
          body:requestBody,
        };
      },
    }),
    deleteLikePost: builder.mutation<any, ILikePost>({
      query: (body) => {
        var requestBody = {
          "binaryvalue": 1,
        };
        return {
          url: `/feed/${body.content_id}/interactions`,
          method: 'DELETE',
          body:requestBody,
        };
      },
    }),
    getPostLikedList: builder.query<any, any>({
      query: (postId) => {
        return {
          url: `/feed/${postId}/interactions`,
          method: 'GET',
          formData: true,
        };
      },
    }),
    getPostComments: builder.mutation<any, any>({
      query: (body) => {
        const { postId, page = 1 } = body;
        return {
          url: `/feed/${postId}/comments`,
          method: 'GET',
        };
      },
    }),
    likeComment: builder.mutation<any, ILikeComment>({
      query: (body) => {
        return {
          url: `/post/comment/like`,
          method: 'POST',
          body,
        };
      },
    }),
    deletePost: builder.mutation<any, any>({
      query: (postId) => {
        console.log(postId)
        return {
          url: `/posts/${postId}`,
          method: 'DELETE',
        };
      },
      async onQueryStarted(postId, { dispatch, queryFulfilled }) {
        const deleteResultsFromFeed = await dispatch(
          feedApi.util.updateQueryData('getFeedPosts', undefined, (draft) => {
            return { ...draft, items: draft?.items?.filter((item: any) => item?.id !== postId) };
          }),
        );
        const deleteResultFromTimeLine = await dispatch(
          homeApi.util.updateQueryData('getCombinedList', undefined, (draft) => {
            return { ...draft, items: draft?.items?.filter((item: any) => item?.id !== postId) };
          }),
        );
        const deleteResultFromApproveReject = await dispatch(
          homeApi.util.updateQueryData('getApproveRejectList', undefined, (draft) => {
            return { ...draft, posts: draft?.posts?.filter((item: any) => item?.id !== postId) };
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          deleteResultsFromFeed.undo();
          deleteResultFromTimeLine.undo();
          deleteResultFromApproveReject.undo();
        }
      },
      invalidatesTags: (arg) => ['ProfilePosts'],
    }),
    deleteComment: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: `/feed/${body.feed_id}/comments/${body.comment_id}`,
          method: 'DELETE',
        };
      },
    }),
    addPost: builder.mutation<any, IAddPost>({
      query: (credentials) => {
        const imageArray: any[] = [];
      if ( credentials.images &&  credentials.images.length > 0) {
        credentials.images.forEach((image: any) => {
          imageArray.push(image);
        });
      }
      const requestBody = {
        posttypeid: 2,
        publishdepartmentid: 0,
        title: "",
        description: credentials.description,
        startdate: "",
        enddate: "",
        publishstartdate: "",
        publishenddate: "",
        featuredenddate:"",
        targetgroupid:0,
        notification:1,
        commentstatusid: credentials.comment,
        interactionstatusid: 1,
        images: imageArray.length > 0 ? imageArray : [],
      };

        return {
          url: `/posts`,
          method: 'POST',
          body: requestBody,
          formData: true,
        };
      },
    }),
    updatePost: builder.mutation<any, any>({
      query: (body) => {
        const { post_id, content, comment, images, deletedImageIds } = body;
        const formData = new FormData();
        formData.append('content', content);
        formData.append('comment', comment);
        images.map((item: any, index: number) => {
          formData.append('images[' + index + ']', item);
        });
        formData.append('deleted_image_ids', JSON.stringify(deletedImageIds));
        return {
          url: `/post/update/${post_id}`,
          method: 'POST',
          body: formData,
          formData: true,
        };
      },
    }),
    getPostDetail: builder.query<any, any>({
      query: (postId) => {
        return {
          url: `/post/detail/${postId}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetFeedPostsQuery,
  useAddCommentMutation,
  useLikePostMutation,
  useDeleteLikePostMutation,
  useGetPostLikedListQuery,
  useGetPostCommentsMutation,
  // useGetPostCommentsQuery,
  useLikeCommentMutation,
  useDeletePostMutation,
  useDeleteCommentMutation,
  useAddPostMutation,
  useUpdatePostMutation,
  useGetPostDetailQuery,
} = feedApi;

export default feedApi;
