import { useIntl } from 'react-intl';
import ModuleTemplate from '../_global/components/ModuleTemplate/ModuleTemplate';
import { KTIcon } from '../../../../_metronic/helpers';
import React, { Fragment, useState } from 'react';
import { Modal } from '../_global/components/Modal/Modal';
import { ModalHeader } from '../_global/components/Modal/ModalHeader';
import CreateCheersForm from './components/CreateCheersForm';
import { CheersCard } from './components/CheersCard';
import { useAppSelector } from '../../../../api/store/hooks';
import { authSelector } from '../../../../api/features/auth/authSlice';
import { useGetCheersAllQuery } from './services/cheersEndpoints';
import { extractFirstName } from './utils/utils';
import InfiniteScroll from 'react-infinite-scroll-component';
import DefaultLoadingSpinner from '../_global/components/Loading/DefaultLoadingSpinner';
import Loading from '../../../components/Loading';

type QueryType = {
  page: number;
  pagesize: number;
};
export default function CheersList() {
  const intl = useIntl();
  const { currentUser } = useAppSelector((state) => authSelector(state.auth));
  const [page, setPage] = useState<number>(1);

  const { data, isLoading, isFetching, refetch } = useGetCheersAllQuery({page});

  const [isOpen, setIsOpen] = React.useState(false);

  const toggleSendCheersModal = () => {
    setIsOpen(!isOpen);
  };
  const handleSuccess = () => {
    refetch();
  };
  return (
    <Fragment>
      <ModuleTemplate
        title="CHEERS_PAGE_TITLE"
        subtitle="CHEERS_PAGE_DESCRIPTION"
        isLoading={isLoading}
        isEmpty={data?.cheers?.length === 0}
        headerEndComponent={
          <div className="d-flex align-items-center" onClick={() => setIsOpen(true)}>
            <div className="btn btn-sm btn-info px-4">
              <div className="d-flex justify-content-center align-items-center">
                <KTIcon iconName="plus-square" className="fs-2 me-2" iconOverride="text-white" />
                <div className="fw-bold fs-7 text-sm">Cheers Gönder</div>
              </div>
            </div>
          </div>
        }
        empty={{
          imgPath: '/media/svg/empty/games/GamesResultEmpty.svg',
          title: 'CHEERS_PAGE_EMPTY_TITLE',
          directSubContent: 'EMPTY_TEXT',
          bottomButtonComponent: (
            <div className="d-flex justify-content-center align-items-center" onClick={() => setIsOpen(true)}>
              <div className="btn btn-sm btn-info px-4">
                <div className="d-flex justify-content-center align-items-end">
                  <KTIcon iconName="plus-square" className="fs-2 me-2" iconOverride="text-white" />
                  <div className="fw-bold fs-7">Cheers Gönder</div>
                </div>
              </div>
            </div>
          ),
          className: 'mt-10',
        }}
      >
        <InfiniteScroll
          hasMore={true}
          scrollThreshold={0.75}
          next={() => setPage(page + 1)}
          dataLength={data?.cheers?.length ?? 0}
          className="d-flex flex-wrap justify-content-center mt-2 me-0"
          loader={isLoading && page > 1 ? <DefaultLoadingSpinner height={100} /> : isLoading && <Loading />}
          endMessage={!isLoading && page < (data?.page_count ?? 1) && null}
        >
          <div>
            {data &&
              data.cheers.map((cheers: any, index: any) => (
                <div key={index}>
                  <CheersCard
                    senderPhotoUrl={cheers.senderpictureurl}
                    receiverPhotoUrl={cheers.receiverpictureurl}
                    senderFullName={cheers.senderfullname}
                    badgeTitle={cheers.title}
                    receiverFullName={cheers.receiverfullname}
                    cheersType={cheers.cheerstypeid}
                    description={cheers.description}
                    receiverFirstName={extractFirstName(cheers.receiverfullname)}
                    senderFirstName={extractFirstName(cheers.senderfullname)}
                    created={cheers.created}
                    cheersBadgeIconUrl={cheers.cheersbadgeiconurl}
                  />
                </div>
              ))}
          </div>
        </InfiniteScroll>
      </ModuleTemplate>
      {isOpen && (
        <Modal
          id="feedback-modal"
          isScrollable={false}
          bodyClassName="mx-xl-0"
          toggle={toggleSendCheersModal}
          isDisposableOnOutsideClick={false}
          headerComponent={<ModalHeader title={'Cheers Gönder'} toggle={toggleSendCheersModal} />}
          formComponent={<CreateCheersForm onSuccess={handleSuccess} toggle={toggleSendCheersModal} />}
        />
      )}
    </Fragment>
  );
}
