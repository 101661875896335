import './style.scss';
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import Spritesheet from './spin';

type Props = {
  height?: number;
};

export default function Loading({ height }: Props) {
  return (
    <div className={`d-flex justify-content-center align-items-center w-100 h-${height ? height : 500}px`}>
      <Spritesheet
        className={`my-element__class--style`}
        image={toAbsoluteUrl('/media/mingSprite.png')}
        widthFrame={420}
        heightFrame={500}
        steps={116}
        fps={15}
        autoplay={true}
        loop={true}
        backgroundSize={`cover`}
        backgroundRepeat={`no-repeat`}
        backgroundPosition={`center center`}
      />
    </div>
  );
}
