import { useState } from 'react';
import { useJoinEventMutation, useJoinOrCancelEventMutation } from '../../../../../api/services/events/eventsEndpoints';
import { useIntl } from 'react-intl';
import { KTIcon } from '../../../../../_metronic/helpers';
import { useAppSelector } from '../../../../../api/store/hooks';
import { authSelector } from '../../../../../api/features/auth/authSlice';

export const RenderButton = ({
  user_event_join_status,
  user_limit,
  last_subscription_date,
  event_participant_count,
  end_date,
  id,
  isJoinData,
  calendar_start_date,
  data
}: any) => {
  const [isJoin, setIsJoin] = useState(isJoinData);
  const [joinEvent, { isLoading: loadingJoin }] = useJoinEventMutation();
  const { currentUser } = useAppSelector((state) => authSelector(state.auth));

  const isFull = user_limit === event_participant_count;
  //svar date = new Date(last_subscription_date);
  //var endDate = new Date(end_date);
  //var startDate = new Date(calendar_start_date);
  var currentDate = new Date();

  function formatDateToISO(dateString:any) {
    if (!dateString) return currentDate;   
    const [datePart, timePart] = dateString.split(' '); 
    const [day, month, year] = datePart.split('/'); 
    const [hours, minutes] = timePart.split(':');   
    
    return new Date(`${year}-${month}-${day}T${hours}:${minutes}:00`);
  }
  
  var date = formatDateToISO(last_subscription_date||data?.cf02);
  var startDate = formatDateToISO(calendar_start_date);
  var endDate = formatDateToISO(end_date);
 
  var lcvDate = currentDate >= date;
  var isEndDate = endDate <= currentDate;
  var isStartDate = startDate >= currentDate;

  const intl = useIntl();
  const joinOrCancelButton = async () => {
   const response = await joinEvent({
      eventId: id ,
      credentials: { userid: currentUser?.usersecret?.userid },
    });

  };
  if (isEndDate) {
    return (
      <button
        disabled={true}
        className={'btn btn-sm btn-bg-light d-flex align-items-center justify-content-center fw-bold'}
      >
        {intl.formatMessage({ id: 'EVENTS_COMPLETED' })}
      </button>
    );
  }
  if (!isEndDate && !isStartDate&& data?.cf01&&data?.currentuserinteractions !== null) {
    return (
      <button
        disabled={true}
        className={'btn btn-sm btn-bg-light d-flex align-items-center justify-content-center fw-bold'}
      >
        Devam Ediyor
      </button>
    );
  }
  if (data?.remainingquota===0 && data?.currentuserinteractions === null && !lcvDate)
    return (
      <button
        disabled={true}
        className={'btn btn-sm btn-bg-light btn-color-danger d-flex align-items-center justify-content-center'}
        style={{ backgroundColor: '#FFF5F8' }}
      >
        <KTIcon iconName={'profile-user'} className={'fs-1 text-black'} iconOverride="text-danger" />
        <div className={'text-danger fs-6  fw-bold'}>{intl.formatMessage({ id: 'EVENTS_QUOTA_FULL' })}</div>
      </button>
    );

  if (lcvDate&& data?.currentuserinteractions === null) {
    return (
      <button
        disabled={true}
        className={'btn btn-sm btn-bg-light btn-color-danger d-flex align-items-center justify-content-center'}
        style={{ backgroundColor: '#FFF5F8' }}
      >
        <div className={'text-danger fs-6 fw-bold'}>{intl.formatMessage({ id: 'EVENTS_LCV_PASSED' })}</div>
      </button>
    );
  }

  return (
    <button
      type="button"
      disabled={isJoin}
      data-kt-indicator="on"
      style={{ minWidth: 120 }}
      className={
        isJoin
          ? 'btn btn-light d-flex align-items-center justify-content-center'
          : 'btn btn-info  d-flex align-items-center justify-content-center'
      }
      onClick={async()=>{
        await joinOrCancelButton()
        await setIsJoin(true)
      }}
    >
      {loadingJoin ? (
        <span className="spinner-border spinner-border-sm align-middle ms-2" />
      ) : (
        <>
          <KTIcon
            iconName={isJoin ? 'check' : 'plus-circle'}
            className={isJoin ? 'fs-1 text-purple' : 'fs-1 text-white'}
            iconOverride="text-white"
          />
          <div className={isJoin ? ' text-purple fs-6' : ' text-white fs-6'}>
            {isJoin ? intl.formatMessage({ id: 'EVENTS_ATTENDED' }) : intl.formatMessage({ id: 'EVENTS_ATTEND' })}
          </div>
        </>
      )}
    </button>
  );
};
