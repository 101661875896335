import { useEffect, useRef, useState } from 'react';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';

type Props = {
  id: string;
  acceptClick: () => void;
  loading: boolean;
  contractTitle: string;
  text: string;
  mustScroll?: boolean;
};

export default function ContractModal({ id, acceptClick, loading, contractTitle, text, mustScroll = true }: Props) {
  const contractText = useRef<any>(null);

  const [buttonDisable, setButtonDisable] = useState(true);

  const handleScroll = () => {
    const div = contractText.current;
    if (div) {
      const remainingScroll = div.scrollHeight - div.scrollTop - div.clientHeight;
      const isAtBottom = remainingScroll <= 20;
      if (isAtBottom) {
        setButtonDisable(false);
      } else {
        setButtonDisable(true);
      }
    }
  };

  useEffect(() => {
    //Loading
    const div = contractText.current;
    if (div) {
      div.scrollTop = 0;
    }
  }, [loading]);

  useEffect(() => {
    const div = contractText.current;
    div.addEventListener('scroll', handleScroll);
    return () => {
      div.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="modal fade" tabIndex={-1} id={id}>
      <div className="modal-dialog  ">
        <div className="modal-content  ">
          <div className="modal-header" style={{ alignItems: 'center' }}>
            <div>
              <div className=" fs-2x fw-bolder" style={{ color: '#5E13D9' }}>
                {contractTitle}
              </div>
              <div className="modal-title fs-2x fw-bolder">Metni</div>
            </div>

            <img
              alt="Logo"
              src={toAbsoluteUrl('/media/logos/logo_renkli.png')}
              style={{
                width: '140px',
                height: '75px',
                objectFit: 'contain',
              }}
            />
          </div>

          <div className="modal-body ">
            {loading ? (
              <div className="h-400px w-100 d-flex justify-content-center align-items-center">
                <span className="indicator-progress" style={{ display: 'block' }}>
                  <span className="spinner-border h-50px w-50px align-middle text-info"></span>
                </span>
              </div>
            ) : (
              <div ref={contractText} style={{ height: 300 }} className="scroll">
                <iframe src={text} style={{ width: '100%', minHeight: '2300px', border: 'none' }} />
              </div>
            )}
          </div>

          <div className="modal-footer ">
            <button
              type="button"
              onClick={acceptClick}
              data-bs-dismiss="modal"
              className="btn btn-success btn-block"
              disabled={mustScroll ? buttonDisable : false}
            >
              Sözleşmeyi Onaylıyorum
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
