import { FC } from 'react';
import { Grid } from '@mui/material';
import Loading from '../../../components/Loading';
import { KTCard, toAbsoluteUrl } from '../../../../_metronic/helpers';
import GameCard from '../components/GameCard/GameCard';
import { FormattedMessage, useIntl } from 'react-intl';
import EventCard from '../components/EventCard/EventCard';
import SurveyCard from '../components/SurveyCard/SurveyCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import SvgBook from '../../../modules/apps/_global/svg/SvgBook';
import SvgPill from '../../../modules/apps/_global/svg/SvgPill';
import SvgMovie from '../../../modules/apps/_global/svg/SvgMovie';
import SvgSurvey from '../../../modules/apps/_global/svg/SvgSurvey';
import BasicFeedCard from '../components/BasicFeedCard/BasicFeedCard';
import Post from '../../../modules/apps/feed_module/components/Post/Post';
import { useAppDispatch, useAppSelector } from '../../../../api/store/hooks';
import { Modal } from '../../../modules/apps/_global/components/Modal/Modal';
import SvgAnnouncement from '../../../modules/apps/_global/svg/SvgAnnouncement';
import { ModalHeader } from '../../../modules/apps/_global/components/Modal/ModalHeader';
import LikedModal from '../../../modules/apps/feed_module/components/LikedModal/LikedModal';
import DefaultLoadingSpinner from '../../../modules/apps/_global/components/Loading/DefaultLoadingSpinner';
import CreatePostModal from '../../../modules/apps/feed_module/components/CreatePostModal/CreatePostModal';
import {
  feedSelector,
  setEditPostId,
  setLikeModal,
  setOpenPostCreate,
} from '../../../modules/apps/feed_module/features/feedSlice';
import GamesModal from '../../../modules/apps/games_module/components/GamesModal/GamesModal';
import ResultDetail from '../../../modules/apps/games_module/components/ResultDetail/ResultDetail';
import { gamesSelector, setAnswersModal, setGamesModal } from '../../../../api/features/games/gamesSlice';
import BackResult from '../../../modules/apps/games_module/components/BackResult/BackResult';
import confirmMessage from '../../../modules/apps/_global/components/Modal/ConfirmMessage';
import { authSelector } from '../../../../api/features/auth/authSlice';
import UserBadge from '../../../modules/apps/_global/components/UserBadge/UserBadge';
import { useNavigate } from 'react-router-dom';
import KudosCard from '../components/KudosCard/KudosCard';
import { CheersCard } from '../../../modules/apps/cheers/components/CheersCard';
import { extractFirstName } from '../../../modules/apps/cheers/utils/utils';
import SaveFeelingForm from '../../../modules/profile/components/Feeling/SaveFeelingForm';

type Props = {
  page: any;
  setPage: any;
  refetch: any;
  timeLineData: any;
  gamesRefetch: any;
  isLoading: boolean;
  surveysRefetch: any;
  approveRejectList: any;
};

const HomeTimeline: FC<Props> = ({
  gamesRefetch,
  isLoading,
  surveysRefetch,
  timeLineData,
  refetch,
  page,
  setPage,
  approveRejectList,
}) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const editPostId = useAppSelector((state) => feedSelector(state.feed).editPostId);
  const isOpenLikeModal = useAppSelector((state) => feedSelector(state.feed).isOpenLikeModal);
  const isOpenPostCreate = useAppSelector((state) => feedSelector(state.feed).isOpenPostCreate);
  const selectedGame = useAppSelector((state) => gamesSelector(state.games).selectedGame);
  const isOpenModal = useAppSelector((state) => gamesSelector(state.games).isOpenModal);
  const isOpenAnswersTab = useAppSelector((state) => gamesSelector(state.games).isOpenAnswersTab);
  const joinGameResponse = useAppSelector((state) => gamesSelector(state.games).joinGameResponse);
  const answers = useAppSelector((state) => gamesSelector(state.games).answers);
  const { questions } = answers || {};
  const navigate = useNavigate();
  const { currentUser } = useAppSelector((state) => authSelector(state.auth));

  const showGameWarning = (open: boolean) => {
    dispatch(setAnswersModal(false));
    if (joinGameResponse !== undefined &&  selectedGame !== undefined && selectedGame != null) {
      confirmMessage(
        intl.formatMessage({ id: 'GAMES.LEAVE_GAME' }),
        intl.formatMessage({ id: 'GAMES.LEAVE_WARNING_MESSAGE' }),
        () => {
          refetch();
          return dispatch(setGamesModal());
        },
        undefined,
        intl.formatMessage({ id: 'GAMES.APPROVE' }),
        intl.formatMessage({ id: 'GAMES.GIVE_UP' }),
      );
    } else {
      dispatch(setGamesModal());
    }
  };
  const getSubTitle = () => {
    if (joinGameResponse) {
      if (joinGameResponse?.questions?.length > 0) {
        return (
          <FormattedMessage
            id="GAMES.TOTAL_QUESTIONS"
            defaultMessage="Toplam {questionCount} soru"
            values={{ questionCount: joinGameResponse?.questions?.length }}
          />
        );
      }
    } else if (questions?.length > 0 && isOpenAnswersTab) {
      return (
        <FormattedMessage
          id="GAMES.TOTAL_QUESTIONS"
          defaultMessage="Toplam {questionCount} soru"
          values={{ questionCount: questions?.length }}
        />
      );
    } else {
      return undefined;
    }
  };

  const postToggle = () => {
    dispatch(setOpenPostCreate());
    dispatch(setEditPostId(undefined));
  };

  return (
    <Grid item xs={8} lg={6}>
      <KTCard className="p-8">
        <div className="mb-8">
          <h3 className="title">
            <FormattedMessage id="HOME.DEVELOPMENT_COMPANY" defaultMessage="Şirketten Gelişmeler" />
          </h3>
          <span className="subtitle text-gray-500">
            <FormattedMessage id="HOME.FOLLOW_TRACK" defaultMessage="Paylaşım ve içerikleri takip et" />
          </span>
        </div>
        <SaveFeelingForm/>
        <InfiniteScroll
          hasMore={true}
          scrollThreshold={0.75}
          next={() => setPage(page + 1)}
          dataLength={timeLineData?.feeds?.length ?? 0}
          loader={isLoading && page > 1 ? <DefaultLoadingSpinner height={100} /> : isLoading && <Loading />}
          endMessage={!isLoading && page < (timeLineData?.page_count ?? 1) && null}
        >
          {[...approveRejectList, ...timeLineData?.feeds]?.map((item: any) => {
            if (item.contenttypeid === 1 && item.posttypeid === 2) {
              const post = {
                ...item,
                promoted: item.currentuserinteractions === 1 ? true : false,
                liked: {
                  count: item.interactioncount,
                  current_user_status: item.currentuserinteractions === 1 ? true : false,
                },
                inactivePostid:item.postid,
                total_comment_count: item.commentcount,
                user: {
                  record_type: item.record_type,
                  title: item.nodename,
                  avatar: item.pictureurl,
                  permission: {
                    deleted: item.permission_delete,
                    updated: item.permission_update,
                  },
                  added_user_id: item.createuser,
                },
              };
              return <Post data={post} key={item.id} refetch={refetch} />;
            } else if (item.contenttypeid === 6) {
              return (
                <GameCard
                  data={item}
                  refetch={() => {
                    refetch();
                    gamesRefetch();
                  }}
                />
              );
            } else if (item.contenttypeid === 2) {
              return <EventCard data={item} />;
            } else if (item.contenttypeid === 3) {
              return (
                <KudosCard
                  key={item.id}
                  data={item}
                  title={item.description}
                  description={
                    item.targetuserdesc && item.targetuserdesc.trim().length > 0
                      ? item.targetuserdesc.replace(/\s+/g, '').toLowerCase()
                      : ''
                  }
                  icon={
                    <UserBadge
                      title={item.feedownerdesc}
                      subtitle={''}
                      rounded={'rounded-circle'}
                      navigate={() =>
                        navigate(
                          `/profile/${
                            item.createuser === undefined ? currentUser?.usersecret.userid : item.createuser
                          }`,
                        )
                      }
                      image={item.pictureurl ? item.pictureurl : toAbsoluteUrl('/media/svg/corporate-icon.svg')}
                    />
                  }
                />
              );
            } else if (item.contenttypeid === 4) {
              return (
                <CheersCard
                  senderPhotoUrl={item.pictureurl}
                  receiverPhotoUrl={item.targetuserpictureurl}
                  senderFullName={item.feedownerdesc}
                  badgeTitle={item.title}
                  receiverFullName={item.targetuserdesc}
                  cheersType={item.cheerstypeid}
                  description={item.description}
                  receiverFirstName={extractFirstName(item.targetuserdesc)}
                  senderFirstName={extractFirstName(item.feedownerdesc)}
                  created={item.created}
                  cheersBadgeIconUrl={item.badgeiconurl}
                />
              );
            } else if (item.contenttypeid === 5) {
              return (
                <SurveyCard
                  key={item.id}
                  data={item}
                  icon={<SvgSurvey />}
                  header={
                    <FormattedMessage
                      id="HOME.SURVEY_SHARED"
                      defaultMessage={`${item.nodename} bir anket paylaştı.`}
                      values={{ whoShared: item.nodename }}
                    />
                  }
                  refetch={() => {
                    refetch();
                    surveysRefetch();
                  }}
                />
              );
            } else if (item.contenttypeid === 1 && item.posttypeid === 3) {
              const post = {
                ...item,
                promoted: item.currentuserinteractions === 1 ? true : false,
                liked: {
                  count: item.interactioncount,
                  current_user_status: item.currentuserinteractions === 1 ? true : false,
                },
                total_comment_count: item.commentcount,
                user: {
                  record_type: item.record_type,
                  title: item.nodename,
                  avatar: item.pictureurl,
                  permission: {
                    deleted: item.permission_delete,
                    updated: item.permission_update,
                  },
                  added_user_id: item.createuser,
                },
              };
              return (
                <BasicFeedCard
                  key={item.id}
                  data={post}
                  icon={<SvgPill />}
                  headerTitle={<FormattedMessage id="HOME.INFO_SHARED" defaultMessage="Bir hap bilgi paylaşıldı." />}
                />
              );
            } else if (item.contenttypeid === 1 && item.posttypeid === 1) {
              const post = {
                ...item,
                promoted: item.currentuserinteractions === 1 ? true : false,
                liked: {
                  count: item.interactioncount,
                  current_user_status: item.currentuserinteractions === 1 ? true : false,
                },
                total_comment_count: item.commentcount,
                user: {
                  record_type: item.record_type,
                  title: item.nodename,
                  avatar: item.pictureurl,
                  permission: {
                    deleted: item.permission_delete,
                    updated: item.permission_update,
                  },
                  added_user_id: item.createuser,
                },
              };
              return (
                <BasicFeedCard
                  key={item.id}
                  data={post}
                  icon={<SvgAnnouncement />}
                  headerTitle={
                    <FormattedMessage
                      id="HOME.ANNOUNCEMENT_SHARED"
                      defaultMessage={`${item.nodename} bir duyuru paylaştı.`}
                      values={{ whoShared: item.nodename }}
                    />
                  }
                />
              );
            } else if (item.contenttypeid === 1 && item.posttypeid === 4) {
              const post = {
                ...item,
                promoted: item.currentuserinteractions === 1 ? true : false,
                liked: {
                  count: item.interactioncount,
                  current_user_status: item.currentuserinteractions === 1 ? true : false,
                },
                total_comment_count: item.commentcount,
                user: {
                  record_type: item.record_type,
                  title: item.nodename,
                  avatar: item.pictureurl,
                  permission: {
                    deleted: item.permission_delete,
                    updated: item.permission_update,
                  },
                  added_user_id: item.createuser,
                },
              };
              return (
                <BasicFeedCard
                  key={item.id}
                  data={post}
                  icon={<SvgBook />}
                  headerTitle={
                    <FormattedMessage id="HOME.BOOK_SHARED" defaultMessage="Bir kitap önerisi paylaşıldı." />
                  }
                />
              );
            } else if (item.contenttypeid === 1 && item.posttypeid === 5) {
              const post = {
                ...item,
                promoted: item.currentuserinteractions === 1 ? true : false,
                liked: {
                  count: item.interactioncount,
                  current_user_status: item.currentuserinteractions === 1 ? true : false,
                },
                total_comment_count: item.commentcount,
                user: {
                  record_type: item.record_type,
                  title: item.nodename,
                  avatar: item.pictureurl,
                  permission: {
                    deleted: item.permission_delete,
                    updated: item.permission_update,
                  },
                  added_user_id: item.createuser,
                },
              };
              return (
                <BasicFeedCard
                  key={item.id}
                  data={post}
                  icon={<SvgMovie />}
                  headerTitle={
                    <FormattedMessage id="HOME.MOVIE_SHARED" defaultMessage="Bir film önerisi paylaşıldı." />
                  }
                />
              );
            } else {
              const post = {
                ...item,
                promoted: item.currentuserinteractions === 1 ? true : false,
                liked: {
                  count: item.interactioncount,
                  current_user_status: item.currentuserinteractions === 1 ? true : false,
                },
                total_comment_count: item.commentcount,
                user: {
                  record_type: item.record_type,
                  title: item.nodename,
                  avatar: item.pictureurl,
                  permission: {
                    deleted: item.permission_delete,
                    updated: item.permission_update,
                  },
                  added_user_id: item.createuser,
                },
              };
              return <Post data={post} key={item.id} refetch={refetch} />;
            }
          })}
          {isOpenLikeModal === true && (
            <Modal
              className="w-50"
              id="feed_liked_ones"
              isScrollable={false}
              toggle={() => dispatch(setLikeModal())}
              headerComponent={
                <ModalHeader
                  toggle={() => dispatch(setLikeModal())}
                  title={intl.formatMessage({ id: 'FEED_LIKED_ONES' })}
                />
              }
              formComponent={<LikedModal />}
            />
          )}
          {isOpenPostCreate && (
            <Modal
              className="w-75"
              isScrollable={false}
              id="feed_create_post"
              isDisposableOnOutsideClick={false}
              toggle={() => postToggle()}
              headerComponent={
                <ModalHeader
                  toggle={postToggle}
                  title={
                    editPostId === undefined
                      ? intl.formatMessage({ id: 'FEED_CREATE_FEED_TEXT' })
                      : intl.formatMessage({ id: 'FEED_UPDATE_FEED_TEXT' })
                  }
                />
              }
              formComponent={<CreatePostModal refetch={refetch} toggle={postToggle} />}
            />
          )}
          {isOpenModal && (
            <Modal
              headerComponent={
                <ModalHeader
                  leftComponent={isOpenAnswersTab ? <BackResult /> : undefined}
                  title={
                    selectedGame !== undefined && selectedGame != null 
                      ? joinGameResponse === undefined
                        ? intl.formatMessage({ id: 'GAMES.GAME' })
                        : selectedGame.title
                      : `${selectedGame.title} ${intl.formatMessage({ id: 'GAMES.GAME_RESULT' })}`
                  }
                  subTitle={getSubTitle()}
                  toggle={showGameWarning}
                />
              }
              formComponent={
                selectedGame !== undefined && selectedGame != null ? (
                  <GamesModal
                    id={selectedGame.contentid}
                    title={selectedGame.title}
                    description={selectedGame.description}
                    category={selectedGame.category.title}
                    resultDate={selectedGame.enddate}
                  />
                ) : (
                  <ResultDetail id={selectedGame.contentid} />
                )
              }
              isDisposableOnOutsideClick={false}
              isScrollable={false}
              id="game"
            />
          )}
        </InfiniteScroll>
      </KTCard>
    </Grid>
  );
};

export default HomeTimeline;
