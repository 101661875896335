import { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import GenericEmptyContent from '../EmptyContent/GenericEmptyContent';
import Loading from '../../../../../components/Loading';
import PageWrapper from '../PageWrapper/PageWrapper';

export interface IMargin {
  xs?: string;
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
}

type Props = {
  title?: string;
  subtitle?: string;
  hideHeader?: boolean;
  headerEndComponent?: ReactNode;
  topPersistComponent?: ReactNode;
  children: ReactNode;
  isLoading?: boolean;
  isEmpty?: boolean;
  empty: {
    title: string;
    imgPath: string;
    className?: string;
    directSubContent?: string;
    bottomButtonComponent?: ReactNode;
    textBeforeNavigationLink?: string;
    textAfterNavigationLink?: string;
  };
  marginX?: IMargin;
};

const ModuleTemplate: FC<Props> = ({
  title,
  empty,
  marginX,
  isEmpty,
  children,
  subtitle,
  isLoading,
  headerEndComponent,
  hideHeader = false,
  topPersistComponent,
}) => {
  const intl = useIntl();
  return (
    <PageWrapper marginX={marginX}>
      {!hideHeader ? (
        <div className="d-flex justify-content-between mb-8">
          <div className="">
            <h3 className="title">{title && intl.formatMessage({ id: title })}</h3>
            <span className="subtitle text-gray-500">{subtitle && intl.formatMessage({ id: subtitle })}</span>
          </div>
          {headerEndComponent}
        </div>
      ) : null}
      {topPersistComponent}
      {isLoading ? (
        <Loading />
      ) : isEmpty ? (
        <GenericEmptyContent
          title={empty.title}
          imgPath={empty.imgPath}
          className={empty.className}
          directSubContent={empty.directSubContent}
          bottomButtonComponent={empty.bottomButtonComponent}
          textAfterNavigationLink={empty.textAfterNavigationLink}
          textBeforeNavigationLink={empty.textBeforeNavigationLink}
        />
      ) : (
        children
      )}
    </PageWrapper>
  );
};

export default ModuleTemplate;
