/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useAppDispatch } from '../../../../api/store/hooks';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { setAuth } from '../../../../api/features/auth/authSlice';
import { useLoginMutation } from '../../../../api/services/auth/authEndpoints';

const initialValues = {
  contact: '', // Telefon numarası veya e-posta
};

const Schema = Yup.object().shape({
  contact: Yup.string()
    .matches(/(^(\+?90|0)?\d{10}$)|(^[^\s@]+@[^\s@]+\.[^\s@]+$)/, 'Geçerli bir telefon numarası veya e-posta girin.')
    .required('Bu alan zorunludur.'),
});

export default function LoginInput({ onSubmitLogin }: any) {
  const dispatch = useAppDispatch();
  const [login, { isLoading: loading }] = useLoginMutation();

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values) => {
      try {
        const response = await login({ username: values.contact }).unwrap();
        onSubmitLogin(response, values.contact);
      } catch (error: any) {
        formik.setFieldError('contact', error.data.exMsg);
      }
    },
  });

  return (
    <div className="col-md-6  d-flex flex-column justify-content-center align-items-center py-10">
      <form className="d-flex flex-column" onSubmit={formik.handleSubmit}>
        <div className="fs-3x fw-bolder" style={{ color: '#5E13D9' }}>
          Ming'e
        </div>
        <div className="fs-3x fw-bolder mb-10">Hoş geldin.</div>
        <div className="mb-3" style={{ width: '30rem' }}>
          <label htmlFor="contact" className="form-label">
            Telefon Numarası veya E-posta
          </label>
          <input
            {...formik.getFieldProps('contact')}
            name="contact"
            type="text"
            autoComplete="off"
            placeholder="Telefon Numarası veya E-posta"
            className={clsx(
              'form-control bg-transparent',
              { 'is-invalid': formik.touched.contact && formik.errors.contact },
              {
                'is-valid': formik.touched.contact && !formik.errors.contact,
              },
            )}
          />
          {formik.touched.contact && formik.errors.contact && (
            <div className="fv-plugins-message-container text-danger">
              <span role="alert" className="--bs-danger-text-emphasis">
                {formik.errors.contact}
              </span>
            </div>
          )}
        </div>

        <button
          type="submit"
          style={{ width: '30rem' }}
          className="btn btn-info"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {loading ? <span className="spinner-border spinner-border-sm align-middle" /> : ' Giriş Yap'}
        </button>
      </form>

      <img
        alt="Logo"
        className="mt-17"
        src={toAbsoluteUrl('/media/logos/logo_renkli.png')}
        style={{
          width: '140px',
          height: '75px',
          justifyContent: 'center',
          alignSelf: 'center',
          objectFit: 'contain',
        }}
      />
    </div>
  );
}
