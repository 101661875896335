import * as React from 'react';
const SvgAnnouncement = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} fill="none" {...props}>
    <rect width={50} height={50} fill="#F7C000" rx={6} />
    <path
      fill="#fff"
      d="m31.39 20.75 3.923-4.902a1.416 1.416 0 0 0-1.048-2.182H15.452v14.167h18.813a1.415 1.415 0 0 0 1.048-2.182L31.39 20.75Z"
      opacity={0.6}
    />
    <path
      fill="#fff"
      d="M14.39 39.165a1.063 1.063 0 0 1-1.063-1.062V11.909a1.062 1.062 0 1 1 2.125 0v26.18a1.05 1.05 0 0 1-1.063 1.076Z"
    />
  </svg>
);
export default SvgAnnouncement;
