import React, { FC, useEffect } from 'react';
import './_option.scss';

type Props = {
  option: any;
  userAnswer: any;
  setStatus: any;
};

const ResultOption: FC<Props> = ({ option, userAnswer, setStatus }) => {
  const isChecked = () => {
    if (userAnswer?.game_question_option_id === option.id && userAnswer?.user_answer_correct === 'false') {
      return true;
    } else if (option.correct_answer === 'true') {
      return true;
    } else {
      return false;
    }
  };

  const isCorrect = () => {
    if (userAnswer?.game_question_option_id === option.id && userAnswer?.user_answer_correct === 'false') {
      return 'wrong';
    } else if (option.correct_answer === 'true') {
      return 'correct';
    } else {
      return null;
    }
  };

  useEffect(() => {
    const isStatus = () => {
      if (userAnswer === null) {
        return 'not-completed';
      } else if (userAnswer?.user_answer_correct === 'true') {
        return 'correct';
      } else {
        return 'wrong';
      }
    };
    setStatus(isStatus());
  }, [userAnswer, option, setStatus]);

  const getTitle = () => {
    if (userAnswer?.game_question_option_id === option.id && userAnswer?.user_answer_correct === 'false') {
      return '(Seçim) ' + option.title;
    } else if (option.correct_answer === 'true') {
      return '(Cevap) ' + option.title;
    } else {
      return option.title;
    }
  };

  return (
    <div
      className={`border ${isCorrect() === null && 'border-gray-300 bg-white'} ${
        isCorrect() === 'wrong' && 'border-danger bg-light-danger'
      } ${
        isCorrect() === 'correct' && 'border-success bg-light-success'
      } border-dashed mb-4 rounded-1 px-5 py-4 d-flex align-items-center`}
    >
      <span className={`form-check form-check-custom  form-check-solid me-4`}>
        <input
          className={`form-check-input ${isCorrect() === 'correct' && 'form-check-correct'} ${
            isCorrect() === 'wrong' && 'form-check-wrong'
          }`}
          type="radio"
          value={option.id}
          checked={isChecked()}
          disabled
        />
      </span>
      <div className="fs-5 fw-bold">{getTitle()}</div>
    </div>
  );
};

export default ResultOption;
