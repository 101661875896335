import React, { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Linkify from 'react-linkify';

type Props = {
  children: string;
  maxChars?: number;
  className?: string;
};

const Expandable: FC<Props> = ({ children, className, maxChars = 180 }) => {
  const [expanded, setExpanded] = useState(true);
  if (children.length <= maxChars)
    return (
      <p className={className}>
        <Linkify
          componentDecorator={(decoratedHref: string, decoratedText: string, key: number) => (
            <a href={decoratedHref} key={key} target="_blank" rel="noreferrer">
              {decoratedText}
            </a>
          )}
        >
          {children}
        </Linkify>
      </p>
    );
  let text = expanded ? children.substring(0, maxChars) : children;

  return (
    <>
      <div className={className}>
        <Linkify
          componentDecorator={(decoratedHref: string, decoratedText: string, key: number) => (
            <a href={decoratedHref} key={key} target="_blank" rel="noreferrer">
              {decoratedText}
            </a>
          )}
        >
          {text}
        </Linkify>
        {expanded && '...'}
      </div>
      <span className="cursor-pointer fw-bold text-gray-700 mb-4" onClick={() => setExpanded(!expanded)}>
        <FormattedMessage
          id={expanded ? 'SEE_MORE' : 'SHOW_LESS'}
          defaultMessage={expanded ? 'Devamını gör' : 'Daha az göster'}
        />
      </span>
    </>
  );
};

export default Expandable;
