import * as React from 'react';
const SvgPill = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} fill="none" {...props}>
    <rect width={50} height={50} fill="#3E97FF" rx={6} />
    <path
      fill="#EEF6FF"
      d="M37.006 12.994c-1.38-1.381-3.293-2.231-5.206-2.231-1.912 0-3.825.743-5.312 2.23L12.994 26.489c-1.381 1.38-2.231 3.293-2.231 5.206 0 2.019.743 3.825 2.23 5.206 1.382 1.381 3.295 2.231 5.207 2.231 1.913 0 3.825-.743 5.313-2.23l13.493-13.495c1.382-1.38 2.232-3.293 2.232-5.206 0-1.912-.85-3.825-2.232-5.206Zm-14.98 22.525c-.957.956-2.338 1.594-3.72 1.594-1.38 0-2.762-.532-3.718-1.594-.957-1.063-1.594-2.338-1.594-3.719 0-1.381.531-2.762 1.594-3.719l6.056-6.056 7.544 7.544-6.163 5.95Z"
    />
  </svg>
);
export default SvgPill;
