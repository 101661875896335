import { FC, useEffect, useState } from "react";
import { ErrorMessage, useFormikContext } from "formik";
import { DatePicker } from "antd";
import moment from "moment";
import "dayjs/locale/tr";
import locale from "antd/es/date-picker/locale/tr_TR";
import dayjs from "dayjs";

type IFormValues = {
  date: Date | null;
};

type Props = {
  containerClassName?: string;
  datePickerClassName?: string;
  title?: string;
  className?: string;
  required?: boolean;
  name: string;
  disabled?: boolean;
  changeEvent?: any;
  placeholder?: string;
  value?: any;
};

const FormDatePicker: FC<Props> = ({
  name,
  disabled,
  changeEvent,
  title,
  placeholder,
  value,
  required,
}) => {
  const [dateValue, setDateValue] = useState(new Date());
 

  

  useEffect(() => {
    if (value) {
      setDateValue(value);
    }
  }, [value]);

  const handleDateChange = (date: any) => {
    setDateValue(new Date(date));
    if (changeEvent) {
      changeEvent(new Date(date));
    }
  };

  const range = (start: any, end: any) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDate = (current: any) => {
    return current && current >= dayjs().endOf('day');
  };

  const disabledTime = (current: any) => {
    if (current && current.isSame(moment(), "day")) {
      const now = moment();
      return {
        disabledHours: () => range(0, now.hour()),
        disabledMinutes: () => {
          if (now.hour() === current.hour()) {
            return range(0, Math.ceil(now.minute()));
          } else {
            return [];
          }
        },
        disabledSeconds: () => [],
      };
    } else {
      return {};
    }
  };

  const checkTime = (current: any) => {
    const now = moment();
    if (current && current.isSame(now, "day")) {
      if (now.hour() >= current.hour()) {
        if (now.minute() >= 1) {
          if (dateValue) {
            const newDate = new Date(dateValue);
            newDate.setMinutes(0);
            newDate.setHours(now.hour() + 1);
            setDateValue(newDate);
          }
        } else {
          if (dateValue) {
            const newDate = new Date(dateValue);
            newDate.setMinutes(30);
            newDate.setHours(now.hour());
            setDateValue(newDate);
          }
        }
      }
    }
  };

  const dateFormat = "DD MMM YYYY HH:mm";

  return (
    <div>
      <label className="form-label fs-6">
        <span className={`${required ? "required" : ""} `}>
          {title || "Tarih"}
        </span>
      </label>
      <DatePicker
       getPopupContainer={(triggerNode: any) => {
        return triggerNode.parentNode;
      }}
        className="form-control form-control-solid"
        disabledDate={disabledDate}

        onChange={(date) => handleDateChange(date)}
        allowClear={false}
        showSecond={false}
        placeholder={placeholder}
        locale={{
          ...locale,
          lang: {
            ...locale.lang,
            ok:
              locale.lang.ok.charAt(0).toUpperCase() + locale.lang.ok.slice(1),
          },
        }}
        format={dateFormat}
        showTime={{
          defaultValue: dayjs("23:30", "HH:mm"),
          minuteStep: 1,
        }}
        showNow={false}
        disabled={disabled}
        value={dateValue ? dayjs(dateValue) : null}
      />
     
    </div>
  );
};

export { FormDatePicker };
