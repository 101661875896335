import * as React from 'react';

const SvgAppreciationIcon = (props: any) => (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="36" height="36" rx="6.75" fill="url(#paint0_linear_5379_34026)"/>
        <path
            d="M25.875 14.625C25.875 16.2563 25.3912 17.7525 24.5587 19.0013C23.3437 20.8013 21.42 22.0725 19.1813 22.3988C18.7988 22.4663 18.405 22.5 18 22.5C17.595 22.5 17.2012 22.4663 16.8187 22.3988C14.58 22.0725 12.6563 20.8013 11.4413 19.0013C10.6088 17.7525 10.125 16.2563 10.125 14.625C10.125 10.2713 13.6463 6.75 18 6.75C22.3537 6.75 25.875 10.2713 25.875 14.625Z"
            stroke="white" stroke-width="1.6875" stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M28.406 25.2788L26.5497 25.7175C26.1335 25.8188 25.8072 26.1338 25.7172 26.55L25.3235 28.2038C25.1097 29.1038 23.9622 29.3738 23.366 28.665L17.9997 22.5L12.6335 28.6763C12.0372 29.385 10.8897 29.115 10.676 28.215L10.2822 26.5613C10.181 26.145 9.85472 25.8188 9.44972 25.7288L7.59347 25.29C6.73847 25.0875 6.43472 24.0188 7.05347 23.4L11.441 19.0125C12.656 20.8125 14.5797 22.0838 16.8185 22.41C17.201 22.4775 17.5947 22.5113 17.9997 22.5113C18.4047 22.5113 18.7985 22.4775 19.181 22.41C21.4197 22.0838 23.3435 20.8125 24.5585 19.0125L28.946 23.4C29.5647 24.0075 29.261 25.0763 28.406 25.2788Z"
            stroke="white" stroke-width="1.6875" stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M18.6547 11.2275L19.3184 12.555C19.4084 12.735 19.6447 12.915 19.8584 12.9487L21.0622 13.1512C21.8272 13.275 22.0072 13.8375 21.4559 14.3887L20.5222 15.3225C20.3647 15.48 20.2747 15.7838 20.3309 16.0088L20.6009 17.1675C20.8147 18.0787 20.3309 18.4387 19.5209 17.955L18.3959 17.2912C18.1934 17.1675 17.8559 17.1675 17.6534 17.2912L16.5284 17.955C15.7184 18.4275 15.2347 18.0787 15.4484 17.1675L15.7184 16.0088C15.7634 15.795 15.6847 15.48 15.5272 15.3225L14.5934 14.3887C14.0422 13.8375 14.2222 13.2862 14.9872 13.1512L16.1909 12.9487C16.3934 12.915 16.6297 12.735 16.7197 12.555L17.3834 11.2275C17.7097 10.5075 18.2947 10.5075 18.6547 11.2275Z"
            stroke="white" stroke-width="1.6875" stroke-linecap="round" stroke-linejoin="round"/>
        <defs>
            <linearGradient id="paint0_linear_5379_34026" x1="18" y1="0" x2="18" y2="36" gradientUnits="userSpaceOnUse">
                <stop stop-color="#FF997D"/>
                <stop offset="1" stop-color="#FF6E7D"/>
            </linearGradient>
        </defs>
    </svg>

);
export default SvgAppreciationIcon;



