import { Stack } from '@mui/material';
import { IMargin } from '../ModuleTemplate/ModuleTemplate';

export type Props = {
  children: React.ReactNode;
  marginX?: IMargin;
};
export default function PageWrapper({ children, marginX }: Props) {
  return (
    <Stack marginX={{ xs: '0px', sm: '50px', md: '75px', lg: '150px', xl: '250px', ...marginX }}>{children}</Stack>
  );
}
