import React, { FC, useEffect, useState } from 'react';
import './_question.scss';
import Option from '../Option/Option';
import { Field, useFormikContext } from 'formik';
import { ICreateSurvey } from '../../CreateSurveyHelper';
import { useAnswerQuestionMutation } from '../../../../../../api/services/surveys/surveyEndpoints';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../../../../../api/store/hooks';
import { setIsButtonDisabledSlice } from '../../../../../../api/features/games/gamesSlice';

type Props = {
  question: any;
  questionIndex: number;
  id?: number;
  onNext: () => void; // Bir sonraki soruya geçiş için fonksiyon
  onComplete: () => void; // Anketi tamamlama fonksiyonu
  isLastQuestion: boolean; // Son soru olup olmadığını kontrol etmek için
  isSubQuestion?: boolean;
  passable?: number;
};

const Question: FC<Props> = ({
  question,
  questionIndex,
  id,
  onNext,
  onComplete,
  isLastQuestion,
  isSubQuestion,
  passable,
}) => {
  const { questiontext, description, questiontypeid, choicelist, required_question } = question || {};
  const { setFieldValue, values } = useFormikContext<ICreateSurvey>();
  const [answerQuestion] = useAnswerQuestionMutation();
  const [isSubQuestionSelectCount,setIsSubQuestionSelectCount]=useState(0)
  const [answerList, setAnswerList] = useState<Array<any>>([]);
  const [activeOptionIndex, setActiveOptionIndex] = useState<number | null>(null);
  const [stringValue, setStringValue] = useState<any>('');
  const [stringValue2, setStringValue2] = useState<any>('');

  const { isButtonDisabled } = useAppSelector((state) => state.games);

  const dispatch = useAppDispatch();

  const setIsButtonDisabled = (isDisabled: boolean) => {
    dispatch(setIsButtonDisabledSlice(isDisabled));
  };

  const intl = useIntl();
  useEffect(() => {
    if (question) {
      setFieldValue(`questions[${questionIndex}].surveyid`, id);
      setFieldValue(`questions[${questionIndex}].questionid`, question.questionid);
      setFieldValue(`questions[${questionIndex}].questiontypeid`, question.questiontypeid);
      setFieldValue(`questions[${questionIndex}].required_question`, question.required_question);
      setFieldValue(`questions[${questionIndex}].choicelist`, question.choicelist);
      setFieldValue(`questions[${questionIndex}].selectcount`, question.selectcount);
    }
  }, [question, setFieldValue, questionIndex]);

  useEffect(() => {
    checkbutton();
  }, [values.questions, questionIndex, questiontypeid]);

  const checkbutton = () => {
    if (passable === 1) {
      if (
        values.questions[questionIndex] !== undefined &&
        values.questions[questionIndex].subquestionid !== undefined &&
        values.questions[questionIndex].subquestionid !== 0 &&
        values?.questions[questionIndex]?.passablesub === 0
      ) {
        if (questiontypeid === 1 || questiontypeid === 2) {
          setIsButtonDisabled(!values.questions[questionIndex]?.question_option_val);
        } else if (questiontypeid === 3 && question.selectcount === 1) {
          setIsButtonDisabled(!values.questions[questionIndex]?.question_radio_id_sub);
        } else if (questiontypeid === 3 && question.selectcount > 1) {
          const selectedCount = values.questions[questionIndex]?.question_checkbox_ids_sub?.length || 0;
          setIsButtonDisabled(selectedCount === 0 || selectedCount > question.selectcount);
        }
      } else {
        setIsButtonDisabled(false);
      }
    } 
    else {
      if (questiontypeid === 1 || questiontypeid === 2) {
        // Serbest metin
        setIsButtonDisabled(!values.questions[questionIndex]?.question_option_val);
      } else if (questiontypeid === 3 && question.selectcount === 1) {
        if (
          values.questions[questionIndex] !== undefined &&
          values.questions[questionIndex].subquestionid !== undefined &&
          values.questions[questionIndex].subquestionid !== 0 &&
          values?.questions[questionIndex]?.question_radio_id === values?.questions[questionIndex]?.selectoption&&
          values.questions[questionIndex]?.question_checkbox_ids_sub?.length!==0 ) {
          if(isSubQuestionSelectCount === 0){
            setIsButtonDisabled(!values.questions[questionIndex]?.question_radio_id_sub);
            setIsButtonDisabled(!values.questions[questionIndex]?.question_option_val);
            setIsButtonDisabled(!values.questions[questionIndex]?.question_checkbox_ids_sub);
          }else{
            setIsButtonDisabled(true)
          }
        } else {
          if(isSubQuestionSelectCount === 0){
            setIsButtonDisabled(!values.questions[questionIndex]?.question_radio_id);
          }else{
            setIsButtonDisabled(true)
          }
        }
      } else if (questiontypeid === 3 && question.selectcount > 1) {
        // Çoklu seçim (checkbox)
        if (
          values.questions[questionIndex] !== undefined &&
          values.questions[questionIndex].subquestionid !== undefined &&
          values.questions[questionIndex].subquestionid !== 0 &&
          values.questions[questionIndex]?.question_checkbox_ids === values?.questions[questionIndex]?.selectoption
        ) {
          
          const selectedCount = values.questions[questionIndex]?.question_checkbox_ids_sub?.length || 0;
          setIsButtonDisabled(selectedCount === 0 || selectedCount > question.selectcount);
          setIsButtonDisabled(!values.questions[questionIndex]?.question_radio_id_sub);
          setIsButtonDisabled(!values.questions[questionIndex]?.question_option_val);
        } else {
          if(isSubQuestionSelectCount === 0){
            const selectedCount = values.questions[questionIndex]?.question_checkbox_ids?.length || 0;
            setIsButtonDisabled((selectedCount) === question.selectcount ? false: true);
          }else{setIsButtonDisabled(true)}
        }
      }
    }
  };

  const handleAnswerSubmit = async () => {
    const newAnswers: Array<any> = [];
    try {
      if (question.questiontypeid === 1 || question.questiontypeid === 2) {
        if (
          values !== undefined &&
          values.questions !== undefined &&
          values.questions[questionIndex] !== undefined &&
          values.questions[questionIndex].question_option_val !== undefined &&
          values.questions[questionIndex].question_option_val !== null &&
          Array.isArray(values.questions[questionIndex].question_option_val)
        ) {
          values.questions[questionIndex]?.question_option_val?.forEach((optionVal: string, optionIndex: number) => {
            newAnswers.push({
              surveyid: id,
              questionid: optionIndex,
              response: optionVal,
              choiceid: null,
            });
          });
        } else {
          newAnswers.push({
            surveyid: id,
            questionid: question.questionid,
            response: values.questions[questionIndex].question_option_val,
            choiceid: null,
          });
        }
      } else if (question.questiontypeid === 3) {
        const selectedChoices = [
          ...(values.questions[questionIndex]?.question_checkbox_ids || []),
          ...(values.questions[questionIndex]?.question_radio_id
            ? [values.questions[questionIndex]?.question_radio_id]
            : []),
        ];
        const isOtherOptionSelected = question.choicelist.find(
          (choice: any) => selectedChoices.includes(choice.choiceid.toString()) && choice.isother === 1,
        );

        const validChoices = selectedChoices.filter((choice): choice is string => typeof choice === 'string').reverse();
        
        validChoices.forEach((choiceId: string) => {
          let questionOptionVal;
          let questionOptionVal2;
          if (
            values !== undefined &&
            values.questions !== undefined &&
            values.questions[questionIndex] !== undefined &&
            values.questions[questionIndex].question_option_val !== undefined &&
            values.questions[questionIndex].question_option_val !== null &&
            Array.isArray(values.questions[questionIndex].question_option_val)
          ) {
            const currentValues = values.questions[questionIndex]?.question_option_val || [];
            const optionIndex = 100;
            questionOptionVal = currentValues[optionIndex];
            
            delete currentValues[optionIndex];
            setFieldValue(`questions[${questionIndex}].question_option_val`, currentValues);
          } else {
            questionOptionVal = values.questions[questionIndex]?.question_option_val;
          }
          
          newAnswers.push({
            surveyid: id,
            questionid: question.questionid,
            response: isOtherOptionSelected !== undefined && isOtherOptionSelected.isother === 1
            ? questionOptionVal // "Other" seçeneği için yanıt
            : null,
            choiceid: choiceId,
          });
        });
        if (
          values.questions[questionIndex].subquestionid !== undefined &&
          values.questions[questionIndex].subquestionid !== 0
        ) {
          const selectedChoices = [
            ...(values.questions[questionIndex]?.question_checkbox_ids_sub || []),
            ...(values.questions[questionIndex]?.question_radio_id_sub
              ? [values.questions[questionIndex]?.question_radio_id_sub]
              : []),
          ];
          const isOtherOptionSelected = values.questions[questionIndex]?.choicelist.find((choice: any) =>
            selectedChoices.length > 0
              ? selectedChoices.includes(choice.choiceid.toString())
              : values.questions[questionIndex].subquestionid && choice.isother === 1,
          );
          const validChoices =
            selectedChoices.length > 0
              ? selectedChoices.filter((choice): choice is string => typeof choice === 'string')
              : [];

          if (validChoices.length > 0) {
            validChoices.forEach((choiceId: string) => {
              if (
                values !== undefined &&
                values.questions !== undefined &&
                values.questions[questionIndex] !== undefined &&
                values.questions[questionIndex].question_option_val !== undefined &&
                values.questions[questionIndex].question_option_val !== null &&
                Array.isArray(values.questions[questionIndex].question_option_val)
              ) {
                values.questions[questionIndex]?.question_option_val?.forEach(
                  (optionVal: string, optionIndex: number) => {
                    newAnswers.push({
                      surveyid: id,
                      questionid: values.questions[questionIndex].subquestionid,
                      response:
                        (isOtherOptionSelected !== undefined && isOtherOptionSelected.isother === 1) ||
                        (values.questions[questionIndex].subquestionid !== 0 &&
                          (values.questions[questionIndex]?.questiontypeid === 1 ||
                            values.questions[questionIndex]?.questiontypeid === 2))
                          ? optionVal
                          : null,
                      choiceid: choiceId,
                    });
                  },
                );
              }
              if (
                values?.questions[questionIndex]?.question_option_val == null ||
                values?.questions[questionIndex]?.question_option_val === undefined ||
                values?.questions[questionIndex]?.question_option_val?.length === 0
              ) {
                newAnswers.push({
                  surveyid: id,
                  questionid: values.questions[questionIndex].subquestionid,
                  response: null,
                  choiceid: choiceId,
                });
              }
            });
          } else if (typeof values.questions[questionIndex].subquestionid === 'number') {
            if (
              values !== undefined &&
              values.questions !== undefined &&
              values.questions[questionIndex] !== undefined &&
              values.questions[questionIndex].question_option_val !== undefined &&
              values.questions[questionIndex].question_option_val !== null &&
              Array.isArray(values.questions[questionIndex].question_option_val)
            ) {
              values.questions[questionIndex]?.question_option_val?.forEach(
                (optionVal: string, optionIndex: number) => {
                  newAnswers.push({
                    surveyid: id,
                    questionid: optionIndex,
                    response: optionVal,
                    choiceid: null,
                  });
                },
              );
            } else {
              newAnswers.push({
                surveyid: id,
                questionid: values.questions[questionIndex].subquestionid,
                response: values.questions[questionIndex].question_option_val,
                choiceid: null,
              });
            }
          }
        }
      }

      // Yeni cevapları answerList'e ekleyin
      setAnswerList((prevAnswers) => [...prevAnswers, ...newAnswers]);

      let requestBody = {
        answerlist: answerList.concat(newAnswers),
        surveycompleted: 0,
        surveyid: id,
      };
      if (isLastQuestion) {
        requestBody.surveycompleted = 1;
      }
      await answerQuestion(requestBody);
      if (isLastQuestion) {
        onComplete();
      } else {
        onNext();
      }
    } catch (error) {
      console.error('Cevap gönderilirken bir hata oluştu:', error);
      alert('Cevap gönderilirken bir hata oluştu.');
    }
  };

  const handleChange = (e: any, optionIndex: number, type: number) => {
    const newOptions = values.questions[questionIndex].question_option_val || [];
    if (type === 1) {
      setStringValue(e.target.value);
    } else {
      setStringValue2(e.target.value);
    }
    newOptions[optionIndex] = e.target.value;
    setFieldValue(`questions[${questionIndex}].question_option_val`, newOptions);
  };

  const selectedCount = values.questions[questionIndex]?.question_checkbox_ids?.length || 0;

  useEffect(() => {
    if (isSubQuestion) {
      handleAnswerSubmit();
    }
  }, [values.questions[questionIndex]?.choicelist]);

  function getQuestionOptionValueLength(values: any, questionIndex: number): string {
    return `${stringValue.length}/500 karakter`;
  }

  function getQuestionOptionValueLength2(values: any, questionIndex: number): string {
    return `${stringValue2.length}/500 karakter`;
  }
  return (
    <div className="mb-12">
      {question.multimediapath && (
        <img
          src={question.multimediapath}
          style={{
            width: '150px',
            height: '150px',
            objectFit: 'contain',
          }}
          alt="question"
          className="mb-8  "
        />
      )}

      <div className={`question-info text-gray-500 fw-bold mb-3 ${required_question === 'true' ? 'required' : ''}`}>
        {questiontext} -{' '}
        {questiontypeid === 1
          ? 'Serbest Metin'
          : questiontypeid === 2
          ? 'Sayısal Değer'
          : questiontypeid === 3 && question.selectcount === 1
          ? 'Tek seçim'
          : question.selectcount > 1
          ? 'Çoklu seçim'
          : ''}
      </div>
      <div className="text-gray-800 mb-8 fw-bold fs-4">{description}</div>
      {questiontypeid !== 1 && questiontypeid !== 2 ? (
        choicelist?.map((option: any, index: number) => (
          <Option
            key={option.questionid}
            questionType={
              questiontypeid === 3 && question.selectcount === 1
                ? 2
                : questiontypeid === 3 && question.selectcount > 1
                ? 3
                : 1
            }
            option={option}
            questionIndex={questionIndex}
            optionIndex={index}
            onCompleteOption={checkbutton}
            isActive={activeOptionIndex === index}
            mainQuestionSelectcoun={question?.selectcount}
            setIsSubQuestionSelectCount={setIsSubQuestionSelectCount}
          />
        ))
      ) : (
        <div>
          {question.questiontypeid === 2 ? (
            // Number input if questiontypeid is 2
            <div>
              {' '}
              <Field
                type="number"
                className="form-control form-control-lg resize-none"
                onChange={(e: any) => handleChange(e, question.questionid, 1)}
                placeholder="Sayı giriniz."
                value={stringValue}
                maxLength={500}
              />
              {getQuestionOptionValueLength(values, questionIndex)}
            </div>
          ) : (
            // Textarea if questiontypeid is not 2 (e.g., questiontypeid === 1)
            <div>
              <Field
                as="textarea"
                className="form-control form-control-lg resize-none"
                onChange={(e: any) => handleChange(e, question.questionid, 2)}
                rows={3}
                placeholder="Metin giriniz."
                value={stringValue2}
                maxLength={500}
              />
              {getQuestionOptionValueLength2(values, questionIndex)}
            </div>
          )}
        </div>
      )}
      {questiontypeid === 3 && question.selectcount > 1 && (
        <div className="text-muted mt-2">
          {selectedCount < question.selectcount
            ? `Maksimum ${question.selectcount} seçenek işaretleyebilirsiniz. Kalan: ${
                question.selectcount - selectedCount
              }`
            : `En fazla ${question.selectcount} seçenek işaretleyebilirsiniz.`}
        </div>
      )}
      {passable === 1 ? <div style={{ fontWeight: '600', fontSize: '14px' }}>Opsiyonel</div> : <div></div>}
      {!isSubQuestion && (
        <div className="justify-content-center d-flex" style={{ paddingTop: '30px' }}>
          <button
            type="submit"
            disabled={isButtonDisabled}
            className={`btn ${!isButtonDisabled ? 'btn-info' : 'btn-secondary'} px-16 py-3`}
            onClick={handleAnswerSubmit} // Butona basınca bir sonraki soruya geçiş
          >
            {isLastQuestion ? intl.formatMessage({ id: 'SURVEYS.COMPLETE_THE_SURVEY' }) : 'Cevabı Gönder'}
          </button>
        </div>
      )}
    </div>
  );
};

export default Question;
