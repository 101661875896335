import { KTCard } from '../../../../../_metronic/helpers';
import { Stack } from '@mui/material';
import Select from 'react-select';
import { useIntl } from 'react-intl';
import { convertNumberToMonthString } from '../utils/utils';

export function RankingFilter({ month, setMonth }: any) {
  const intl = useIntl();
  return (
    <KTCard className="event-card">
      <Stack direction="row" spacing={3} marginY={3} marginX={5} justifyContent={'space-around'} alignItems={'stretch'}>
        <div className="col-md-6 col-6">
          <Select
            value={month}
            menuPosition={'fixed'}
            menuPortalTarget={document.body}
            key={`monthSelect-${month?.value}`}
            onChange={(data: any) => {
              setMonth(data)
            }}
            placeholder={intl.formatMessage({ id: 'SELECT_COMPONENT_PLACEHOLDER' })}
            classNames={{
              control: (_) => 'form-control form-control-sm  select-container',
            }}
            options={Array.from(Array(3).keys()).map((item: any) => {
              const now = new Date();
              const currentMonth = now.getMonth() + 1;
              const currentYear = now.getFullYear();

              // Calculate the month and year for the last three months
              const calculatedMonth = currentMonth - item;
              let year = currentYear;
              let month;

              if (calculatedMonth <= 0) {
                month = 12 + calculatedMonth;
                year -= 1;
              } else {
                month = calculatedMonth;
              }

              return {
                value: `${String(month)}`,
                label: intl.formatMessage({
                  id: `MONTH_${month}`,
                }),
                labelIdentifier: convertNumberToMonthString(month),
                year: `${year}`,
                yearLabel: `${year.toString()}`,
              };
            })}
          />
        </div>
      </Stack>
    </KTCard>
  );
}
