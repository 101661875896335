import api from '../../api';

interface Option {
  id: number;
  game_id: number;
  game_question_id: string;
  title: string;
  order: number | null;
  correct_answer: 'true' | 'false';
  deleted: number;
}

export interface IQuestion {
  id: number;
  game_id: number;
  title: string;
  order: number | null;
  time: number | null;
  image: string;
  choices: any;
}

interface IJoinGame {
  success: boolean;
  questions: IQuestion[];
}

export const gamesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getGames: builder.query<any, { page: number }>({
      query: ({ page }) => {
        return {
          url: `/contests?page=${page}`,
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => endpointName,
      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg?.page === 1) {
          currentCache.contests = newItems.contests;
        } else {
          currentCache?.contests?.push(...newItems.contests);
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    joinGame: builder.mutation<any, any>({
      query: (game_id) => ({
        url: `/contests/${game_id}/questions`,
        method: 'GET',
      }),
    }),
    answerGame: builder.mutation<any, any>({
      query: (data) => {
        const { game_id, game_question_id, game_question_option_id, ...body } = data || {};
        var reuqestBody = {
          choiceid: Number(game_question_option_id),
        };
        return {
          url: `/contests/${game_id}/questions/${game_question_id}/answers`,
          method: 'POST',
          body: reuqestBody,
        };
      },
      invalidatesTags: ['CombinedList'],
    }),
    getUserGames: builder.query<any, void>({
      query: () => {
        return {
          url: `/contests`,
        };
      },
    }),
    getUserResult: builder.query<any, { contestId?: string; query?: string }>({
      query: ({ contestId, query }) => {
        return {
          url: `/contests/${contestId}/rankings${query}`,
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => endpointName,
      merge: (currentCache, newItems, otherArgs) => {
        currentCache.rankings = newItems.rankings;
        // if (otherArgs.arg?.query === '1') {
        // } else {
        //   currentCache?.rankings?.push(newItems.rankings);
        // }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getGameDetail: builder.query<any, number>({
      query: (game_id) => {
        return {
          url: `/game/user/detail/${game_id}`,
        };
      },
    }),
    getGameAnswers: builder.query<any, number>({
      query: (game_id) => {
        return {
          url: `/game/question/answers/${game_id}`,
        };
      },
    }),
  }),
});

export const {
  useGetGamesQuery,
  useJoinGameMutation,
  useAnswerGameMutation,
  useGetUserGamesQuery,
  useGetGameDetailQuery,
  useGetGameAnswersQuery,
  useGetUserResultQuery,
} = gamesApi;

export default gamesApi;
