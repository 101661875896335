import { FC } from 'react';
import './_option.scss';
import { KTCard } from '../../../../../../_metronic/helpers';

type Props = {
  option: any;
  isUseMessageForBirthdays?: boolean;
  questionIndex: number;
  optionIndex: number;
  setOptionId: Function;
  optionId: string;
  disabled?: boolean;
};

const Option: FC<Props> = ({ option, setOptionId, isUseMessageForBirthdays = false, optionId, disabled }) => {
  const isChecked = () => {
    return optionId === option.choiceid.toString() ? true : false;
  };

  const handleClick = async () => {
    if (!disabled) {
      setOptionId(option.choiceid.toString());
    }
  };

  return (
    <div
      onClick={handleClick}
      className={`border ${
        isChecked() ? 'border-info bg-light-info' : 'border-gray-300 bg-white'
      } border-dashed mb-4 rounded-1 px-5 py-4 align-items-center cursor-pointer`}
    >
      <div 
      className=' mb-4 rounded-1 px-5 py-4 d-flex align-items-center cursor-pointer'
      >
          <div className="form-check form-check-custom form-check-solid me-4">
            <input
              className="form-check-input"
              type="radio"
              value={optionId}
              checked={isChecked()}
              disabled={disabled ?? false}
            />
          </div>
          <div className="fs-5 fw-bold">{isUseMessageForBirthdays ? option.choicetext : option.choicetext}</div>
      </div>
      {option?.mediauri !== undefined && option?.mediauri != null && (
        // <KTCard className="event-card image-card-style">
          <img
            width={100}
            height={100}
            alt="market_image"
            src={option?.mediauri ?? ''}
            style={{ objectFit: 'contain' }}
            className="rounded-3 image-card-style"
          />
        // </KTCard>
      )}
    </div>
  );
};

export default Option;
