/* eslint-disable array-callback-return */
import { isMobile } from 'react-device-detect';
import { Fragment, memo, useEffect, useMemo, useState } from 'react';
import { Modal } from '../../modules/apps/_global/components/Modal/Modal';
import { ModalHeader } from '../../modules/apps/_global/components/Modal/ModalHeader';
import SurveyModal from '../../modules/apps/surveys_module/components/SurveyModal/SurveyModal';
import MobileDownloadModal from '../../modules/apps/_global/components/MobileDownloadModal/MobileDownloadModal';
import SaveFeelingForm from '../../modules/profile/components/Feeling/SaveFeelingForm';
import { useIntl } from 'react-intl';
import { useGetSurveysQuery } from '../../../api/services/home/homeEndpoints';

const InitialModals = () => {
  const [modalQueue, setModalQueue] = useState<any>([]);
  const { data, isLoading, refetch } = useGetSurveysQuery({ query: "page=1&pagesize=100" });
  const survey = data?.surveylist !== undefined && data?.surveylist != null ? data?.surveylist : [];
  const intl = useIntl();
  const mandatorySurvey =  survey.find((survey: any) => {
    return (
      survey.passable === 0 &&
      (survey.iscompleted === 0 || survey.iscompleted === null)  // 'dd/MM/yyyy hh:mm' formatını JS Date formatına çevir
    );
  });
  
  const addModalToQueue = (modal: any) => {
    setModalQueue((prevQueue: any) => [...prevQueue, modal]);
  };

  const closeAndPopNewModal = () => {
    setModalQueue((prevQueue: any) => prevQueue.slice(1));
    window.location.reload(); 
  };
  const ModalTypes = useMemo(
    () => ({
      MOBILE_DOWNLOAD: {
        name: 'mobile_download',
        component: (
          <Modal
            formComponent={<MobileDownloadModal />}
            className="mx-6"
            isDisposableOnOutsideClick={false}
            isScrollable={false}
            id="mobileapp"
            key="mobileapp"
          />
        ),
      },
      SURVEY: {
        name: 'survey',
        component: (
          <Modal
            id="survey"
            key="survey"
            isScrollable={false}
            isDisposableOnOutsideClick={false}
            headerComponent={<ModalHeader title={mandatorySurvey?.surveyname} subTitle={`Tahmini ${mandatorySurvey?.expectedduration} dk`} />}
            formComponent={
              <SurveyModal
                closeModal={closeAndPopNewModal}
                isMustSurvey={true}
                title={mandatorySurvey?.surveyname}
                remaining_time={mandatorySurvey?.enddate}
                description={mandatorySurvey?.description}
                id={mandatorySurvey?.surveyid}
                refetch={() => {}}
                expectedduration={mandatorySurvey?.expectedduration}
                department_name={mandatorySurvey?.nodename}
                anonymous={mandatorySurvey?.anonymous}
                created_date_formatted={mandatorySurvey?.startdate}
              />
            }
          />
        ),
      },
      // FEELING: {
      //   name: 'feeling',
      //   component: (
      //     <Modal
      //       id="feeling"
      //       className="w-500px"
      //       bodyClassName="px-10"
      //       isScrollable={false}
      //       isDisposableOnOutsideClick={false}
      //       headerComponent={<ModalHeader title={intl.formatMessage({ id: 'GENERAL.FEEL_TODAY' })} />}
      //       formComponent={<SaveFeelingForm closeModal={closeAndPopNewModal} />}
      //       key="feeling"
      //     />
      //   ),
      // },
    }),
    [survey],
  );

  //* Don't interfere these useEffects in order to prevent excessive re-renders

  //! PLEASE READ => This effect is for async gathered data's (if we have a async data, we need to fetch it from api)
  useEffect(() => {
    if (isMobile) {
      addModalToQueue(ModalTypes.MOBILE_DOWNLOAD.component);
    }
    // Eğer survey varsa ve kuyrukta yoksa modal açılacak
    const queSurvey = modalQueue.some((i: any) => i.key === 'home');
    if (mandatorySurvey) {
      addModalToQueue(ModalTypes.SURVEY.component);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [survey]);

  //? PLEASE READ => This effect is for locally gathered data's (if we have a local data, we don't need to fetch it from api)
  // useEffect(() => {
  //   const today = new Date().toLocaleDateString();
  //   const lastFeelingDateArray = localStorage.getItem('lastFeelingDate');

  //   if (lastFeelingDateArray !== today) {
  //     addModalToQueue(ModalTypes.FEELING.component);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return <Fragment>{modalQueue.length > 0 && modalQueue[0]}</Fragment>;
};
export default memo(InitialModals);
