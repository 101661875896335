import { Outlet, Route, Routes } from 'react-router-dom';
import SuggestionList from './SuggestionList';
import SuggestionDetail from './SuggestionDetail';

const SuggestionPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />} />
      <Route path=":id" element={<SuggestionDetail />} />
      <Route index element={<SuggestionList />} />
    </Routes>
  );
};

export default SuggestionPage;
