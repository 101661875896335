import { Suspense, useEffect } from 'react';
import { AuthInit } from './modules/auth';
import { Outlet } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { MasterInit } from '../_metronic/layout/MasterInit';
import { I18nProvider } from '../_metronic/i18n/i18nProvider';
import { Modal } from './modules/apps/_global/components/Modal/Modal';
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core';
import MobileDownloadModal from './modules/apps/_global/components/MobileDownloadModal/MobileDownloadModal';

//BERKE <3

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            {isMobile && (
              <Modal
                formComponent={<MobileDownloadModal />}
                className="mx-6"
                isDisposableOnOutsideClick={false}
                isScrollable={false}
                id="mobileapp"
                key="mobileapp"
              />
            )}
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  );
};

export { App };
