import * as React from 'react';

const SvgThanksIcon = (props: any) => (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="36" height="36" rx="6.75" fill="url(#paint0_linear_5379_33986)"/>
        <path d="M14.2537 20.25H9C7.7625 20.25 6.75 21.2625 6.75 22.5V29.25H14.2537V20.25Z" stroke="white"
              stroke-width="1.6875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M19.4959 15.75H16.4922C15.2547 15.75 14.2422 16.7625 14.2422 18V29.25H21.7459V18C21.7459 16.7625 20.7447 15.75 19.4959 15.75Z"
            stroke="white" stroke-width="1.6875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M26.9959 23.625H21.7422V29.25H29.2459V25.875C29.2459 24.6375 28.2334 23.625 26.9959 23.625Z"
              stroke="white" stroke-width="1.6875" stroke-miterlimit="10" stroke-linecap="round"
              stroke-linejoin="round"/>
        <path
            d="M18.584 6.82873L19.1803 8.02123C19.259 8.18998 19.4728 8.34748 19.6528 8.36998L20.7328 8.54998C21.419 8.66248 21.5878 9.16871 21.0928 9.65246L20.249 10.4962C20.1028 10.6425 20.024 10.9125 20.069 11.1037L20.3053 12.1387C20.4965 12.96 20.0578 13.275 19.3378 12.8475L18.3253 12.2512C18.1453 12.1387 17.8415 12.1387 17.6615 12.2512L16.649 12.8475C15.929 13.275 15.4903 12.96 15.6815 12.1387L15.9178 11.1037C15.9628 10.9125 15.884 10.6312 15.7378 10.4962L14.9053 9.66372C14.4103 9.16872 14.5678 8.67371 15.2653 8.56121L16.3453 8.38124C16.5253 8.34749 16.739 8.18999 16.8178 8.03249L17.414 6.83996C17.7403 6.18746 18.2578 6.18748 18.584 6.82873Z"
            stroke="white" stroke-width="1.6875" stroke-linecap="round" stroke-linejoin="round"/>
        <defs>
            <linearGradient id="paint0_linear_5379_33986" x1="18" y1="0" x2="18" y2="36" gradientUnits="userSpaceOnUse">
                <stop stop-color="#9F60E0"/>
                <stop offset="1" stop-color="#793FB5"/>
            </linearGradient>
        </defs>
    </svg>

);
export default SvgThanksIcon;



