import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { usePostSetPasswordMutation } from '../../../../api/services/auth/authEndpoints';
import { useAppDispatch } from '../../../../api/store/hooks';
import { setAuth, setHeaderCode } from '../../../../api/features/auth/authSlice';
import ContractModal from './ContractModal';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import * as Yup from 'yup';

type Consent = {
  consentdocdesc: string;
  consentdocid: number;
  fileuri: string;
  isrequired: number;
  version: string;
};

interface LoginCreatePasswordProps {
  consentsList: Consent[];
  sessionkey: string;
  username: string;
}
const LoginCreatePassword: React.FC<LoginCreatePasswordProps> = ({ consentsList, sessionkey, username }) => {
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [passwordErrorText2, setPasswordErrorText2] = useState('');
  const [passwordVisibility2, setPasswordVisibility2] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [activeConsentTitle, setActiveConsentTitle] = useState<string | null>(null);
  const [activeConsentIndex, setActiveConsentIndex] = useState<number | null>(null);
  const [checkedConsentsList, setCheckedConsentsList] = useState<boolean[]>([]);
  const [consentedDocIds, setConsentedDocIds] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [setPassword, { isLoading: loading }] = usePostSetPasswordMutation();
  const dispatch = useAppDispatch();
  const [modalContent, setModalContent] = useState('');

  const initialValues = {
    password1: '',
    password2: '',
  };
  console.log(consentsList);
  const Schema = Yup.object().shape({
    password2: Yup.string().required('Parola girilmesi zorunludur.'),
    password1: Yup.string().required('Parola girilmesi zorunludur.'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values) => {
      const biggerOrEqualEightCharacter = values.password1.length >= 8;
      const includeUppercaseLowercaseCharacter = /[A-Z]/.test(values.password1) && /[a-z]/.test(values.password1);
      const includeNumber = /\d/.test(values.password1);
      const specialCharacter = /[\^$*.\[\]{}()?\-"!@#%&/\\,><:;_~`+=' ']/.test(values.password1);

      const allConsentsChecked = !checkedConsentsList.includes(false);

      if (
        biggerOrEqualEightCharacter &&
        includeUppercaseLowercaseCharacter &&
        includeNumber &&
        specialCharacter &&
        allConsentsChecked
      ) {
        try {
          if (values.password1 === values.password2) {
            setPasswordVisibility2(false);
            setPassword1(values.password1);
            setPassword2(values.password2);

            await handleSubmit(values.password1);
          } else {
            formik.setFieldError('password2', 'Girilen şifreler birbirinden farklıdır.');
            setPasswordVisibility2(true);
          }
        } catch (error: any) {
          formik.setFieldError('password', error.data.exMsg);
        }
      } else {
        if (!biggerOrEqualEightCharacter) {
          formik.setFieldError('password1', 'Parola en az 8 karakter olmalıdır.');
        }
        if (!includeUppercaseLowercaseCharacter) {
          formik.setFieldError('password1', 'Parola büyük ve küçük harf içermelidir.');
        }
        if (!includeNumber) {
          formik.setFieldError('password1', 'Parola en az bir rakam içermelidir.');
        }
        if (!specialCharacter) {
          formik.setFieldError('password1', 'Parola özel karakter içermelidir.');
        }
        if (!allConsentsChecked) {
          alert('Tüm sözleşmeleri onaylamanız gerekmektedir.');
        }
      }
    },
  });
  useEffect(() => {
    setCheckedConsentsList(Array(consentsList.length).fill(false));
  }, [consentsList]);

  const handleShowDocument = async (url: string, consentTitle: string, index: number) => {
    setActiveConsentTitle(consentTitle);
    setActiveConsentIndex(index);
    setModalContent(url);
  };

  const acceptConsent = () => {
    if (activeConsentIndex !== null) {
      const updatedCheckedConsentsList = [...checkedConsentsList];
      updatedCheckedConsentsList[activeConsentIndex] = true;
      setCheckedConsentsList(updatedCheckedConsentsList);

      const consentId = consentsList[activeConsentIndex].consentdocid;
      setConsentedDocIds((prev) => [...prev, consentId]);

      // Close modal
      setModalContent('');
      setActiveConsentTitle(null);
      setActiveConsentIndex(null);
    }
  };
  const handleSubmit = async (password: string) => {
    setIsLoading(true);
    try {
      const body = {
        username: username,
        password: password,
        sessionkey: sessionkey,
        appversion: '',
        clienttypeid: 4,
        ipv4: '',
        devicekey: '',
        devicedesc: '',
        devicemodel: '',
        version: '',
        macaddress: '',
        consenteddocids: consentedDocIds,
      };
      const response = await setPassword(body).unwrap();

      setIsLoading(false);
      dispatch(setAuth(response));
      dispatch(setHeaderCode(response.usersecret.token));
      navigate('/success');
    } catch (error) {
      setPasswordErrorText2('Şifre oluşturma hatası.');
      setIsLoading(false);
    }
  };

  return (
    <div className="col-md-6 d-flex flex-column justify-content-center align-items-center py-10 ">
      <form className="d-flex flex-column" onSubmit={formik.handleSubmit}>
        <div className="fs-3x fw-bolder mt-10" style={{ color: '#5E13D9' }}>
          Şifre Oluşturma
        </div>
        <div className="fs-3x fw-bolder mb-10 ">Yeni bir şifre oluşturun</div>
        <div className="mb-3 mt-10" style={{ width: '30rem' }}>
          <label htmlFor="Email" className="form-label">
            Parola
          </label>
          <input
            placeholder="Şifre"
            {...formik.getFieldProps('password1')}
            className={clsx('form-control bg-transparent mt-1')}
            type="password"
            name="password1"
            autoComplete="off"
          />
          {formik.touched.password1 && formik.errors.password1 && (
            <div className="fv-plugins-message-container text-danger">
              <span role="alert" className="--bs-danger-text-emphasis">
                {formik.errors.password1}
              </span>
            </div>
          )}
        </div>
        <div className="mb-3 mt-3" style={{ width: '30rem' }}>
          <label htmlFor="Email" className="form-label">
            Parola Tekrar
          </label>
          <input
            placeholder="Şifre"
            {...formik.getFieldProps('password2')}
            className={clsx('form-control bg-transparent mt-1')}
            type="password"
            name="password2"
            autoComplete="off"
          />
          {formik.touched.password2 && formik.errors.password2 && (
            <div className="fv-plugins-message-container text-danger">
              <span role="alert" className="--bs-danger-text-emphasis">
                {formik.errors.password2}
              </span>
            </div>
          )}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          {consentsList.map((consent, index) => (
            <div key={index}>
              <label>
                <input
                  type="checkbox"
                  checked={checkedConsentsList[index]}
                  id="isPlatformRulesCheck"
                  className="form-check-input"
                  {...formik.getFieldProps('isPlatformRulesCheck')}
                  onChange={() => handleShowDocument(consent.fileuri, consent.consentdocdesc, index)}
                  {...(checkedConsentsList[index]
                    ? {}
                    : {
                        'data-bs-toggle': 'modal',
                        'data-bs-target': `#contract_modal1${consent.consentdocid}`,
                      })}
                />
                <button
                  type="button"
                  onClick={() => handleShowDocument(consent.fileuri, consent.consentdocdesc, index)}
                  style={{
                    background: 'none',
                    border: 'none',
                    color: 'blue',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    padding: '0',
                    marginLeft: '10px',
                  }}
                >
                  {consent.consentdocdesc}
                </button>
              </label>
            </div>
          ))}
        </div>

        <button
          type="submit"
          className="btn btn-info mt-5"
          style={{ width: '30rem' }}
          disabled={formik.isSubmitting || !formik.isValid || !buttonDisabled}
        >
          {loading ? <span className="spinner-border spinner-border-sm align-middle" /> : 'Devam Et'}
        </button>
        <img
          className="mt-17"
          alt="Logo"
          src={toAbsoluteUrl('/media/logos/logo_renkli.png')}
          style={{
            width: '140px',
            height: '75px',
            justifyContent: 'center',
            alignSelf: 'center',
            objectFit: 'contain',
          }}
        />
      </form>
      {consentsList.map((consent, index) => (
        <ContractModal
          text={modalContent}
          id={`contract_modal1${consent.consentdocid}`}
          loading={false}
          contractTitle={activeConsentTitle || 'Document'}
          acceptClick={acceptConsent}
        />
      ))}
    </div>
  );
};

export default LoginCreatePassword;
