/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment, memo, useState } from 'react';
import { KTCard } from '../../../../../_metronic/helpers';
import './_quickLinks.scss';
import { useIntl } from 'react-intl';
import Collapse from '../../../../modules/apps/_global/components/Collapse/Collapse';
import TooltipInfo from '../../../../modules/apps/_global/components/Tooltip';

type Props = {
  title: string;
  data: any;
  limit?: number;
};

const QuickLinks = ({ title, data, limit = 5 }: Props) => {
  const [isShowMore, setShowMore] = useState(false);
  const intl = useIntl();

const formatLink = (link: string) => {
  if (!link.startsWith('https://') && !link.startsWith('http://') && !link.startsWith('www')) {
    return `https://${link}`;
  }
  return link;
};

  return (
    <KTCard className="quicklinks-card">
      <div className="quicklinks-card-header">
        <h3 className="title">{title && intl.formatMessage({ id: title })}</h3>
      </div>
      <div className="d-flex flex-column justify-content-between">
        <div className="mb-4">
          {data?.slice(0, isShowMore ? data?.length : limit)?.map((category: any, index: number) => (
            <Collapse key={category.quickaccessid + index} title={category.quickaccessname}>
                <Fragment key={`lw26-rows-${index}`}>
                    <div className="d-flex justify-content-between align-items-center mb-4 mt-2">
                      <div className="d-flex align-items-center  gap-2">
                        <img
                          alt="Logo"
                          src={category.mediauri}
                          className="quicklinks-image rounded-2 border"
                          width={22}
                          height={22}
                        />
                        <a href={formatLink(category.quickaccesslink)} target="_blank" rel="noreferrer" className="text-info fw-semibold fs-6">
                          {category.quickaccessname}
                        </a>
                      </div>
                      <TooltipInfo title={category.quickaccessdescription} />
                    </div>
                  </Fragment>
            </Collapse>
          ))}
        </div>
        {data?.length > limit && (
          <button
            type="button"
            onClick={() => setShowMore(!isShowMore)}
            className="text-center border-0 bg-transparent"
          >
            <div className="text-info fw-bolder ">
              {intl.formatMessage({ id: isShowMore ? 'HOME.SHOW_LESS' : 'HOME.SHOW_MORE' })}
            </div>
          </button>
        )}
      </div>
    </KTCard>
  );
};
export default memo(QuickLinks);
