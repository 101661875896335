import { useIntl } from 'react-intl';
import { forwardRef, memo, useState } from 'react';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { useAddCommentMutation } from '../../services/feedEndpoints';
import { okayMessage } from '../../../_global/components/Modal/ConfirmMessage';

type Props = {
  id: number;
  data: any;
  image: string | any;
  setCommentCount: Function;
  getPostComments: Function;
  handleScrollToFirstComment: Function;
  commentReplyPlaceholder?:string;
  commentParentId?:number;
  setVisibleCommentsReply?:any;
};

const CommentInput = forwardRef<HTMLInputElement, Props>(
  ({ id, image, setCommentCount, getPostComments, handleScrollToFirstComment,data,commentReplyPlaceholder,commentParentId,setVisibleCommentsReply}, ref: any) => {
    const intl = useIntl();
    const [comment, setComment] = useState('');
    const [addComment, { isLoading }] = useAddCommentMutation();
    const sendComment = (value: any) => {
      addComment({ comment: value, post_id: id, post_image:image, parent_id: commentParentId??0 }).then(async (res: any) => {
        if (res != null) {
          await getPostComments({ postId: id });
          handleScrollToFirstComment();
          setCommentCount((prev: number) => prev + 1);
          setComment('');
          setVisibleCommentsReply(false)
        } else {
          okayMessage(
            intl.formatMessage({ id: 'FEED_ERROR_SORRY_TEXT' }),
            res?.data?.message,
            undefined,
            intl.formatMessage({ id: 'OKAY' }),
          );
        }
      });
    };

    const handleKeyDown = (event: any) => {
      if (event.target.value !== '') {
        if (event.key === 'Enter') {
          sendComment(event.target.value);
        }
      }
    };

    return (
      <div className="mt-6 d-flex gap-4 align-items-center">
        <img
          src={image != null && image?.length !== 0 ? image : toAbsoluteUrl(`/media/avatars/Default Avatar.svg`)}
          alt="user"
          width={48}
          height={48}
          className="rounded-2"
        />
        <div className="w-100 position-relative">
          <input
          placeholder={commentReplyPlaceholder?? ''}
            onChange={(e: any) => setComment(e.target.value)}
            ref={ref}
            type="text"
            value={comment}
            onKeyDown={handleKeyDown}
            className="form-control form-control-lg  form-control-solid border border-1"
          />

          <button
            type="button"
            onClick={() => sendComment(comment)}
            disabled={comment === '' || isLoading}
            className={`border-0 rounded-2 px-4 position-absolute fw-bold right-end ${
              comment === '' ? 'bg-secondary text-gray-600' : 'bg-info text-white'
            }`}
            style={{
              top: 8,
              bottom: 8,
              right: 16,
            }}
          >
            {isLoading ? (
              <span className="d-flex indicator-progress justify-content-end" style={{ display: 'block' }}>
                <span className="spinner-border align-middle text-white w-20px h-20px" role="status"></span>
              </span>
            ) : (
              intl.formatMessage({ id: 'FEEDS_SHARE_COMMENT' })
            )}
          </button>
        </div>
      </div>
    );
  },
);
export default memo(CommentInput);
