// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swal2-actions {
  justify-content: flex-end !important;
  width: 100%;
  padding: 0px 12px;
}

.swal2-cancel {
  color: #000 !important;
  border-radius: 6px !important;
}

.swal2-confirm {
  border-radius: 6px !important;
}

.swal2-actions button {
  margin-top: 10px;
}

.swal2-content {
  width: 500px;
  padding: 0;
  margin: 0;
}

.swal2-okay-content {
  width: 500px;
  padding: 0;
  margin: 0;
}

.swal2-header {
  text-align: left;
  padding: 12px 0px;
}

.swal2-title {
  padding: 0px 20px;
  text-align: left;
  font-size: 16px !important;
}

.swal2-text {
  padding: 0px 20px;
  text-align: left;
  font-size: 16px !important;
  color: #3f4254 !important;
  font-family: Inter !important;
}

.swal2-html-container {
  margin: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/apps/_global/components/Modal/_confirmMessage.scss"],"names":[],"mappings":"AAAA;EACE,oCAAA;EACA,WAAA;EACA,iBAAA;AACF;;AAEA;EACE,sBAAA;EACA,6BAAA;AACF;;AAEA;EACE,6BAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EAEE,YAAA;EACA,UAAA;EACA,SAAA;AAAF;;AAEA;EAEE,YAAA;EACA,UAAA;EACA,SAAA;AAAF;;AAGA;EAEE,gBAAA;EACA,iBAAA;AADF;;AAIA;EACE,iBAAA;EACA,gBAAA;EACA,0BAAA;AADF;;AAKA;EACE,iBAAA;EACA,gBAAA;EACA,0BAAA;EACA,yBAAA;EACA,6BAAA;AAFF;;AAKA;EAEE,oBAAA;AAHF","sourcesContent":[".swal2-actions {\n  justify-content: flex-end !important;\n  width: 100%;\n  padding: 0px 12px;\n}\n\n.swal2-cancel {\n  color: #000 !important;\n  border-radius: 6px !important;\n}\n\n.swal2-confirm {\n  border-radius: 6px !important;\n}\n\n.swal2-actions button {\n  margin-top: 10px;\n}\n\n.swal2-content {\n  //   background-color: red;\n  width: 500px;\n  padding: 0;\n  margin: 0;\n}\n.swal2-okay-content {\n  // background-color: red;\n  width: 500px;\n  padding: 0;\n  margin: 0;\n}\n\n.swal2-header {\n  //   background-color: green;\n  text-align: left;\n  padding: 12px 0px;\n}\n\n.swal2-title {\n  padding: 0px 20px;\n  text-align: left;\n  font-size: 16px !important;\n  //   font-weight: 600 !important;\n}\n\n.swal2-text {\n  padding: 0px 20px;\n  text-align: left;\n  font-size: 16px !important;\n  color: #3f4254 !important;\n  font-family: Inter !important;\n}\n\n.swal2-html-container {\n  //   background-color: yellow;\n  margin: 0 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
