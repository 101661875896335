// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CSS for scaling up and down animation */
.scale-down-entered {
  transform: scale(1);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 1;
}

.scale-up-entered {
  transform: scale(1.025);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/apps/_global/components/Modal/_modal.scss"],"names":[],"mappings":"AAAA,0CAAA;AACA;EACE,mBAAA;EACA,gEAAA;EACA,UAAA;AACF;;AAEC;EACC,uBAAA;EACA,gEAAA;EACA,UAAA;AACF","sourcesContent":["/* CSS for scaling up and down animation */\n.scale-down-entered {\n  transform: scale(1);\n  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;\n  opacity: 1;\n}\n\n .scale-up-entered {\n  transform: scale(1.025);\n  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
