import React, { FC } from 'react';
import { KTCard } from '../../../../../_metronic/helpers';
import './_kudosCard.scss';
import Expandable from '../../../../modules/apps/_global/components/Expandable/Expandable';
import { dateFormatter } from '../../../../modules/_helpers/Helpers';
import ImageSlider from '../../../../modules/apps/_global/components/Swiper/ImageSlider/ImageSlider';
import { format, parse } from 'date-fns';

type Props = {
  data: any;
  icon?: any;
  description?: any;
  title:any
};

function mingFlowDurationRounded(stringDateTime: string | null): string {
  if (!stringDateTime) {
    return '';
  }

  let duration: Date;
  if (stringDateTime.length === 16) {
    duration = parse(stringDateTime, 'dd/MM/yyyy HH:mm', new Date());
  } else if (stringDateTime.length === 19) {
    duration = parse(stringDateTime, 'dd/MM/yyyy HH:mm:ss', new Date());
  } else {
    return '';
  }

  const now = new Date();
  const difference = now.getTime() - duration.getTime(); // Get difference in milliseconds

  const differenceInDays = Math.floor(difference / (1000 * 60 * 60 * 24));
  const differenceInHours = Math.floor((difference / (1000 * 60 * 60)) % 24);
  const differenceInMinutes = Math.floor((difference / (1000 * 60)) % 60);

  if (differenceInDays > 0) {
    return `${differenceInDays}g`;
  } else if (differenceInHours > 0) {
    return `${differenceInHours}s`;
  } else if (differenceInMinutes > 0) {
    return `${differenceInMinutes}d`;
  } else {
    return '1d';
  }
}

const KudosCard: FC<Props> = ({ data, icon, description,title }) => {
  const { startdate } = data || {};

  return (
    <KTCard className="p-8 event-card mb-8">
      <div className="d-flex gap-4 align-items-center mb-8">{icon}</div>
      <div className="post-description text-gray-800 my-3">
        <span className="fw-bold text-gray-800 fs-6">#Tebrikler </span>{' '}
        <span className="fw-bold text-gray-800 fs-6">@{description}</span> <span className="fw-normal">{title}</span>
      </div>
      {data?.mediauri !== undefined && data?.mediauri !== null && (
        <div className="d-flex justify-content-center">
          <ImageSlider images={data?.mediauri} isPreviewEnabled={true} />
        </div>
      )}

      <div className="mt-4 d-flex justify-content-end">
        <span className="fw-bold text-gray-500">{mingFlowDurationRounded(data.created)}</span>
      </div>
    </KTCard>
  );
};

export default KudosCard;
