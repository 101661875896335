import * as React from 'react';
const BulkDotsSquare = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
    <path
      fill="#A1A5B7"
      d="M14.25 0h-8.5A5.75 5.75 0 0 0 0 5.75v8.5A5.75 5.75 0 0 0 5.75 20h8.5A5.75 5.75 0 0 0 20 14.25v-8.5A5.75 5.75 0 0 0 14.25 0Z"
      opacity={0.3}
    />
    <path
      fill="#A1A5B7"
      d="M10.37 11.22a1.34 1.34 0 1 0 0-2.68 1.34 1.34 0 0 0 0 2.68ZM5.35 11.22a1.34 1.34 0 1 0 0-2.68 1.34 1.34 0 0 0 0 2.68ZM15.38 11.22a1.34 1.34 0 1 0 0-2.68 1.34 1.34 0 0 0 0 2.68Z"
    />
  </svg>
);
export default BulkDotsSquare;
