import Select from 'react-select';
import { useEffect, useState } from 'react';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { useAppSelector } from '../../../../../api/store/hooks';
import { authSelector } from '../../../../../api/features/auth/authSlice';
import { okayMessage } from '../../_global/components/Modal/ConfirmMessage';
import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';
import {
  useCheersUserScoreQuery,
  useCreateCheersMutation,
  useGetCheersUserScoreQuery,
  useGetFilteredUsersQuery,
} from '../services/cheersEndpoints';
import InputWrapper from '../../_global/components/Input/InputWrapper';
import { Stack } from 'react-bootstrap';
import { BadgeSelection } from './BadgeSelection';
import MingMonyIcon from '../../../../../_metronic/assets/icontsx/mingMony';

type Props = {
  toggle?: () => void;
  onSuccess?: () => void;
};

export default function CreateCheersForm({ toggle, onSuccess }: Props) {
  const intl = useIntl();
  const [createCheers, { isLoading: createCheersFetch }] = useCreateCheersMutation();
  const { currentUser } = useAppSelector((state) => authSelector(state.auth));
  const [selectedEmployee, setSelectedEmployee] = useState<any>({
    label: 'Seçiniz',
    value: null,
    image: null,
    position: null,
  });
  const [selectedBadgeId, setSelectedBadgeId] = useState<any>([]);
  const [anyBadge, setAnyBadge] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState('');
  const [page, setPage] = useState(1);
  const { data: usersData, refetch } = useGetFilteredUsersQuery({ page, filterkey: inputValue });
  const [peopleList, setPeopleList] = useState<any>([]);
  const [cheersTypeOptions, setCheersTypeOptions] = useState<any>(1);
  const { data: userScoreData = { score: 0 } } = useGetCheersUserScoreQuery();

  const cheersType = [
    { value: 1, label: 'Takdir' },
    { value: 2, label: 'Teşekkür' },
  ];

  useEffect(() => {
    setCheersTypeOptions(1);
    if (usersData !== undefined && usersData?.people != null) {
      const temp = usersData.people
        .filter((usr: any) => usr.userid !== currentUser?.usersecret?.userid)
        .map((usr: any) => ({
          label: usr.fullname,
          value: usr.userid,
          image: usr.pictureurl,
          position: usr.positiondesc,
        }));
      setPeopleList(temp);
    }
  }, [currentUser?.usersecret?.userid, usersData]);

  const handleMenuScrollToBottom = () => {
    setPage((prevPage) => prevPage + 1);
    refetch();
  };

  const formatOptionLabel = ({ value, label, position, image }: any) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={image ?? toAbsoluteUrl('/media/avatars/Default Avatar.svg')}
        alt={value?.name}
        height={25}
        width={25}
        className="mr-4"
        style={{ marginRight: 10, borderRadius: '50%' }}
      />
      <div>{label}</div>
      <div style={{ marginLeft: '10px', color: '#ccc' }}>{position}</div>
    </div>
  );

  const badgeClicked = (badgeId: number) => {
    setSelectedBadgeId(badgeId);
  };

  const isThereAnyBadge = (anyBadge: boolean) => {
    setAnyBadge(anyBadge);
  };

  return (
    <Formik
      initialValues={{
        cheerstypeid: 1,
        userid: 0,
        description: '',
      }}
      onSubmit={async (values, { resetForm }) => {
        const requestBody = {
          cheerstypeid: values.cheerstypeid,
          badgeid: selectedBadgeId,
          userid: selectedEmployee.value,
          description: values.description,
        };
        console.log(selectedEmployee.value);

        await createCheers(requestBody)
          .unwrap()
          .then(() => {
            okayMessage(
              intl.formatMessage({ id: 'SEND_CHEERS_SUCCESS_TITLE_TEXT' }),
              intl.formatMessage({ id: 'SEND_CHEERS_SUCCESS_SUBTITLE_TEXT' }),
            );
            resetForm();
            toggle && toggle();
            onSuccess && onSuccess();
          })
          .catch((err) => {
            okayMessage(intl.formatMessage({ id: 'SEND_CHEERS_ERROR_TITLE_TEXT' }), err.data.exMsg);
          });
      }}
    >
      {({ setFieldValue, values, handleChange }) => (
        <Form>
          <div className="row col-md-12 d-flex justify-content-center align-items-center ">
            <div className="col-md-6">
              <InputWrapper
                required={true}
                name={'selectedEmployee'}
                title={intl.formatMessage({ id: 'CHEERS_EMPLOYEE_TITLE' })}
              >
                <Select
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      height: '45px',
                    }),
                  }}
                  className="mt-1"
                  formatOptionLabel={formatOptionLabel}
                  placeholder="Seçiniz"
                  options={peopleList}
                  value={selectedEmployee}
                  onMenuScrollToBottom={handleMenuScrollToBottom}
                  onChange={(value: any) => {
                    setSelectedEmployee(value);
                    setFieldValue('userid', value?.value);
                  }}
                />
              </InputWrapper>
            </div>
            <div className="col-md-6">
              <InputWrapper
                required={true}
                name={'cheerstypeid'}
                title={intl.formatMessage({ id: 'CHEERS_TYPE_TITLE' })}
              >
                <Select
                  closeMenuOnSelect
                  isClearable={false}
                  name="cheerstypeid"
                  value={{ value: cheersType[0].value, label: cheersType[0].label }}
                  options={cheersType}
                  onChange={(option: any) => {
                    setCheersTypeOptions(option);
                  }}
                />
              </InputWrapper>
            </div>
          </div>
          <div className="row col-md-12">
            <InputWrapper
              required
              name={'description'}
              title={intl.formatMessage({ id: 'CHEERS_DESCRIPTION_TEXT_AREA_TITLE' })}
            >
              <textarea
                rows={3}
                className="form-control"
                name={'description'}
                value={values.description}
                onChange={handleChange}
                placeholder={intl.formatMessage({ id: 'TEXT_AREA_PLACEHOLDER' })}
              />
            </InputWrapper>
          </div>
          <div className="row col-md-12">
            {anyBadge && values.cheerstypeid > 0 && (
              <>
                <h3> {intl.formatMessage({ id: 'CHEERS_BADGE_SELECTION_AREA_TITLE' })}</h3>
                <h5 style={{ color: 'gray' }}>{intl.formatMessage({ id: 'CHEERS_BADGE_SELECTION_AREA_SUB_TITLE' })}</h5>
                <div className="cheersBlance">
                  {intl.formatMessage({ id: 'CHEERS_BLANCE' })}

                  <div className="cheersPriceInfo">
                    <div className="text">{userScoreData?.score}</div>
                    {/* <img
                      alt="coin"
                      width={16}
                      height={16}
                      className="rounded-2"
                      src={toAbsoluteUrl('/media/svg/coin/coin.svg')}
                    /> */}
                            <MingMonyIcon />

                  </div>
                </div>
              </>
            )}
            {!anyBadge && values.cheerstypeid > 0 && (
              <>
                <h3> {intl.formatMessage({ id: 'CHEERS_BADGE_SELECTION_AREA_TITLE' })}</h3>
                <h5 style={{ color: 'gray' }}>
                  {intl.formatMessage({ id: 'NO_CHEERS_BADGE_SELECTION_AREA_SUB_TITLE' })}
                </h5>
              </>
            )}
            <div>
              <hr />
              <BadgeSelection
                score={userScoreData?.score}
                isThereAnyBadge={isThereAnyBadge}
                badgeClicked={badgeClicked}
                cheerstypeid={cheersTypeOptions.value ?? 1}
                cheersbadgeidSelected={-1}
              />
            </div>
          </div>
          <Stack>
            <hr />
            <button
              type="submit"
              disabled={
                selectedEmployee.value === null ||
                values.cheerstypeid === 0 ||
                selectedBadgeId === 0 ||
                values.cheerstypeid === null ||
                selectedBadgeId === null ||
                values.cheerstypeid === undefined ||
                selectedBadgeId === undefined ||
                values.description === '' ||
                !anyBadge
              }
              className={`btn ${'btn-info'}`}
              style={{ alignSelf: 'center' }}
            >
              Gönder
            </button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
}
