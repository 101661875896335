import React, { FC, useEffect, useState } from 'react';
import { KTCard, toAbsoluteUrl } from '../../../../../_metronic/helpers';
import './_eventCard.scss';
import { RenderButton } from './RenderButton';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import tr from 'dayjs/locale/tr';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { IParticipant } from '../../../../../api/services/events/eventsEndpoints';
import { parse, differenceInSeconds } from 'date-fns';
dayjs.locale(tr);
dayjs.extend(relativeTime);

enum EventTime {
  now = 'Şu Anda',
  later = 'Sonra',
  before = 'Geçmiş',
}

const EventCard = ({ data }: any) => {
  const navigate = useNavigate();


  function mingFlowDurationRounded(stringDateTime: string | null): string {
    if (!stringDateTime) {
      return '';
    }

    let duration: Date;
    if (stringDateTime.length === 16) {
      duration = parse(stringDateTime, 'dd/MM/yyyy HH:mm', new Date());
    } else if (stringDateTime.length === 19) {
      duration = parse(stringDateTime, 'dd/MM/yyyy HH:mm:ss', new Date());
    } else {
      return '';
    }

    const now = new Date();
    const difference = now.getTime() - duration.getTime(); // Get difference in milliseconds

    const differenceInDays = Math.floor(difference / (1000 * 60 * 60 * 24));
    const differenceInHours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const differenceInMinutes = Math.floor((difference / (1000 * 60)) % 60);

    if (differenceInDays > 0) {
      return `${differenceInDays}g`;
    } else if (differenceInHours > 0) {
      return `${differenceInHours}s`;
    } else if (differenceInMinutes > 0) {
      return `${differenceInMinutes}d`;
    } else {
      return '1d';
    }
  }
  return (
    <KTCard className="p-8 event-card mb-8">
      <div className="d-flex gapp-4 align-items-center mb-8">
        <img
          src={'/media/svg/event.svg'}
          alt="dummy"
          width={50}
          height={50}
          className="rounded-2"
          style={{ objectFit: 'cover' }}
        />
        <div className="ms-4">
          <div className="department-title">
            <FormattedMessage
              id="HOME.EVENT_SHARED"
              defaultMessage={`${data?.nodename} bir etkinlik paylaştı.`}
              values={{ whoShared: data?.nodename }}
            />
          </div>
          <span className="department-subtitle text-gray-500">{mingFlowDurationRounded(data?.created)}</span>
        </div>
      </div>
      <KTCard className="p-8 event-card mb-8 ">
        {data?.mediauri !== null && data?.mediauri.length > 0 && (
          <div className="d-flex justify-content-center">
            <img
              src={data?.mediauri}
              alt="event_image"
              width={420}
              height={180}
              className="mb-8 rounded-2 w-100 h-100"
              style={{ objectFit: 'cover' }}
            />
          </div>
        )}

        <div className="event-title cursor-pointer" onClick={() => navigate(`/apps/events/detail/${data?.contentid}`)}>
          {data?.title}
        </div>
        <span className="event-subtitle text-gray-500">{data?.startdate}</span>
      </KTCard>
      <RenderButton
      data={data}
        id={data?.contentid}
        last_subscription_date={data?.lastsubscriptiondate}
        end_date={data.contentenddate}
        event_participant_count={data.cf01 != null
          &&  data.cf01! > 0 && data.remaininQquota === 0 ? data.userlimit
          : 0}
        isJoinData={data?.currentuserinteractions === 1}
        user_limit={data?.cf01}
        calendar_start_date={data?.startdate}
      />
    </KTCard>
  );
};

export default EventCard;
