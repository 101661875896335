import React, { FC, useState } from 'react';
import './_question.scss';

import { useIntl } from 'react-intl';
import ResultOption from '../ResultOption/ResultOption';

type Props = {
  question: any;
  index: number;
};

const ResultQuestion: FC<Props> = ({ question, index }) => {
  const [status, setStatus] = useState('');
  const intl = useIntl();

  const selectedOption = question?.options?.find(
    (i: any) => i.id.toString() === question?.user_answer?.game_question_option_id.toString(),
  );

  return (
    <div className=" d-flex flex-column justify-content-between">
      <div>
        <div className="d-flex align-items-center mb-8 gap-6">
          <div>
            <div className="question-info text-gray-500 fw-bold ">
              {index + 1}. Soru -{' '}
              {intl.formatMessage({
                id: 'SURVEYS.QUESTION_OPTION_RADIO_MESSAGE',
              })}
            </div>
            <div className="text-gray-800 fw-bold fs-4">{question.title}</div>
            <div className="text-gray-500 fw-bold fs-6">
              <span className="text-danger">{status === 'not-completed' && 'Bu soruyu cevaplamadınız.'}</span>
              {status === 'correct' && `Seçiminiz ${selectedOption?.title} - (Doğru)`}
              {status === 'wrong' && `Seçiminiz ${selectedOption?.title} - (Yanlış)`}
            </div>
          </div>
        </div>
        <div className="mb-4">
          {question.options?.map((option: any, index: number) => (
            <ResultOption key={option.id} option={option} userAnswer={question?.user_answer} setStatus={setStatus} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ResultQuestion;
