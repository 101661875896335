import { FC, useEffect } from 'react';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperBigButtonPrev from './SwiperBigButtonPrev';
import SwiperBigButtonNext from './SwiperBigButtonNext';

type Props = {
  images: any;
  id: string;
  setIsOpen: Function;
  initialSlide?: number;
  toggle?: Function;
};

const ImagesSliderModal: FC<Props> = ({ images, id, setIsOpen, initialSlide, toggle }) => {
  useEffect(() => {
    if (document.body.getElementsByClassName(id).length > 0)
      document.body.getElementsByClassName(id)[0].classList.add('modal-open');
    return () => {
      if (document.body.getElementsByClassName(id).length > 0)
        document.body.getElementsByClassName(id)[0].classList.remove('modal-open');
    };
  }, []);

  return (
    <div>
      <div
        className={'modal d-flex justify-content-center align-items-center show' + id}
        id={id}
        role="dialog"
        tabIndex={-1}
        aria-modal="true"
        onClick={() => {
          if (toggle) toggle!();
          setIsOpen(false);
        }}
      >
        {/* begin::Modal dialog */}

        {/* {headerComponent} */}
        <div className="position-absolute top-0  end-0 mx-12 my-6  ">
          <div
            onClick={() => setIsOpen(false)}
            className="text-dark fs-2 bg-white border px-3 py-1 rounded cursor-pointer"
          >
            <i className="bi bi-x-lg fs-3 text-black"></i>
          </div>
        </div>

        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          initialSlide={initialSlide ?? 0}
          modules={[Pagination]}
          allowTouchMove={false}
          draggable={false}
          className="text-center my-8 h-75"
        >
          {images?.map((i: any, index: number) => (
            <SwiperSlide
              key={index}
              className="rounded-4 cursor-pointer d-flex justify-content-center align-items-center"
            >
              {images?.length > 1 && index !== 0 && <SwiperBigButtonPrev />}
              <div className="h-100 w-75">
                <img
                  className="rounded-4 w-100 h-100"
                  style={{
                    objectFit: 'contain',
                  }}
                  src={i?.mediauri ?? i}
                  alt="ads_list_image"
                />
              </div>
              {images?.length > 1 && index !== images.length - 1 && <SwiperBigButtonNext />}
            </SwiperSlide>
          ))}
        </Swiper>
        {/* begin::Modal Backdrop */}
        {/* end::Modal Backdrop */}
      </div>
      <div className="modal-backdrop fade show"></div>
    </div>
  );
};

export default ImagesSliderModal;
