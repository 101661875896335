import * as React from 'react';

const SvgFifthIcon = (props: any) => (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="8" cy="8" r="7" fill="#C4C4C4"/>
<circle cx="8" cy="8" r="7" fill="url(#paint0_linear_5379_32985)"/>
<circle cx="8" cy="8" r="7" fill="url(#paint1_linear_5379_32985)"/>
<circle cx="8" cy="8" r="7" fill="url(#paint2_linear_5379_32985)"/>
<circle cx="8" cy="8" r="7" fill="url(#paint3_linear_5379_32985)"/>
<circle cx="8" cy="8" r="7" fill="url(#paint4_linear_5379_32985)"/>
<circle cx="5" cy="5" r="5" transform="matrix(1 0 0 -1 3 13)" fill="url(#paint5_linear_5379_32985)"/>
<path d="M8.03516 11.0923C7.63672 11.0923 7.26904 11.019 6.93213 10.8726C6.59521 10.7231 6.32715 10.5122 6.12793 10.2397C5.93164 9.96436 5.83789 9.64062 5.84668 9.26855L5.85547 9.25098L7.21777 9.16748C7.21777 9.43408 7.29248 9.64355 7.44189 9.7959C7.59131 9.94531 7.78906 10.02 8.03516 10.02C8.31348 10.02 8.51562 9.91895 8.6416 9.7168C8.76758 9.51465 8.83057 9.25977 8.83057 8.95215C8.83057 8.59766 8.76172 8.31494 8.62402 8.104C8.48926 7.89014 8.27832 7.7832 7.99121 7.7832C7.77734 7.7832 7.60889 7.82715 7.48584 7.91504C7.36279 8.00293 7.2749 8.11572 7.22217 8.25342L5.96533 8.17871L6.33447 4.60156H10.1094V6.11768H9.27441L9.15137 5.70898H7.48584L7.31006 7.08887C7.38916 7.0332 7.47852 6.9834 7.57812 6.93945C7.68066 6.89551 7.79199 6.86035 7.91211 6.83398C8.03223 6.80762 8.15674 6.79443 8.28564 6.79443C8.70166 6.78564 9.05469 6.86621 9.34473 7.03613C9.63477 7.20312 9.85449 7.44922 10.0039 7.77441C10.1533 8.09668 10.228 8.48633 10.228 8.94336C10.228 9.34766 10.1431 9.7124 9.97314 10.0376C9.80615 10.3628 9.55859 10.6206 9.23047 10.811C8.90527 10.9985 8.50684 11.0923 8.03516 11.0923Z" fill="#DCB8FF"/>
<defs>
<linearGradient id="paint0_linear_5379_32985" x1="8" y1="1" x2="8" y2="15" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFDD86"/>
<stop offset="1" stop-color="#FFC32A"/>
</linearGradient>
<linearGradient id="paint1_linear_5379_32985" x1="8" y1="1" x2="8" y2="15" gradientUnits="userSpaceOnUse">
<stop stop-color="#E7E7E7"/>
<stop offset="1" stop-color="#B5B5B5"/>
</linearGradient>
<linearGradient id="paint2_linear_5379_32985" x1="8" y1="1" x2="8" y2="15" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFA37B"/>
<stop offset="1" stop-color="#CF5D2D"/>
</linearGradient>
<linearGradient id="paint3_linear_5379_32985" x1="8" y1="1" x2="8" y2="15" gradientUnits="userSpaceOnUse">
<stop stop-color="#5D308C"/>
<stop offset="1" stop-color="#301948"/>
</linearGradient>
<linearGradient id="paint4_linear_5379_32985" x1="8" y1="1" x2="8" y2="15" gradientUnits="userSpaceOnUse">
<stop stop-color="#793FB5"/>
<stop offset="1" stop-color="#5D308C"/>
</linearGradient>
<linearGradient id="paint5_linear_5379_32985" x1="5" y1="0" x2="5" y2="10" gradientUnits="userSpaceOnUse">
<stop stop-color="#793FB5"/>
<stop offset="1" stop-color="#5D308C"/>
</linearGradient>
</defs>
</svg>

    
);
export default SvgFifthIcon;



