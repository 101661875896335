import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Module {
  moduledesc: string;
  moduleid: number;
  iconurl: string;
  sortorder: number;
  textcolor: string;
}

interface Modules {
  modules: {
    [key: string]: Module;
  };
  isLoading: boolean;
}

const initialState: Modules = {
  modules: {},
  isLoading: false,
};

const moduleSlice = createSlice({
  name: 'module',
  initialState,
  reducers: {
    setActiveModules: (state, action: PayloadAction<Module[]>) => {
      if (action.payload) {
        const activeModules: { [key: string]: Module } = {};

        // Gelen modüllerin 'moduledesc' değerine göre kontrol yapıyoruz
        action.payload.forEach((module) => {
          const key = module.moduledesc.toLowerCase().replace(/\s+/g, '_');
          activeModules[key] = {
            moduledesc: module.moduledesc,
            moduleid: module.moduleid,
            iconurl: module.iconurl,
            sortorder: module.sortorder,
            textcolor: module.textcolor,
          };
        });

        state.modules = activeModules;
        state.isLoading = false;
      }
    },
    clearActiveModules: (state) => {
      state.modules = {};
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setActiveModules, clearActiveModules, setLoading } = moduleSlice.actions;

export const moduleSelector = (state: { module: Modules }) => state.module;

export default moduleSlice.reducer;
