import { useIntl } from 'react-intl';
import { useGetSecondHandsListQuery } from '../../../../api/services/secondhands/secondHandsEndpoints';
import { useCallback, useEffect, useState } from 'react';
import { useGetLeaderboardQuery, useGetUserRankQuery } from './services/leadershipEndpoints';
import { useAppSelector } from '../../../../api/store/hooks';
import { convertNumberToMonthString } from '../leadership_module/utils/utils';
import ModuleTemplate from '../_global/components/ModuleTemplate/ModuleTemplate';
import RankingCard from './components/RankingCard';
import { RankingFilter } from './components/RankingFilter';
import { calculateDates, getFormattedCurrentFirstDays } from './utils/utils';
import { authSelector } from '../../../../api/features/auth/authSlice';

type QueryType = {
  page: number;
  pagesize: number;
  startdate: string;
  enddate: string;
};

type UserRankQueryType = {
  userid: number;
  startdate: string;
  enddate: string;
};
export default function CheersLeadershipList() {
  const [page, setPage] = useState(1);
  const intl = useIntl();
  const currentDate = new Date();
  const [query, setQuery] = useState<QueryType>({
    page: page,
    pagesize: 100,
    startdate: `01/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`.toString(),
    enddate: `${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}`.toString(),
  });

  const [userRankquery, setUserRankQuery] = useState<UserRankQueryType>({
    userid: 0,
    startdate: getFormattedCurrentFirstDays().startDate,
    enddate: getFormattedCurrentFirstDays().endDate,
  });
  const {
    data: data,
    isLoading: isLoading,
    isFetching: isFetching,
    refetch,
  } = useGetLeaderboardQuery(query, { skip: false });
  const { currentUser } = useAppSelector((state) => authSelector(state.auth));

  const list = useAppSelector((state: any) => state.auth.list);
  const [month, setMonth] = useState<any>({
    value: new Date().getMonth() + 1,
    label: intl.formatMessage({ id: `MONTH_${new Date().getMonth() + 1}` }),
    labelIdentifier: convertNumberToMonthString(new Date().getMonth() + 1),
    year: new Date().getFullYear(),
    yearLabel: new Date().getFullYear().toString(),
  });

  useEffect(() => {
    const dates = calculateDates(month.value, month.year);
    setQuery({ startdate: dates.startdate, enddate: dates.enddate, page: 1, pagesize: 100 });
  }, [month]);

  useEffect(() => {
    refetch();
  }, [query, refetch]);

  useEffect(() => {
    setQuery((prev) => ({ ...prev, page }));
  }, [page]);

  const user = data?.leaderboard?.find(
    (element: { userId: number }) => element.userId === currentUser?.usersecret?.userid,
  );
  return (
    <ModuleTemplate
      title="CHEERS_LEADERSHIP_PAGE_TITLE"
      subtitle="CHEERS_LEADERSHIP_PAGE_DESCRIPTION"
      isLoading={isLoading}
      isEmpty={data?.leaderboard?.length === 0}
      topPersistComponent={<RankingFilter list={list} month={month} setMonth={setMonth} />}
      empty={{
        imgPath: '/media/svg/empty/games/GamesResultEmpty.svg',
        title: 'CHEERS_LEADERSHIP_PAGE_EMPTY_TITLE',
        directSubContent: 'EMPTY_TEXT',
        className: 'mt-10',
      }}
    >
      <RankingCard userRank={user} data={data} month={month} page={page} setPage={setPage} isLoading={isLoading} />
    </ModuleTemplate>
  );
}
