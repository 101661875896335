import React from 'react';
import { useSwiper } from 'swiper/react';
import { KTIcon } from '../../../../../../../_metronic/helpers';

const SwiperBigButtonPrev = () => {
  const swiper = useSwiper();
  return (
    <button
      className="btn px-1 py-1 swiper-prev-button"
      onClick={(e) => {
        e.stopPropagation();
        swiper.slidePrev();
      }}
    >
      <KTIcon iconName="double-left" iconOverride="text-white" className="fs-6x p-0" />
    </button>
  );
};

export default SwiperBigButtonPrev;
