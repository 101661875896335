/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../api/store/hooks';
import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { setHeaderCode } from '../../../../api/features/auth/authSlice';
import { useSmsForgotPasswordMutation, useSmsVerificationMutation } from '../../../../api/services/auth/authEndpoints';
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics';
import LoginCreatePassword from './LoginCreatePassword';
import { okayMessage } from '../../apps/_global/components/Modal/ConfirmMessage';

const initialValues = {
  verificationCode: '',
  contact: '',
};

const Schema = Yup.object().shape({
  verificationCode: Yup.string().required('Kod girilmesi zorunludur.'),
});

export default function SmsVerificationInput(userData: any, { username }: { username: string }) {
  const [smsVerification, { isLoading: loading }] = useSmsVerificationMutation();
  const [smsForgotPassword] = useSmsForgotPasswordMutation();
  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [sessionkey, setSessionkey] = useState('');
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: async (values) => {
      try {
        const response = await smsVerification({
          otpid: otpId,
          otpcode: values.verificationCode,
          username: userData.username,
        }).unwrap();
        setSessionkey(response?.newpasskey);
        setVerificationSuccess(true);
      } catch (error: any) {
        okayMessage('Hata', `${error.data.exMsg}`);
        formik.setFieldError('verificationCode', error.data.exMsg);
      }
    },
  });

  const handleForgotPassword = async () => {
    try {
      const response = await smsForgotPassword({
        username: userData.username,
      }).unwrap();
      if (response.otpseconds) {
        setSeconds(response.otpseconds);
        setOtpId(response.otpid);
      }
    } catch (error) {
      console.error('Forgot password failed:', error);
    }
  };

  const [seconds, setSeconds] = useState(
    userData !== undefined && userData !== null ? userData?.userData?.otpseconds : 180,
  );
  const [otpId, setOtpId] = useState(userData !== undefined && userData !== null ? userData?.userData?.otpid : 0);

  useEffect(() => {
    if (seconds > 0) {
      const intervalId = setInterval(() => {
        setSeconds((prevSeconds: number) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [seconds]);

  const formatTime = (timeInSeconds: any) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  console.log(userData);

  if (verificationSuccess) {
    return (
      <LoginCreatePassword
        consentsList={userData?.userData?.requireddocs ?? []}
        username={userData.username}
        sessionkey={sessionkey}
      />
    );
  }
  return (
    <div className="col-md-6 d-flex flex-column justify-content-center align-items-center py-10 ">
      <form className="d-flex flex-column" onSubmit={formik.handleSubmit}>
        <div className="fs-3x fw-bolder mt-10" style={{ color: '#5E13D9' }}>
          Girişini
        </div>
        <div className="fs-3x fw-bolder mb-10 ">Doğrula</div>
        <div className="mb-3 mt-10" style={{ width: '30rem' }}>
          <label htmlFor="Email" className="form-label">
            Telefonunuza SMS olarak gönderilen doğrulama kodunu giriniz
          </label>
          <input
            placeholder="Sms Doğruma kodu"
            {...formik.getFieldProps('verificationCode')}
            className={clsx('form-control bg-transparent mt-5')}
            type="verificationCode"
            name="verificationCode"
            autoComplete="off"
          />
          {formik.touched.verificationCode && formik.errors.verificationCode && (
            <div className="fv-plugins-message-container text-danger">
              <span role="alert" className="--bs-danger-text-emphasis">
                {formik.errors.verificationCode}
              </span>
            </div>
          )}
        </div>
        {seconds !== 0 ? (
          <span
            className="mt5 center fs-5 mt-5 fw-semibold"
            style={{
              justifyContent: 'center',
              alignSelf: 'center',
              color: 'grey',
            }}
          >
            {' '}
            {formatTime(seconds)}
          </span>
        ) : (
          <a
            className="mt5 center fs-6 mt-5 fw-bold cursor-pointer"
            style={{
              justifyContent: 'center',
              alignSelf: 'center',
              color: '#7239EA',
            }}
            onClick={handleForgotPassword}
          >
            Doğrulama Kodunu Yeniden Gönder
          </a>
        )}

        <button
          type="submit"
          className={seconds === 0 ? 'btn btn-secondary mt-5' : 'btn btn-info mt-5'}
          style={{ width: '30rem' }}
          disabled={formik.isSubmitting || !formik.isValid || seconds === 0}
        >
          {loading ? (
            <span className="spinner-border spinner-border-sm align-middle" />
          ) : (
            <> {seconds === 0 ? 'Kod Geçerliliği Zaman Aşımına Uğradı' : 'Devam Et'}</>
          )}
        </button>
        <img
          className="mt-17"
          alt="Logo"
          src={toAbsoluteUrl('/media/logos/logo_renkli.png')}
          style={{
            width: '140px',
            height: '75px',
            justifyContent: 'center',
            alignSelf: 'center',
            objectFit: 'contain',
          }}
        />
      </form>
    </div>
  );
}
