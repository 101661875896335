import api from '../../../../api/api';

interface IProfileDetail {
  user_id: number;
  ownerBadges: boolean;
  eventLimit: number;
  badgeType: string;
}

interface IPostList {
  userid: string;
  page: number;
}

export const profileApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProfileDetail: builder.query<any, IProfileDetail>({
      query: ({ user_id, ownerBadges, eventLimit, badgeType }) => {
        return {
          url: `/profile/detail?user_id=${user_id}&ownerBadges=${ownerBadges}&eventLimit=${eventLimit}&badgeType=${badgeType}`,
        };
      },
    }),
    getProfileOrganization: builder.query<any, any>({
      query: (user_id) => {
        return {
          url: `/profile/organization/${user_id}`,
        };
      },
      providesTags: ['OrganizationChart'],
    }),
    uploadPhoto: builder.mutation<any, any>({
      query: (formData) => {
        return {
          url: `/upload`,
          method: 'POST',
          body: formData,
          formData: true,
        };
      },
      invalidatesTags: ['CurrentUser', 'ProfilePosts', 'OrganizationChart'],
    }),
    contentBlock: builder.mutation<any, any>({
      query: (data: any) => {
        const { content_type, content_id } = data || {};
        return {
          url: `/content/block?content_type=${content_type}&content_id=${content_id}`,
          method: 'POST',
        };
      },
      invalidatesTags: (): any => [{ type: 'PostComment' }],
    }),
    saveFeeling: builder.mutation<any, any>({
      query: ({ feel_selected }: any) => {
        return {
          url: `/dailymood`,
          method: 'POST',
          body: {
            'moodresponseid':feel_selected,
          },
        };
      },
    }),
    getFeeling: builder.query<any, any>({
      query: (user_id) => {
        return {
          url: `/dailymood`,
          method: 'GET',
        };
      },
    }),
    getProfilePostList: builder.query<any, any>({
      query: (user_id) => {
        return {
          url: `/profile/user/${user_id}`,
        };
      },
      providesTags: ['ProfilePosts'],
    }),
    getProfilePost: builder.query<any, any>({
      query: (user_id) => {
        return {
          url: `/profile/user/${user_id}/posts`,
        };
      },
      providesTags: ['ProfilePosts'],
    }),
    getUpdateProfile: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: `/profile/picture/` + data.id,
          method: 'PUT',
          body: data,
        };
      },
    }),
    getDeleteProfilePicture: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: `/profile/picture/` + data.id,
          method: 'DELETE',
        };
      },
    }),
    getUpdateProfileCover: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: `/profile/cover/` + data.id,
          method: 'PUT',
          body: data,
        };
      },
    }),
    getDeleteProfileCover: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: `/profile/cover/` + data.id,
          method: 'DELETE',
        };
      },
    }),
    getUpdateProfileEdit: builder.mutation<any, any>({
      query: (data: any) => {
        return {
          url: `/profile/user/`+data.id,
          method: 'PUT',
          body: data.body,
        };
      },
    }),
  }),
});

export const {
  useGetUpdateProfileEditMutation,
  useGetUpdateProfileCoverMutation,
  useGetDeleteProfileCoverMutation,
  useGetDeleteProfilePictureMutation,
  useGetProfileDetailQuery,
  useUploadPhotoMutation,
  useGetProfileOrganizationQuery,
  useContentBlockMutation,
  useSaveFeelingMutation,
  useGetProfilePostListQuery,
  useGetProfilePostQuery,
  useGetUpdateProfileMutation,
  useGetFeelingQuery,
} = profileApi;

export default profileApi;
