import React from 'react';

const SvgLine = (props: any) => {
  return (
    <svg
      width={props.width ? props.width : '100%'}
      height="7"
      viewBox="0 0 631 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.154143 0.138916C0.353564 -0.0520961 0.670073 -0.045279 0.861085 0.154143L2.94277 2.32748C3.37384 2.77752 3.37384 3.50248 2.94277 3.95253L0.861085 6.12586C0.670073 6.32528 0.353564 6.3321 0.154143 6.14109C-0.045279 5.95007 -0.0520961 5.63356 0.138916 5.43414L2.2206 3.26081C2.28123 3.19752 2.28123 3.08248 2.2206 3.01919L0.138916 0.845859C-0.0520961 0.646437 -0.045279 0.329928 0.154143 0.138916Z"
        fill="url(#paint0_linear_5730_27732)"
      />
      <path
        d="M15.8311 0.138916C16.0306 -0.0520961 16.3471 -0.045279 16.5381 0.154143L18.6198 2.32748C19.0508 2.77752 19.0508 3.50248 18.6198 3.95253L16.5381 6.12586C16.3471 6.32528 16.0306 6.3321 15.8311 6.14109C15.6317 5.95007 15.6249 5.63356 15.8159 5.43414L17.8976 3.26081C17.9582 3.19752 17.9582 3.08248 17.8976 3.01919L15.8159 0.845859C15.6249 0.646437 15.6317 0.329928 15.8311 0.138916Z"
        fill="url(#paint1_linear_5730_27732)"
      />
      <path
        d="M32.2114 0.154143C32.0203 -0.045279 31.7038 -0.0520961 31.5044 0.138916C31.305 0.329928 31.2982 0.646437 31.4892 0.845859L33.5709 3.01919C33.6315 3.08248 33.6315 3.19752 33.5709 3.26081L31.4892 5.43414C31.2982 5.63356 31.305 5.95007 31.5044 6.14109C31.7038 6.3321 32.0203 6.32528 32.2114 6.12586L34.293 3.95253C34.7241 3.50248 34.7241 2.77752 34.293 2.32748L32.2114 0.154143Z"
        fill="url(#paint2_linear_5730_27732)"
      />
      <path
        d="M47.1814 0.138916C47.3808 -0.0520961 47.6973 -0.045279 47.8884 0.154143L49.97 2.32748C50.4011 2.77752 50.4011 3.50248 49.97 3.95253L47.8884 6.12586C47.6973 6.32528 47.3808 6.3321 47.1814 6.14109C46.982 5.95007 46.9752 5.63356 47.1662 5.43414L49.2479 3.26081C49.3085 3.19752 49.3085 3.08248 49.2479 3.01919L47.1662 0.845859C46.9752 0.646437 46.982 0.329928 47.1814 0.138916Z"
        fill="url(#paint3_linear_5730_27732)"
      />
      <path
        d="M63.5616 0.154143C63.3706 -0.045279 63.0541 -0.0520961 62.8547 0.138916C62.6553 0.329928 62.6484 0.646437 62.8395 0.845859L64.9211 3.01919C64.9818 3.08248 64.9818 3.19752 64.9211 3.26081L62.8395 5.43414C62.6484 5.63356 62.6553 5.95007 62.8547 6.14109C63.0541 6.3321 63.3706 6.32528 63.5616 6.12586L65.6433 3.95253C66.0744 3.50248 66.0744 2.77752 65.6433 2.32748L63.5616 0.154143Z"
        fill="url(#paint4_linear_5730_27732)"
      />
      <path
        d="M78.5317 0.138916C78.7311 -0.0520961 79.0476 -0.045279 79.2386 0.154143L81.3203 2.32748C81.7514 2.77752 81.7514 3.50248 81.3203 3.95253L79.2386 6.12586C79.0476 6.32528 78.7311 6.3321 78.5317 6.14109C78.3323 5.95007 78.3254 5.63356 78.5165 5.43414L80.5981 3.26081C80.6588 3.19752 80.6588 3.08248 80.5981 3.01919L78.5165 0.845859C78.3254 0.646437 78.3323 0.329928 78.5317 0.138916Z"
        fill="url(#paint5_linear_5730_27732)"
      />
      <path
        d="M94.9156 0.154143C94.7246 -0.045279 94.4081 -0.0520961 94.2087 0.138916C94.0093 0.329928 94.0025 0.646437 94.1935 0.845859L96.2752 3.01919C96.3358 3.08248 96.3358 3.19752 96.2752 3.26081L94.1935 5.43414C94.0025 5.63356 94.0093 5.95007 94.2087 6.14109C94.4081 6.3321 94.7246 6.32528 94.9156 6.12586L96.9973 3.95253C97.4284 3.50248 97.4284 2.77752 96.9973 2.32748L94.9156 0.154143Z"
        fill="url(#paint6_linear_5730_27732)"
      />
      <path
        d="M109.886 0.138916C110.085 -0.0520961 110.402 -0.045279 110.593 0.154143L112.674 2.32748C113.105 2.77752 113.105 3.50248 112.674 3.95253L110.593 6.12586C110.402 6.32528 110.085 6.3321 109.886 6.14109C109.686 5.95007 109.679 5.63356 109.87 5.43414L111.952 3.26081C112.013 3.19752 112.013 3.08248 111.952 3.01919L109.87 0.845859C109.679 0.646437 109.686 0.329928 109.886 0.138916Z"
        fill="url(#paint7_linear_5730_27732)"
      />
      <path
        d="M126.266 0.154143C126.075 -0.045279 125.758 -0.0520961 125.559 0.138916C125.36 0.329928 125.353 0.646437 125.544 0.845859L127.625 3.01919C127.686 3.08248 127.686 3.19752 127.625 3.26081L125.544 5.43414C125.353 5.63356 125.36 5.95007 125.559 6.14109C125.758 6.3321 126.075 6.32528 126.266 6.12586L128.348 3.95253C128.779 3.50248 128.779 2.77752 128.348 2.32748L126.266 0.154143Z"
        fill="url(#paint8_linear_5730_27732)"
      />
      <path
        d="M141.236 0.138916C141.435 -0.0520961 141.752 -0.045279 141.943 0.154143L144.025 2.32748C144.456 2.77752 144.456 3.50248 144.025 3.95253L141.943 6.12586C141.752 6.32528 141.435 6.3321 141.236 6.14109C141.037 5.95007 141.03 5.63356 141.221 5.43414L143.302 3.26081C143.363 3.19752 143.363 3.08248 143.302 3.01919L141.221 0.845859C141.03 0.646437 141.037 0.329928 141.236 0.138916Z"
        fill="url(#paint9_linear_5730_27732)"
      />
      <path
        d="M157.616 0.154143C157.425 -0.045279 157.109 -0.0520961 156.909 0.138916C156.71 0.329928 156.703 0.646437 156.894 0.845859L158.976 3.01919C159.036 3.08248 159.036 3.19752 158.976 3.26081L156.894 5.43414C156.703 5.63356 156.71 5.95007 156.909 6.14109C157.109 6.3321 157.425 6.32528 157.616 6.12586L159.698 3.95253C160.129 3.50248 160.129 2.77752 159.698 2.32748L157.616 0.154143Z"
        fill="url(#paint10_linear_5730_27732)"
      />
      <path
        d="M172.586 0.138916C172.786 -0.0520961 173.102 -0.045279 173.293 0.154143L175.375 2.32748C175.806 2.77752 175.806 3.50248 175.375 3.95253L173.293 6.12586C173.102 6.32528 172.786 6.3321 172.586 6.14109C172.387 5.95007 172.38 5.63356 172.571 5.43414L174.653 3.26081C174.713 3.19752 174.713 3.08248 174.653 3.01919L172.571 0.845859C172.38 0.646437 172.387 0.329928 172.586 0.138916Z"
        fill="url(#paint11_linear_5730_27732)"
      />
      <path
        d="M188.97 0.154143C188.779 -0.045279 188.463 -0.0520961 188.263 0.138916C188.064 0.329928 188.057 0.646437 188.248 0.845859L190.33 3.01919C190.39 3.08248 190.39 3.19752 190.33 3.26081L188.248 5.43414C188.057 5.63356 188.064 5.95007 188.263 6.14109C188.463 6.3321 188.779 6.32528 188.97 6.12586L191.052 3.95253C191.483 3.50248 191.483 2.77752 191.052 2.32748L188.97 0.154143Z"
        fill="url(#paint12_linear_5730_27732)"
      />
      <path
        d="M203.94 0.138916C204.14 -0.0520961 204.456 -0.045279 204.647 0.154143L206.729 2.32748C207.16 2.77752 207.16 3.50248 206.729 3.95253L204.647 6.12586C204.456 6.32528 204.14 6.3321 203.94 6.14109C203.741 5.95007 203.734 5.63356 203.925 5.43414L206.007 3.26081C206.067 3.19752 206.067 3.08248 206.007 3.01919L203.925 0.845859C203.734 0.646437 203.741 0.329928 203.94 0.138916Z"
        fill="url(#paint13_linear_5730_27732)"
      />
      <path
        d="M220.32 0.154143C220.129 -0.045279 219.813 -0.0520961 219.613 0.138916C219.414 0.329928 219.407 0.646437 219.598 0.845859L221.68 3.01919C221.741 3.08248 221.741 3.19752 221.68 3.26081L219.598 5.43414C219.407 5.63356 219.414 5.95007 219.613 6.14109C219.813 6.3321 220.129 6.32528 220.32 6.12586L222.402 3.95253C222.833 3.50248 222.833 2.77752 222.402 2.32748L220.32 0.154143Z"
        fill="url(#paint14_linear_5730_27732)"
      />
      <path
        d="M220.457 0.138916C220.656 -0.0520961 220.973 -0.045279 221.164 0.154143L223.246 2.32748C223.677 2.77752 223.677 3.50248 223.246 3.95253L221.164 6.12586C220.973 6.32528 220.656 6.3321 220.457 6.14109C220.257 5.95007 220.251 5.63356 220.442 5.43414L222.523 3.26081C222.584 3.19752 222.584 3.08248 222.523 3.01919L220.442 0.845859C220.251 0.646437 220.257 0.329928 220.457 0.138916Z"
        fill="url(#paint15_linear_5730_27732)"
      />
      <path
        d="M236.134 0.138916C236.333 -0.0520961 236.65 -0.045279 236.841 0.154143L238.923 2.32748C239.354 2.77752 239.354 3.50248 238.923 3.95253L236.841 6.12586C236.65 6.32528 236.333 6.3321 236.134 6.14109C235.934 5.95007 235.928 5.63356 236.119 5.43414L238.2 3.26081C238.261 3.19752 238.261 3.08248 238.2 3.01919L236.119 0.845859C235.928 0.646437 235.934 0.329928 236.134 0.138916Z"
        fill="url(#paint16_linear_5730_27732)"
      />
      <path
        d="M252.514 0.154143C252.323 -0.045279 252.007 -0.0520961 251.807 0.138916C251.608 0.329928 251.601 0.646437 251.792 0.845859L253.874 3.01919C253.934 3.08248 253.934 3.19752 253.874 3.26081L251.792 5.43414C251.601 5.63356 251.608 5.95007 251.807 6.14109C252.007 6.3321 252.323 6.32528 252.514 6.12586L254.596 3.95253C255.027 3.50248 255.027 2.77752 254.596 2.32748L252.514 0.154143Z"
        fill="url(#paint17_linear_5730_27732)"
      />
      <path
        d="M267.484 0.138916C267.684 -0.0520961 268 -0.045279 268.191 0.154143L270.273 2.32748C270.704 2.77752 270.704 3.50248 270.273 3.95253L268.191 6.12586C268 6.32528 267.684 6.3321 267.484 6.14109C267.285 5.95007 267.278 5.63356 267.469 5.43414L269.551 3.26081C269.611 3.19752 269.611 3.08248 269.551 3.01919L267.469 0.845859C267.278 0.646437 267.285 0.329928 267.484 0.138916Z"
        fill="url(#paint18_linear_5730_27732)"
      />
      <path
        d="M283.864 0.154143C283.673 -0.045279 283.357 -0.0520961 283.157 0.138916C282.958 0.329928 282.951 0.646437 283.142 0.845859L285.224 3.01919C285.284 3.08248 285.284 3.19752 285.224 3.26081L283.142 5.43414C282.951 5.63356 282.958 5.95007 283.157 6.14109C283.357 6.3321 283.673 6.32528 283.864 6.12586L285.946 3.95253C286.377 3.50248 286.377 2.77752 285.946 2.32748L283.864 0.154143Z"
        fill="url(#paint19_linear_5730_27732)"
      />
      <path
        d="M298.834 0.138916C299.034 -0.0520961 299.35 -0.045279 299.541 0.154143L301.623 2.32748C302.054 2.77752 302.054 3.50248 301.623 3.95253L299.541 6.12586C299.35 6.32528 299.034 6.3321 298.834 6.14109C298.635 5.95007 298.628 5.63356 298.819 5.43414L300.901 3.26081C300.961 3.19752 300.961 3.08248 300.901 3.01919L298.819 0.845859C298.628 0.646437 298.635 0.329928 298.834 0.138916Z"
        fill="url(#paint20_linear_5730_27732)"
      />
      <path
        d="M315.218 0.154143C315.027 -0.045279 314.711 -0.0520961 314.511 0.138916C314.312 0.329928 314.305 0.646437 314.496 0.845859L316.578 3.01919C316.638 3.08248 316.638 3.19752 316.578 3.26081L314.496 5.43414C314.305 5.63356 314.312 5.95007 314.511 6.14109C314.711 6.3321 315.027 6.32528 315.218 6.12586L317.3 3.95253C317.731 3.50248 317.731 2.77752 317.3 2.32748L315.218 0.154143Z"
        fill="url(#paint21_linear_5730_27732)"
      />
      <path
        d="M330.188 0.138916C330.388 -0.0520961 330.704 -0.045279 330.895 0.154143L332.977 2.32748C333.408 2.77752 333.408 3.50248 332.977 3.95253L330.895 6.12586C330.704 6.32528 330.388 6.3321 330.188 6.14109C329.989 5.95007 329.982 5.63356 330.173 5.43414L332.255 3.26081C332.315 3.19752 332.315 3.08248 332.255 3.01919L330.173 0.845859C329.982 0.646437 329.989 0.329928 330.188 0.138916Z"
        fill="url(#paint22_linear_5730_27732)"
      />
      <path
        d="M346.569 0.154143C346.378 -0.045279 346.061 -0.0520961 345.862 0.138916C345.662 0.329928 345.655 0.646437 345.846 0.845859L347.928 3.01919C347.989 3.08248 347.989 3.19752 347.928 3.26081L345.846 5.43414C345.655 5.63356 345.662 5.95007 345.862 6.14109C346.061 6.3321 346.378 6.32528 346.569 6.12586L348.65 3.95253C349.081 3.50248 349.081 2.77752 348.65 2.32748L346.569 0.154143Z"
        fill="url(#paint23_linear_5730_27732)"
      />
      <path
        d="M361.539 0.138916C361.738 -0.0520961 362.055 -0.045279 362.246 0.154143L364.327 2.32748C364.758 2.77752 364.758 3.50248 364.327 3.95253L362.246 6.12586C362.055 6.32528 361.738 6.3321 361.539 6.14109C361.339 5.95007 361.332 5.63356 361.523 5.43414L363.605 3.26081C363.666 3.19752 363.666 3.08248 363.605 3.01919L361.523 0.845859C361.332 0.646437 361.339 0.329928 361.539 0.138916Z"
        fill="url(#paint24_linear_5730_27732)"
      />
      <path
        d="M377.919 0.154143C377.728 -0.045279 377.411 -0.0520961 377.212 0.138916C377.013 0.329928 377.006 0.646437 377.197 0.845859L379.278 3.01919C379.339 3.08248 379.339 3.19752 379.278 3.26081L377.197 5.43414C377.006 5.63356 377.013 5.95007 377.212 6.14109C377.411 6.3321 377.728 6.32528 377.919 6.12586L380.001 3.95253C380.432 3.50248 380.432 2.77752 380.001 2.32748L377.919 0.154143Z"
        fill="url(#paint25_linear_5730_27732)"
      />
      <path
        d="M392.889 0.138916C393.088 -0.0520961 393.405 -0.045279 393.596 0.154143L395.678 2.32748C396.109 2.77752 396.109 3.50248 395.678 3.95253L393.596 6.12586C393.405 6.32528 393.088 6.3321 392.889 6.14109C392.69 5.95007 392.683 5.63356 392.874 5.43414L394.955 3.26081C395.016 3.19752 395.016 3.08248 394.955 3.01919L392.874 0.845859C392.683 0.646437 392.69 0.329928 392.889 0.138916Z"
        fill="url(#paint26_linear_5730_27732)"
      />
      <path
        d="M409.273 0.154143C409.082 -0.045279 408.765 -0.0520961 408.566 0.138916C408.367 0.329928 408.36 0.646437 408.551 0.845859L410.632 3.01919C410.693 3.08248 410.693 3.19752 410.632 3.26081L408.551 5.43414C408.36 5.63356 408.367 5.95007 408.566 6.14109C408.765 6.3321 409.082 6.32528 409.273 6.12586L411.355 3.95253C411.786 3.50248 411.786 2.77752 411.355 2.32748L409.273 0.154143Z"
        fill="url(#paint27_linear_5730_27732)"
      />
      <path
        d="M424.243 0.138916C424.442 -0.0520961 424.759 -0.045279 424.95 0.154143L427.032 2.32748C427.463 2.77752 427.463 3.50248 427.032 3.95253L424.95 6.12586C424.759 6.32528 424.442 6.3321 424.243 6.14109C424.044 5.95007 424.037 5.63356 424.228 5.43414L426.309 3.26081C426.37 3.19752 426.37 3.08248 426.309 3.01919L424.228 0.845859C424.037 0.646437 424.044 0.329928 424.243 0.138916Z"
        fill="url(#paint28_linear_5730_27732)"
      />
      <path
        d="M440.623 0.154143C440.432 -0.045279 440.116 -0.0520961 439.916 0.138916C439.717 0.329928 439.71 0.646437 439.901 0.845859L441.983 3.01919C442.043 3.08248 442.043 3.19752 441.983 3.26081L439.901 5.43414C439.71 5.63356 439.717 5.95007 439.916 6.14109C440.116 6.3321 440.432 6.32528 440.623 6.12586L442.705 3.95253C443.136 3.50248 443.136 2.77752 442.705 2.32748L440.623 0.154143Z"
        fill="url(#paint29_linear_5730_27732)"
      />
      <path
        d="M408.174 0.138916C408.373 -0.0520961 408.69 -0.045279 408.881 0.154143L410.962 2.32748C411.393 2.77752 411.393 3.50248 410.962 3.95253L408.881 6.12586C408.69 6.32528 408.373 6.3321 408.174 6.14109C407.974 5.95007 407.967 5.63356 408.158 5.43414L410.24 3.26081C410.301 3.19752 410.301 3.08248 410.24 3.01919L408.158 0.845859C407.967 0.646437 407.974 0.329928 408.174 0.138916Z"
        fill="url(#paint30_linear_5730_27732)"
      />
      <path
        d="M423.851 0.138916C424.05 -0.0520961 424.367 -0.045279 424.558 0.154143L426.639 2.32748C427.07 2.77752 427.07 3.50248 426.639 3.95253L424.558 6.12586C424.367 6.32528 424.05 6.3321 423.851 6.14109C423.651 5.95007 423.644 5.63356 423.835 5.43414L425.917 3.26081C425.978 3.19752 425.978 3.08248 425.917 3.01919L423.835 0.845859C423.644 0.646437 423.651 0.329928 423.851 0.138916Z"
        fill="url(#paint31_linear_5730_27732)"
      />
      <path
        d="M440.231 0.154143C440.04 -0.045279 439.723 -0.0520961 439.524 0.138916C439.325 0.329928 439.318 0.646437 439.509 0.845859L441.59 3.01919C441.651 3.08248 441.651 3.19752 441.59 3.26081L439.509 5.43414C439.318 5.63356 439.325 5.95007 439.524 6.14109C439.723 6.3321 440.04 6.32528 440.231 6.12586L442.313 3.95253C442.744 3.50248 442.744 2.77752 442.313 2.32748L440.231 0.154143Z"
        fill="url(#paint32_linear_5730_27732)"
      />
      <path
        d="M455.201 0.138916C455.4 -0.0520961 455.717 -0.045279 455.908 0.154143L457.99 2.32748C458.421 2.77752 458.421 3.50248 457.99 3.95253L455.908 6.12586C455.717 6.32528 455.4 6.3321 455.201 6.14109C455.002 5.95007 454.995 5.63356 455.186 5.43414L457.267 3.26081C457.328 3.19752 457.328 3.08248 457.267 3.01919L455.186 0.845859C454.995 0.646437 455.002 0.329928 455.201 0.138916Z"
        fill="url(#paint33_linear_5730_27732)"
      />
      <path
        d="M471.581 0.154143C471.39 -0.045279 471.074 -0.0520961 470.874 0.138916C470.675 0.329928 470.668 0.646437 470.859 0.845859L472.941 3.01919C473.001 3.08248 473.001 3.19752 472.941 3.26081L470.859 5.43414C470.668 5.63356 470.675 5.95007 470.874 6.14109C471.074 6.3321 471.39 6.32528 471.581 6.12586L473.663 3.95253C474.094 3.50248 474.094 2.77752 473.663 2.32748L471.581 0.154143Z"
        fill="url(#paint34_linear_5730_27732)"
      />
      <path
        d="M486.551 0.138916C486.751 -0.0520961 487.067 -0.045279 487.258 0.154143L489.34 2.32748C489.771 2.77752 489.771 3.50248 489.34 3.95253L487.258 6.12586C487.067 6.32528 486.751 6.3321 486.551 6.14109C486.352 5.95007 486.345 5.63356 486.536 5.43414L488.618 3.26081C488.678 3.19752 488.678 3.08248 488.618 3.01919L486.536 0.845859C486.345 0.646437 486.352 0.329928 486.551 0.138916Z"
        fill="url(#paint35_linear_5730_27732)"
      />
      <path
        d="M502.935 0.154143C502.744 -0.045279 502.428 -0.0520961 502.228 0.138916C502.029 0.329928 502.022 0.646437 502.213 0.845859L504.295 3.01919C504.355 3.08248 504.355 3.19752 504.295 3.26081L502.213 5.43414C502.022 5.63356 502.029 5.95007 502.228 6.14109C502.428 6.3321 502.744 6.32528 502.935 6.12586L505.017 3.95253C505.448 3.50248 505.448 2.77752 505.017 2.32748L502.935 0.154143Z"
        fill="url(#paint36_linear_5730_27732)"
      />
      <path
        d="M517.905 0.138916C518.105 -0.0520961 518.421 -0.045279 518.612 0.154143L520.694 2.32748C521.125 2.77752 521.125 3.50248 520.694 3.95253L518.612 6.12586C518.421 6.32528 518.105 6.3321 517.905 6.14109C517.706 5.95007 517.699 5.63356 517.89 5.43414L519.972 3.26081C520.032 3.19752 520.032 3.08248 519.972 3.01919L517.89 0.845859C517.699 0.646437 517.706 0.329928 517.905 0.138916Z"
        fill="url(#paint37_linear_5730_27732)"
      />
      <path
        d="M534.285 0.154143C534.094 -0.045279 533.778 -0.0520961 533.578 0.138916C533.379 0.329928 533.372 0.646437 533.563 0.845859L535.645 3.01919C535.706 3.08248 535.706 3.19752 535.645 3.26081L533.563 5.43414C533.372 5.63356 533.379 5.95007 533.578 6.14109C533.778 6.3321 534.094 6.32528 534.285 6.12586L536.367 3.95253C536.798 3.50248 536.798 2.77752 536.367 2.32748L534.285 0.154143Z"
        fill="url(#paint38_linear_5730_27732)"
      />
      <path
        d="M549.255 0.138916C549.455 -0.0520961 549.771 -0.045279 549.962 0.154143L552.044 2.32748C552.475 2.77752 552.475 3.50248 552.044 3.95253L549.962 6.12586C549.771 6.32528 549.455 6.3321 549.255 6.14109C549.056 5.95007 549.049 5.63356 549.24 5.43414L551.322 3.26081C551.383 3.19752 551.383 3.08248 551.322 3.01919L549.24 0.845859C549.049 0.646437 549.056 0.329928 549.255 0.138916Z"
        fill="url(#paint39_linear_5730_27732)"
      />
      <path
        d="M565.636 0.154143C565.445 -0.045279 565.128 -0.0520961 564.929 0.138916C564.729 0.329928 564.723 0.646437 564.914 0.845859L566.995 3.01919C567.056 3.08248 567.056 3.19752 566.995 3.26081L564.914 5.43414C564.723 5.63356 564.729 5.95007 564.929 6.14109C565.128 6.3321 565.445 6.32528 565.636 6.12586L567.717 3.95253C568.148 3.50248 568.148 2.77752 567.717 2.32748L565.636 0.154143Z"
        fill="url(#paint40_linear_5730_27732)"
      />
      <path
        d="M580.606 0.138916C580.805 -0.0520961 581.122 -0.045279 581.313 0.154143L583.394 2.32748C583.826 2.77752 583.826 3.50248 583.394 3.95253L581.313 6.12586C581.122 6.32528 580.805 6.3321 580.606 6.14109C580.406 5.95007 580.4 5.63356 580.591 5.43414L582.672 3.26081C582.733 3.19752 582.733 3.08248 582.672 3.01919L580.591 0.845859C580.4 0.646437 580.406 0.329928 580.606 0.138916Z"
        fill="url(#paint41_linear_5730_27732)"
      />
      <path
        d="M596.99 0.154143C596.799 -0.045279 596.482 -0.0520961 596.283 0.138916C596.083 0.329928 596.077 0.646437 596.268 0.845859L598.349 3.01919C598.41 3.08248 598.41 3.19752 598.349 3.26081L596.268 5.43414C596.077 5.63356 596.083 5.95007 596.283 6.14109C596.482 6.3321 596.799 6.32528 596.99 6.12586L599.071 3.95253C599.503 3.50248 599.503 2.77752 599.071 2.32748L596.99 0.154143Z"
        fill="url(#paint42_linear_5730_27732)"
      />
      <path
        d="M611.96 0.138916C612.159 -0.0520961 612.476 -0.045279 612.667 0.154143L614.748 2.32748C615.18 2.77752 615.18 3.50248 614.748 3.95253L612.667 6.12586C612.476 6.32528 612.159 6.3321 611.96 6.14109C611.76 5.95007 611.754 5.63356 611.945 5.43414L614.026 3.26081C614.087 3.19752 614.087 3.08248 614.026 3.01919L611.945 0.845859C611.754 0.646437 611.76 0.329928 611.96 0.138916Z"
        fill="url(#paint43_linear_5730_27732)"
      />
      <path
        d="M628.34 0.154143C628.149 -0.045279 627.832 -0.0520961 627.633 0.138916C627.434 0.329928 627.427 0.646437 627.618 0.845859L629.7 3.01919C629.76 3.08248 629.76 3.19752 629.7 3.26081L627.618 5.43414C627.427 5.63356 627.434 5.95007 627.633 6.14109C627.832 6.3321 628.149 6.32528 628.34 6.12586L630.422 3.95253C630.853 3.50248 630.853 2.77752 630.422 2.32748L628.34 0.154143Z"
        fill="url(#paint44_linear_5730_27732)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint39_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint40_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint41_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint42_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint43_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint44_linear_5730_27732"
          x1="408.02"
          y1="3.14"
          x2="672.909"
          y2="3.14"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.07" stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SvgLine;
