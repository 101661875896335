import { ErrorMessage } from 'formik';
import React, { FC, ReactNode } from 'react';

type Props = {
  title: string;
  required?: boolean;
  containerClassName?: string;
  children: ReactNode;
  isBold?: boolean;
  name?: any;
};

const InputWrapper: FC<Props> = ({ title, required, containerClassName, isBold = true, children, name }) => {
  return (
    <div className={`d-flex flex-column   ${containerClassName}`}>
      <label className={`form-label ${isBold && 'fw-bold'} fs-6`}>
        <span className={`${required && 'required'}`}>{title}</span>
      </label>
      <div className="mb-2">{children}</div>
      <div className="text-danger" style={{ fontSize: 12 }}>
        {name && <ErrorMessage name={name} />}
      </div>
    </div>
  );
};

export default InputWrapper;
