import { FC } from 'react';
import SvgSupport from '../../../_global/svg/Support';
import SvgCommunication from '../../../_global/svg/Communication';
import { useDeleteLikePostMutation, useLikePostMutation } from '../../services/feedEndpoints';
import { useIntl } from 'react-intl';

type Props = {
  innerRef?: any;
  postId: number;
  isLiked: boolean;
  setLiked: Function;
  setCount: Function;
  isPromoted: boolean;
  isCommentAvaliable: boolean;
  commentButtonClick: any;
  allowcomment?:number
  allowinteraction?:number
};

const LikeAndComment: FC<Props> = ({
  innerRef,
  isLiked,
  postId,
  isPromoted,
  isCommentAvaliable,
  setLiked,
  setCount,
  commentButtonClick,
  allowcomment,
  allowinteraction
}) => {
  const intl = useIntl();
  const [likePost] = useLikePostMutation();
  const [deleteLikePost] = useDeleteLikePostMutation();
  const handleLike = () => {
    const body = { content_id: postId, type: 'post', promoted_status: isPromoted?.toString() };
    if(!isLiked){
      likePost(body);
    }else{
      deleteLikePost(body);
    }
    setCount((prev: number) => (isLiked ? prev - 1 : prev + 1));
    setLiked(!isLiked);
  };

  return (
    <div ref={innerRef} className="d-flex align-items-center gap-2">
    {allowinteraction===1 &&
      <button
        type="button"
        onClick={handleLike}
        className={`d-flex align-items-center gap-2 btn ${isLiked && 'bg-light-primary text-primary'}`}
      >
        <SvgSupport color={isLiked ? '#3E97FF' : '#7E8299'} />
        <div className={`fs-6 fw-bold  ${isLiked ? 'text-primary' : 'text-gray-600'}`}>
          {intl.formatMessage({ id: isLiked ? 'FEED_LIKED' : 'FEED_LIKE' })}
        </div>
      </button>
      }
    {allowcomment===1&&  (isCommentAvaliable && (
        <button type="button" onClick={commentButtonClick} className="d-flex btn align-items-center gap-2">
          <SvgCommunication />
          <div className="text-gray-600 fs-6 fw-bold">{intl.formatMessage({ id: 'FEED_COMMENT' })}</div>
        </button>
      ))
      }
    </div>
  );
};

export default LikeAndComment;
