import React, { useState, useEffect } from 'react';

type Props = {
  description: string;
  maxLines?: number;
  className?: string;
};

export default function ExpandableHtml({ description, maxLines = 1, className }: Props) {
  const [showFullDescription, setShowFullDescription] = useState(false);

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const [firstTwoTags, setFirstTwoTags] = useState<string >();
  useEffect(() => {
    const regex = /<[^>]+>([^<]*)<\/[^>]+>/g;
    const matches = description.match(regex);
    if (matches && matches.length >= 2) {
      // İlk iki etiketi içeriğiyle birlikte al, birleştir ve sakla
      setFirstTwoTags(matches.slice(0, 2).join(''));
    } else {
      // İlk iki etiket yoksa, tüm açıklamayı göster
      setFirstTwoTags(description);
      setShowFullDescription(true);
    }
  }, [description]);


  return (
    <div>
      {!showFullDescription && (
        <div
          className={className}
          dangerouslySetInnerHTML={{
            __html: String(firstTwoTags) ,
          }}
        />
      )}
      {showFullDescription && (
        <div
          className={className}
          dangerouslySetInnerHTML={{
            __html: description.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>')
            ,
          }}
        />
      )}
    
      {description.length > 50 && (
        <span className="cursor-pointer fw-bold text-gray-700" onClick={toggleDescription}>
          {showFullDescription ? 'Daha az göster' : 'Devamını gör'}
        </span>
      )}
    </div>
  );
}
