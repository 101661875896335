import * as React from 'react';
const SvgSupport = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={19} height={18} fill="none" {...props}>
    <path
      fill={props.color ?? '#7E8299'}
      d="M9.3 17.167a2.408 2.408 0 0 1-.95-.209C6.4 16.067.017 12.692.125 6.55a5.692 5.692 0 0 1 4.242-5.508 6.067 6.067 0 0 1 4.833.75.067.067 0 0 0 .092 0 6.041 6.041 0 0 1 5.258-.625A5.733 5.733 0 0 1 18.36 6.5c-.117 6.875-6.342 9.775-8.242 10.508-.26.102-.537.155-.817.159ZM5.967 2.092c-.43-.001-.858.052-1.275.158a4.45 4.45 0 0 0-3.333 4.325c-.092 5.375 5.716 8.433 7.5 9.242a1.026 1.026 0 0 0 .833 0c1.725-.65 7.342-3.259 7.442-9.35a4.5 4.5 0 0 0-2.967-4.134A4.825 4.825 0 0 0 10 2.825a1.317 1.317 0 0 1-1.441 0 4.708 4.708 0 0 0-2.609-.733h.017Z"
    />
  </svg>
);
export default SvgSupport;
