import * as React from 'react';

const SvgThirdIcon = (props: any) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_5379_32945)">
<rect x="1.5" width="13" height="10" rx="4" fill="#F6BA20"/>
<rect x="4.62109" width="7.28" height="10" fill="#8F3EF7"/>
</g>
<circle cx="8" cy="9" r="7" fill="#C4C4C4"/>
<circle cx="8" cy="9" r="7" fill="url(#paint0_linear_5379_32945)"/>
<circle cx="8" cy="9" r="7" fill="url(#paint1_linear_5379_32945)"/>
<circle cx="8" cy="9" r="7" fill="url(#paint2_linear_5379_32945)"/>
<circle cx="5" cy="5" r="5" transform="matrix(1 0 0 -1 3 14)" fill="url(#paint3_linear_5379_32945)"/>
<path d="M8.01758 12.0923C7.60449 12.0923 7.22656 12.0205 6.88379 11.877C6.54102 11.7305 6.26855 11.5195 6.06641 11.2441C5.86719 10.9658 5.77197 10.6274 5.78076 10.229L5.78516 10.2026H7.15186C7.15186 10.355 7.18701 10.4941 7.25732 10.6201C7.32764 10.7432 7.42725 10.8413 7.55615 10.9146C7.68799 10.9849 7.8418 11.02 8.01758 11.02C8.29297 11.02 8.50977 10.9409 8.66797 10.7827C8.8291 10.6245 8.90967 10.4136 8.90967 10.1499C8.90967 9.87744 8.82617 9.66211 8.65918 9.50391C8.49512 9.34277 8.25049 9.26221 7.92529 9.26221H7.24854V8.21631H7.96045C8.14795 8.21631 8.30469 8.18262 8.43066 8.11523C8.55664 8.04492 8.65039 7.94971 8.71191 7.82959C8.77637 7.70654 8.80859 7.56152 8.80859 7.39453C8.80859 7.1543 8.74121 6.95947 8.60645 6.81006C8.47168 6.66064 8.27539 6.58594 8.01758 6.58594C7.86816 6.58594 7.73486 6.6167 7.61768 6.67822C7.50049 6.73682 7.4082 6.82031 7.34082 6.92871C7.27637 7.03418 7.24414 7.15869 7.24414 7.30225H5.87744L5.86865 7.27588C5.85693 6.93018 5.94189 6.62402 6.12354 6.35742C6.30518 6.09082 6.55859 5.88281 6.88379 5.7334C7.21191 5.58398 7.58545 5.50928 8.00439 5.50928C8.67236 5.50928 9.20703 5.66748 9.6084 5.98389C10.0127 6.29736 10.2148 6.74268 10.2148 7.31982C10.2148 7.62744 10.1313 7.89844 9.96436 8.13281C9.80029 8.36719 9.55273 8.56201 9.22168 8.71729C9.56738 8.84326 9.83545 9.03516 10.0259 9.29297C10.2192 9.55078 10.3159 9.85547 10.3159 10.207C10.3159 10.5967 10.2163 10.9321 10.0171 11.2134C9.81787 11.4946 9.54541 11.7114 9.19971 11.8638C8.854 12.0161 8.45996 12.0923 8.01758 12.0923Z" fill="#773C22"/>
<defs>
<linearGradient id="paint0_linear_5379_32945" x1="8" y1="2" x2="8" y2="16" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFDD86"/>
<stop offset="1" stop-color="#FFC32A"/>
</linearGradient>
<linearGradient id="paint1_linear_5379_32945" x1="8" y1="2" x2="8" y2="16" gradientUnits="userSpaceOnUse">
<stop stop-color="#E7E7E7"/>
<stop offset="1" stop-color="#B5B5B5"/>
</linearGradient>
<linearGradient id="paint2_linear_5379_32945" x1="8" y1="2" x2="8" y2="16" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFA37B"/>
<stop offset="1" stop-color="#CF5D2D"/>
</linearGradient>
<linearGradient id="paint3_linear_5379_32945" x1="5" y1="0" x2="5" y2="10" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFA37B"/>
<stop offset="1" stop-color="#CF5D2D"/>
</linearGradient>
<clipPath id="clip0_5379_32945">
<rect x="1.5" width="13" height="10" rx="4" fill="white"/>
</clipPath>
</defs>
</svg>

);
export default SvgThirdIcon;



