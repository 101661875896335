import { useIntl } from 'react-intl';
import Loading from '../../../../../components/Loading';
import { feedSelector, setEditPostId } from '../../features/feedSlice';
import InputWrapper from '../../../_global/components/Input/InputWrapper';
import { ImageUpload } from '../../../_global/components/Input/ImageUpload';
import confirmMessage from '../../../_global/components/Modal/ConfirmMessage';
import { FC, Fragment, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../api/store/hooks';
import { useAddPostMutation, useGetPostDetailQuery, useUpdatePostMutation } from '../../services/feedEndpoints';
import { authSelector } from '../../../../../../api/features/auth/authSlice';
import { useUploadServiceMutation } from '../../../feedback_module/core/_requests';

type Props = {
  toggle: Function;
  refetch?: Function;
  setQuery?: Function;
};

const CreatePostModal: FC<Props> = ({ refetch, setQuery, toggle }) => {
  const intl = useIntl();
  const maxImageCount = 5;
  const fileRef = useRef(null);
  const dispatch = useAppDispatch();

  const auth = useAppSelector((state) => authSelector(state.auth));

  const [postText, setPostText] = useState<string>('');
  const [imageFiles, setImageFiles] = useState<any>([]);
  const [removedImages, setRemovedImages] = useState<any>([]);
  const [imageValues, setImageValues] = useState<any>(null);
  const [commentSwitch, setCommentSwitch] = useState<boolean>(true);

  const [addPost, { isLoading: addPostLoading }] = useAddPostMutation();
  const [updatePost, { isLoading: updatePostLoading }] = useUpdatePostMutation();
  const isLoading = addPostLoading || updatePostLoading;

  const editPostId = useAppSelector((state) => feedSelector(state.feed).editPostId);

  const {
    data,
    isLoading: postDetailFetch,
    isFetching: postDetailRefetch,
  } = useGetPostDetailQuery(editPostId, { skip: editPostId === undefined });

  const isPostDetailLoading = postDetailFetch || postDetailRefetch;
  const { info } = data || {};
  const { description, comment, images } = info || {};
  const [uploadImage] = useUploadServiceMutation();
  const [uploadedImageIds, setUploadedImageIds] = useState<any>([]);

  const handleImageUpload = useCallback(async (images: any) => {
    const newImages = images.filter((image: { id: any; }) => !uploadedImageIds.includes(image.id));
    const uploadedImagePaths = [];
  
    for (const image of newImages) {
      if (!removedImages.includes(image.id)) {
        try {
          const result = await uploadImage(image).unwrap();
          if (result.filepath) {
            const imageWithId =  result.filepath;
              // id: Math.floor(Math.random() * 10000),
              // name: image.name
            
            uploadedImagePaths.push(imageWithId);
            setUploadedImageIds((prevIds: any) => [...prevIds,]); 
          }
        } catch (error) {
          console.error('Resim yükleme hatası:', error);
        }
      }
    }
  
    setImageFiles(uploadedImagePaths);
  }, [uploadedImageIds, removedImages, uploadImage]);

  const handleImageRemove = useCallback((idToRemove: SetStateAction<never[]>) => {
    const index = imageValues.findIndex((image: { id: SetStateAction<never[]>; }) => image.id === idToRemove);
    if (index !== -1) {
      const newImageValues = [...imageValues];
      newImageValues.splice(index, 1);
      setImageValues(newImageValues);
    }
  
    setRemovedImages(idToRemove);
  }, [imageValues]);
  useEffect(() => {
    if (editPostId !== undefined) {
      setPostText(description);
      setCommentSwitch(comment);
      setImageValues(images?.map((image: any) => ({ id: image.id, url: image?.horizontal_image })));
    }

    return () => {
      setPostText('');
      setCommentSwitch(true);
      setImageValues(null);
      setImageFiles([]);
      setRemovedImages([]);
    };
  }, [description, comment, images, editPostId, dispatch]);

  const isDisabled = postText === '';

  const sendPost = () => {
    confirmMessage(
      intl.formatMessage({ id: 'FEED_SHARE_POST' }),
       //BURAYA BAK
      auth.currentUser?.otpid === 1
        ? intl.formatMessage({ id: 'FEED_SHARE_POST_APPROVEL_DESCRIPTION' })
        : intl.formatMessage({ id: 'FEED_SHARE_POST_DESCRIPTION' }),
      async () => {
        await addPost({ description: postText, comment: commentSwitch ? 1 : 0, images: imageFiles })
          .unwrap()
          .then(() => {
            setQuery && setQuery((e: any) => ({ ...e, branch_id: 0, page: 1 }));
            refetch && refetch();
          });
        toggle();
      },
      undefined,
       //BURAYA BAK
      auth.currentUser?.otpid === 1
        ? intl.formatMessage({ id: 'SEND_APPROVE_TEXT' })
        : intl.formatMessage({ id: 'APPROVE_TEXT' }),
      intl.formatMessage({ id: 'CANCEL_TEXT' }),
      undefined,
      '#7239EA',
    );
  };

  const updateUserPost = () => {
    confirmMessage(
      intl.formatMessage({ id: 'FEED_UPDATE_POST' }),
      intl.formatMessage({ id: 'FEED_UPDATE_POST_DESCRIPTION' }),
      async () => {
        await updatePost({
          post_id: editPostId,
          content: postText,
          comment: commentSwitch ? '1' : '0',
          images: imageFiles,
        })
          .unwrap()
          .then(() => {
            refetch &&
              refetch().then(() => {
                dispatch(setEditPostId(undefined));
              });
          });
        toggle();
      },
      undefined,
      intl.formatMessage({ id: 'APPROVE_TEXT' }),
      intl.formatMessage({ id: 'CANCEL_TEXT' }),
      undefined,
      '#7239EA',
    );
  };

  return (
    <Fragment>
      {isPostDetailLoading ? (
        <Loading />
      ) : (
        <div>
          <InputWrapper containerClassName="mb-6" title={intl.formatMessage({ id: 'FEED_DESCRIPTION_TEXT' })} required>
            <textarea
              value={postText}
              onChange={(e) => setPostText(e.target.value)}
              placeholder={intl.formatMessage({ id: 'FEED_INPUT_PLACEHOLDER_TEXT' })}
              rows={3}
              className="form-control form-control-lg resize-none disabled"
            ></textarea>
          </InputWrapper>
          <div className="form-label fw-bold fs-6 mb-3"> {intl.formatMessage({ id: 'FEED_UPLOAD_PHOTO_TEXT' })}</div>
          <div className="mb-6">
            <ImageUpload
              width="200px"
              ref={fileRef}
              isMulti={true}
              maxImageCount={maxImageCount}
              onImageUpload={handleImageUpload}
              images={imageValues}
              onImageRemove={handleImageRemove}
            />
          </div>
          <div className="d-flex gap-2 align-items-center mb-6">
            <div className="form-label fw-bold fs-6">{intl.formatMessage({ id: 'FEED_COMMENTABLE_TEXT' })}</div>
            <div className="form-check  form-check-solid form-switch">
              <input
                checked={commentSwitch}
                onChange={() => setCommentSwitch(!commentSwitch)}
                className="form-check-input w-45px h-30px"
                type="checkbox"
              />
            </div>
          </div>
          <div className="justify-content-center d-flex">
            <button
              onClick={editPostId !== undefined ? updateUserPost : sendPost}
              disabled={isDisabled}
              className={`btn ${isDisabled ? 'btn-secondary' : 'btn-info'}`}
            >
              {isLoading ? (
                <span className="indicator-progress" style={{ display: 'block' }}>
                  {intl.formatMessage({ id: 'PLEASE.WAITING' })}
                  <span className="spinner-border spinner-border-sm align-middle ms-3"></span>
                </span>
              ) : editPostId !== undefined ? (
                intl.formatMessage({ id: 'FEED_UPDATE_FEED_TEXT' })
                 //BURAYA BAK
              ) : auth.currentUser?.otpid === 1 ? (
                intl.formatMessage({ id: 'AUTH.GENERAL.SUBMIT_BUTTON' })
              ) : (
                intl.formatMessage({ id: 'FEED_CREATE_FEED_TEXT' })
              )}
            </button>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default CreatePostModal;
