type Props = {
  height?: number | string;
};
export default function DefaultLoadingSpinner({ height }: Props) {
  return (
    <div className={`d-flex justify-content-center align-items-center w-100 h-${height ? height : 500}px`}>
      <span className="spinner-border text-info w-50px h-50px" role="status"></span>
    </div>
  );
}
