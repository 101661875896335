/* eslint-disable react/jsx-no-target-blank */
import { toAbsoluteUrl } from '../../../helpers';

const SidebarFooter = () => {
  return (
    <div className="app-sidebar-footer flex-column-auto pt-2 pb-6 px-6" id="kt_app_sidebar_footer">
      <div className="btn btn-flex flex-center btn-custom overflow-hidden text-nowrap px-0 h-40px w-100 gap-2">
        <span className="fw-bold text-gray-400 fs-6">Powered By</span>
        <img
          alt="Logo"
          style={{objectFit:'fill'}}
          src={toAbsoluteUrl('/media/logos/MingNewLogo.png')}
          className="h-20px app-sidebar-logo-default theme-light-show"
        />
      </div>
    </div>
  );
};

export { SidebarFooter };
