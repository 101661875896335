import { useSwiper } from 'swiper/react';
import { KTIcon } from '../../../../../../../_metronic/helpers';

const SwiperButtonPrev = ({ arrowVisibility, swiperRef }: any) => {
  const swiper = useSwiper();

  return arrowVisibility ? (
    <button
      className="btn px-1 py-1 bg-white border border-gray-300 swiper-prev-button shadow-sm"
      style={
        swiperRef != null
          ? {
              position: 'absolute',
              left: '-15px',
              zIndex: 90,
              top: '50%',
              transform: 'translateY(-50%)',
            }
          : {}
      }
      onClick={() => {
        swiperRef != null ? swiperRef.current.slidePrev() : swiper.slidePrev();
      }}
    >
      <KTIcon iconName="arrow-left" className="fs-2x text-dark p-0" />
    </button>
  ) : (
    <></>
  );
};

export default SwiperButtonPrev;
