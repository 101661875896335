import * as React from 'react';
const SvgCommunication = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none" {...props}>
    <path
      fill={props.color ?? '#7E8299'}
      d="M13.017 17.958H1.5A1.475 1.475 0 0 1 .042 16.5V4.983A4.95 4.95 0 0 1 4.984.042h8.033a4.95 4.95 0 0 1 4.942 4.941v8.034a4.95 4.95 0 0 1-4.942 4.941ZM4.984 1.292a3.7 3.7 0 0 0-3.692 3.691V16.5a.225.225 0 0 0 .225.225h11.5a3.7 3.7 0 0 0 3.692-3.692v-8.05a3.7 3.7 0 0 0-3.692-3.691H4.984Zm8.608 5.625a.633.633 0 0 0-.625-.625H5.034a.625.625 0 0 0 0 1.25h7.933a.633.633 0 0 0 .625-.625Zm-2.642 4.166a.633.633 0 0 0-.625-.625H5.034a.625.625 0 1 0 0 1.25h5.291a.634.634 0 0 0 .625-.625Z"
    />
  </svg>
);
export default SvgCommunication;
