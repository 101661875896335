import React, { FC, ReactNode, useState } from 'react';
import './_collapse.scss';
import DashedLine from '../DashedLine/DashedLine';
import { KTIcon } from '../../../../../../_metronic/helpers';

type Props = {
  children: ReactNode;
  title: string;
};

const Collapse: FC<Props> = ({ children, title }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div onClick={toggleCollapse} className="d-flex align-items-center justify-content-between cursor-pointer">
        <div className="text-gray-800 fw-bold">{title}</div>
        <KTIcon iconName={isOpen ? 'up' : 'down'} className="fs-2 d-flex" />
      </div>
      <DashedLine margin="my-2" borderWidth={1} />
      <div className={`collapse-container ${isOpen ? 'collapse-open' : ''}`}>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default Collapse;
