import { forwardRef, memo, useEffect, useRef, useState } from 'react';
import { KTIcon } from '../../../../../../_metronic/helpers';
import { useIntl } from 'react-intl';

type Props = {
  images?: any[];
  width?: string;
  isMulti?: boolean;
  maxImageCount?: number;
  maxImageSize?: number;
  onImageUpload: (image: File | any | null) => void;
  onImageRemove?: (image: File | any | null) => void;
  isPreview?: boolean;
};

export type Ref = HTMLButtonElement;

const ImageUpload = memo(
  forwardRef<Ref, Props>(
    (
      {
        images,
        width,
        onImageUpload,
        onImageRemove,
        isMulti = false,
        maxImageCount,
        maxImageSize = 5,
        isPreview = false,
      },
      ref: any,
    ) => {
      const intl = useIntl();
      const [errorMessage, setErrorMessage] = useState('');
      const [selectedImages, setSelectedImages] = useState<any>(null);
      const [selectedFiles, setSelectedFiles] = useState<any>([]);
      const [removedImageIds, setRemovedImageIds] = useState<any>([]);

      const handleImageChange = (e: any) => {
        const imageFiles = Array.from(e.target.files);
        const fileSizes = imageFiles?.map((file: any) => file.size).reduce((a: any, b: any) => a + b, 0);

        if (imageFiles) {
          const currentStack = selectedImages?.length ?? 0;
          if (maxImageCount && imageFiles?.length + currentStack > maxImageCount) {
            setErrorMessage(
              `${intl.formatMessage({ id: 'MAX_IMAGE_COUNT_BEFORE' })} 
            ${maxImageCount} 
            ${intl.formatMessage({
              id: 'MAX_IMAGE_COUNT_AFTER',
            })}`,
            );
            return;
          }
          if (fileSizes <= maxImageSize * 1024 * 1024) {
            const blobs = imageFiles.map((_, index) => ({
              name: e.target.files[index].name,
              url: URL.createObjectURL(e.target.files[index]),
            }));
            if (isMulti) {
              setSelectedImages([...(selectedImages ?? []), ...blobs]);
              setSelectedFiles([...(selectedFiles ?? []), ...imageFiles]);
              onImageUpload([...(selectedFiles ?? []), ...imageFiles]);
              setErrorMessage('');
            } else {
              const blobs = [
                {
                  name: e.target.files[0].name,
                  url: URL.createObjectURL(e.target.files[0]),
                },
              ];
              setSelectedImages(blobs);
              setSelectedFiles(imageFiles);
              onImageUpload(imageFiles);
              setErrorMessage('');
            }
          } else {
            setErrorMessage(`${intl.formatMessage({ id: 'MAX_IMAGE_SIZE_BEFORE' })} 
          ${maxImageSize} 
          ${intl.formatMessage({
            id: 'MAX_IMAGE_SIZE_AFTER',
          })}`);
          }
        }
      };

      const handleRemove = (id: number, imageName: string) => {
        setSelectedImages(selectedImages?.filter((img: any) => img?.name !== imageName || img?.id !== id));
        setSelectedFiles(selectedFiles?.filter((img: any) => img?.name !== imageName));
        onImageUpload(selectedFiles?.filter((img: any) => img?.name !== imageName));
        if (id && onImageRemove) {
          setRemovedImageIds((prevIds: any) => [...prevIds, id]);
          onImageRemove([...removedImageIds, id]);
        }
      };

      const fileInput = useRef(null);

      useEffect(() => {
        if (images) {
          setSelectedImages(images);
        }
      }, [images]);

      return (
        <>
          <div className="d-flex flex-column ">
            <input
              type="file"
              accept="image/*"
              multiple={isMulti ?? false}
              ref={ref ?? fileInput}
              className="hidden d-none"
              onChange={handleImageChange}
              onClick={(event: any) => {
                event.target.value = null;
              }}
            />
            <div className="d-flex flex-wrap gap-2 ">
              {selectedImages?.length !== 0 &&
                selectedImages?.map((image: any, index: number) => (
                  <div key={index} className={`col-xs-12 ${width ? width : 'w-100'}`}>
                    <div
                      className="border border-2 d-flex justify-content-center align-items-center rounded-4 position-relative"
                      style={{
                        width: width ?? '100%',
                        height: 200,
                        padding: 10,
                        backgroundImage: `url(${image.url})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundOrigin: 'content-box',
                      }}
                    >
                      {!isPreview && (
                        <div
                          onClick={() => handleRemove(image?.id, image?.name)}
                          className="position-absolute cursor-pointer"
                          style={{
                            top: 4,
                            right: 4,
                          }}
                        >
                          <KTIcon iconName="cross-circle" className="btn-icon fs-2x m-0 text-danger" />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              {!isPreview && (
                <>
                  {(selectedImages?.length === 0 ||
                    selectedImages === null ||
                    (maxImageCount && selectedImages.length < maxImageCount)) && (
                    <div className={`col-xs-12 ${width ? width : 'w-100'}`}>
                      <div
                        className="border border-2 d-flex justify-content-center align-items-center rounded-4 position-relative cursor-pointer"
                        onClick={() => {
                          if (ref !== undefined) {
                            (ref!.current! as any).click();
                          } else {
                            (fileInput!.current! as any).click();
                          }
                        }}
                        style={{
                          width: width ?? '100%',
                          height: 200,
                          padding: 10,
                          backgroundPosition: 'center',
                          backgroundSize: 'contain',
                          backgroundRepeat: 'no-repeat',
                          backgroundOrigin: 'content-box',
                        }}
                      >
                        <KTIcon iconName="plus-square" className="btn-icon fs-4x m-0" />
                      </div>
                      {errorMessage && <div className="text-danger mt-2">{errorMessage}</div>}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      );
    },
  ),
);

export { ImageUpload };
