// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event-card {
  border: 1px solid var(--gray-gray-200, #f1f1f2);
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);
}

.header-card {
  margin-bottom: 0px;
}

.post-department-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.18px;
  margin-bottom: 6px;
}

.post-department-subtitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
}

.event-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.18px;
  margin-bottom: 10px;
}

.event-subtitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
}

.post-description {
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
}

.like-and-comment {
  font-size: 13px;
  font-weight: 600;
  line-height: 14px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/home/components/KudosCard/_kudosCard.scss"],"names":[],"mappings":"AAAA;EACI,+CAAA;EACA,+CAAA;AACJ;;AAEE;EACE,kBAAA;AACJ;;AAEE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EACA,kBAAA;AACJ;;AACE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AAEJ;;AACE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;AAEJ;;AACE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AAEJ;;AACE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AAEJ;;AACE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AAEJ","sourcesContent":[".event-card {\n    border: 1px solid var(--gray-gray-200, #f1f1f2);\n    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03);\n  }\n  \n  .header-card {\n    margin-bottom: 0px;\n  }\n  \n  .post-department-title {\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 18px;\n    letter-spacing: -0.18px;\n    margin-bottom: 6px;\n  }\n  .post-department-subtitle {\n    font-size: 14px;\n    font-weight: 500;\n    line-height: 14px;\n  }\n  \n  .event-title {\n    font-size: 18px;\n    font-weight: 600;\n    line-height: 18px;\n    letter-spacing: -0.18px;\n    margin-bottom: 10px;\n  }\n  \n  .event-subtitle {\n    font-size: 14px;\n    font-weight: 600;\n    line-height: 14px;\n  }\n  \n  .post-description {\n    font-size: 13px;\n    font-weight: 500;\n    line-height: 21px;\n  }\n  \n  .like-and-comment {\n    font-size: 13px;\n    font-weight: 600;\n    line-height: 14px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
