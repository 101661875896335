import { FC } from 'react';
import { useIntl } from 'react-intl';

type Props = {
  likeCount: number;
  commentCount: number;
  likeButtonClick: any;
  isCommentAvaliable: boolean;
  commentButtonClick: any;
};

const LikeAndCommentCount: FC<Props> = ({
  likeCount,
  commentCount,
  isCommentAvaliable,
  likeButtonClick,
  commentButtonClick,
}) => {
  const intl = useIntl();
  return (
    <div className="d-flex gap-2 align-items-center text-gray-500 fw-bold mb-4">
      {likeCount > 0 && (
        <div className="cursor-pointer" onClick={likeButtonClick}>
          {`${intl.formatMessage({ id: 'FEED_LIKE_COUNT' }, { count: likeCount })}`}
        </div>
      )}
      {likeCount > 0 && commentCount > 0 && <div>&#x2022;</div>}
      {commentCount > 0 && (
        <div className="cursor-pointer" onClick={commentButtonClick}>
          {`${intl.formatMessage({ id: 'FEED_COMMENT_COUNT' }, { count: commentCount })}`}
        </div>
      )}
    </div>
  );
};

export default LikeAndCommentCount;
