import { AuthModel } from '../../../app/modules/auth';
import api from '../../api';

//? We can move this type definitions to "_requests" file

export type IResponse = {
  success: boolean;
};
export const secondHandsEndpoints = api.injectEndpoints({
  endpoints: (builder) => ({
    getSecondHandsList: builder.query<any, any>({
      query: ({ user_id, page, category_id, city_id, is_free, page_size }: any) => {
        const addedParams: any = {};
        if (user_id) {
          addedParams['user_id'] = user_id;
        }
        if (category_id) {
          addedParams['categories'] = category_id;
        }
        if (city_id) {
          addedParams['cities'] = city_id;
        }
        if (is_free) {
          addedParams['minprice'] = 0;
          addedParams['maxprice'] = 0;
        }
        if (page) {
          addedParams['page'] = page;
        }
        if (page_size) {
          addedParams['pagesize'] = page_size;
        }
        const params = new URLSearchParams(addedParams);

        const baseUrl = user_id ? '/listing/user' : '/listing';

        if (user_id) {
          params.delete('user_id');
        }

        return `${baseUrl}?${params.toString()}`;
      },

      serializeQueryArgs: ({ endpointName, queryArgs }) => endpointName + queryArgs.search,

      //* Always merge incoming data to the cache entry

      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg?.page === 1) {
          currentCache.listings = newItems.listings;
        } else {
          currentCache.listings.push(...newItems.listings);
        }
      },

      //* Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getSecondHandDetail: builder.query<any, any>({
      query: (id) => {
        return `/listing/${id}`;
      },
    }),
    createSecondHand: builder.mutation<IResponse, any>({
      query: (credentials) => {
        const imageArray: any[] = [];
        if (credentials.images && credentials.images.length > 0) {
          credentials.images.forEach((image: any) => {
            imageArray.push(image.mediauri);
          });
        }
        const requestBody = {
          phone: credentials.phone,
          title: credentials.title,
          description: credentials.description,
          categoryid: credentials.category_id,
          cityid: credentials.city_id,
          price: credentials.price,
          currencyid: credentials.currency_id,
          images: credentials.images,
        };
        return {
          url: '/listing',
          method: 'POST',
          body: requestBody,
          formData: true,
        };
      },
    }),
    updateStatusSecondHand: builder.mutation<any, any>({
      query: (credentials) => {
        const url = `/listing/sold_status/update/${credentials.list_id}`;

        return { url: url, method: 'POST', body: credentials };
      },
    }),
    updateSecondHand: builder.mutation<IResponse, any>({
      query: (credentials) => {
        const imageArray: any[] = [];
        if (credentials.images && credentials.images.length > 0) {
          credentials.images.forEach((image: any) => {
            if (image.mediauri !== undefined) {
              imageArray.push(image.mediauri);
            } else {
              imageArray.push(image);
            }
          });
        }
        const requestBody = {
          phone: credentials.phone,
          title: credentials.title,
          description: credentials.description,
          categoryid: credentials.category_id,
          cityid: credentials.city_id,
          price: credentials.price,
          currencyid: credentials.currency_id,
          images: imageArray.length > 0 ? imageArray : [],
          sold: 0,
        };
        const url = `/listing/${credentials.item_id}`;
        return {
          url: url,
          method: 'PUT',
          body: requestBody,
          formData: true,
        };
      },
    }),
    getListiningCategories: builder.query<any, void>({
      query: () => {
        return `listing/category`;
      },
    }),
    getListCities: builder.query<any, void>({
      query: () => {
        return `cities`;
      },
    }),
  }),
});

export const {
  useGetSecondHandsListQuery,
  useGetSecondHandDetailQuery,
  useCreateSecondHandMutation,
  useUpdateStatusSecondHandMutation,
  useUpdateSecondHandMutation,
  useGetListiningCategoriesQuery,
  useGetListCitiesQuery,
} = secondHandsEndpoints;

export default secondHandsEndpoints;
