import api from '../../../../../api/api';
import { ICreateCheers } from '../core/_modals';

export type IResponse = {
    success: boolean;
};

const API_URL = process.env.REACT_APP_CHEERS_API_URL;
export const GET_BADGES_URL = `${API_URL}/badges`;
export const GET_CHEERS_TYPES_URL = `${API_URL}/types`;
export const GET_CHEERS_LIST = `${API_URL}/list`;
export const GET_CHEERS_ALL = `${API_URL}/list`;

export const cheersEndpoints = api.injectEndpoints({
    endpoints: (builder) => ({
        getCheers: builder.query<any, { receiveruserid?: number; page: number; pagesize: number }>({
            query: ({ receiveruserid, page, pagesize }) => {
                return `/profile/user/${receiveruserid}/congratulations?page=${page}&pagesize=10`;
            },
            serializeQueryArgs: ({ endpointName }) => endpointName,
            merge: (currentCache, newItems, { arg }) => {
              if (arg.page === 1) {
                currentCache.congratulations = newItems.congratulations;
              } else {
                currentCache.congratulations.push(...newItems.congratulations);
              }
            },
            forceRefetch({ currentArg, previousArg }) {
              return currentArg?.page !== previousArg?.page;
            },
        }),
        getCheersAll: builder.query<any, { page: number }>({
            query: ({ page }) => {
                return `cheers/list?page=${page}&pagesize=100`;
            },
            serializeQueryArgs: ({ endpointName, queryArgs }) => endpointName,
            merge: (currentCache, newItems, otherArgs) => {
                if (otherArgs.arg?.page === 1) {
                    currentCache.cheers = newItems.cheers;
                } else {
                    currentCache.cheers.push(...newItems.cheers);
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg !== previousArg;
            },
        }),

        getFilteredUsers: builder.query<any, { page: number; filterkey?: string }>({
            query: ({ page, filterkey }) => {
                const params = new URLSearchParams();
                params.append('page', page.toString());
                params.append('pagesize', '50');
                if (filterkey) {
                    params.append('filterkey', filterkey);
                }
                return {
                    url: `people?${params.toString()}`,
                };
            },
            serializeQueryArgs: ({ endpointName }) => endpointName,
            merge: (currentCache, newItems, { arg }) => {
                if (arg.page === 1) {
                    currentCache.people = newItems.people;
                } else {
                    currentCache.people.push(...newItems.people);
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg?.page !== previousArg?.page || currentArg?.filterkey !== previousArg?.filterkey;
            },
        }),
        getCheersBadges: builder.query<any, { cheersTypeId?: number }>({
            query: ({ cheersTypeId }) => {
                return {
                    url: `cheers/badges?cheerstypeid=${cheersTypeId}`,
                };
            },
        }),

        getCheersTypes: builder.query<any, void>({
            query: () => GET_CHEERS_TYPES_URL,
        }),
        getBadges: builder.query<any, { cheerstypeid?: number }>({
            query: ({ cheerstypeid }) => {
                const params = new URLSearchParams();
                if (cheerstypeid) {
                    params.append('cheerstypeid', cheerstypeid.toString());
                }
                return `${GET_BADGES_URL}?${params.toString()}`;
            },
        }),
        cheersUserScore: builder.query<{ score: number }, { userid?: number }>({
            query: ({ userid }) => {
                const params = new URLSearchParams();
                if (userid) {
                    params.append('userid', userid.toString());
                }
                return `${API_URL}/userscore?${params.toString()}`;
            },
        }),

        createCheers: builder.mutation<any, ICreateCheers>({
            query: (body: ICreateCheers) => ({
                url: `cheers`,
                method: 'POST',
                body: body,
            }),
        }),
        getCheersUserScore: builder.query<any, void>({
            query: () => {
                return {
                    url: `cheers/userscore`,
                };
            },
        }),
    }),
});

export const {
    useGetCheersQuery,
    useGetCheersAllQuery,
    useGetCheersTypesQuery,
    useGetBadgesQuery,
    useCreateCheersMutation,
    useCheersUserScoreQuery,
    useGetFilteredUsersQuery,
    useGetCheersBadgesQuery,
    useGetCheersUserScoreQuery
} = cheersEndpoints;

export default cheersEndpoints;
