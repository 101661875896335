import React, { FC, useEffect } from 'react';
import { useGetGameAnswersQuery } from '../../../../../../../../api/services/games/gamesEndpoints';
import ResultQuestion from '../ResultQuestion/ResultQuestion';
import { setAnswers } from '../../../../../../../../api/features/games/gamesSlice';
import { useAppDispatch } from '../../../../../../../../api/store/hooks';
import Loading from '../../../../../../../components/Loading';

type Props = {
  id: number;
};

const Answers: FC<Props> = ({ id }) => {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useGetGameAnswersQuery(id);
  const { questions } = data || {};

  useEffect(() => {
    dispatch(setAnswers(data));
  }, [data, dispatch]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      {questions?.map((question: any, index: number) => (
        <ResultQuestion key={question.question_id} question={question} index={index} />
      ))}
    </div>
  );
};

export default Answers;
