import * as React from 'react';
import SvgLine from './SvgLine';

const SvgThanksLine = (props: any) => (
  <div className=" thanks-line">
    <div>
      <SvgLine width={props.width} />
    </div>
  </div>
);
export default SvgThanksLine;
