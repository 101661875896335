import clsx from 'clsx';
import { toAbsoluteUrl } from '../../../helpers';
import { HeaderUserMenu } from '../../../partials';
import { useLayout } from '../../core';
import { useAppSelector } from '../../../../api/store/hooks';
import { authSelector } from '../../../../api/features/auth/authSlice';
import AnimatedSearchbar from './searchbar/AnimatedSearchbar';
import { useState } from 'react';

const userAvatarClass = 'symbol-35px symbol-md-40px';

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { currentUser } = useAppSelector((state) => authSelector(state.auth));

  return (
    <div className="app-navbar flex-shrink-0">
      <div className={'app-navbar-item align-items-stretch'}>
        <AnimatedSearchbar />
      </div>
      <div className={clsx('app-navbar-item', '')}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          onClick={handleClick}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <img src={currentUser?.pictureurl || toAbsoluteUrl(`/media/avatars/blank.png`)} alt="" />
        </div>
        <HeaderUserMenu anchorEl={anchorEl} open={open} handleClose={handleClose} />
      </div>
    </div>
  );
};

export { Navbar };
