import Comments from '../Comments/Comments';
import Featured from '../Featured/Featured';
import { useNavigate } from 'react-router-dom';
import CommentInput from '../CommentInput/CommentInput';
import { FC, useEffect, useRef, useState } from 'react';
import LikeAndComment from '../LikeAndComment/LikeAndComment';
import { KTIcon, toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import UserBadge from '../../../_global/components/UserBadge/UserBadge';
import { useGetPostCommentsMutation } from '../../services/feedEndpoints';

import { feedSelector, setLikeModal, setSelectedPostId } from '../../features/feedSlice';
import LikeAndCommentCount from '../LikeAndCommentCount/LikeAndCommentCount';
import { authSelector } from '../../../../../../api/features/auth/authSlice';
import { ElementAnimateUtil } from '../../../../../../_metronic/assets/ts/_utils';
import { useAppDispatch, useAppSelector } from '../../../../../../api/store/hooks';
import ImageSlider from '../../../_global/components/Swiper/ImageSlider/ImageSlider';
import BulkDotsDropdown from '../../../_global/components/Dropdown/BulkDotsDropdown';
import Expandable from '../../../_global/components/Expandable/Expandable';
import ApprovelStepper from './components/ApprovelStepper';
import { format, parse } from 'date-fns';
import { Modal } from '../../../_global/components/Modal/Modal';
import { ModalHeader } from '../../../_global/components/Modal/ModalHeader';
import { useIntl } from 'react-intl';
import LikedModal from '../LikedModal/LikedModal';

function mingFlowDurationRounded(stringDateTime: string | null): string {
  if (!stringDateTime) {
    return '';
  }

  let duration: Date;
  if (stringDateTime.length === 16) {
    duration = parse(stringDateTime, 'dd/MM/yyyy HH:mm', new Date());
  } else if (stringDateTime.length === 19) {
    duration = parse(stringDateTime, 'dd/MM/yyyy HH:mm:ss', new Date());
  } else {
    return '';
  }

  const now = new Date();
  const difference = now.getTime() - duration.getTime(); // Get difference in milliseconds

  const differenceInDays = Math.floor(difference / (1000 * 60 * 60 * 24));
  const differenceInHours = Math.floor((difference / (1000 * 60 * 60)) % 24);
  const differenceInMinutes = Math.floor((difference / (1000 * 60)) % 60);

  if (differenceInDays > 0) {
    return `${differenceInDays}g`;
  } else if (differenceInHours > 0) {
    return `${differenceInHours}s`;
  } else if (differenceInMinutes > 0) {
    return `${differenceInMinutes}d`;
  } else {
    return '1d';
  }
}

type Props = {
  data?: any;
  refetch?: Function;
};

enum APPROVE_STATUS {
  LIVE = 'LIVE',
  REJECTED = 'REJECTED',
  WAITING_APPROVE = 'WAITING_APPROVEL',
  NOT_LIVE = 'NOT_LIVE',
}

const Post: FC<Props> = ({ data, refetch }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const postRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const commentsRef = useRef<HTMLInputElement>(null);
  const intl = useIntl();
  const isOpenLikeModal = useAppSelector((state) => feedSelector(state.feed).isOpenLikeModal);

  const {
    promoted,
    record_type,
    feedownerdesc,
    pictureurl,
    permission,
    createuser,
    description,
    mediauri,
    liked,
    created,
    total_comment_count,
    feedid,
    allow_comment,
    nodename,
    contentid,
    allowcomment,
    inactivePostid,
    allowinteraction,
  } = data || {};
  const { count, current_user_status } = liked || {};
  const { currentUser } = useAppSelector((state) => authSelector(state.auth));
  let isOwner;
  if (createuser === undefined) {
    isOwner = true;
  } else {
    isOwner = Number(createuser) === Number(currentUser?.usersecret.userid);
  }
  const isFromWorker = true;
  const approval_status = data.allowinteraction === 1 ? 'LIVE' : 'WAITING_APPROVEL';

  const [getPostComments, { data: comments, isLoading: isPostCommentsLoading }] = useGetPostCommentsMutation();

  const [likeCount, setCount] = useState(0);
  const [commentCount, setCommentCount] = useState(0);
  const [isLiked, setLiked] = useState(false);
  const [isVisibleComments, setVisibleComments] = useState(false);
  const [isVisibleCommentsReply, setVisibleCommentsReply] = useState(false);
  const [isCommentAvaliable, setIsCommentAvaliable] = useState(true);
  const [commentParentId,setCommentParentId]=useState<number>(0)

  useEffect(() => {
    setCount(count);
    setLiked(current_user_status);
    setCommentCount(total_comment_count);
    if (allowcomment === 0) {
      setIsCommentAvaliable(false);
    } else {
      setIsCommentAvaliable(true);
    }
  }, [current_user_status, count, total_comment_count, allowcomment]);

  const handleScrollToFirstComment = () => {
    if (commentsRef.current) {
      ElementAnimateUtil.scrollTo(commentsRef.current, 100);
    }
  };

  const handleScrollBackToPost = () => {
    if (postRef.current) {
      ElementAnimateUtil.scrollTo(postRef.current, 100);
    }
  };

  const commentButtonClick = async (isFocused?: boolean, scrollToFirstComment?: boolean) => {
    if (!isVisibleComments) {
      setVisibleComments(true);
      await getPostComments({ postId: feedid }).then((res: any) => {
        if (scrollToFirstComment && res?.data?.data?.length > 0) {
          handleScrollToFirstComment();
        }
        if (isFocused) {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }
      });
    } else {
      setVisibleComments(false);
      setCommentParentId(0)
      setVisibleCommentsReply(false)

    }
  };

  const likeButtonClick = async () => {
    dispatch(setLikeModal());
    dispatch(setSelectedPostId(feedid));
  };

  return (
    <div ref={postRef} className="p-8 border rounded-4 border-gray-200 bg-white mb-8">
      {approval_status === APPROVE_STATUS.WAITING_APPROVE && data.posttypedesc === 'Gönderi' && (
        <div
          style={{
            backgroundColor: '#FFFAE5',
            borderLeft: '4px solid #FFA500',
            padding: '10px',
            margin: '10px 0',
            marginTop: -10,
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <strong>Paylaşımınız Değerlendiriliyor</strong>
            <p style={{ margin: 0 }}>Paylaşımınız onaylandıktan sonra yayınlanacaktır.</p>
          </div>
        </div>
      )}
      <div className="d-flex justify-content-between align-items-start mb-6">
        <UserBadge
          data={data}
          title={feedownerdesc}
          subtitle={nodename}
          rounded={isFromWorker ? 'rounded-circle' : undefined}
          navigate={() =>
            navigate(`/profile/${createuser === undefined ? currentUser?.usersecret.userid : createuser}`)
          }
          image={isFromWorker ? pictureurl : toAbsoluteUrl('/media/svg/corporate-icon.svg')}
        />
        {isFromWorker && isOwner && data.posttypedesc === 'Gönderi' && (
          <BulkDotsDropdown
            postId={contentid || inactivePostid}
            refetch={refetch}
            isOwner={isOwner}
            approval_status={approval_status}
          />
        )}
      </div>
      {description && (
        <div className={`${mediauri?.length > 0 && 'mt-6'} mb-6`}>
          <Expandable maxChars={350} className="fs-6 fw-medium text-gray-600">
            {description}
          </Expandable>
        </div>
      )}
      <ImageSlider images={mediauri} isPreviewEnabled={true} />
      <div className="d-flex justify-content-between align-items-center">
        <LikeAndCommentCount
          likeCount={likeCount}
          commentCount={commentCount}
          likeButtonClick={likeButtonClick}
          isCommentAvaliable={isCommentAvaliable}
          commentButtonClick={() => commentButtonClick(false, true)}
        />
        {created && <div className={`${mediauri?.length > 0 && 'mt-6'} mb-6`}>{mingFlowDurationRounded(created)}</div>}
      </div>

      <div className={`border-top border-bottom border-top-2 border-bottom-2 py-2 ${isVisibleComments && 'mb-6'}`}>
        <LikeAndComment
          allowinteraction={allowinteraction}
          allowcomment={allowcomment}
          postId={feedid}
          innerRef={commentsRef}
          isLiked={isLiked}
          setCount={setCount}
          setLiked={setLiked}
          isPromoted={promoted}
          isCommentAvaliable={isCommentAvaliable}
          commentButtonClick={() => commentButtonClick(true, true)}
        />
      </div>

      {isVisibleComments && (
        <Comments
          postId={feedid}
          data={comments?.comments}
          isPostOwner={isOwner}
          totalCount={comments?.comments?.length}
          setCommentCount={setCommentCount}
          isVisibleComments={isVisibleComments}
          setVisibleComments={setVisibleComments}
          isLoadingComments={isPostCommentsLoading}
          handleScrollBackToPost={handleScrollBackToPost}
          refetch={() => getPostComments({ postId: feedid })}
          setVisibleCommentsReply={setVisibleCommentsReply}
          isVisibleCommentsReply={isVisibleCommentsReply}
          setCommentParentId={setCommentParentId}
        />
      )}
      {(isVisibleComments&& isVisibleCommentsReply===false) && (
        <CommentInput
          data={comments?.comments}
          id={feedid}
          ref={inputRef}
          image={
            currentUser?.pictureurl === '' || currentUser?.pictureurl == null
              ? toAbsoluteUrl('/media/avatars/blank.png')
              : currentUser?.pictureurl
          }
          setCommentCount={setCommentCount}
          getPostComments={getPostComments}
          handleScrollToFirstComment={handleScrollToFirstComment}
          setVisibleCommentsReply={setVisibleCommentsReply}

        />
      )}
      {isVisibleCommentsReply && (
        <CommentInput
          data={comments?.comments}
          id={feedid}
          ref={inputRef}
          image={
            currentUser?.pictureurl === '' || currentUser?.pictureurl == null
              ? toAbsoluteUrl('/media/avatars/blank.png')
              : currentUser?.pictureurl
          }
          setCommentCount={setCommentCount}
          getPostComments={getPostComments}
          handleScrollToFirstComment={handleScrollToFirstComment}
          commentReplyPlaceholder='Yanıtla'
          commentParentId={commentParentId}
          setVisibleCommentsReply={setVisibleCommentsReply}
        />
      )}
       {isOpenLikeModal === true && (
            <Modal
              bg={false}
              className="w-50"
              id="feed_liked_ones"
              isScrollable={false}
              toggle={() => dispatch(setLikeModal())}
              headerComponent={
                <ModalHeader
                  toggle={() => dispatch(setLikeModal())}
                  title={intl.formatMessage({ id: 'FEED_LIKED_ONES' })}
                />
              }
              formComponent={<LikedModal />}
            />
          )}
    </div>
  );
};

export default Post;
