import React, { FC } from 'react';

type Props = {
  title: string;
  description: string;
  category: string;
  resultDate: string;
  joinGame: Function;
  isLoading: boolean;
};

const PreApproval: FC<Props> = ({ title, description, category, resultDate, joinGame, isLoading }) => {
  const handleJoinGame = async () => {
    await joinGame();
  };
  return (
    <div>
      <div className="mb-4">
        <div className="fs-2x fw-bold mb-10">{title}</div>
        <div className="mb-10">
          <div className="text-gray-500 mb-3 fs-5 fw-bold">Açıklama</div>
          <div className="survey-subtitle text-gray-800 mb-3 fs-5">{description}</div>
        </div>
        <div className="mb-10">
          <div className="text-gray-500 mb-3 fs-5 fw-bold">Kategori</div>
          <div className="survey-subtitle text-gray-800 mb-3 fs-5">{category}</div>
        </div>
        <div className="mb-10">
          <div className="text-gray-500 mb-3 fs-5 fw-bold">Sonuçlanma Tarihi</div>
          <div className="survey-subtitle text-gray-800 mb-3 fs-5">{resultDate}</div>
        </div>
      </div>
      <div className="justify-content-center d-flex">
        <button
          type="button"
          onClick={handleJoinGame}
          className="btn btn-info px-16 py-3 d-flex align-items-center gap-3"
        >
          <span>Oyuna Başla</span>
          {isLoading ? <div className="spinner-border spinner-border-md text-white" /> : null}
        </button>
      </div>
    </div>
  );
};

export default PreApproval;
