import { FC, useEffect, useRef, useState } from 'react';
import { KTIcon, toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { right } from '@popperjs/core';
import { useUploadServiceMutation } from '../../../feedback_module/core/_requests';

type Props = {
  imagePath?: string;
  changeEvent?: any;
  disabled?: boolean;
  width?: number;
  currentImages?: any;
  imagesLength: number;
  required: boolean;
};
const ImageFileAndPreviewInput: FC<Props> = ({
  changeEvent,
  imagesLength = 3,
  currentImages,
  width,
  disabled,
  required = false,
}: Props) => {
  const fileInput = useRef(null);

  const scrollRef = useRef<HTMLInputElement>(null);

  const [images, setImages] = useState<any>([]);
  const [previewImages, setPreviewImages] = useState<any>([]);
  const [removeImageIds, setRemoveImageIds] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadImage] = useUploadServiceMutation();

  const onImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;
    const imageFiles = Array.from(event.target.files) as File[];
    const fileSizes = imageFiles.reduce((total, file) => total + file.size, 0);

    const currentStack = previewImages.length;
    if (imagesLength && imageFiles.length + currentStack > imagesLength) {
        setErrorMessage(`En fazla ${imagesLength} görsel seçebilirsiniz.`);
        return;
    }
    if (fileSizes > 10 * 1024 * 1024) {
        setErrorMessage("Toplam görsel boyutu 10 MB'dan büyük olamaz.");
        return;
    }

    interface ImageObject {
      cover: number;
      mediauri: string;
      multimediaid: number;
  }
  
  const uploadedImagePaths: ImageObject[] = [];

    for (const image of imageFiles) {
        try {
            const result = await uploadImage(image).unwrap();
            if (result.filepath) {
              const imageObject = {
                cover: 0, 
                mediauri: result.filepath, 
                multimediaid: 0,
              };
                uploadedImagePaths.push(imageObject);
            }
        } catch (error) {
            console.error("Resim yükleme hatası:", error);
            setErrorMessage("Resim yüklenirken bir hata oluştu.");
            return;
        }
    }

    setPreviewImages([...previewImages, ...uploadedImagePaths]);
    setImages([...previewImages, ...uploadedImagePaths]);
    changeEvent([...previewImages, ...uploadedImagePaths]);
    setErrorMessage('');
};

const removeImage = (index: number) => {
  // Resimlerin önizleme listesinden seçilen indeksi kaldır
  const updatedPreviewImages = previewImages.filter((_: any, i: number) => i !== index);

  // Eğer currentImages içinde benzersiz bir id bilgisi varsa ve bu bilgiye ihtiyacınız varsa:
  let itemIdToRemove = undefined;
  if (Array.isArray(currentImages) && currentImages[index]) {
    itemIdToRemove = currentImages[index].id;  // Varsayım: currentImages her elemanında bir id barındırıyor.
  }

  // itemIdToRemove undefined değilse ve henüz removeImageIds listesine eklenmemişse:
  if (itemIdToRemove !== undefined && !removeImageIds.includes(itemIdToRemove)) {
    setRemoveImageIds([...removeImageIds, itemIdToRemove]);  // Yeni id'yi ekleyerek yeni bir liste oluştur
  }

  // Güncellemeleri state'lere uygula
  setPreviewImages(updatedPreviewImages);
  if (changeEvent) {
    changeEvent(updatedPreviewImages, removeImageIds);
  }
  setPreviewImages(updatedPreviewImages);
  changeEvent(updatedPreviewImages, removeImageIds);
};

  function isObject(val: any) {
    return typeof val === 'object';
  }

  useEffect(() => {
    if (Array.isArray(currentImages.value)) {
      const previewImages = currentImages.value.map((e: { mediauri: any; }) => {
        if(e.mediauri !== undefined){
          return e.mediauri; 
        }else{
          return e;
        }  
      });
      setPreviewImages(previewImages);
    }
  }, [currentImages]);

  return (
    <div className=" " style={{ flex: '0 0 auto' }} ref={scrollRef}>
      <input
        maxLength={imagesLength}
        type="file"
        accept="image/*"
        ref={fileInput}
        className="hidden d-none"
        onChange={onImageChange}
        multiple={true}
      />
      <div className={`${required && 'required'} fw-bold`}>Görsel Ekle</div>
      <div className="mt-4  d-flex flex-wrap gap-2 " style={{}}>
        {previewImages?.map((e: any, index: number) => (
          <div style={{ position: 'relative' }}>
            <div
              onClick={() => removeImage(index)}
              style={{
                position: 'absolute',
                right: 10,
              }}
            >
              <KTIcon iconName="cross-circle" className="btn-icon fs-2x m-0 text-danger" />
            </div>
            <img
              key={index}
              src={currentImages === undefined ? e : e}
              className="border border-2 d-flex justify-content-center align-items-center rounded-4 cursor-pointer me-3"
              style={{
                width: width || 400,
                height: 200,
                padding: 10,
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundOrigin: 'content-box',
              }}
            />
          </div>
        ))}
        {previewImages?.length < imagesLength && (
          <div
            className="border border-2 d-flex justify-content-center align-items-center rounded-4 cursor-pointer"
            onClick={() => !disabled && (fileInput!.current! as any).click()}
            style={{
              flex: '0 0 auto',
              width: width || 400,
              height: 200,
              padding: 10,
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundOrigin: 'content-box',
            }}
          >
            <KTIcon iconName="plus-square" className="btn-icon fs-4x m-0" />
          </div>
        )}
      </div>
      <div className="mt-2 text-danger">{errorMessage}</div>
    </div>
  );
};

export { ImageFileAndPreviewInput };
