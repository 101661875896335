import * as React from 'react';
const SvgGame = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} fill="none" {...props}>
    <rect width={50} height={50} fill="#FF7B57" rx={6} />
    <g fill="#FFF5F2" clipPath="url(#a)">
      <path d="m37.777 34.71-15.295-9.707 15.295-9.708a.878.878 0 0 0 .16-1.333C27.768 1.986 8 9.27 8 25.003s19.825 23.029 29.967 11a.907.907 0 0 0 .202-.646.888.888 0 0 0-.392-.646Zm-14.35-21.73a2.434 2.434 0 0 1 2.253 1.508 2.445 2.445 0 0 1-1.777 3.329 2.434 2.434 0 0 1-2.503-1.038 2.444 2.444 0 0 1 2.027-3.798Z" />
      <path d="M30.124 25.003a2.445 2.445 0 0 0 1.504 2.256 2.435 2.435 0 0 0 3.325-1.779 2.445 2.445 0 0 0-1.038-2.507 2.435 2.435 0 0 0-3.077.305 2.444 2.444 0 0 0-.714 1.725ZM39.564 22.563a2.435 2.435 0 0 0-2.253 1.506 2.445 2.445 0 0 0 1.776 3.33 2.435 2.435 0 0 0 2.503-1.04 2.444 2.444 0 0 0-.303-3.082 2.435 2.435 0 0 0-1.723-.715Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M8 8h34v34H8z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgGame;
