import { useFormikContext } from 'formik';
import { FC, useEffect, useRef, useState, memo } from 'react';
import Select from 'react-select';
import { selectColorStyles } from '../Styles';
import { ISelectType } from './ISelectType';
import InputWrapper from '../Input/InputWrapper';
import { useIntl } from 'react-intl';

type Props = {
  className?: string;
  changeEvent?: any;
  value?: any;
  title?: string;
  isMulti?: boolean;
  placeholder?: string;
  disabled?: boolean;
  name?: string;
  required?: boolean;
  closeMenuOnSelect?: boolean;
  isClearable?: boolean;
  options?: any;
  action?: Function;
};

const GenericSelect: FC<Props> = (props) => {
  const isClearable = props.isClearable === undefined ? true : props.isClearable;
  const [options, setOptions] = useState<Array<ISelectType>>([]);
  const [selected, setSelected] = useState<ISelectType | null | undefined>(null);

  const intl = useIntl();
  const selectRef = useRef<any>(null);
  const formik = useFormikContext<any>();

  const handleChange = (e: any) => {
    if (props.changeEvent) {
      props.changeEvent(e ? e : { value: undefined });
    }
    if (formik) {
      const { setFieldValue } = formik;
      if (props.isMulti) {
        setFieldValue(
          props.name!,
          e.map((vl: any) => vl.value),
        );
      } else {
        setFieldValue(props.name!, e.value);
      }
    }
  };

  useEffect(() => {
    if (props.options) {
      setOptions(props.options);
    } else {
      if (props.action) {
        props
          .action()
          .then((data: any) => {
            const response = data?.data || [];
            const optionsData = response.map((br: any) => ({
              value: br.id,
              label: br.title,
            }));
            setOptions(optionsData);
          })
          .catch((error: any) => {
            setOptions([]);
          });
      }
    }
  }, [props, props.action, props.options]);

  useEffect(() => {
    if (!props.value) {
      try {
        if (selectRef.current) {
          if (props.value) {
            selectRef.current.select.clearValue();
          }
        }
      } catch (error) {}
      setSelected(null);
    } else {
      if (props.value!.length) {
        const list: any = options?.filter((item) => props.value!.indexOf(item.value) !== -1);
        setSelected(list);
      } else {
        setSelected(options?.filter((x) => x.value == props.value)[0]);
      }
    }
  }, [props.value, options]);

  return (
    <InputWrapper
      containerClassName={props.className}
      required={props.required}
      title={props.title ? props.title : ''}
      name={props.name}
    >
      <Select
        key={`unique_select_key_${selected}`}
        options={options}
        ref={selectRef}
        closeMenuOnSelect={props.closeMenuOnSelect ?? false}
        onChange={handleChange}
        isDisabled={props.disabled}
        filterOption={(option, query) =>
          String(option.data.label).toLocaleLowerCase('tr').includes(query.toLocaleLowerCase('tr'))
        }
        classNames={{
          control: (state) => 'form-control form-control-sm form-control-solid select-container',
        }}
        placeholder={props.placeholder ? props.placeholder : intl.formatMessage({ id: 'SELECT_COMPONENT_PLACEHOLDER' })}
        styles={selectColorStyles}
        name={props.name}
        isClearable={isClearable}
        isMulti={props.isMulti}
        value={selected}
      />
    </InputWrapper>
  );
};

export default memo(GenericSelect);
