export const convertNumberToMonthString = (monthNumber: number) => {
  const monthMapper: any = {
    1: 'Ocak',
    2: 'Şubat',
    3: 'Mart',
    4: 'Nisan',
    5: 'Mayıs',
    6: 'Haziran',
    7: 'Temmuz',
    8: 'Ağustos',
    9: 'Eylül',
    10: 'Ekim',
    11: 'Kasım',
    12: 'Aralık',
  };
  return monthMapper[monthNumber];
};

export const convertMonthToNumberString = (monthNumber: number) => {
  const monthMapper: any = {
    Ocak: 1,
    Şubat: 2,
    Mart: 3,
    Nisan: 4,
    Mayıs: 5,
    Haziran: 6,
    Temmuz: 7,
    Ağustos: 8,
    Eylül: 9,
    Ekim: 10,
    Kasım: 11,
    Aralık: 12,
  };
  return monthMapper[monthNumber];
};

export const timeAgo = (timestamp: number) => {
  const now = Date.now();
  const secondsPast = (now - timestamp) / 1000;

  if (secondsPast < 60) {
    return `${Math.floor(secondsPast)}s`;
  } else if (secondsPast < 3600) {
    return `${Math.floor(secondsPast / 60)}m`;
  } else if (secondsPast < 86400) {
    return `${Math.floor(secondsPast / 3600)}h`;
  } else if (secondsPast < 604800) {
    return `${Math.floor(secondsPast / 86400)}d`;
  } else if (secondsPast < 2419200) {
    return `${Math.floor(secondsPast / 604800)}w`;
  } else if (secondsPast < 29030400) {
    return `${Math.floor(secondsPast / 2419200)}mo`;
  } else {
    return `${Math.floor(secondsPast / 29030400)}y`;
  }
};

export function extractFirstName(fullName: string) {
  // fullName'i boşluk karakterine göre böl ve parçaları bir diziye ata
  const nameParts = fullName?.trim()?.split(' ');
  if (!nameParts) return '';

  // İlk elemanı (ilk ismi) döndür
  return nameParts[0];
}

export function daysUntilNextMonth(): number {
  const now: Date = new Date();
  const currentYear: number = now.getFullYear();
  const currentMonth: number = now.getMonth();

  // First day of the next month
  const firstDayNextMonth: Date = new Date(currentYear, currentMonth + 1, 1);

  // Calculate the difference in days
  const differenceInTime: number = firstDayNextMonth.getTime() - now.getTime();
  const differenceInDays: number = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));

  return differenceInDays;
}

export function calculateDates(month: number, year: number): { startdate: string; enddate: string } {
  let startdate: Date;
  let enddate: Date;

  // Startdate ayın ilk günü
  startdate = new Date(year, month - 1, 1, 0, 0);

  // Enddate sonraki ayın ilk günü
  if (month === 12) {
    enddate = new Date(year + 1, 0, 1, 0, 0);
  } else {
    enddate = new Date(year, month, 1, 0, 0);
  }

  const formatDate = (date: Date) => {
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const yyyy = date.getFullYear();

    return `${dd}/${mm}/${yyyy}`;
  };

  return {
    startdate: formatDate(startdate),
    enddate: formatDate(enddate),
  };
}

export function getFormattedCurrentFirstDays(): { startDate: string; endDate: string } {
  // Şu anki tarihi al
  const now = new Date();

  // Tarihi gg/aa/yyyy 00:00 formatına çeviren yardımcı fonksiyon
  function formatDate(date: Date): string {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Aylar 0'dan başladığı için +1 ekliyoruz
    const year = date.getFullYear();
    return `${day}/${month}/${year} 00:00`;
  }

  // İçinde bulunduğumuz ayın ilk gününü hesapla
  const currentMonthFirstDay = new Date(now.getFullYear(), now.getMonth(), 1);
  const startDate = formatDate(currentMonthFirstDay);

  // Gelecek ayın ilk gününü hesapla
  const nextMonthFirstDay = new Date(now.getFullYear(), now.getMonth() + 1, 1);
  const endDate = formatDate(nextMonthFirstDay);

  return {
    startDate,
    endDate,
  };
}
