// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-bar {
  display: flex;
  align-items: center;
  transition: all 0.35s ease;
}

.search-input-container {
  display: flex;
  align-items: center;
  width: 30px; /* Initial width of the container */
  transition: all 0.35s ease;
}

.search-icon {
  cursor: pointer;
  transition: all 0.4s ease;
}

.search-input {
  border: none;
  outline: none;
  padding: 5px 10px;
  width: 0; /* Initial width of the input */
  opacity: 0;
  transition: all 0.35s ease;
}

.expanded .search-input-container {
  margin-top: 1px;
  width: 275px; /* Expanded width of the container */
}

.expanded .search-icon {
  margin-right: 10px;
}

.expanded .search-input {
  width: 100%; /* Expanded width of the input */
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/_metronic/layout/components/header/searchbar/_animatedSearchBar.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,0BAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,WAAA,EAAA,mCAAA;EACA,0BAAA;AACF;;AAEA;EACE,eAAA;EACA,yBAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;EACA,iBAAA;EACA,QAAA,EAAA,+BAAA;EACA,UAAA;EACA,0BAAA;AACF;;AAEA;EACE,eAAA;EACA,YAAA,EAAA,oCAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,WAAA,EAAA,gCAAA;EACA,UAAA;AACF","sourcesContent":[".search-bar {\n  display: flex;\n  align-items: center;\n  transition: all 0.35s ease;\n}\n\n.search-input-container {\n  display: flex;\n  align-items: center;\n  width: 30px; /* Initial width of the container */\n  transition: all 0.35s ease;\n}\n\n.search-icon {\n  cursor: pointer;\n  transition: all 0.4s ease;\n}\n\n.search-input {\n  border: none;\n  outline: none;\n  padding: 5px 10px;\n  width: 0; /* Initial width of the input */\n  opacity: 0;\n  transition: all 0.35s ease;\n}\n\n.expanded .search-input-container {\n  margin-top: 1px;\n  width: 275px; /* Expanded width of the container */\n}\n\n.expanded .search-icon {\n  margin-right: 10px;\n}\n\n.expanded .search-input {\n  width: 100%; /* Expanded width of the input */\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
