// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #7239ea;
  color: transparent;
  opacity: 1;
}

/* target all bullets */
.swiper-pagination {
  width: -moz-fit-content !important;
  width: fit-content !important;
  margin: auto;
  padding: 0.1rem 0.5rem !important;
  border-radius: 25px 25px 25px 25px;
  background-color: whitesmoke !important;
}

.swiper-pagination-bullets {
  position: relative !important;
  bottom: 2.5rem !important;
}

.swiper-wrapper {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/apps/_global/components/Swiper/ImageSlider/_imageSlider.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,kBAAA;EACA,UAAA;AACF;;AACA,uBAAA;AAKA;EACE,kCAAA;EAAA,6BAAA;EACA,YAAA;EACA,iCAAA;EACA,kCAAA;EACA,uCAAA;AAFF;;AAIA;EACE,6BAAA;EACA,yBAAA;AADF;;AAGA;EACE,aAAA;EACA,mBAAA;AAAF","sourcesContent":["span.swiper-pagination-bullet.swiper-pagination-bullet-active {\n  background-color: #7239ea;\n  color: transparent;\n  opacity: 1;\n}\n/* target all bullets */\n// .swiper-pagination-bullet {\n//     background-color: blue;\n//     opacity: 1;\n// }\n.swiper-pagination {\n  width: fit-content !important;\n  margin: auto;\n  padding: 0.1rem 0.5rem !important;\n  border-radius: 25px 25px 25px 25px;\n  background-color: whitesmoke !important;\n}\n.swiper-pagination-bullets {\n  position: relative !important;\n  bottom: 2.5rem !important;\n}\n.swiper-wrapper {\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
