import React, { useCallback, useEffect, useState } from 'react';
import ModuleTemplate from '../_global/components/ModuleTemplate/ModuleTemplate';
import { KTCard, KTIcon } from '../../../../_metronic/helpers';
import { Divider } from '@mui/material';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { ModalHeader } from '../_global/components/Modal/ModalHeader';
import { Modal } from '../_global/components/Modal/Modal';
import CreateSuggestionModal from './CreateSuggestionModal';
import confirmMessage from '../_global/components/Modal/ConfirmMessage';
import { useIntl } from 'react-intl';
import CreateKaizenForm from './CreateKaizenModal';
import { authSelector } from '../../../../api/features/auth/authSlice';
import { useAppSelector } from '../../../../api/store/hooks';

import { WaitSuggestionStepper } from './components/Steppers/SuggestionStepper';
import { RedSuggestionStepper } from './components/Steppers/RedSuggestionStepper';
import { WaitingSuggestionStepper } from './components/Steppers/WaitSuggestionStepper';
import { SendKaizenStepper } from './components/Steppers/SendKazizenStepper';
import { SuccessKaizenStepper } from './components/Steppers/SuccessKazizenStepper';
import { RedKaizenStepper } from './components/Steppers/RedKazizenStepper';
import { RedPurposeStepper } from './components/Steppers/RedPurposeStepper';
import { SuccessSuggestionStepper } from './components/Steppers/SuccessSuggestion';
import GenericEmptyContent from '../_global/components/EmptyContent/GenericEmptyContent';
import { useGetSuggestionQuery } from '../../../../api/services/home/homeEndpoints';
import InfiniteScroll from 'react-infinite-scroll-component';
import DefaultLoadingSpinner from '../_global/components/Loading/DefaultLoadingSpinner';
import Loading from '../../../components/Loading';

export default function SuggestionList() {
  const intl = useIntl();

  const [isOpen, setIsOpen] = useState(false);
  const [isCreateKaizenFormOpen, setIsCreateKaizenFormOpen] = useState({ show: false, isPreview: false });
  const [selectedSuggestion, setSelectedSuggestion] = useState<any>({});

  const [page, setPage] = useState(1);
  const [list, setList] = useState<any>([]);
  const { data: suggestions, isLoading: linksLoading, refetch: suggestionefetch } = useGetSuggestionQuery({ page });

  useEffect(() => {
    if (suggestions != null) {
      setList(suggestions);
    }
  }, [suggestions]);

  const handleCloseModalWarning = useCallback(() => {
    confirmMessage(
      'Öneriden Ayrıl',
      'Girdiğiniz bilgiler kaybolacaktır. Ayrılmak istediğinize emin misiniz?',
      () => {
        setIsOpen(false);
        setIsCreateKaizenFormOpen({ show: false, isPreview: false });
      },
      undefined,
      'Onayla',
      'İptal Et',
    );
  }, []);

  const returnStepper = (string: string) => {
    switch (string) {
      case 'pending':
        return <WaitSuggestionStepper />;
      case 'rejected':
        return <RedSuggestionStepper />;
      case 'accepted' ||
        'attend_for_review' ||
        'attend_solver' ||
        'solved_waiting_approve' ||
        'rejected_solve' ||
        'solve_not_possible' ||
        'solve_not_possible_rejected':
        return <WaitingSuggestionStepper />;
      case 'approve_solve':
        return <SuccessSuggestionStepper />;
      case 'solve_not_possible_approve':
        return <RedPurposeStepper />;
      case 'kaizen_pending':
        return <SendKaizenStepper />;
      case 'kaizen_rejected':
        return <RedKaizenStepper />;
      case 'kaizen_approved':
        return <SuccessKaizenStepper />;
      case 'attend_for_review':
        return <WaitSuggestionStepper />;
      case 'solved_waiting_approve':
        return <WaitingSuggestionStepper />;
      case 'attend_solver':
        return <WaitingSuggestionStepper />;
      case 'rejected_solve':
        return <WaitingSuggestionStepper />;
      case 'solve_not_possible':
        return <WaitingSuggestionStepper />;
      case 'solve_not_possible_rejected':
        return <WaitingSuggestionStepper />;
      default:
        break;
    }
  };
  return (
    <ModuleTemplate
      isLoading={linksLoading}
      title="Öneriler"
      subtitle="Çalışma sürecine yönelik fikirlerin "
      headerEndComponent={
        <div className="d-flex align-items-center" onClick={() => setIsOpen(true)}>
          <div className="btn btn-sm btn-info px-4">
            <div className="d-flex justify-content-center align-items-end">
              <KTIcon iconName="plus-square" className="fs-2 me-2" iconOverride="text-white" />
              <div className="fw-bold fs-7">Öneri Oluştur</div>
            </div>
          </div>
        </div>
      }
      empty={{
        imgPath: '/media/svg/empty/announcements/AnnouncementsEmpty.svg',
        title: 'Henüz öneri iletmedin.',
        textBeforeNavigationLink: '“Öneri Oluştur” butonuna dokunarak yeni bir öneri oluşturabilirsin.',
      }}
    >
      {Array.isArray(list?.suggestions) && list?.suggestions.length === 0 && (
        <GenericEmptyContent
          title="Henüz öneri iletmedin."
          imgPath="/media/svg/empty/announcements/AnnouncementsEmpty.svg"
          textBeforeNavigationLink="“Öneri Oluştur” butonuna dokunarak yeni bir öneri oluşturabilirsin."
        />
      )}
      <InfiniteScroll
        hasMore={true}
        scrollThreshold={0.75}
        next={() => setPage(page + 1)}
        dataLength={list?.suggestions?.length ?? 0}
        loader={linksLoading && page > 1 ? <DefaultLoadingSpinner height={100} /> : linksLoading && <Loading />}
        endMessage={!linksLoading && page < (list?.page_count ?? 1) && null}
      >
        {list !== null &&
          list?.suggestions !== undefined &&
          list?.suggestions?.map((e: any) => {
            const isKazien = e?.statusid === 6 || e?.statusid === 4 || e?.statusid === 3;
            return (
              <KTCard className={'mt-5 p-10'}>
                <div style={{ display: 'grid', gridTemplateColumns: '0.5fr 0.5fr' }}>
                  <div className="fw-bold fs-4">{e?.title}</div>
                  <Link
                    to={String(e.suggestionid != null ? e?.suggestionid : 0)}
                    style={{ display: 'flex', justifySelf: 'end', alignItems: 'center' }}
                  >
                    <div className="fw-bold fs-6 px-2 text-gray-600">Öneriye Git</div>
                    <KTIcon iconName={'black-right-line '} className={'fs-1 text-grey '} iconOverride="text-grey" />
                  </Link>
                </div>
                <Divider className="my-5" />
                <div style={{ display: 'grid', gridTemplateColumns: `0.5fr 0.5fr ${isKazien ? '0.3fr' : '0fr'}` }}>
                  <div>
                    <div className="fw-semibold">Bildirim Tarihi</div>
                    <div className="fs-7 text-gray-600">{e?.created}</div>
                  </div>
                  <div>
                    <div className="fw-semibold">Öneri Türü</div>
                    <div className="fs-7 text-gray-600">{e?.suggestiontypedesc}</div>
                  </div>
                  {e?.statusid === 3 && (
                    <div style={{ justifySelf: 'end' }}>
                      <button
                        onClick={() => {
                          setSelectedSuggestion(e);
                          setIsCreateKaizenFormOpen({ show: true, isPreview: false });
                        }}
                        type="submit"
                        disabled={false}
                        className={`btn btn-sm ${'btn-info'}`}
                        style={{ alignSelf: 'flex-end', justifySelf: 'flex-end' }}
                      >
                        Kaizen Formunu Yeniden Gönder
                      </button>
                    </div>
                  )}
                  {e?.statusid === 6 && (
                    <div style={{ justifySelf: 'end' }}>
                      <button
                        onClick={() => {
                          setSelectedSuggestion(e);
                          setIsCreateKaizenFormOpen({ show: true, isPreview: false });
                        }}
                        type="submit"
                        disabled={false}
                        className={`btn btn-sm ${'btn-info'}`}
                        style={{ alignSelf: 'flex-end', justifySelf: 'flex-end' }}
                      >
                        Kaizen Formu Oluştur
                      </button>
                    </div>
                  )}
                  {e?.statusid === 4 && (
                    <div style={{ justifySelf: 'end' }}>
                      <button
                        onClick={() => {
                          setSelectedSuggestion(e);
                          setIsCreateKaizenFormOpen({ show: true, isPreview: true });
                        }}
                        type="submit"
                        disabled={false}
                        className={`btn btn-sm ${'btn-info'}`}
                        style={{ alignSelf: 'flex-end', justifySelf: 'flex-end' }}
                      >
                        Kaizen Formunu Görüntüle
                      </button>
                    </div>
                  )}
                </div>
                <div className="mt-10">{returnStepper(e?.statusid)}</div>
              </KTCard>
            );
          })}
      </InfiniteScroll>

      {isOpen && (
        <Modal
          id="feedback-modal"
          isScrollable={false}
          bodyClassName="mx-xl-0"
          toggle={handleCloseModalWarning}
          isDisposableOnOutsideClick={false}
          headerComponent={<ModalHeader title={'Öneri Oluştur'} toggle={handleCloseModalWarning} />}
          formComponent={
            <CreateSuggestionModal
              onSuccess={() => {
                setIsOpen(false);
                suggestionefetch();
              }}
            />
          }
        />
      )}
      {isCreateKaizenFormOpen.show && (
        <Modal
          id="feedback-modal"
          isScrollable={false}
          bodyClassName="mx-xl-0"
          toggle={() => setIsCreateKaizenFormOpen({ show: true, isPreview: true })}
          isDisposableOnOutsideClick={false}
          headerComponent={
            <ModalHeader
              title={'Kaizen Formu'}
              toggle={() => setIsCreateKaizenFormOpen({ show: false, isPreview: false })}
            />
          }
          formComponent={
            <CreateKaizenForm
              isList
              onSuccess={() => {
                setIsCreateKaizenFormOpen({ show: false, isPreview: false });
                suggestionefetch();
              }}
              isReview={isCreateKaizenFormOpen.isPreview}
              selectedSuggestion={{ ...selectedSuggestion, kaizenid: selectedSuggestion?.kaizenid }}
            />
          }
        />
      )}
    </ModuleTemplate>
  );
}
