import SvgLine from './SvgLine';

const SvgAppreciationLine = (props: any) => (
  <>
    <div className=" appreciation-line">
      <div>
        <SvgLine width={props.width} />
      </div>
    </div>
  </>
);
export default SvgAppreciationLine;
