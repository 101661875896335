import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Games {
  isOpenModal: boolean;
  joinGameResponse:
    | {
        questions?: any;
      }
    | undefined;
  selectedGame: {
    contentid: number;
    title: string;
    description: string;
    category: { title: string };
    enddate: string;
    played: boolean;
  };
  isOpenAnswersTab: boolean;
  answers: {
    questions: any[];
  };
  isButtonDisabled: boolean;
}

const initialState: Games = {
  isOpenModal: false,
  joinGameResponse: undefined,
  selectedGame: {
    contentid: 0,
    title: '',
    description: '',
    category: { title: '' },
    enddate: '',
    played: false,
  },
  isOpenAnswersTab: false,
  answers: {
    questions: [],
  },
  isButtonDisabled: false,
};

const gamesSlice = createSlice({
  name: 'games',
  initialState,
  reducers: {
    setGamesModal: (state: any) => {
      state.isOpenModal = !state.isOpenModal;
    },
    setIsButtonDisabledSlice: (state: any, action: PayloadAction<boolean>) => {
      state.isButtonDisabled = action.payload;
    },
    setGame: (state: any, action: PayloadAction<any>) => {
      if (action.payload != null) {
        state.selectedGame = action.payload;
      }
    },
    setJoinGameResponse: (state: any, action: PayloadAction<any>) => {
      state.joinGameResponse = action.payload;
    },
    setAnswersModal: (state: any, action: PayloadAction<any>) => {
      if (action.payload != null) {
        state.isOpenAnswersTab = action.payload;
      }
    },
    setAnswers: (state: any, action: PayloadAction<any>) => {
      if (action.payload != null) {
        state.answers = action.payload;
      }
    },
  },
});

export const { setGamesModal, setGame, setJoinGameResponse, setAnswersModal, setAnswers, setIsButtonDisabledSlice } =
  gamesSlice.actions;

export const gamesSelector = (state: any) => state;

export default gamesSlice.reducer;
