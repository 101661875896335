/* eslint-disable react-hooks/exhaustive-deps */
import { FC, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import {
  PaginationState,
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  stringifyRequestQuery
} from "../../../../../_metronic/helpers";
import { useQueryRequest } from "./QueryRequestProvider";

const QueryResponseContext = createResponseContext<any>(initialQueryResponse);
type WithChildren2 = {
  children?: ReactNode;
  apiRequest: Function;
  uniqueKey?: any;
  id?: any;
  requestType?: string
};
const QueryResponseProvider: FC<WithChildren2> = (props) => {
  const { state } = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);
  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${props.uniqueKey}-${query}` ?? `request-${query}`,
    () => { 
      if(props.requestType == "extra" && query.indexOf("start_date") == -1)
        return [];
      return props.id ? props.apiRequest!(props.id,query) : props.apiRequest!(query);
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  );

  return (
    <QueryResponseContext.Provider
      value={{ isLoading: isFetching, refetch, response, query }}
    >
      {props.children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
  const { response } = useQueryResponse();
  if (response === undefined || response === null) {
    return [];
  }

  return response?.data || [];
};

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  };

  const { response } = useQueryResponse();
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState;
  }

  return response.payload.pagination;
};

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData, useQueryResponseLoading, useQueryResponsePagination
};

