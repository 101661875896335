import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import './_confirmMessage.scss';

const MySwal = withReactContent(Swal);

export const confirmMessage = (
  title: string,
  text: string,
  okMethod: Function,
  cancelMethod?: Function,
  confirmButtonText?: string,
  cancelButtonText?: string,
  customComponent?: any,
  confirmButtonColor?: string,
) => {
  MySwal.fire({
    html: (
      <div>
        <div className="swal2-header">
          <div className="mx-5 mt-1 mb-3 text-gray-900 fs-4 fw-bold  ">{title}</div>
          <hr />
          <p className="text-gray-800 fs-5 fw-medium mx-5 mt-5">{text}</p>
          {customComponent && customComponent}
        </div>
      </div>
    ),
    showCancelButton: true,
    confirmButtonColor: confirmButtonColor ?? '#7239EA',
    cancelButtonColor: '#F1F1F2',
    confirmButtonText: confirmButtonText ?? 'Onay',
    cancelButtonText: cancelButtonText ?? 'İptal',
    reverseButtons: true,
    customClass: {
      confirmButton: 'fw-bold',
      cancelButton: 'fw-bold',
      actions: 'swal2-actions',
      htmlContainer: 'swal2-html-container',
      popup: 'swal2-content',
    },
  }).then((result) => {
    if (result.isConfirmed) {
      okMethod();
    } else if (result.isDismissed) {
      if (cancelMethod) {
        cancelMethod();
      }
    }
  });
};

export const okayMessage = (title: string, text?: string, okMethod?: Function, okText?: string) => {
  MySwal.fire({
    html: (
      <div>
        <div className="swal2-header">
          <div className="mx-5 mt-1 mb-3 text-gray-900 fs-4 fw-bold  ">{title}</div>
          <hr />
          <p className="text-gray-800 fs-5 fw-medium mx-5 mt-5">{text}</p>
        </div>
      </div>
    ),
    confirmButtonColor: '#7239EA',
    confirmButtonText: okText ?? 'Tamam',
    customClass: {
      confirmButton: 'fw-bold',
      cancelButton: 'fw-bold',
      actions: 'swal2-actions',
      popup: 'swal2-okay-content',
    },
  }).then((result) => {
    if (result.isConfirmed) {
      okMethod && okMethod();
    }
  });
};

export default confirmMessage;
