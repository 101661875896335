import React, { FC } from 'react';

type Props = {
  borderColor?: string;
  margin?: string;
  borderWidth?: number;
};

const DashedLine: FC<Props> = ({ borderColor, margin, borderWidth }) => {
  return (
    <div
      className={`${borderColor ? borderColor : 'border-gray-300'} border-bottom-dashed border-bottom-${borderWidth}  ${
        margin ?? 'my-8'
      }`}
    />
  );
};

export default DashedLine;
