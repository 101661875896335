import React from 'react';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material';
import { KTIcon } from '../../../../../../_metronic/helpers';
import zIndex from '@mui/material/styles/zIndex';

export  function WaitSuggestionStepper() {
  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 8,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
      zIndex: 1,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#A1A5B7',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#784af4',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 6,
      borderRadius: 10,
      zIndex:-1
    },
  }));

  const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#F6C000',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#784af4',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 38,
      height: 38,
      backgroundColor: 'currentColor',
      borderRadius: 24,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      zIndex: 23,
    },
  }));

  function QontoStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;
    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <div className="QontoStepIcon-circle" style={{ backgroundColor: '#F6C000' }}>
            <KTIcon iconName="check-circle" className="fs-2x text-white" iconOverride="text-white" />
          </div>
        ) : (
          <div className="QontoStepIcon-circle">
            <KTIcon iconName="check-circle" className="fs-2x text-white" iconOverride="text-white" />
          </div>
        )}
      </QontoStepIconRoot>
    );
  }

  const steps = ['Öneri Verildi', 'Çözüm Önerisi', 'Kaizen Durumu'];

  return (
    <Stack sx={{ width: '100%' }} spacing={4}>
      <Stepper alternativeLabel  connector={<QontoConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
