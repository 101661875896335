import { ICreateFeedback } from '../../../app/modules/apps/feedback_module/core/_modals';
import api from '../../api';

export const homeApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAnnouncements: builder.query<any, void>({
      query: (type) => {
        return {
          url: `/feed?page=1&pagesize=10&posttypeid=1`
        };
      },
    }),
    getEvents: builder.query<any, string>({
      query: (date) => {
        return {
          url: `/events?page=1&pagesize=10&startdate=${date}&sortby=startdate&sortorder=ASC`,
        };
      },
    }),
    getApproveRejectList: builder.query<any, void>({
      query: () => {
        return {
          url: 'feed/inactives/my',
        };
      },
    }),
    getSurveys: builder.query<any, any>({
      query: ({ query }) => {
        return {
          url: `/surveys/user?${query}`,
        };
      },
      providesTags: ['Survey'],
    }),
    getLinks: builder.query<any, void>({
      query: () => {
        return {
          url: '/quickaccess',
        };
      },
    }),
    getSuggestion: builder.query<any, any>({
      query: ({ page }) => {
        return {
          url: `/suggestions?page=${page}&pagesize=10`
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => endpointName,
      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg?.page === 1) {
          currentCache.suggestions = newItems.suggestions;
        } else {
          currentCache?.suggestions?.push(...newItems.suggestions);
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: ['CombinedList'],
    }),
    getSuggestionType: builder.query<any, void>({
      query: () => {
        return {
          url: '/suggestions/types',
        };
      },
    }),
    getCombinedList: builder.query<any, any>({
      query: ({ page }) => {
        return {
          url: `/feed?promoted=0&page=${page}&pagesize=10`,
        };
      },
      //* Only have one cache entry because the arg always maps to one string
      serializeQueryArgs: ({ endpointName, queryArgs }) => endpointName,

      //* Always merge incoming data to the cache entry
      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg?.page === 1) {
          currentCache.feeds = newItems.feeds;
        } else {
          currentCache?.feeds?.push(...newItems.feeds);
        }
      },
      //* Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
      providesTags: ['CombinedList'],
    }),
    getModules: builder.query<any, void>({
      query: () => {
        return {
          url: '/company/moduleicons',
        };
      },
    }),
    createSuggestion: builder.mutation<any, any>({
      query: (requestBody) => {
        return {
          url: `/suggestions`,
          method: 'POST',
          body: requestBody,
        };
      },
    }),
    suggestionDetail: builder.query<any, string | number>({
      query: (id) => ({
        url: `/suggestions/${id}`,
        method: 'GET',
      }),
    }),
    getMoney: builder.query<any, void>({
      query: () => {
        return {
          url: '/market/info',
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetAnnouncementsQuery,
  useGetEventsQuery,
  useGetSurveysQuery,
  useGetLinksQuery,
  useGetCombinedListQuery,
  useGetApproveRejectListQuery,
  useGetModulesQuery,
  useGetSuggestionQuery,
  useGetSuggestionTypeQuery,
  useCreateSuggestionMutation,
  useSuggestionDetailQuery,
  useGetMoneyQuery,
} = homeApi;

export default homeApi;
