import React from 'react';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import { useIntl } from 'react-intl';

const MobileDownloadModal = () => {
  const intl = useIntl();
  return (
    <div className="d-flex flex-column justify-content-between">
      <div className="mb-8">
        <div className="d-flex justify-content-center">
          <img src={toAbsoluteUrl('/media/svg/circle-ming.svg')} className="mb-4" alt="" />
        </div>
        <div className="text-center">
          <h3 className="mb-2">{intl.formatMessage({ id: 'GENERAL.WELCOME_MING_APP' })}</h3>
          <span className="fs-5 text-gray-600">{intl.formatMessage({ id: 'GENERAL.DOWNLOAD_INFO_MESSAGE' })}</span>
        </div>
      </div>
      <button
        onClick={() => (window.location.href = 'https://mingapp.co/download')}
        className="btn text-white fw-bold"
        style={{ backgroundColor: '#804CB6' }}
      >
        {intl.formatMessage({ id: 'GENERAL.DOWNLOAD_APP' })}
      </button>
    </div>
  );
};

export default MobileDownloadModal;
