const MingMonyIcon = () => (
<svg width="20" height="20" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="25" cy="25" r="23" fill="url(#paint0_linear_1271_27114)" stroke="url(#paint1_linear_1271_27114)" strokeWidth="4"/>
<path d="M21.0648 27.9787C25.2502 27.8728 28.6805 29.285 28.7272 31.1316L28.789 33.5757C28.803 34.1275 28.5131 34.6566 27.9881 35.1297C27.039 35.983 25.3213 36.6547 23.2396 36.9509C22.6201 37.0394 21.9693 37.095 21.2959 37.112C17.1116 37.2179 13.6813 35.8057 13.6346 33.9591L13.5727 31.515C13.526 29.6684 16.8805 28.0846 21.0648 27.9787Z" fill="#FFA04A"/>
<path d="M15.1929 35.9234L15.1279 33.3562C15.1279 33.3562 15.6073 34.1403 17.3651 34.3009C19.1229 34.4615 19.7066 34.5248 19.7066 34.5248L19.7673 36.9245C19.7673 36.9245 16.9422 36.4678 16.8881 36.4584C16.834 36.449 15.1929 35.9234 15.1929 35.9234Z" fill="#FFCD37"/>
<path d="M27.9537 32.3743C27.9537 32.3743 27.9631 33.9088 27.9886 35.1299C27.0395 35.9833 25.3218 36.655 23.2401 36.9511C23.2126 35.8649 23.1631 33.9063 23.1631 33.9063L27.9537 32.3743Z" fill="#DD6726"/>
<path d="M21.235 34.6687C25.4198 34.5628 28.7745 32.9793 28.7277 31.1319C28.681 29.2845 25.2505 27.8727 21.0657 27.9786C16.8808 28.0846 13.5262 29.668 13.5729 31.5154C13.6197 33.3629 17.0501 34.7746 21.235 34.6687Z" fill="#FFCD37"/>
<path d="M20.8542 37.5506C18.9395 37.5507 17.1413 37.2413 15.749 36.6683C14.1338 36.0036 13.2293 35.0454 13.2021 33.9703L13.1403 31.5268C13.1131 30.4517 13.968 29.449 15.5475 28.7035C17.0158 28.0104 18.9715 27.5996 21.0542 27.5469C22.4807 27.5107 23.8825 27.648 25.108 27.9437C25.3244 27.996 25.5372 28.0536 25.7407 28.1152C25.8424 28.1459 25.9438 28.1782 26.0421 28.2113C27.9929 28.8666 29.1292 29.9273 29.1594 31.1215L29.2212 33.565C29.2484 34.6401 28.3935 35.6428 26.814 36.3884C25.3456 37.0815 23.39 37.4922 21.3072 37.5449C21.1557 37.5487 21.0045 37.5506 20.8542 37.5506ZM21.5021 28.4061C21.3604 28.4061 21.2185 28.4079 21.0761 28.4115C19.1109 28.4612 17.2786 28.8427 15.9166 29.4856C14.6839 30.0674 13.9871 30.8035 14.0049 31.505L14.0667 33.9484C14.0844 34.6499 14.8176 35.3498 16.0782 35.8686C17.4709 36.4418 19.3205 36.7299 21.2853 36.6803C23.2505 36.6306 25.0828 36.2492 26.4448 35.6063C27.6775 35.0244 28.3743 34.2884 28.3566 33.5869L28.2947 31.1434C28.2746 30.3457 27.3295 29.556 25.7666 29.0311C25.6766 29.0008 25.5838 28.9712 25.4905 28.943C25.3025 28.8862 25.1056 28.8328 24.905 28.7844C23.8736 28.5356 22.705 28.4061 21.5021 28.4061Z" fill="#32356B"/>
<path d="M17.8187 34.4731C16.245 34.2337 13.4887 33.3782 13.4434 31.4486C13.4512 30.4126 14.3094 29.6292 15.1301 29.1353C18.2178 27.3099 23.5775 27.1772 26.8231 28.6624C28.1676 29.2754 29.5992 30.5554 28.6854 32.1291C27.9571 33.2942 26.4024 33.8948 25.1274 34.2296C24.8313 34.3041 24.5331 34.3672 24.2337 34.4208C24.1833 34.4298 24.1352 34.3963 24.1261 34.3459C24.1175 34.2978 24.1477 34.2517 24.1943 34.2398C24.7738 34.0918 25.3455 33.9089 25.897 33.6801C26.9806 33.2309 28.8571 32.1866 28.4122 30.7835C28.3311 30.5337 28.1772 30.31 27.9947 30.1176C27.6074 29.7109 27.0978 29.4215 26.5827 29.1895C23.9159 28.0512 20.2287 28.0658 17.4529 28.8269C16.3034 29.154 14.859 29.7117 14.1839 30.7329C13.8928 31.1822 13.8642 31.7211 14.1357 32.1951C14.7139 33.1617 15.9519 33.6899 16.993 34.0432C17.2765 34.137 17.565 34.2175 17.8557 34.2915C17.9694 34.322 17.9334 34.489 17.8187 34.4731Z" fill="#32356B"/>
<path d="M21.213 33.7841C24.5647 33.6993 27.2539 32.5284 27.2195 31.1689C27.1851 29.8093 24.4401 28.776 21.0884 28.8608C17.7366 28.9456 15.0474 30.1165 15.0818 31.476C15.1162 32.8356 17.8612 33.8689 21.213 33.7841Z" fill="#FFA04A"/>
<path d="M21.087 28.8601C24.4392 28.7753 27.1839 29.8091 27.2183 31.1691C27.2215 31.2962 27.2008 31.4217 27.1582 31.5456C27.1582 31.5456 27.1582 31.5456 27.1583 31.5467C26.7406 32.7388 24.2488 33.7073 21.2116 33.7841C18.5351 33.8518 16.2448 33.2065 15.4141 32.2504C15.2048 32.01 15.0873 31.7499 15.0804 31.4762C15.046 30.1162 17.7359 28.9449 21.087 28.8601Z" fill="#DD6726"/>
<path d="M21.2627 29.6108C24.2184 29.536 26.6908 30.3768 27.1584 31.5454C27.1584 31.5454 27.1584 31.5454 27.1584 31.5464C26.7407 32.7385 24.2489 33.707 21.2117 33.7839C18.5352 33.8516 16.245 33.2063 15.4142 32.2501C15.4129 32.2404 15.4127 32.2317 15.4124 32.2219C15.378 30.8619 17.9974 29.6934 21.2627 29.6108Z" fill="#FFA04A"/>
<path d="M24.3733 31.4843L23.8168 31.4984C23.8688 31.754 23.7752 31.9521 23.527 32.0813C23.2705 32.215 22.8489 32.2908 22.2686 32.3055L22.0406 32.3113L21.9236 31.5503L22.0805 31.5463C22.4643 31.5366 22.6398 31.4687 22.608 31.3461C22.5945 31.2943 22.5537 31.2577 22.4857 31.2357C22.4182 31.2139 22.3431 31.2042 22.26 31.2063C22.113 31.21 21.9698 31.2515 21.8282 31.3322L20.7764 31.9372C20.3469 32.1858 19.8675 32.3227 19.3592 32.3355C19.0844 32.3425 18.8513 32.3121 18.6627 32.2452C18.4775 32.1795 18.3406 32.0915 18.2513 31.9825C18.1646 31.8768 18.1254 31.7631 18.1317 31.6422L17.6797 31.6537L17.8272 31.3238L18.2475 31.3132C18.3756 31.1111 18.5892 30.9623 18.887 30.8602C19.176 30.7611 19.5301 30.7072 19.9554 30.6965L19.8694 31.2865L19.6374 31.2924C19.5351 31.295 19.4537 31.3109 19.3928 31.3404C19.3314 31.3701 19.2952 31.4087 19.2843 31.4565C19.2721 31.5101 19.2887 31.5503 19.3347 31.5764C19.3809 31.6028 19.4478 31.6149 19.535 31.6127C19.6597 31.6095 19.8534 31.5399 20.109 31.4074L21.0939 30.8952C21.2273 30.8257 21.3873 30.766 21.574 30.7155C21.758 30.6657 21.9682 30.6381 22.2066 30.632C22.5643 30.623 22.8666 30.6662 23.1198 30.7641C23.3818 30.8654 23.5712 31.0012 23.6857 31.1756L24.2093 31.1624L24.3733 31.4843Z" fill="#FFCD37"/>
<path d="M21.0648 25.261C25.2502 25.1551 28.6805 26.5672 28.7272 28.4138L28.789 30.8579C28.803 31.4097 28.5131 31.9388 27.9881 32.4119C27.039 33.2652 25.3213 33.937 23.2396 34.2331C22.6201 34.3216 21.9693 34.3772 21.2959 34.3942C17.1116 34.5001 13.6813 33.088 13.6346 31.2413L13.5727 28.7972C13.526 26.9506 16.8805 25.3668 21.0648 25.261Z" fill="#FFA04A"/>
<path d="M15.1929 33.2054L15.1279 30.6382C15.1279 30.6382 15.6073 31.4223 17.3651 31.5829C19.1229 31.7435 19.7066 31.8068 19.7066 31.8068L19.7673 34.2065C19.7673 34.2065 16.9422 33.7498 16.8881 33.7404C16.834 33.7309 15.1929 33.2054 15.1929 33.2054Z" fill="#FFCD37"/>
<path d="M27.9537 29.6562C27.9537 29.6562 27.9631 31.1908 27.9886 32.4119C27.0395 33.2652 25.3218 33.937 23.2401 34.2331C23.2126 33.1468 23.1631 31.1883 23.1631 31.1883L27.9537 29.6562Z" fill="#DD6726"/>
<path d="M21.235 31.9514C25.4198 31.8455 28.7745 30.262 28.7277 28.4146C28.681 26.5672 25.2505 25.1555 21.0657 25.2614C16.8808 25.3673 13.5262 26.9507 13.5729 28.7982C13.6197 30.6456 17.0501 32.0573 21.235 31.9514Z" fill="#FFCD37"/>
<path d="M20.8542 34.8323C18.9395 34.8323 17.1413 34.523 15.749 33.95C14.1338 33.2853 13.2293 32.3271 13.2021 31.252L13.1403 28.8085C13.1131 27.7334 13.968 26.7307 15.5475 25.9851C17.0158 25.292 18.9715 24.8813 21.0542 24.8286C22.481 24.7926 23.8826 24.9297 25.108 25.2254C25.3242 25.2776 25.5371 25.3353 25.7407 25.3968C25.8424 25.4275 25.9438 25.4599 26.042 25.4929C27.9929 26.1482 29.1292 27.209 29.1594 28.4032L29.2212 30.8467C29.2484 31.9218 28.3935 32.9245 26.814 33.67C25.3456 34.3631 23.39 34.7739 21.3072 34.8265C21.1557 34.8304 21.0045 34.8323 20.8542 34.8323ZM21.5022 25.6878C21.3605 25.6878 21.2185 25.6896 21.0761 25.6932C19.111 25.7429 17.2786 26.1243 15.9166 26.7672C14.6839 27.3491 13.9871 28.0851 14.0049 28.7866L14.0667 31.2301C14.0844 31.9316 14.8176 32.6315 16.0782 33.1502C17.4709 33.7234 19.3205 34.0117 21.2853 33.962C23.2505 33.9123 25.0828 33.5308 26.4448 32.8879C27.6775 32.3061 28.3743 31.57 28.3566 30.8685L28.2948 28.4251C28.2746 27.6274 27.3295 26.8377 25.7666 26.3127C25.6767 26.2825 25.5838 26.2529 25.4905 26.2246C25.3024 26.1678 25.1055 26.1145 24.9051 26.0661C23.8737 25.8172 22.7051 25.6878 21.5022 25.6878Z" fill="#32356B"/>
<path d="M17.8187 31.7553C16.245 31.5159 13.4887 30.6604 13.4434 28.7309C13.4512 27.6948 14.3094 26.9114 15.1301 26.4175C18.2178 24.5921 23.5775 24.4595 26.8231 25.9446C28.1676 26.5576 29.5992 27.8377 28.6854 29.4114C27.9571 30.5764 26.4024 31.177 25.1274 31.5118C24.8313 31.5863 24.5331 31.6495 24.2337 31.7031C24.1833 31.7121 24.1352 31.6785 24.1261 31.6281C24.1175 31.58 24.1477 31.534 24.1943 31.522C24.7738 31.3741 25.3455 31.1911 25.897 30.9624C26.9806 30.5131 28.8571 29.4688 28.4122 28.0657C28.3311 27.8159 28.1772 27.5923 27.9947 27.3998C27.6074 26.9931 27.0978 26.7037 26.5827 26.4717C23.9158 25.3334 20.2287 25.348 17.4529 26.1092C16.3034 26.4363 14.8591 26.9939 14.1839 28.0151C13.8928 28.4644 13.8642 29.0034 14.1357 29.4773C14.7139 30.4439 15.9519 30.9721 16.993 31.3254C17.2765 31.4193 17.565 31.4997 17.8557 31.5738C17.9694 31.6042 17.9334 31.7712 17.8187 31.7553Z" fill="#32356B"/>
<path d="M21.213 31.0658C24.5647 30.981 27.2539 29.8101 27.2195 28.4506C27.1851 27.0911 24.4401 26.0577 21.0884 26.1426C17.7366 26.2274 15.0474 27.3983 15.0818 28.7578C15.1162 30.1173 17.8612 31.1507 21.213 31.0658Z" fill="#FFA04A"/>
<path d="M21.087 26.1423C24.4392 26.0575 27.1839 27.0913 27.2183 28.4513C27.2215 28.5784 27.2008 28.7039 27.1582 28.8278C27.1582 28.8278 27.1582 28.8278 27.1583 28.8289C26.7406 30.021 24.2488 30.9895 21.2116 31.0663C18.5351 31.1341 16.2448 30.4887 15.4141 29.5326C15.2048 29.2922 15.0873 29.0322 15.0804 28.7584C15.046 27.3984 17.7359 26.2271 21.087 26.1423Z" fill="#DD6726"/>
<path d="M21.2627 26.8933C24.2184 26.8185 26.6908 27.6592 27.1584 28.8278C27.1584 28.8278 27.1584 28.8278 27.1584 28.8289C26.7407 30.021 24.2489 30.9895 21.2117 31.0663C18.5352 31.134 16.245 30.4887 15.4142 29.5326C15.4129 29.5228 15.4127 29.5141 15.4124 29.5043C15.378 28.1444 17.9974 26.9759 21.2627 26.8933Z" fill="#FFA04A"/>
<path d="M24.3733 28.7664L23.8168 28.7804C23.8688 29.036 23.7752 29.2341 23.527 29.3634C23.2705 29.497 22.8489 29.5728 22.2686 29.5875L22.0406 29.5933L21.9236 28.8323L22.0805 28.8283C22.4643 28.8186 22.6398 28.7507 22.608 28.6281C22.5945 28.5763 22.5537 28.5397 22.4857 28.5177C22.4182 28.4959 22.3431 28.4862 22.26 28.4883C22.113 28.492 21.9698 28.5335 21.8282 28.6142L20.7764 29.2192C20.3469 29.4678 19.8675 29.6047 19.3592 29.6175C19.0844 29.6245 18.8513 29.5941 18.6627 29.5272C18.4775 29.4615 18.3406 29.3735 18.2513 29.2645C18.1646 29.1588 18.1254 29.0451 18.1317 28.9243L17.6797 28.9357L17.8272 28.6058L18.2475 28.5952C18.3756 28.3931 18.5892 28.2443 18.887 28.1422C19.176 28.0431 19.5301 27.9892 19.9554 27.9785L19.8694 28.5685L19.6374 28.5744C19.5351 28.577 19.4537 28.5929 19.3928 28.6224C19.3314 28.6521 19.2952 28.6907 19.2843 28.7385C19.2721 28.7921 19.2887 28.8323 19.3347 28.8584C19.3809 28.8847 19.4478 28.8969 19.535 28.8947C19.6597 28.8915 19.8534 28.8219 20.109 28.6894L21.0939 28.1772C21.2273 28.1077 21.3873 28.048 21.574 27.9974C21.758 27.9477 21.9682 27.9201 22.2066 27.914C22.5643 27.905 22.8666 27.9482 23.1198 28.0461C23.3818 28.1474 23.5712 28.2832 23.6857 28.4576L24.2093 28.4443L24.3733 28.7664Z" fill="#FFCD37"/>
<path d="M21.0648 22.3257C25.2502 22.2198 28.6805 23.6319 28.7272 25.4785L28.789 27.9226C28.803 28.4744 28.5131 29.0035 27.9881 29.4766C27.039 30.3299 25.3213 31.0017 23.2396 31.2978C22.6201 31.3863 21.9693 31.4419 21.2959 31.4589C17.1116 31.5648 13.6813 30.1527 13.6346 28.306L13.5727 25.8619C13.526 24.0153 16.8805 22.4315 21.0648 22.3257Z" fill="#FFA04A"/>
<path d="M15.1929 30.27L15.1279 27.7029C15.1279 27.7029 15.6073 28.487 17.3651 28.6476C19.1229 28.8082 19.7066 28.8715 19.7066 28.8715L19.7673 31.2712C19.7673 31.2712 16.9422 30.8145 16.8881 30.805C16.834 30.7956 15.1929 30.27 15.1929 30.27Z" fill="#FFCD37"/>
<path d="M27.9537 26.7209C27.9537 26.7209 27.9631 28.2555 27.9886 29.4766C27.0395 30.3299 25.3218 31.0017 23.2401 31.2978C23.2126 30.2115 23.1631 28.253 23.1631 28.253L27.9537 26.7209Z" fill="#DD6726"/>
<path d="M21.235 29.0159C25.4198 28.91 28.7745 27.3265 28.7277 25.4791C28.681 23.6317 25.2505 22.2199 21.0657 22.3258C16.8808 22.4317 13.5262 24.0152 13.5729 25.8626C13.6197 27.71 17.0501 29.1218 21.235 29.0159Z" fill="#FFCD37"/>
<path d="M20.8542 31.897C18.9395 31.8971 17.1413 31.5877 15.749 31.0147C14.1338 30.35 13.2293 29.3918 13.2021 28.3167L13.1403 25.8732C13.1131 24.7981 13.968 23.7954 15.5475 23.0499C17.0158 22.3568 18.9715 21.946 21.0542 21.8934C22.481 21.8572 23.8827 21.9945 25.108 22.2902C25.3242 22.3423 25.5371 22.4 25.7407 22.4616C25.8424 22.4923 25.9438 22.5246 26.042 22.5576C27.9929 23.213 29.1292 24.2738 29.1594 25.468L29.2212 27.9114C29.2484 28.9865 28.3935 29.9892 26.814 30.7348C25.3456 31.4279 23.39 31.8386 21.3072 31.8913C21.1557 31.8951 21.0045 31.897 20.8542 31.897ZM21.5019 22.7525C21.3603 22.7525 21.2184 22.7543 21.0761 22.7579C19.1109 22.8076 17.2786 23.1891 15.9166 23.832C14.6839 24.4138 13.9871 25.1499 14.0049 25.8514L14.0667 28.2948C14.0844 28.9963 14.8176 29.6962 16.0782 30.215C17.4709 30.7882 19.3205 31.0764 21.2853 31.0268C23.2505 30.977 25.0828 30.5956 26.4448 29.9527C27.6775 29.3709 28.3743 28.6348 28.3566 27.9333L28.2947 25.4899C28.2746 24.6921 27.3295 23.9024 25.7666 23.3775C25.6767 23.3472 25.5838 23.3176 25.4905 23.2894C25.3024 23.2326 25.1055 23.1792 24.9051 23.1309C23.8736 22.882 22.7049 22.7525 21.5019 22.7525Z" fill="#32356B"/>
<path d="M17.8187 28.8205C16.245 28.5811 13.4887 27.7256 13.4434 25.796C13.4512 24.76 14.3094 23.9766 15.1301 23.4827C18.2178 21.6573 23.5775 21.5247 26.8231 23.0098C28.1676 23.6228 29.5992 24.9028 28.6854 26.4765C27.9571 27.6416 26.4024 28.2421 25.1274 28.577C24.8313 28.6515 24.5331 28.7147 24.2337 28.7682C24.1833 28.7772 24.1352 28.7437 24.1261 28.6933C24.1175 28.6452 24.1477 28.5991 24.1943 28.5872C24.7738 28.4392 25.3455 28.2563 25.897 28.0275C26.9806 27.5783 28.8571 26.534 28.4122 25.1309C28.3311 24.8811 28.1772 24.6574 27.9947 24.465C27.6074 24.0583 27.0978 23.7689 26.5827 23.5369C23.9158 22.3986 20.2287 22.4132 17.4529 23.1744C16.3034 23.5014 14.859 24.0591 14.1839 25.0803C13.8928 25.5296 13.8642 26.0685 14.1357 26.5425C14.7139 27.5091 15.9519 28.0373 16.993 28.3906C17.2765 28.4845 17.565 28.5649 17.8557 28.6389C17.9694 28.6694 17.9334 28.8364 17.8187 28.8205Z" fill="#32356B"/>
<path d="M21.213 28.1305C24.5647 28.0457 27.2539 26.8748 27.2195 25.5153C27.1851 24.1558 24.4401 23.1224 21.0884 23.2073C17.7366 23.2921 15.0474 24.463 15.0818 25.8225C15.1162 27.182 17.8612 28.2154 21.213 28.1305Z" fill="#FFA04A"/>
<path d="M21.087 23.2065C24.4392 23.1217 27.1839 24.1555 27.2183 25.5155C27.2215 25.6426 27.2008 25.7682 27.1582 25.8921C27.1582 25.8921 27.1582 25.8921 27.1583 25.8931C26.7406 27.0852 24.2488 28.0537 21.2116 28.1305C18.5351 28.1983 16.2448 27.5529 15.4141 26.5968C15.2048 26.3564 15.0873 26.0964 15.0804 25.8226C15.046 24.4626 17.7359 23.2913 21.087 23.2065Z" fill="#DD6726"/>
<path d="M21.2627 23.9575C24.2184 23.8827 26.6908 24.7234 27.1584 25.892C27.1584 25.892 27.1584 25.892 27.1584 25.8931C26.7407 27.0852 24.2489 28.0537 21.2117 28.1305C18.5352 28.1983 16.245 27.5529 15.4142 26.5968C15.4129 26.587 15.4127 26.5783 15.4124 26.5686C15.378 25.2086 17.9974 24.0401 21.2627 23.9575Z" fill="#FFA04A"/>
<path d="M24.3733 25.8308L23.8168 25.8449C23.8688 26.1005 23.7752 26.2985 23.527 26.4278C23.2705 26.5614 22.8489 26.6373 22.2686 26.652L22.0406 26.6577L21.9236 25.8967L22.0805 25.8928C22.4643 25.8831 22.6398 25.8152 22.608 25.6925C22.5945 25.6407 22.5537 25.6041 22.4857 25.5822C22.4182 25.5604 22.3431 25.5506 22.26 25.5527C22.113 25.5564 21.9698 25.5979 21.8282 25.6786L20.7764 26.2836C20.3469 26.5323 19.8675 26.6691 19.3592 26.682C19.0844 26.6889 18.8513 26.6585 18.6627 26.5916C18.4775 26.526 18.3406 26.4379 18.2513 26.329C18.1646 26.2232 18.1254 26.1095 18.1317 25.9887L17.6797 26.0001L17.8272 25.6702L18.2475 25.6596C18.3756 25.4576 18.5892 25.3088 18.887 25.2066C19.176 25.1075 19.5301 25.0537 19.9554 25.0429L19.8694 25.633L19.6374 25.6389C19.5351 25.6414 19.4537 25.6574 19.3928 25.6868C19.3314 25.7165 19.2952 25.7551 19.2843 25.803C19.2721 25.8566 19.2887 25.8967 19.3347 25.9228C19.3809 25.9492 19.4478 25.9613 19.535 25.9591C19.6597 25.956 19.8534 25.8863 20.109 25.7539L21.0939 25.2416C21.2273 25.1721 21.3873 25.1124 21.574 25.0619C21.758 25.0121 21.9682 24.9845 22.2066 24.9785C22.5643 24.9694 22.8666 25.0126 23.1198 25.1106C23.3818 25.2119 23.5712 25.3476 23.6857 25.522L24.2093 25.5088L24.3733 25.8308Z" fill="#FFCD37"/>
<path d="M38.5636 25.1545C40.301 28.8296 39.2056 32.9941 36.1163 34.4545L34.1071 35.4044C33.2513 35.809 32.333 35.963 31.4149 35.8974C30.1696 35.8091 28.9248 35.3143 27.8403 34.4869C27.2884 34.065 26.7765 33.5576 26.3286 32.9732C26.3034 32.9396 26.2786 32.907 26.2539 32.8744C26.2337 32.8465 26.214 32.8197 26.1943 32.7928C25.8823 32.3648 25.6037 31.8965 25.366 31.3938C23.6286 27.7187 24.7249 23.5538 27.8142 22.0933L29.8234 21.1434C32.9127 19.6829 36.8262 21.4795 38.5636 25.1545Z" fill="#FFA04A"/>
<path d="M33.4482 34.9492C33.3138 34.9858 32.2332 35.5026 31.4149 35.8976C30.1697 35.8092 28.9249 35.3144 27.8404 34.4871C27.2846 34.0226 26.7428 33.5037 26.3287 32.9734C26.3034 32.9398 26.2787 32.9072 26.2539 32.8745C26.2338 32.8467 26.214 32.8198 26.1943 32.793L28.1836 31.8525C28.1836 31.8525 29.7376 33.6731 31.0657 34.1565C32.3938 34.6398 33.6676 34.8898 33.4482 34.9492Z" fill="#DD6726"/>
<path d="M25.0765 24.6233L27.4238 23.5137C27.4238 23.5137 26.6906 24.4623 26.5791 25.6958C26.4677 26.9293 27.0238 29.5885 27.0238 29.5885L25.0311 30.5305C25.0311 30.5305 24.5657 27.8601 24.553 27.3641C24.5402 26.8681 25.0765 24.6233 25.0765 24.6233Z" fill="#FFCD37"/>
<path d="M36.1165 34.4553C39.2062 32.9947 40.3024 28.8309 38.5648 25.1552C36.8271 21.4796 32.9138 19.6839 29.824 21.1446C26.7342 22.6052 25.6381 26.769 27.3757 30.4447C29.1133 34.1203 33.0267 35.916 36.1165 34.4553Z" fill="#FFCD37"/>
<path d="M35.5756 33.3105C38.033 32.1488 38.8589 28.7399 37.4202 25.6966C35.9815 22.6532 32.823 21.1279 30.3656 22.2896C27.9082 23.4513 27.0823 26.8602 28.521 29.9035C29.9597 32.9469 33.1181 34.4722 35.5756 33.3105Z" fill="#FFA04A"/>
<path d="M38.0455 29.7541C38.09 28.6778 37.8666 27.5262 37.3457 26.4243C35.9091 23.3856 32.745 21.8581 30.2921 23.0177C28.9307 23.6614 28.0699 24.9986 27.8193 26.5797C27.8946 24.687 28.7983 23.0282 30.3639 22.2881C32.8168 21.1285 35.9814 22.6569 37.4179 25.6956C38.0593 27.0523 38.2501 28.4831 38.0455 29.7541Z" fill="#DD6726"/>
<path d="M31.8319 36.3434C30.9085 36.3434 29.9617 36.1292 29.0392 35.7014C27.2919 34.8914 25.8394 33.4082 24.949 31.525C24.0587 29.6417 23.8344 27.5779 24.3173 25.7135C24.8076 23.8206 25.9656 22.3583 27.5778 21.5961L29.5868 20.6464C30.7469 20.098 32.058 19.9595 33.3781 20.2462C34.6476 20.5218 35.867 21.1754 36.9044 22.1363C37.0845 22.3031 37.2604 22.4807 37.4272 22.6641C37.5958 22.8495 37.7589 23.045 37.9119 23.2452C37.9892 23.3463 38.0656 23.4505 38.1386 23.555C38.2866 23.7663 38.4276 23.9873 38.558 24.2118C38.6903 24.4397 38.8148 24.6768 38.9281 24.9163C39.7001 26.5495 39.9756 28.3697 39.7039 30.0416C39.3464 32.2405 38.1055 33.9913 36.2993 34.8452C36.2981 34.8457 36.297 34.8462 36.2959 34.8468L34.2904 35.7949C33.5165 36.1607 32.6842 36.3433 31.8319 36.3434ZM32.0311 20.9725C31.31 20.9725 30.605 21.1216 29.9564 21.4283L27.9475 22.3779C25.0785 23.7343 24.0841 27.6718 25.7309 31.1553C27.3777 34.6388 31.0516 36.3694 33.9207 35.013L35.9294 34.0634C35.9304 34.0629 35.9314 34.0625 35.9325 34.062C37.476 33.3311 38.5393 31.8153 38.8502 29.9029C39.0942 28.402 38.8442 26.7624 38.1462 25.286C38.0433 25.0683 37.9302 24.853 37.81 24.646C37.6919 24.4426 37.5641 24.2423 37.43 24.0508C37.3639 23.9563 37.2949 23.8621 37.225 23.7706C37.0867 23.5897 36.9395 23.4132 36.7873 23.2459C36.6372 23.0808 36.4788 22.9209 36.3167 22.7708C35.0484 21.596 33.5058 20.9725 32.0311 20.9725Z" fill="#32356B"/>
<path d="M26.5417 28.016C26.0168 25.2599 27.1132 22.102 29.7766 20.8724C33.1097 19.3146 36.8713 21.3878 38.5349 24.4035C39.8332 26.6767 40.1385 29.5907 38.9601 31.9772C37.618 34.7048 34.5641 35.8623 31.74 34.7687C30.623 34.3509 29.6313 33.6342 28.837 32.7515C28.7596 32.6605 28.8862 32.5425 28.971 32.6235C30.0475 33.6914 31.451 34.4871 32.9597 34.6929C35.2433 35.0435 37.4792 33.8141 38.4395 31.723C39.085 30.374 39.2142 28.8107 38.9427 27.3478C38.5966 25.5098 37.623 23.7845 36.2033 22.5633C33.4631 20.2074 29.5051 20.2869 27.5665 23.5769C26.9985 24.5567 26.7128 25.704 26.6801 26.8393C26.6669 27.2223 26.684 27.6072 26.7253 27.9903C26.7374 28.1068 26.564 28.1331 26.5417 28.016Z" fill="#32356B"/>
<path d="M13.4346 16.656C11.6972 20.3311 12.7925 24.4955 15.8818 25.956L17.8911 26.9059C18.7469 27.3105 19.6651 27.4644 20.5833 27.3989C21.8285 27.3105 23.0733 26.8157 24.1578 25.9884C24.7098 25.5665 25.2216 25.0591 25.6695 24.4747C25.6947 24.4411 25.7195 24.4085 25.7443 24.3758C25.7644 24.348 25.7841 24.3211 25.8038 24.2943C26.1159 23.8663 26.3945 23.398 26.6322 22.8952C28.3695 19.2202 27.2732 15.0552 24.184 13.5948L22.1747 12.6449C19.0854 11.1844 15.172 12.9809 13.4346 16.656Z" fill="#FFA04A"/>
<path d="M18.5511 26.4504C18.6855 26.4871 19.7662 27.0038 20.5845 27.3988C21.8297 27.3104 23.0745 26.8157 24.159 25.9883C24.7148 25.5238 25.2566 25.0049 25.6707 24.4746C25.6959 24.441 25.7207 24.4084 25.7455 24.3757C25.7656 24.3479 25.7853 24.321 25.805 24.2942L23.8158 23.3538C23.8158 23.3538 22.2618 25.1743 20.9336 25.6577C19.6055 26.1411 18.3317 26.391 18.5511 26.4504Z" fill="#DD6726"/>
<path d="M26.9215 16.1248L24.5742 15.0151C24.5742 15.0151 25.3075 15.9638 25.4189 17.1972C25.5304 18.4307 24.9743 21.0899 24.9743 21.0899L26.967 22.032C26.967 22.032 27.4323 19.3615 27.4451 18.8655C27.4578 18.3695 26.9215 16.1248 26.9215 16.1248Z" fill="#FFCD37"/>
<path d="M24.6243 21.9446C26.3619 18.269 25.2658 14.1052 22.176 12.6445C19.0863 11.1839 15.1729 12.9795 13.4353 16.6552C11.6977 20.3308 12.7938 24.4946 15.8836 25.9553C18.9733 27.4159 22.8867 25.6203 24.6243 21.9446Z" fill="#FFCD37"/>
<path d="M23.4798 21.4035C24.9184 18.3602 24.0926 14.9513 21.6352 13.7896C19.1777 12.6279 16.0193 14.1532 14.5806 17.1966C13.1419 20.2399 13.9677 23.6488 16.4252 24.8105C18.8826 25.9722 22.0411 24.4469 23.4798 21.4035Z" fill="#FFA04A"/>
<path d="M13.9525 21.2555C13.908 20.1792 14.1314 19.0277 14.6523 17.9257C16.0889 14.887 19.253 13.3596 21.7059 14.5192C23.0673 15.1628 23.9281 16.5001 24.1787 18.0812C24.1034 16.1884 23.1997 14.5297 21.6341 13.7896C19.1812 12.6299 16.0166 14.1583 14.5801 17.197C13.9387 18.5537 13.7479 19.9845 13.9525 21.2555Z" fill="#DD6726"/>
<path d="M20.1653 27.8453C19.3129 27.8453 18.4809 27.6627 17.7069 27.2968L15.7012 26.3486C15.6998 26.348 15.6985 26.3473 15.6972 26.3467C13.8915 25.4926 12.6508 23.742 12.2934 21.5436C12.0216 19.8716 12.2971 18.0514 13.0692 16.4182C13.1823 16.179 13.3068 15.9419 13.4393 15.7137C13.4394 15.7137 13.4394 15.7137 13.4394 15.7136C13.57 15.4887 13.7111 15.2678 13.8587 15.0569C13.9318 14.9524 14.0081 14.8481 14.0854 14.747C14.2387 14.5466 14.4018 14.3511 14.5702 14.166C14.7367 13.9828 14.9126 13.8052 15.0929 13.6382C16.1303 12.6774 17.3497 12.0238 18.6192 11.7481C19.9391 11.4615 21.2504 11.5998 22.4106 12.1483L24.4194 13.098C26.0317 13.8602 27.1897 15.3225 27.68 17.2154C28.1629 19.0798 27.9385 21.1437 27.0482 23.0269C26.1579 24.9101 24.7054 26.3934 22.9581 27.2034C22.0357 27.631 21.0887 27.8453 20.1653 27.8453ZM16.068 25.5654L18.0765 26.515C20.9455 27.8713 24.6195 26.1408 26.2664 22.6573C27.9132 19.1738 26.9189 15.2363 24.0498 13.8799L22.0409 12.9302C20.0658 11.9964 17.5693 12.5234 15.6806 14.2727C15.5184 14.4229 15.3601 14.5828 15.21 14.7478C15.0581 14.9149 14.9108 15.0914 14.7724 15.2725C14.7024 15.3639 14.6334 15.4582 14.5673 15.5527C14.4335 15.7438 14.3056 15.944 14.1872 16.148C14.0669 16.3552 13.9538 16.5704 13.8511 16.7878C13.1531 18.2643 12.9031 19.9039 13.1471 21.4048C13.4579 23.3173 14.5214 24.833 16.0649 25.5639C16.0659 25.5644 16.0669 25.5649 16.068 25.5654L16.068 25.5654Z" fill="#32356B"/>
<path d="M25.2728 19.4911C25.532 17.2637 24.8215 14.7743 22.9576 13.4159C22.0663 12.7524 20.9555 12.4087 19.8465 12.4324C18.3539 12.4559 16.9215 13.1056 15.7947 14.0649C14.375 15.2862 13.4014 17.0114 13.0553 18.8494C12.7838 20.3123 12.913 21.8758 13.5585 23.2246C14.5186 25.3151 16.7549 26.5457 19.0383 26.1946C20.547 25.9887 21.9502 25.1931 23.0268 24.1254C23.1104 24.0421 23.2396 24.1642 23.1609 24.2532C22.3667 25.1359 21.375 25.8526 20.258 26.2704C17.4341 27.3634 14.3805 26.2073 13.0379 23.4788C11.8596 21.0922 12.1647 18.1785 13.4631 15.9052C14.5531 13.9733 16.42 12.4143 18.6239 11.9704C21.1131 11.4356 23.635 12.6085 24.8039 14.8731C25.3664 15.9417 25.6088 17.1448 25.5781 18.3407C25.5672 18.7364 25.5233 19.1298 25.4562 19.5177C25.4313 19.6354 25.2621 19.6088 25.2728 19.4911Z" fill="#32356B"/>
<g filter="url(#filter0_d_1271_27114)">
<path d="M14.9997 20.586L17.3027 16L18.2482 16.4094L18.7255 20.5561L18.2245 20.3392L21.9171 17.998L22.8555 18.4043L20.5666 22.9964L19.4941 22.532L21.0235 19.4694L21.2211 19.555L18.2771 21.4148L17.762 21.1918L17.3687 17.887L17.6086 17.9908L16.0721 21.0503L14.9997 20.586Z" fill="#FFCD37"/>
</g>
<g filter="url(#filter1_d_1271_27114)">
<path d="M36.8726 28.5884L34.5684 24L33.6224 24.4096L33.1449 28.5585L33.6461 28.3415L29.9516 25.999L29.0127 26.4055L31.3028 31L32.3758 30.5354L30.8456 27.4712L30.648 27.5568L33.5935 29.4176L34.1089 29.1945L34.5024 25.8879L34.2623 25.9919L35.7996 29.053L36.8726 28.5884Z" fill="#FFCD37"/>
</g>
<defs>
<filter id="filter0_d_1271_27114" x="15" y="16" width="7.85547" height="7.99634" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1271_27114"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1271_27114" result="shape"/>
</filter>
<filter id="filter1_d_1271_27114" x="29.0127" y="24" width="7.86035" height="8" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="1"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1271_27114"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1271_27114" result="shape"/>
</filter>
<linearGradient id="paint0_linear_1271_27114" x1="25" y1="0" x2="25" y2="50" gradientUnits="userSpaceOnUse">
<stop stopColor="#5B3FE1"/>
<stop offset="1" stopColor="#5135F9"/>
</linearGradient>
<linearGradient id="paint1_linear_1271_27114" x1="25" y1="0" x2="25" y2="50" gradientUnits="userSpaceOnUse">
<stop stopColor="#795DFF"/>
<stop offset="1" stopColor="#3317DB"/>
</linearGradient>
</defs>
</svg>
  );
  
  export default MingMonyIcon;  