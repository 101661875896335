import { createSlice } from '@reduxjs/toolkit';

interface EasyLink {
  isAddPostModalOpen: boolean;
  isAddFeedbackModalOpen: boolean;
  isAddSecondhandModalOpen: boolean;
  isAddNearMissModalOpen: boolean;
}

const initialState: EasyLink = {
  isAddPostModalOpen: false,
  isAddFeedbackModalOpen: false,
  isAddSecondhandModalOpen: false,
  isAddNearMissModalOpen: false,
};

const easyLinkSlice = createSlice({
  name: 'easyLinks',
  initialState,
  reducers: {
    toggleAddPostModal: (state: EasyLink) => {
      state.isAddPostModalOpen = !state.isAddPostModalOpen;
    },
    toggleAddSecondhandModal: (state: EasyLink) => {
      state.isAddSecondhandModalOpen = !state.isAddSecondhandModalOpen;
    },
    toggleAddFeedbackModal: (state: EasyLink) => {
      state.isAddFeedbackModalOpen = !state.isAddFeedbackModalOpen;
    },
    toggleAddNearMissModal: (state: EasyLink) => {
      state.isAddNearMissModalOpen = !state.isAddNearMissModalOpen;
    },
    resetEasyLinksStates: () => initialState,
  },
});

export const {
  toggleAddPostModal,
  toggleAddSecondhandModal,
  toggleAddFeedbackModal,
  resetEasyLinksStates,
  toggleAddNearMissModal,
} = easyLinkSlice.actions;

export const easyLinkSelector = (state: EasyLink) => state;

export default easyLinkSlice.reducer;
