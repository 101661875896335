import confirmMessage from '../Modal/ConfirmMessage';
import BulkDotsSquare from '../../svg/BulkDotsSquare';
import { KTIcon } from '../../../../../../_metronic/helpers';
import { FC, Fragment, useEffect, useRef, useState } from 'react';
import { useDeleteCommentMutation } from '../../../feed_module/services/feedEndpoints';
import { useIntl } from 'react-intl';
import { Alert, Snackbar } from '@mui/material';
type Props = {
  isOwner: boolean;
  commentId: number;
  refetch?: Function;
  isPostOwner: boolean;
  setCommentCount: Function;
  feedid:number;
};

const CommentDotsDropdown: FC<Props> = ({ isOwner, commentId, refetch, isPostOwner, setCommentCount,feedid }) => {
  const intl = useIntl();
  const [isOpen, setOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [deleteComment] = useDeleteCommentMutation();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleOutsideClick = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', handleOutsideClick);
    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleDelete = () => {
    confirmMessage(
      intl.formatMessage({ id: 'FEED_DELETE_COMMENT' }),
      intl.formatMessage({ id: 'FEED_DELETE_COMMENT_DESCRIPTION' }),

      async () => {
        await deleteComment({ comment_id: commentId,feed_id:feedid })
          .unwrap()
          .then(() => {
            setCommentCount((prev: number) => prev - 1);
            if (refetch) refetch();
          }).catch((err)=>{
            setErrorMessage(err?.data?.exMsg);
            setSnackbarOpen(true);
            })
        setOpen(false);
      },
      undefined,
      intl.formatMessage({ id: 'APPROVE_TEXT' }),
      intl.formatMessage({ id: 'CANCEL_TEXT' }),
      undefined,
      '#F1416C',
    );
  };

  const renderButton = (text: string, iconName: string, onClickFunction: any) => (
    <button
      onClick={onClickFunction}
      type="button"
      className="border-0 bg-white rounded-1 p-2 d-flex aling-items-center gap-4 mt-1"
    >
      <KTIcon iconName={iconName} className="fs-2" iconOverride="text-danger" />
      <span className="fw-bold text-danger">{text}</span>
    </button>
  );
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  return (
    <Fragment>
      {(isPostOwner || isOwner) && (
        <div className="position-relative">
          <BulkDotsSquare
            className="cursor-pointer"
            onClick={(e: any) => {
              setOpen(!isOpen);
            }}
          />
          <div
            ref={dropdownRef}
            className={`menu menu-sub menu-sub-dropdown w-200px w-md-250px m-2 px-4 py-2 ${
              isOpen ? 'show' : ''
            } position-absolute`}
            style={{
              right: 0,
            }}
          >
            {renderButton(intl.formatMessage({ id: 'FEED_DELETE_TEXT' }), 'trash', handleDelete)}
          </div>
        </div>
      )}
       <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default CommentDotsDropdown;
