import React, { FC } from 'react';
import { KTCard, toAbsoluteUrl } from '../../../../../_metronic/helpers';
import './_userCard.scss';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { authSelector } from '../../../../../api/features/auth/authSlice';
import { useAppSelector } from '../../../../../api/store/hooks';

const UserCard: FC = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const currentUser = useAppSelector((state) => authSelector(state.auth).currentUser);

  return (
    <KTCard className="user-card">
      <div
        className="mb-6"
        style={{
          borderRadius: 12,
          width: 120,
          height: 120,
        }}
      >
        <img
          src={currentUser?.pictureurl === '' || currentUser?.pictureurl == null ? toAbsoluteUrl('/media/avatars/blank.png') : currentUser?.pictureurl }
          alt="user"
          width={120}
          height={120}
          style={{ borderRadius: 12 }}
        />
      </div>
      <div className="text-center mb-8">
        <div className="user-fullname">{currentUser?.userdesc}</div>
        <div className="text-gray-500 user-position">{currentUser?.nodename}</div>
      </div>
      <button className="btn btn-info w-100 button-md" onClick={() => navigate(`/profile/${currentUser?.usersecret?.userid}`)}>
        {intl.formatMessage({ id: 'HOME.GO_TO_PROFILE' })}
      </button>
    </KTCard>
  );
};

export default UserCard;
