/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react';
import { SidebarMenuItem } from './SidebarMenuItem';
import { useIntl } from 'react-intl';
import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub';
import { authSelector } from '../../../../../api/features/auth/authSlice';
import { useAppSelector } from '../../../../../api/store/hooks';
import { useGetModulesQuery } from '../../../../../api/services/home/homeEndpoints';
import { setActiveModules } from '../../../../../api/features/module/moduleSlice';

const SidebarMenuMain = () => {
  const intl = useIntl();
  const auth = useAppSelector((state) => authSelector(state.auth));
  const { currentUser } = useAppSelector((state) => authSelector(state.auth));
  const { data: modulesList = [], isLoading: modulesLoading } = useGetModulesQuery();

  return (
    <div>
      {/* Modüllere göre sidebar öğelerini render et */}
      {modulesList !== undefined &&  modulesList?.modules !== undefined && modulesList?.modules.length > 0 ? (
        modulesList?.modules.map((module: any) => {
          switch (module.moduledesc) {
            case 'Anket':
              return (
                <SidebarMenuItem
                  key={module.moduleid}
                  to="/apps/surveys"
                  icon="question"
                  title={intl.formatMessage({ id: 'SURVEYS.TITLE' })}
                />
              );
            case 'Duyuru':
              return (
                <SidebarMenuItem
                  key={module.moduleid}
                  to="/apps/announcements"
                  icon="message-notif"
                  title={intl.formatMessage({ id: 'ANNOUNCEMENT_PAGE_TITLE' })}
                />
              );
            case 'Oyunlar':
              return (
                <SidebarMenuItem
                  key={module.moduleid}
                  to="/apps/games"
                  icon="calculator"
                  title={intl.formatMessage({ id: 'MENU.GAMES' })}
                />
              );
            case 'Yemek Menüsü':
              return (
                <SidebarMenuItem
                  key={module.moduleid}
                  to="/apps/food-menu"
                  icon="coffee"
                  title={intl.formatMessage({ id: 'FOOD_MENU_PAGE_TITLE' })}
                />
              );
            case 'Cheers' :
              return (
                <SidebarMenuItemWithSub
                  key={module.moduleid}
                  to="/crafted/pages"
                  title={intl.formatMessage({ id: 'CHEERS_PAGE_TITLE' })}
                  svgItem={
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M6.26613 11.2667L6.25781 17.4167C6.25781 18.1667 6.78282 18.5334 7.43282 18.225L9.66614 17.1667C9.84948 17.075 10.1578 17.075 10.3411 17.1667L12.5828 18.225C13.2245 18.525 13.7578 18.1667 13.7578 17.4167V11.1167"
                        fill="#D5C4F9"
                      />
                      <path
                        d="M9.625 13.8333C12.7316 13.8333 15.25 11.4082 15.25 8.41667C15.25 5.42512 12.7316 3 9.625 3C6.5184 3 4 5.42512 4 8.41667C4 11.4082 6.5184 13.8333 9.625 13.8333Z"
                        fill="#D5C4F9"
                        stroke="#7239EA"
                        stroke-width="1.25"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  }
                >
                  <SidebarMenuItem
                    to="apps/cheers/cheers"
                    title={intl.formatMessage({ id: 'CHEERS_LIST_PAGE_TITLE' })}
                    svgItem={
                      <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="4" height="4" rx="2" fill="#7E8299" />
                      </svg>
                    }
                  />
                  <SidebarMenuItem
                    to="apps/cheers/leadership"
                    title={intl.formatMessage({ id: 'CHEERS_LEADERSHIP_PAGE_TITLE' })}
                    svgItem={
                      <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="4" height="4" rx="2" fill="#7E8299" />
                      </svg>
                    }
                  />
                </SidebarMenuItemWithSub>
              );
            case 'Geri Bildirim':
              return (
                <SidebarMenuItem
                  key={module.moduleid}
                  to="/apps/feedback"
                  icon="sms"
                  title={intl.formatMessage({ id: 'FEEDBACK_PAGE_PAGE_TITLE' })}
                />
              );
            case 'Ramak Kala':
              return (
                <SidebarMenuItem
                  key={module.moduleid}
                  to="/apps/near-miss"
                  icon="information-3"
                  title={'Ramak Kala Bildirimi'}
                />
              );
            case 'Market':
              return (
                <SidebarMenuItem
                  key={module.moduleid}
                  to="/apps/market"
                  icon="basket"
                  title={intl.formatMessage({ id: 'MARKET_PAGE_TITLE' })}
                />
              );
            case 'Öneri':
              return (
                <SidebarMenuItem
                  key={module.moduleid}
                  to="/apps/suggestions"
                  icon="notepad-edit"
                  title={'Öneri'}
                />
              );
            case 'İlanlar':
              return (
                <SidebarMenuItem
                  key={module.moduleid}
                  to="/apps/secondhands"
                  icon="shop"
                  title={intl.formatMessage({ id: 'MENU.SECONDHAND' })}
                />
              );
            case 'Etkinlikler':
              return (
                <SidebarMenuItem
                  key={module.moduleid}
                  to="/apps/events"
                  icon="calendar"
                  title={intl.formatMessage({ id: 'MENU.EVENTS' })}
                />
              );
              case 'Doğum Günleri':
                return (
                  <SidebarMenuItem
                    key={module.moduleid}
                    to="/apps/birthdays"
                    icon="emoji-happy"
                    title={intl.formatMessage({ id: 'BIRTHDAYS_PAGE_TITLE' })}
                  />
                );
            case 'Bordro':
              return (
                <SidebarMenuItem
                  key={module.moduleid}
                  to="/apps/payroll"
                  icon="abstract-28"
                  title="Bordrom"
                />
              );
            case 'Arkadaş Davet':
              return (
                <SidebarMenuItem
                  key={module.moduleid}
                  to="/apps/invite-friends"
                  icon="like-2"
                  title={intl.formatMessage({ id: 'INVITE_FRIENDS_PAGE_TITLE' })}
                />
              );
            case 'Kişiler':
              return (
                <SidebarMenuItem
                  key={module.moduleid}
                  to="/apps/users"
                  icon="address-book"
                  title={intl.formatMessage({ id: 'USERS_PAGE_TITLE' })}
                />
              );
            case 'Hap Bilgiler':
              return (
                <SidebarMenuItem
                  key={module.moduleid}
                  to="/apps/informations"
                  icon="capsule"
                  title={intl.formatMessage({ id: 'PILL_INFORMATIONS_PAGE_TITLE' })}
                />
              );
            case 'Kudos':
              return (
                <SidebarMenuItem
                  key={module.moduleid}
                  to="/apps/leadership"
                  icon="ranking"
                  title={intl.formatMessage({ id: 'LEADERSHIP_PAGE_TITLE' })}
                />
              );
            case 'İş İlanları':
              return (
                <SidebarMenuItem
                  key={module.moduleid}
                  to="/apps/jobs"
                  icon="badge"
                  title={intl.formatMessage({ id: 'MENU.JOBS' })}
                />
              );
            default:
              return null;
          }
        })
      ) : (
        <div>No modules available</div>
      )}

    </div>
  );
};

export { SidebarMenuMain };
  function dispatch(arg0: any) {
    throw new Error('Function not implemented.');
  }

