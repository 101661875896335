import React, { FC } from 'react';
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers';
import SvgPill from '../../svg/SvgPill';

type Props = {
  image?: string;
  title: string;
  subtitle: string;
  rounded?: string;
  navigate?: any;
  imageComp?:any
  data?:any
};

const UserBadge: FC<Props> = ({ image, title, subtitle, rounded, navigate,imageComp,data }) => {
  return (
    <div onClick={data?.posttypedesc === 'Gönderi'? navigate:null} className="d-flex gap-5 align-items-center cursor-pointer">
      {imageComp != null && (imageComp)}
     {/* {(imageComp == null && <img
        src={image ?? toAbsoluteUrl(`/media/avatars/Default Avatar.svg`)}
        alt="user"
        width={50}
        height={50}
        className={`${rounded ?? 'rounded-2'}`}
      />)} */}
      {!["Hap Bilgi", "Kitap Önerisi", "Film Önerisi"].includes(data?.posttypedesc)&& (imageComp == null && <img
        src={image ?? toAbsoluteUrl(`/media/avatars/Default Avatar.svg`)}
        alt="user"
        width={50}
        height={50}
        className={`${rounded ?? 'rounded-2'}`}
      />)}
     {["Hap Bilgi", "Kitap Önerisi", "Film Önerisi"].includes(data?.posttypedesc) && <SvgPill/>}

      <div className="d-flex flex-column justify-content-between">
        <div className="fw-bold fs-4">{title|| data?.title }</div>
        <div className="fw-bold fs-6 text-gray-500">{subtitle}</div>
      </div>
    </div>
  );
};

export default UserBadge;
