import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useAppDispatch } from '../../../api/store/hooks'
import { logout } from '../../../api/features/auth/authSlice'

export function Logout() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(logout())
    // document.location.reload()
  }, [dispatch])

  return (
    <Navigate to='/auth/login' />
  )
}
