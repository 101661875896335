export const convertNumberToMonthString = (monthNumber: number) => {
  const monthMapper: any = {
    1: 'Ocak',
    2: 'Şubat',
    3: 'Mart',
    4: 'Nisan',
    5: 'Mayıs',
    6: 'Haziran',
    7: 'Temmuz',
    8: 'Ağustos',
    9: 'Eylül',
    10: 'Ekim',
    11: 'Kasım',
    12: 'Aralık',
  };
  return monthMapper[monthNumber];
};

export const convertMonthToNumberString = (monthNumber: number) => {
  const monthMapper: any = {
    Ocak: 1,
    Şubat: 2,
    Mart: 3,
    Nisan: 4,
    Mayıs: 5,
    Haziran: 6,
    Temmuz: 7,
    Ağustos: 8,
    Eylül: 9,
    Ekim: 10,
    Kasım: 11,
    Aralık: 12,
  };
  return monthMapper[monthNumber];
};
