import React from 'react';
import icons from '../icons-config/icons';
import { getLayoutFromLocalStorage } from '../../layout/core';

type Props = {
  className?: string;
  iconType?: 'duotone' | 'solid' | 'outline' | 'fa';
  iconName: string;
  iconOverride?: string;
};

const KTIcon: React.FC<Props> = ({ className = '', iconType, iconName, iconOverride = 'text-info' }) => {
  if (!iconType) {
    iconType = getLayoutFromLocalStorage().main?.iconType;
  }

  return iconType !== 'fa' ? (
    <i className={`ki-${iconType} ki-${iconName}${className && ' ' + className}`}>
      {iconType === 'duotone' &&
        [...Array(icons[iconName])].map((e, i) => {
          return (
            <span
              key={`${iconType}-${iconName}-${className}-path-${i + 1}`}
              className={`path${i + 1} ${iconOverride}`}
            ></span>
          );
        })}
    </i>
  ) : (
    <i className={`${iconName} ${className}`}></i>
  );
};

export { KTIcon };
