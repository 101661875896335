import { useIntl } from 'react-intl';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { useGetFeelingQuery, useSaveFeelingMutation } from '../../services/profileEndpoints';
import Loading from '../../../../components/Loading';

type Props = {
  closeModal?: () => void;
};

export default function SaveFeelingForm({ closeModal }: Props) {
  const intl = useIntl();
  const [selectedFeeling, setSelectedFeeling] = useState<any>(undefined);
  const [show, setShow] = useState<any>(true);
  const [saveFeeling, { isLoading: saveFeelingLoading }] = useSaveFeelingMutation();

  const { data, isLoading, refetch } = useGetFeelingQuery('');

  useEffect(() => {
    if (data != null && data.moodresponseid != null) {
      setShow(false);
    }
  }, [data]);

  const handleAddToLocalStorage = useCallback(async () => {
    await refetch()
      .unwrap()
      .then((_: any) => {
        setShow(false);
      })
      .catch((_: any) => {
        setShow(false);
      });
    localStorage.setItem('lastFeelingDate', new Date().toLocaleDateString());
  }, []);

  const feelings = useMemo(
    () => [
      {
        id: 5,
        title: intl.formatMessage({ id: 'FEELING_HAPPY' }),
        img: '/media/svg/user-feelings/Mutlu.svg',
      },
      {
        id: 4,
        title: intl.formatMessage({ id: 'FEELING_PLEASANT' }),
        img: '/media/svg/user-feelings/Keyifli.svg',
      },
      {
        id: 3,
        title: intl.formatMessage({ id: 'FEELING_NORMAL' }),
        img: '/media/svg/user-feelings/Normal.svg',
      },
      {
        id: 2,
        title: intl.formatMessage({ id: 'FEELING_SEEDY' }),
        img: '/media/svg/user-feelings/Keyifsiz.svg',
      },
      {
        id: 1,
        title: intl.formatMessage({ id: 'FEELING_SAD' }),
        img: '/media/svg/user-feelings/Mutsuz.svg',
      },
    ],
    [intl],
  );

  return isLoading===false ? ( show ? (
    <Fragment>
      {feelings.map((feeling, index) => (
        <div
          key={index}
          onClick={async () => {
            setSelectedFeeling(feeling);
            await saveFeeling({ feel_selected: feeling?.id })
              .then((_: any) => {
                handleAddToLocalStorage();
              })
              .catch((_: any) => {
                setShow(false);
              });
            closeModal && closeModal();
          }}
          className={`border ${
            selectedFeeling === feeling ? 'border-info bg-light-info' : 'border-gray-300 bg-white'
          } border-dashed mb-4 rounded-1 px-5 py-4 d-flex align-items-center justify-content-between cursor-pointer`}
        >
          <div className="d-flex align-items-center">
            <img
              src={toAbsoluteUrl(feeling.img)}
              alt={feeling.title}
              className="w-25px h-25px object-fit-cover rounded-circle me-5"
            />
            <div className="fs-5 fw-bold">{feeling.title}</div>
          </div>

          {saveFeelingLoading && selectedFeeling === feeling && (
            <span className="d-flex indicator-progress justify-content-end" style={{ display: 'block' }}>
              <span className="spinner-border align-middle text-info" role="status"></span>
            </span>
          )}
        </div>
      ))}
    </Fragment>
  ) : (
    <div></div>
  )
)
:
<Loading/>
}
