import {Outlet, Route, Routes} from "react-router-dom";
import CheersLeadershipList from "./CheersLeadershipList";

const CheersLeadershipPage = () => {
    return (
        <Routes>
            <Route element={<Outlet />} />
            <Route index element={<CheersLeadershipList />} />
        </Routes>
    );
};

export default CheersLeadershipPage;
